<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col cols="3" class="py-0 my-0">
                    <v-autocomplete class="py-0 mt-0" v-model="type" :items="customer_types" :label="$t('condensed_fulfillment_report.customer_type')" item-value="value" item-text="text"></v-autocomplete> 
                </v-col>

                <v-col cols="3" class="py-0 my-0">
                    <v-autocomplete class="py-0 mt-0" v-model="currentWeek" :items="weeks" :label="$t('condensed_fulfillment_report.week')" item-value="value" item-text="text"></v-autocomplete> 
                </v-col>

                <v-spacer></v-spacer> 
                
                <v-col cols="6" align="end" class="py-0">                    
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" @click="show_export_options = true" v-if="show_export_options == false">
                        {{ $t('condensed_fulfillment_report.export') }}
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataPdf">
                        PDF
                    </v-btn>
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataExcel">
                        Excel
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="show_export_options = false;">
                        <v-icon small class="px-0">
                            keyboard_arrow_left
                        </v-icon>
                    </v-btn>
                
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="generate">
                        {{ $t('condensed_fulfillment_report.generate') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('condensed_fulfillment_report.client'), align: "start", value: 'name', class: 'text-uppercase' },
                { text: this.$t('condensed_fulfillment_report.monday'), align: "center", value: '2', class: 'text-uppercase' },
                { text: this.$t('condensed_fulfillment_report.tuesday'), align: "center", value: '3', class: 'text-uppercase' },
                { text: this.$t('condensed_fulfillment_report.wednesday'), align: "center", value: '4', class: 'text-uppercase' },
                { text: this.$t('condensed_fulfillment_report.thursday'), align: "center", value: '5', class: 'text-uppercase' },
                { text: this.$t('condensed_fulfillment_report.friday'), align: "center", value: '6', class: 'text-uppercase' },
                { text: this.$t('condensed_fulfillment_report.total'), align: "center", value: 'total', class: 'text-uppercase' },
            ];
        },
        client: function(){
            return this.$store.state.clientId
        },
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            customer_types: [ 
                { value: "prepaid", text: this.$t("condensed_fulfillment_report.prepaid") },
                { value: "cod", text: this.$t("condensed_fulfillment_report.cod") }
             ],
            type: "prepaid",
            initial_date: '',
            final_date: '',
            showExport: false,
            show_export_options: false,
            anio: new Date().getFullYear(),
            currentWeek: '',
            weeks: [],
            months: [
                { month: 1, es: 'Enero', en: 'January', days: 31},
                { month: 2, es: 'Febrero', en: 'February', days: 28},
                { month: 3, es: 'Marzo', en: 'March', days: 31},
                { month: 4, es: 'Abril', en: 'April',days: 30},
                { month: 5, es: 'Mayo', en: 'May', days: 31},
                { month: 6, es: 'Junio', en: 'June', days: 30},
                { month: 7, es: 'Julio', en: 'July', days: 31},
                { month: 8, es: 'Agosto', en: 'August', days: 31},
                { month: 9, es: 'Septiembre', en: 'September', days: 30},
                { month: 10, es: 'Octubre', en: 'October', days: 31},
                { month: 11, es: 'Noviembre', en: 'November', days: 30},
                { month: 12, es: 'Diciembre', en: 'December', days: 31},
            ],


        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.condensed_fulfillment_report";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.getWeeksInMonth();
        },
        generate(){
            this.props.items =[];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'reports/condensedFulfillment?initial_date=' + this.initial_date + '&final_date=' + this.final_date + '&type=' + this.type, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach( x => {
                    this.props.items.push({
                        "name": x.name,
                        "2": x["2"] == null ? 0 : x["2"],
                        "3": x["3"] == null ? 0 : x["3"],
                        "4": x["4"] == null ? 0 : x["4"],
                        "5": x["5"] == null ? 0 : x["5"],
                        "6": x["6"] == null ? 0 : x["6"],
                        "total": x.total
                    })
                })
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        exportDataExcel(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'reports/condensedFulfillmentExport?initial_date=' + this.initial_date + '&final_date=' + this.final_date + '&type=' + this.type, {headers: {Authorization: lsToken}, responseType:'blob'})
            .then(response => {
                this.$store.state.loaderProps.visible = false; 
                console.log(response.data)
                FileSaver.saveAs(response.data, 'CondensadoFulfillment.xlsx');
                this.show_export_options = false;
            })
            .catch((error) => {
                let message = "";
                if(error.response.status == 404) message = this.$t("yego_reports.errors.not_found");
                else message = this.$t("yego_reports.errors.unexpected");
                this.$store.state.loaderProps.visible = false;
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: message
                };
            })
        },
        exportDataPdf(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'reports/condensedFulfillmentPDF?initial_date=' + this.initial_date + '&final_date=' + this.final_date + '&type=' + this.type, {headers: {Authorization: lsToken}, responseType:'blob'})
            .then(response => {
                this.$store.state.loaderProps.visible = false; 
                FileSaver.saveAs(response.data, 'CondensadoFulfillment.pdf');
                this.show_export_options = false;
            })
            .catch(() => {
                this.$store.state.loaderProps.visible = false;
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            })
        },
        getWeeksInMonth() {
            let year = this.anio;
            let currentMonth = 0;
            let count = 1;
            const d = new Date();
            let day = d.getDate();
            let month = d.getMonth();
            let totalDays = ((year % 4 === 0 && year % 100 > 0) || year % 400 == 0) ? 366 : 365;
            if(totalDays == 366) this.months[1].days = 29;

            for(let i = 1; i <= totalDays; i++){
                if(currentMonth > 11) break;
                if(count <= this.months[currentMonth].days){
                    let start = count;
                    if(count + 6 > this.months[currentMonth].days){
                        
                        if(currentMonth >= 11){
                            this.weeks.push(start + '/' + this.months[currentMonth][this.$store.state.language] + ' - ' + this.months[currentMonth].days + '/' + this.months[currentMonth][this.$store.state.language]);

                            if(month == currentMonth && day >= start) this.currentWeek = start + '/' + this.months[currentMonth][this.$store.state.language] + ' - ' + this.months[currentMonth].days + '/' + this.months[currentMonth][this.$store.state.language];
                            break;
                        }

                        count = (count + 6) - this.months[currentMonth].days;
                        let end = count;
                        currentMonth += 1;
                        this.weeks.push(start + '/' + this.months[currentMonth - 1][this.$store.state.language] + ' - ' + end + '/' + this.months[currentMonth][this.$store.state.language]);

                        if((month == currentMonth - 1 && day >= start)) this.currentWeek = start + '/' + this.months[currentMonth - 1][this.$store.state.language] + ' - ' + end + '/' + this.months[currentMonth][this.$store.state.language];
                        else if ((month == currentMonth && day <= end)) this.currentWeek = start + '/' + this.months[currentMonth - 1][this.$store.state.language] + ' - ' + end + '/' + this.months[currentMonth][this.$store.state.language];
                        count++;
                    }
                    else {
                        this.weeks.push(start + '/' + this.months[currentMonth][this.$store.state.language] + ' - ' + (count + 6) + '/' + this.months[currentMonth][this.$store.state.language]);
                        
                        if(month == currentMonth && day >= start && month == currentMonth && day <= (count + 6)) this.currentWeek = start + '/' + this.months[currentMonth][this.$store.state.language] + ' - ' + (count + 6) + '/' + this.months[currentMonth][this.$store.state.language];

                        count += 7;
                        if(count + 1 > this.months[currentMonth].days){
                            count = 1;
                            currentMonth += 1;
                        }
                    }
                    
                    i += 5;
                }
            }
        },
    },
    watch:{
        'currentWeek': function(){
            if(this.$store.state.language == 'es') this.monthsNames = [
                'Enero','Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ];
            else this.monthsNames = [
                'January','February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
            ];

            let dates = this.currentWeek.split('-');
            let month1 = this.monthsNames.indexOf(dates[0].split('/')[1].trim());
            let month2 = this.monthsNames.indexOf(dates[1].split('/')[1].trim());
            this.initial_date = this.anio + '-'+ (month1 + 1).toString().padStart(2, '0') + '-' + dates[0].split('/')[0].trim().padStart(2, '0');
            this.final_date = this.anio + '-' + (month2 + 1).toString().padStart(2, '0') + '-' + dates[1].split('/')[0].trim().padStart(2, '0');
        }
    }
}
</script>

<style>
</style>