<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-card class="px-0 py-0 size">
                <v-card-text class="px-0 ">
                    <v-row class="px-5 py-0 mt-3" justify="center" align="center">
                        <v-col cols="8" align="center" justify="center">        
                            <h2 class="text-uppercase azul--text">{{ $t('upload_annex.upload_annex')}}</h2>
                        </v-col>
                    </v-row>
                    <v-card-text class="px-0">
                        <v-row class="px-5 py-5 mt-3">
                            <v-col cols="12" align="center" justify="center">    
                                <h2><v-icon>
                                    description
                                </v-icon>
                                    {{ $t('upload_annex.files') }}</h2>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 pb-3" justify="center" align="center">
                            <v-btn class="white--text" color="amarillo" @click="modal2 = true">
                                <v-icon small class="px-0 mx-0">add</v-icon>
                                {{$t('upload_annex.select_appointment_file')}}
                            </v-btn>
                        </v-row>
                    </v-card-text>
                    <br>
                    <v-row class="px-5 py-0" justify="end">
                        <v-col cols="6">
                            <v-btn class="right mx-3" color="white" @click="cancel" >
                                {{ $t('upload_annex.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="saveAppointments" >
                                {{ $t('upload_annex.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
        <v-dialog v-model="modal2" persistent max-width="90%">
            <v-card>
                <v-card-title class="justify-center">
                    <v-spacer></v-spacer>
                    {{ $t('upload_annex.upload_annex') }}
                    <v-spacer></v-spacer>
                    <v-btn color="amarillo" small icon @click="stepper2 = 1;modal2 = false"><v-icon small>close</v-icon></v-btn>
                </v-card-title>

                <v-card-text>
                    <v-stepper v-model="stepper2">
                        <v-stepper-header>
                            <v-stepper-step :complete="stepper2 > 1" step="1" color="amarillo">{{$t('upload_annex.select_appointment_file')}}</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="stepper2 > 2" step="2" color="amarillo">{{ $t('upload_annex.verify_data') }}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content step="1" class="mx-0 px-0">
                                <v-card flat class="mb-5">
                                    <v-form ref="form2" lazy-validation>
                                        <v-row class="mx-0 px-0" justify="center" align="center">
                                            <v-col cols="10">
                                                <v-file-input v-model="inputFile2" accept=".csv" color="amarillo" :label="$t('upload_annex.select_appointment_file')"></v-file-input>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn class="mx-0" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="stepper2 = 3">
                                                            <v-icon aria-hidden="false" color="white" small>
                                                                help
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('upload_annex.help') }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card>
                                
                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="right mr-2 white--text" color="amarillo" @click="uploadFilePetition" >
                                        {{ $t('upload_annex.next') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-card flat class="mb-5">
                                    <v-card-text class="my-0 py-0">
                                        <p class="my-0 py-0">{{readRows2.length}} {{ $t('upload_annex.appointments_in_file') }}</p>
                                    </v-card-text>
                                    <v-simple-table v-if="this.readRows2.length > 0" fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th v-for="(th, key) in sTHeaders2" :key="key" class="text-left">{{ th }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in readRows2" :key="i">
                                                    <td v-for="(td, j) in item.split(',')" :key="j">{{ td }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>

                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="mx-3" text @click="stepper2 = 1; sTHeaders2 = []; readRows2 = []">{{ $t('upload_annex.go_back') }}</v-btn>
                                    <v-btn color="amarillo" class="white--text" @click="modal2 = false;">
                                        {{ $t('upload_annex.save_data') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="3">
                                    <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                                    <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                                    <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                                    <p class="py-0 my-0">4.- El archivo de citas debe ser formato CSV.</p> 
                                    <p>5.- Si el campo presenta la leyenda 'Opcional' este campo puede ir vacío.</p> 
                                    <p class="py-0 my-0">Columnas: </p>

                                    <v-simple-table fixed-header>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left" style="padding: 0 5px !important;">Pedimento</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Fecha_pago</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Fecha_entrada</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">CVE_Ped</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Regimen</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">País_V/C</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Partida_pedimento</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Número_de_parte</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Descripción</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Cantidad_factura</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">No_De_Factura</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">UMC</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Fraccion</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Valor_Factura_USD</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Cod_COVE</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Nombre_proveedor_cliente</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">No_De_Aviso</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">UMT</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Id_Cita</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>222416112000089</td>
                                                    <td>2022-01-11</td>
                                                    <td>2022-01-10</td>
                                                    <td>M3</td>
                                                    <td>RFS</td>
                                                    <td>USA</td>
                                                    <td>1</td>
                                                    <td>AMCU002</td>
                                                    <td>INSUMOS MATERIAS PRIMAS MATERIALES AUXILIARES ENVASES Y EMPAQUES (ALEACION DE COBRE)</td>
                                                    <td>6</td>
                                                    <td>SA190-36</td>
                                                    <td>14</td>
                                                    <td>98060009</td>
                                                    <td>27739</td>
                                                    <td>0</td>
                                                    <td>PROCESOS INDUSTRIALES RYMSA SA DE CV</td>
                                                    <td>0</td>
                                                    <td>1</td>
                                                    <td>9056</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>

                                    <v-row align="center" justify="center" class="my-3">
                                        <v-btn color="amarillo" class="white--text" @click="stepper2 = 1">
                                            {{ $t('products.go_back') }}
                                        </v-btn>
                                    </v-row>
                                </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modal2: false,
            inputFile2: [],
            readFile2: '',
            readRows2: [],
            stepper2: 1,
            sTHeaders2:''
        }
    },
    mounted(){
        this.$store.state.module = "headers.upload_annex";
    },
    methods: {
        uploadFilePetition(){
            if(this.$refs.form2.validate()){
                this.createInput2(this.inputFile2);
            }
        },
        createInput2(file) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    /* handle a successful result */
                    this.readRows2 = result.split('\r\n');
                    this.sTHeaders2 = this.readRows2[0].split(',');
                    this.readRows2.shift();
                    this.stepper2 = 2;
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },
        saveAppointments(){
            console.log("KOKOKOKKOK")
            this.$store.state.loaderProps.visible = true
            const petitions = [];
            if(this.readRows2.length > 0){
                this.readRows2.forEach((x) => {
                    const num = x.split(',');
                    petitions.push({
                        'pedimento': num[0],
                        'fecha_pago': num[1],
                        'fecha_entrada': num[2],
                        'cve_ped': num[3],
                        'regimen': num[4],
                        'país_vc': num[5],
                        'partida_pedimento': num[6],
                        'numero_de_parte': num[7],
                        'descripcion': num[8],
                        'cantidad_factura': num[9],
                        'no_de_factura': num[10],
                        'umc': num[11],
                        'fraccion': num[12],
                        'valor_factura_usd': num[13],
                        'cod_cove': num[14],
                        'nombre_proveedor_cliente': num[15],
                        'no_de_aviso': num[16],
                        'umt': num[17],
                        'id': num[18]
                    });
                })

                const lsToken = localStorage.getItem("access_token")
                this.$http.post(this.$store.state.chronosApi + 'massiveAnnex', { data: petitions}, { headers: { Authorization: lsToken } })
                .then((response) => {
                    console.log(response);
                    if(response.data.status === 200){
                        this.modal2 = false;
                        this.stepper2 = 1;
                        this.sTHeaders2 = [];
                        this.readRows2 = [];
                        this.inputFile2 = null;
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('upload_annex.responses.ok')
                        };
                    }
                })
                .catch((error) => {
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: "error " + error 
                    };
                })
                .finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('upload_annex.validations.select_file_first')
                };
            }
        },
        cancel(){
            this.$router.go(-1);
        }
    },
}
</script>

<style lang="css">
.size{
    min-height: calc(100vh - 120px) !important;
    position:relative !important;
}
</style>