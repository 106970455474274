import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
// import Delete from '@/Custom_Icons/Delete.vue'
// import Edit from '@/Custom_Icons/Edit.vue'
// import Gallery from '@/Custom_Icons/Gallery.vue'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#009cde',
        amarillo: '#9fb700',
        azul: '#00587d',
        info: '#00587d',
        accent: '#444444',
        error: '#de0000',
        success: '#0fba09',
        success2: '#4CAF50',
        option: "#006682",
        orange: "#fcbc10",
        purple: "#8850BF",
        white_highlight: "#F5F6FB",
        warning: '#f59b42', //orange
        
        blue_dashboard: "#259ce6",
        verde: '#27C940',
        morado: '#9B1DDE',
        gris: '#707070',
      },
    },
    
  },
  icons: {
    iconfont: 'md',
    // values: {
    //   Delete: { // name of our custom icon
    //     component: Delete, // our custom component
    //   },
    //   Edit: { // name of our custom icon
    //     component: Edit, // our custom component
    //   },
    //   Gallery: { // name of our custom icon
    //     component: Gallery, // our custom component
    //   },
    // },
  },
});
