import customs_patchesService from "../../services/customs_patches.service";
import { CustomsPatchesTypes } from "./customs_patches.mutation";

const initial_state = {
  list:{
   payload:[],
   loading: false,
   success: false,
  },
  create:{
   payload:null,
   loading: false,
   success: false,
  },
  destroy:{
   payload:null,
   loading: false,
   success: false,
  },
   detail:{
     payload: {
      "id": "",
      "type": "",
      "attributes": {
        "id": "",
        "customs_document": "",
        "customs_document_date": "",
        "income_date": "",
        "doc_key": "",
        "regime": "",
        "origin_country": "",
        "source_country": "",
        "customs_document_departure": "",
        "part_number": "",
        "description": "",
        "invoice_quantity": "",
        "invoice_number": "",
        "umc": "",
        "fraction": "",
        "value_in_dollars": "",
        "codcove": "",
        "client_supplier": "",
        "notice_number": "",
        "umt": ""
      }
    },
     loading: false,
     success: false,
   }
}
const ModuleCustomsPatches = {
    namespaced: true,
    state: () => (initial_state),
    mutations: { 
      // [CustomsPatchesTypes.CLEAR] (state) {
      //   state = initial_state;
      // },
      [CustomsPatchesTypes.LIST_REQUEST] (state) {
        state.list.payload = []
				state.list.loading = true
				state.list.success = false
      },
			[CustomsPatchesTypes.LIST_SUCCESS] (state, payload) {
        state.list.payload = payload
				state.list.loading = false
				state.list.success = true
      },
			[CustomsPatchesTypes.LIST_FAILURE] (state) {
        state.list.payload = []
				state.list.loading = false
				state.list.success = false
      },
      [CustomsPatchesTypes.CREATE_REQUEST] (state) {
        state.create.payload = null
				state.create.loading = true
				state.create.success = false
      },
			[CustomsPatchesTypes.CREATE_SUCCESS] (state, payload) {
        state.create.payload = payload
				state.create.loading = false
				state.create.success = true
      },
			[CustomsPatchesTypes.CREATE_FAILURE] (state) {
        state.create.payload = null
				state.create.loading = false
				state.create.success = false
      },
      [CustomsPatchesTypes.DELETE_REQUEST] (state) {
        state.destroy.payload = null
				state.destroy.loading = true
				state.destroy.success = false
      },
			[CustomsPatchesTypes.DELETE_SUCCESS] (state, payload) {
        state.destroy.payload = payload
				state.destroy.loading = false
				state.destroy.success = true
      },
			[CustomsPatchesTypes.DELETE_FAILURE] (state) {
        state.destroy.payload = null
				state.destroy.loading = false
				state.destroy.success = false
      },
      [CustomsPatchesTypes.DETAIL_REQUEST] (state) {
        state.detail.payload = initial_state.detail.payload
				state.detail.loading = true
				state.detail.success = false
      },
			[CustomsPatchesTypes.DETAIL_SUCCESS] (state, payload) {
        state.detail.payload = payload
				state.detail.loading = false
				state.detail.success = true
      },
			[CustomsPatchesTypes.DETAIL_FAILURE] (state) {
        state.detail.payload = initial_state.detail.payload
				state.detail.loading = false
				state.detail.success = false
      },
    },
    getters: { 
        list: state => state.list,
        create: state => state.create,
        detail: state => state.detail,
        destroy: state => state.destroy,
    },
    actions:  {
        async getIndex({ commit }, payload) {
          commit(CustomsPatchesTypes.LIST_REQUEST)
          return customs_patchesService.list({initial_date:payload.initial_date,final_date:payload.final_date})
            .then(response => {
              return response.data;
            })
            .then(async data =>  {
              commit(CustomsPatchesTypes.LIST_SUCCESS, data);
            })
            .catch(error => {
              commit(CustomsPatchesTypes.LIST_FAILURE, {});
              if (error.response) {
                if (error.response.data) {
                  if (error.response.data.errors) {
										for (const error of error.response.data.errors) {
											console.log(error)
										}
                  }
                  if (error.response.data.message) {
                    // commit('setError', error.response.data.message);
                  }
                }
              }
            });
        },
        async storeData({ commit }, payload) {
          commit(CustomsPatchesTypes.CREATE_REQUEST)
          return customs_patchesService.create({customs_patches: payload})
            .then(response => {
              return response.data;
            })
            .then(async data =>  {
              commit(CustomsPatchesTypes.CREATE_SUCCESS, data);
            })
            .catch(error => {
              commit(CustomsPatchesTypes.CREATE_FAILURE, {});
              if (error.response) {
                if (error.response.data) {
                  if (error.response.data.errors) {
										for (const error of error.response.data.errors) {
											console.log(error)
										}
                  }
                  if (error.response.data.message) {
                    // commit('setError', error.response.data.message);
                  }
                }
              }
            });
        },
        async getDetail({ commit }, payload) {
          commit(CustomsPatchesTypes.DETAIL_REQUEST)
          return customs_patchesService.detail(payload)
            .then(response => {
              return response.data;
            })
            .then(async data =>  {
              commit(CustomsPatchesTypes.DETAIL_SUCCESS, data.data);
            })
            .catch(error => {
              commit(CustomsPatchesTypes.DETAIL_FAILURE, {});
              if (error.response) {
                if (error.response.data) {
                  if (error.response.data.errors) {
										for (const error of error.response.data.errors) {
											console.log(error)
										}
                  }
                  if (error.response.data.message) {
                    // commit('setError', error.response.data.message);
                  }
                }
              }
            });
        },
        async deleteRecord({ commit }, payload) {
          commit(CustomsPatchesTypes.DELETE_REQUEST);
          console.log(payload);                                
          const data = payload.map(function(el, idx) {
              return 'customs_patches[' + idx + ']=' + el;
          }).join('&');
          return customs_patchesService.destroy(data)
            .then(response => {
              return response.data;
            })
            .then(async data =>  {
              commit(CustomsPatchesTypes.DELETE_SUCCESS, data);
            })
            .catch(error => {
              commit(CustomsPatchesTypes.DELETE_FAILURE, {});
              if (error.response) {
                if (error.response.data) {
                  if (error.response.data.errors) {
										for (const error of error.response.data.errors) {
											console.log(error)
										}
                  }
                  if (error.response.data.message) {
                    // commit('setError', error.response.data.message);
                  }
                }
              }
            });
        },
     },
  }

export default ModuleCustomsPatches;