<template>
    <div>
        <v-dialog
            v-model="$store.state.loaderProps.visible"
            persistent
            width="300"
            :overlay-color="$store.state.loaderProps.overlarColor"
        >
            <v-card :color="$store.state.loaderProps.color">
                <v-card-text :class="$store.state.loaderProps.class">
                    {{ $t($store.state.loaderProps.text) }}
                    <v-progress-linear
                        indeterminate
                        :color="$store.state.loaderProps.lineColor"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    export default {
        props: [],
        data() {
            return {}
        }
    }
</script>