<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col cols="3" class="py-0 my-0">
                    <client-selector :addAllOption="false" class="py-0 my-0 " ref="clientSelector" @getClients="v => items.clients = v" :only_fulfillment="1" :cod="1" :is_national="2"></client-selector>  
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-menu ref="initial_date" v-model="menu" :close-on-content-click="false" :return-value.sync="initial_date" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field class="py-0" v-model="initial_date" :label="$t('operations_per_customer.initial_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                        </template>
                        <v-date-picker v-model="initial_date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">{{ $t('operations_per_customer.cancel') }}</v-btn>
                            <v-btn text color="primary" @click="$refs.initial_date.save(initial_date)">{{ $t('operations_per_customer.save') }}</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-menu ref="final_date" v-model="menu1" :close-on-content-click="false" :return-value.sync="final_date" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field class="py-0" v-model="final_date" :label="$t('operations_per_customer.final_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                        </template>
                        <v-date-picker v-model="final_date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu1 = false">{{ $t('operations_per_customer.cancel') }}</v-btn>
                            <v-btn text color="primary" @click="$refs.final_date.save(final_date)">{{ $t('operations_per_customer.save') }}</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" @click="show_export_options = true" v-if="show_export_options == false">
                        {{ $t('stock_report.export') }}
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataPdf">
                        PDF
                    </v-btn>
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataExcel">
                        Excel
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="show_export_options = false;">
                        <v-icon small class="px-0">
                            keyboard_arrow_left
                        </v-icon>
                    </v-btn>

                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="generate">
                        {{ $t('operations_per_customer.generate') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text >
                    <v-col class="py-0">
                        <v-row class="azul font-weight-bold text-uppercase white--text px-2 py-0">
                            <v-col cols="2" class="py-1" v-for="parcel in summary_parcels" :key="parcel.shipping_service"><span class="font-weight-bold text-uppercase"></span> Total {{ parcel.shipping_service }} : {{ parcel.total }} </v-col>          
                        </v-row>
                    </v-col>
                </v-card-text>

                <v-card-text class="pt-0 pb-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers: headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('operations_per_customer.order'), align: "center", value: 'unique_order_number', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.guide'), align: "center", value: 'shipment_number', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.date'), align: "center", value: 'created_at', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.shipping_service'), align: "center", value: 'shipping_service', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.cod'), align: "center", value: 'total', class: 'text-uppercase' }
            ];
        },
        client: function(){
            return this.$store.state.clientId
        },
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            initial_date: '',
            final_date: '',
            report:{
                initial_date: '',
                final_date: ''
            },
            menu: false,
            menu1: false,
            show_export_options: false,
            summary_parcels: []
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.cod_by_shipping_service";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
        },
        generate(){
            this.props.items = [];
            
            if(this.$store.state.warehouseId != "" && this.$store.state.clientId != "" && this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + 'reports/codByShippingService?client='+ this.$store.state.clientId + '&startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}})
                .then((response) => {
                    response.data.data[0].forEach( x => {
                        x.created_at = x.created_at.split('T')[0];
                        this.props.items.push(x)
                    })
                    this.summary_parcels = response.data.data[1];
                })
                .catch((response) => {
                    this.$store.state.loaderProps.visible = false;
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('pendings.responses.error')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportDataExcel(){
            const lsToken = localStorage.getItem("access_token");
            if(this.$store.state.warehouseId != "" && this.$store.state.clientId != "" && this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;

                this.$http.get(this.$store.state.chronosApi + 'reports/codByShippingServiceExport?client='+ this.$store.state.clientId + '&startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'Cod_Por_Paquetería.xlsx');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportDataPdf(){
            const lsToken = localStorage.getItem("access_token");
            if(this.$store.state.warehouseId != "" && this.$store.state.clientId != "" && this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;

                this.$http.get(this.$store.state.chronosApi + 'reports/codByShippingServicePDF?client='+ this.$store.state.clientId + '&startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'Cod_Por_Paquetería.pdf');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        }
    },
    watch: {
        '$store.state.clientId': function(){
            this.props.items = [];
        }
    }
}
</script>

<style>
</style>