<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-3 pt-2">
                    <v-col cols="12" align="start" justify="start">    
                        <h3 class="azul--text text-uppercase">{{ $t('manage_stores.manage_stores') }}</h3>
                    </v-col>
                </v-row>
                

            <v-card-text class="py-0 my-0">
                <v-row class="px-0 py-0 my-0" style="max-height: 43px !important; min-height: 43px !important;" align="center">
                    <v-col cols="12" class="py-0 my-0 px-0">
                        <v-tabs v-model="tab"  active-class="customers_active_tab" style="max-height: 43px !important;">
                            <v-tabs-slider class="slider" color="amarillo" style="margin: 0px !important;"></v-tabs-slider>
                            <v-tab v-for="item in tab_items" :key="item" class="non_selected_tab slider" style="max-height: 43px !important;">
                                {{ item }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-tabs-items v-model="tab" class="px-0 mx-3 mt-3">
                <!-- SHOPIFY -->
                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" >
                        <v-card-text class="px-0 mx-1">
                            <v-row class="px-0 mx-0 my-2">
                                <v-col class="py-0 px-0 mx-0" cols="7">
                                    <v-form ref="shopify">
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="shopify.shop_url" :label="$t('manage_stores.shop_url')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <!-- <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="shopify.shop_token" :label="$t('manage_stores.shop_token')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row> -->
                                        <!-- <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="shopify.hook_description" :label="$t('manage_stores.description')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row> -->
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="shopify.uon_prefix" :label="$t('manage_stores.uon_prefix')"></v-text-field>
                                            </v-col>
                                        </v-row>
                                
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="shopify.shop_link" :label="$t('manage_stores.shop_name')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="shopify.shop_key" :label="$t('manage_stores.shop_key')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="shopify.shop_secret" :label="$t('manage_stores.access_token')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-autocomplete class="mt-0 py-0" v-model="shopify.shipping_service" :items="shipping_services" :label="$t('manage_stores.shipping_service')"  :rules="rules_required"></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-0 py-0 mt-2" justify="center" align="center" v-if="can_save_shopify">
                                            <v-col cols="10" justify="end" align="end">
                                                <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                                    {{ $t('manage_stores.cancel') }}
                                                </v-btn>
                                                <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'client.manage_stores'">
                                                    {{ $t('manage_stores.save') }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-col>

                                <v-col class="py-0 px-0 mx-0" cols="5">
                                    <v-row justify="center" align="center">
                                        <v-col cols="12" class="py-0 my-0" justify="center" align="center">
                                            <v-textarea class="py-0 my-0" rows="2" v-model="shopify.hook" :label="$t('manage_stores.hook_url')" :readonly="true" :rules="rules_required"></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <!-- WOOCOMMERCE -->
                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" >
                        <v-card-text class="px-0 mx-1">
                            <v-row class="px-0 mx-0 my-2">
                                <v-col class="py-0 px-0 mx-0" cols="7">
                                    <v-form ref="woocommerce">
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="woocommerce.shop_url" :label="$t('manage_stores.shop_url')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="woocommerce.shop_token" :label="$t('manage_stores.shop_token')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="woocommerce.description" :label="$t('manage_stores.description')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="woocommerce.uon_prefix" :label="$t('manage_stores.uon_prefix')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="woocommerce.shop_link" :label="$t('manage_stores.shop_link')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="woocommerce.shop_key" :label="$t('manage_stores.shop_key')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="woocommerce.shop_secret" :label="$t('manage_stores.shop_secret')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-autocomplete class="mt-0 py-0" v-model="woocommerce.shipping_service" :items="shipping_services" :label="$t('manage_stores.shipping_service')"  :rules="rules_required"></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-0 py-0 mt-2" justify="center" align="center" v-if="can_save_woocommerce">
                                            <v-col cols="10" justify="end" align="end">
                                                <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                                    {{ $t('manage_stores.cancel') }}
                                                </v-btn>
                                                <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'client.manage_stores'">
                                                    {{ $t('manage_stores.save') }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-col>

                                <v-col class="py-0 px-0 mx-0" cols="5">
                                    <v-row justify="center" align="center">
                                        <v-col cols="12" class="py-0 my-0" justify="center" align="center">
                                            <v-textarea class="py-0 my-0" rows="2" v-model="woocommerce.hook" :label="$t('manage_stores.hook_url')" :readonly="true" :rules="rules_required"></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <!-- MIRAKLIL -->
                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" >
                        <v-card-text class="px-0 mx-1">
                            <v-row class="px-0 mx-0 my-2">
                                <v-col class="py-0 px-0 mx-0" cols="7">
                                    <v-form ref="mirakl_ig">
                                       <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_ig.shop_url" :label="$t('manage_stores.shop_url')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_ig.shop_token" :label="$t('manage_stores.shop_token')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_ig.description" :label="$t('manage_stores.description')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_ig.uon_prefix" :label="$t('manage_stores.uon_prefix')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_ig.shop_link" :label="$t('manage_stores.shop_link')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_ig.shop_key" :label="$t('manage_stores.shop_key')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        
                                        <!-- <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_ig.shop_secret" :label="$t('manage_stores.shop_secret')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row> -->
                                        
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-autocomplete class="mt-0 py-0" v-model="mirakl_ig.shipping_service" :items="shipping_services" :label="$t('manage_stores.shipping_service')"  :rules="rules_required"></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-0 py-0 mt-2" justify="center" align="center" v-if="can_save_miraklil">
                                            <v-col cols="10" justify="end" align="end">
                                                <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                                    {{ $t('manage_stores.cancel') }}
                                                </v-btn>
                                                <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'client.manage_stores'">
                                                    {{ $t('manage_stores.save') }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-col>

                                <v-col class="py-0 px-0 mx-0" cols="5">
                                    <v-row justify="center" align="center">
                                        <v-col cols="12" class="py-0 my-0" justify="center" align="center">
                                            <v-textarea class="py-0 my-0" rows="2" v-model="mirakl_ig.hook" :label="$t('manage_stores.hook_url')" :readonly="true" :rules="rules_required"></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <!-- MIRAKLEL -->
                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" >
                        <v-card-text class="px-0 mx-1">
                            <v-row class="px-0 mx-0 my-2">
                                <v-col class="py-0 px-0 mx-0" cols="7">
                                    <v-form ref="mirakl_eg">
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_eg.shop_url" :label="$t('manage_stores.shop_url')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_eg.shop_token" :label="$t('manage_stores.shop_token')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_eg.description" :label="$t('manage_stores.description')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_eg.uon_prefix" :label="$t('manage_stores.uon_prefix')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_eg.shop_link" :label="$t('manage_stores.shop_link')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_eg.shop_key" :label="$t('manage_stores.shop_key')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
<!--                                         
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_eg.shop_secret" :label="$t('manage_stores.shop_secret')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row> -->
                                        
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-autocomplete class="mt-0 py-0" v-model="mirakl_eg.shipping_service" :items="shipping_services" :label="$t('manage_stores.shipping_service')"  :rules="rules_required"></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-checkbox :label="$t('manage_stores.automatic_feedback')" v-model="mirakl_eg.automatic_feedback"></v-checkbox>
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-0 py-0 mt-2" justify="center" align="center" v-if="can_save_miraklel">
                                            <v-col cols="10" justify="end" align="end">
                                                <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                                    {{ $t('manage_stores.cancel') }}
                                                </v-btn>
                                                <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'client.manage_stores'">
                                                    {{ $t('manage_stores.save') }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-col>

                                <v-col class="py-0 px-0 mx-0" cols="5">
                                    <v-row justify="center" align="center">
                                        <v-col cols="12" class="py-0 my-0" justify="center" align="center">
                                            <v-textarea class="py-0 my-0" rows="2" v-model="mirakl_eg.hook" :label="$t('manage_stores.hook_url')" :readonly="true" :rules="rules_required"></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <!-- WALMARTIL -->
                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" >
                        <v-card-text class="px-0 mx-1">
                            <v-row class="px-0 mx-0 my-2">
                                <v-col class="py-0 px-0 mx-0" cols="7">
                                    <v-form ref="walmart_ig">
                                       <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="walmart_ig.shop_url" :label="$t('manage_stores.shop_url')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="walmart_ig.shop_token" :label="$t('manage_stores.shop_token')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="walmart_ig.description" :label="$t('manage_stores.description')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="walmart_ig.uon_prefix" :label="$t('manage_stores.uon_prefix')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="walmart_ig.shop_link" :label="$t('manage_stores.shop_link')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="walmart_ig.shop_key" :label="$t('manage_stores.shop_key')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        
                                        <!-- <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_ig.shop_secret" :label="$t('manage_stores.shop_secret')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row> -->
                                        
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-autocomplete class="mt-0 py-0" v-model="walmart_ig.shipping_service" :items="shipping_services" :label="$t('manage_stores.shipping_service')"  :rules="rules_required"></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-0 py-0 mt-2" justify="center" align="center" v-if="can_save_miraklil">
                                            <v-col cols="10" justify="end" align="end">
                                                <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                                    {{ $t('manage_stores.cancel') }}
                                                </v-btn>
                                                <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'client.manage_stores'">
                                                    {{ $t('manage_stores.save') }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-col>

                                <v-col class="py-0 px-0 mx-0" cols="5">
                                    <v-row justify="center" align="center">
                                        <v-col cols="12" class="py-0 my-0" justify="center" align="center">
                                            <v-textarea class="py-0 my-0" rows="2" v-model="walmart_ig.hook" :label="$t('manage_stores.hook_url')" :readonly="true" :rules="rules_required"></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <!-- WALMARTEL -->
                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" >
                        <v-card-text class="px-0 mx-1">
                            <v-row class="px-0 mx-0 my-2">
                                <v-col class="py-0 px-0 mx-0" cols="7">
                                    <v-form ref="walmart_eg">
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="walmart_eg.shop_url" :label="$t('manage_stores.shop_url')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="walmart_eg.shop_token" :label="$t('manage_stores.shop_token')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="walmart_eg.description" :label="$t('manage_stores.description')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="walmart_eg.uon_prefix" :label="$t('manage_stores.uon_prefix')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="walmart_eg.shop_link" :label="$t('manage_stores.shop_link')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="walmart_eg.shop_key" :label="$t('manage_stores.shop_key')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row>
<!--                                         
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-text-field class="py-0 my-0" v-model="mirakl_eg.shop_secret" :label="$t('manage_stores.shop_secret')" :rules="rules_required"></v-text-field>
                                            </v-col>
                                        </v-row> -->
                                        
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-autocomplete class="mt-0 py-0" v-model="walmart_eg.shipping_service" :items="shipping_services" :label="$t('manage_stores.shipping_service')"  :rules="rules_required"></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center">
                                            <v-col cols="10" class="py-0 my-0" justify="center" align="center">
                                                <v-checkbox :label="$t('manage_stores.automatic_feedback')" v-model="walmart_eg.automatic_feedback"></v-checkbox>
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-0 py-0 mt-2" justify="center" align="center" v-if="can_save_miraklel">
                                            <v-col cols="10" justify="end" align="end">
                                                <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                                    {{ $t('manage_stores.cancel') }}
                                                </v-btn>
                                                <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'client.manage_stores'">
                                                    {{ $t('manage_stores.save') }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-col>

                                <v-col class="py-0 px-0 mx-0" cols="5">
                                    <v-row justify="center" align="center">
                                        <v-col cols="12" class="py-0 my-0" justify="center" align="center">
                                            <v-textarea class="py-0 my-0" rows="2" v-model="walmart_eg.hook" :label="$t('manage_stores.hook_url')" :readonly="true" :rules="rules_required"></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            shipping_services: ["99MINUTOS", "ESTAFETA", "FEDEX", "HERMES", "JTEXPRESS", "MERQ", "PAQUETEXPRESS", "QUIKEN",  "UPS" , "YEGO"],
            shopify: {
                source: "", // Client name
                warehouse_id: this.$store.state.warehouseId,
                client_id: this.$route.params.id,
                channel: "Shopify",
                shop_url: "",
                shop_token: "",
                type: "Shopify",
                status: "active",
                eon_customer_token: "", // client token
                hook_description: "",
                uon_prefix: "",
                box_per_product: false,
                shop_link: "",
                shop_key: "",
                shop_secret: "",
                location_id: "",
                shipping_service: "",
                hook: "",
                create_webhooks_store: true
            },
            woocommerce: {
                source: "", // Client name
                warehouse_id: this.$store.state.warehouseId,
                client_id: this.$route.params.id,
                channel: "WooCommerce",
                shop_url: "",
                shop_token: "",
                type: "WooCommerce",
                status: "active",
                eon_customer_token: "", // client token
                hook_description: "",
                uon_prefix: "",
                box_per_product: false,
                shop_link: "",
                shop_key: "",
                shop_secret: "",
                location_id: "",
                shipping_service: "",
                hook: "",
                create_webhooks_store: true
            },
            mirakl_ig: {
                source: "", // Client name
                warehouse_id: this.$store.state.warehouseId,
                client_id: this.$route.params.id,
                channel: "MiraklIL",
                shop_url: "",
                shop_token: "",
                type: "MiraklIL",
                status: "active",
                eon_customer_token: "", // client token
                hook_description: "",
                uon_prefix: "",
                box_per_product: false,
                shop_link: "",
                shop_key: "",
                shop_secret: "",
                location_id: "",
                shipping_service: "",
                hook: "",
                create_webhooks_store: true
            }, 
            mirakl_eg: {
                source: "", // Client name
                warehouse_id: this.$store.state.warehouseId,
                client_id: this.$route.params.id,
                channel: "MiraklEL",
                shop_url: "",
                shop_token: "",
                type: "MiraklEL",
                status: "active",
                eon_customer_token: "", // client token
                hook_description: "",
                uon_prefix: "",
                box_per_product: false,
                shop_link: "",
                shop_key: "",
                shop_secret: "",
                location_id: "",
                shipping_service: "",
                hook: "",
                create_webhooks_store: true,
                automatic_feedback: true
            },
            walmart_ig: {
                source: "", // Client name
                warehouse_id: this.$store.state.warehouseId,
                client_id: this.$route.params.id,
                channel: "WalmartIL",
                shop_url: "",
                shop_token: "",
                type: "WalmartIL",
                status: "active",
                eon_customer_token: "", // client token
                hook_description: "",
                uon_prefix: "",
                box_per_product: false,
                shop_link: "",
                shop_key: "",
                shop_secret: "",
                location_id: "",
                shipping_service: "",
                hook: "",
                create_webhooks_store: true
            }, 
            walmart_eg: {
                source: "", // Client name
                warehouse_id: this.$store.state.warehouseId,
                client_id: this.$route.params.id,
                channel: "WalmartEL",
                shop_url: "",
                shop_token: "",
                type: "WalmartEL",
                status: "active",
                eon_customer_token: "", // client token
                hook_description: "",
                uon_prefix: "",
                box_per_product: false,
                shop_link: "",
                shop_key: "",
                shop_secret: "",
                location_id: "",
                shipping_service: "",
                hook: "",
                create_webhooks_store: true,
                automatic_feedback: true
            },
            can_save_shopify: true,
            can_save_woocommerce: true,
            can_save_miraklil: true,
            can_save_miraklel: true,
            can_save_walmartel: true,
            can_save_walmartil: true,
            warehouses: [],
            tab: null,
            tab_items: [this.$t("manage_stores.shopify"), this.$t("manage_stores.woocommerce"), this.$t("manage_stores.liverpool_ig"), this.$t("manage_stores.liverpool_eg"), this.$t("manage_stores.walmart_ig"), this.$t("manage_stores.walmart_eg")],
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.manage_stores";
    },
    methods: {
        index(){
            // Get current warehouses
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'warehouses', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.warehouses = response.data;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')                      
                    }
                }
            });

            this.$http.get(this.$store.state.chronosApi + 'externalStores/' + this.$route.params.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data.data.forEach(x => {
                        if(x.channel == 'Shopify'){
                            this.can_save_shopify = false;
                            this.shopify =  {
                                source: x.source, // Client name
                                warehouse_id: x.warehouse_id,
                                client_id: this.$route.params.id,
                                channel: "Shopify",
                                shop_url: x.shop_url,
                                shop_token: x.shop_token,
                                type: "Shopify",
                                status: "active",
                                eon_customer_token: x.eon_shops_response.shop.eon_customer_token, // client token
                                hook_description: x.eon_shops_response.hook.description,
                                uon_prefix: x.eon_shops_response.shop.uon_prefix,
                                box_per_product: false,
                                shop_link: x.eon_shops_response.shop.shop_link,
                                shop_key: x.eon_shops_response.shop.shop_key,
                                shop_secret: x.eon_shops_response.shop.shop_secret,
                                location_id: x.eon_shops_response.shop.location_id,
                                shipping_service: x.eon_shops_response.shop.shipping_service,
                                hook: x.eon_shops_response.hook.url
                            }
                        }
                        else if(x.channel == 'WooCommerce'){
                            this.can_save_woocommerce = false;
                            this.woocommerce =  {
                                source: x.source, // Client name
                                warehouse_id: x.warehouse_id,
                                client_id: this.$route.params.id,
                                channel: "Shopify",
                                shop_url: x.shop_url,
                                shop_token: x.shop_token,
                                type: "Shopify",
                                status: "active",
                                eon_customer_token: x.eon_shops_response.shop.eon_customer_token, // client token
                                hook_description: x.eon_shops_response.hook.description,
                                uon_prefix: x.eon_shops_response.shop.uon_prefix,
                                box_per_product: false,
                                shop_link: x.eon_shops_response.shop.shop_link,
                                shop_key: x.eon_shops_response.shop.shop_key,
                                shop_secret: x.eon_shops_response.shop.shop_secret,
                                location_id: x.eon_shops_response.shop.location_id,
                                shipping_service: x.eon_shops_response.shop.shipping_service,
                                hook: x.eon_shops_response.hook.url
                            }
                        }
                        else if(x.channel == 'MiraklIL'){
                            this.can_save_miraklil = false;
                            this.mirakl_ig =  {
                                source: x.source, // Client name
                                warehouse_id: x.warehouse_id,
                                client_id: this.$route.params.id,
                                channel: "Shopify",
                                shop_url: x.shop_url,
                                shop_token: x.shop_token,
                                type: "Shopify",
                                status: "active",
                                eon_customer_token: x.eon_shops_response.shop.eon_customer_token, // client token
                                hook_description: x.eon_shops_response.hook.description,
                                uon_prefix: x.eon_shops_response.shop.uon_prefix,
                                box_per_product: false,
                                shop_link: x.eon_shops_response.shop.shop_link,
                                shop_key: x.eon_shops_response.shop.shop_key,
                                shop_secret: x.eon_shops_response.shop.shop_secret,
                                location_id: x.eon_shops_response.shop.location_id,
                                shipping_service: x.eon_shops_response.shop.shipping_service,
                                hook: x.eon_shops_response.hook.url
                            }
                        }
                        else if(x.channel == 'MiraklEL'){
                            this.can_save_miraklel = false;
                            this.mirakl_eg =  {
                                source: x.source, // Client name
                                warehouse_id: x.warehouse_id,
                                client_id: this.$route.params.id,
                                channel: "Shopify",
                                shop_url: x.shop_url,
                                shop_token: x.shop_token,
                                type: "Shopify",
                                status: "active",
                                eon_customer_token: x.eon_shops_response.shop.eon_customer_token, // client token
                                hook_description: x.eon_shops_response.hook.description,
                                uon_prefix: x.eon_shops_response.shop.uon_prefix,
                                box_per_product: false,
                                shop_link: x.eon_shops_response.shop.shop_link,
                                shop_key: x.eon_shops_response.shop.shop_key,
                                shop_secret: x.eon_shops_response.shop.shop_secret,
                                location_id: x.eon_shops_response.shop.location_id,
                                shipping_service: x.eon_shops_response.shop.shipping_service,
                                hook: x.eon_shops_response.hook.url,
                                automatic_feedback: x.automatic_feedback
                            }
                        }
                        else if(x.channel == 'WalmartEL'){
                            this.can_save_walmartel = false;
                            this.walmart_eg=  {
                                source: x.source, // Client name
                                warehouse_id: x.warehouse_id,
                                client_id: this.$route.params.id,
                                channel: "Shopify",
                                shop_url: x.shop_url,
                                shop_token: x.shop_token,
                                type: "Shopify",
                                status: "active",
                                eon_customer_token: x.eon_shops_response.shop.eon_customer_token, // client token
                                hook_description: x.eon_shops_response.hook.description,
                                uon_prefix: x.eon_shops_response.shop.uon_prefix,
                                box_per_product: false,
                                shop_link: x.eon_shops_response.shop.shop_link,
                                shop_key: x.eon_shops_response.shop.shop_key,
                                shop_secret: x.eon_shops_response.shop.shop_secret,
                                location_id: x.eon_shops_response.shop.location_id,
                                shipping_service: x.eon_shops_response.shop.shipping_service,
                                hook: x.eon_shops_response.hook.url,
                                automatic_feedback: x.automatic_feedback
                            }
                        }
                        else if(x.channel == 'WalmartIL'){
                            this.can_save_walmartil = false;
                            this.walmart_ig=  {
                                source: x.source, // Client name
                                warehouse_id: x.warehouse_id,
                                client_id: this.$route.params.id,
                                channel: "Shopify",
                                shop_url: x.shop_url,
                                shop_token: x.shop_token,
                                type: "Shopify",
                                status: "active",
                                eon_customer_token: x.eon_shops_response.shop.eon_customer_token, // client token
                                hook_description: x.eon_shops_response.hook.description,
                                uon_prefix: x.eon_shops_response.shop.uon_prefix,
                                box_per_product: false,
                                shop_link: x.eon_shops_response.shop.shop_link,
                                shop_key: x.eon_shops_response.shop.shop_key,
                                shop_secret: x.eon_shops_response.shop.shop_secret,
                                location_id: x.eon_shops_response.shop.location_id,
                                shipping_service: x.eon_shops_response.shop.shipping_service,
                                hook: x.eon_shops_response.hook.url
                            }
                        }
                    })
                }
            })
            .catch((response) => {
                console.log(response)
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')                      
                    }
                }
            });
            
        },
        save(){
            var obj = ""
            var validate = false;
            if(this.tab == 0) { 
                validate = this.$refs.shopify.validate();
                obj = this.shopify;
            }
            else if(this.tab == 1) {
                validate = this.$refs.woocommerce.validate();
                obj = this.woocommerce;
            }
            else if(this.tab == 2) {
                validate = this.$refs.mirakl_ig.validate();
                obj = this.mirakl_ig;
            }
            else if(this.tab == 3) {
                validate = this.$refs.mirakl_eg.validate();
                obj = this.mirakl_eg;
            }

            if(validate){
                const lsToken = localStorage.getItem("access_token");
                
                this.$http.post(this.$store.state.chronosApi + 'external_store_clients', obj, { headers: { Authorization: lsToken } })
                .then((response) => {
                    if(response.status === 201){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('manage_stores.success')
                        }
                        this.$router.push({ name: "CustomersIndex" });  
                    }
                })
                .catch((response) => {
                    this.$store.state.loaderProps.visible = false;
                    if(response.status !== 201){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('pendings.error')
                        }
                    }
                });
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('manage_stores.invalid_form')
                }
            }
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
        
    }
}
</script>

<style lang="css">
</style>