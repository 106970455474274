<template>
  <v-row justify="center">
      <v-dialog v-model="rfid_dialog" fullscreen :scrim="false" transition="dialog-bottom-transition" style="width: 100% !important;">
      <v-card>
          <v-toolbar dark color="primary">
          <v-btn icon dark @click="hide()">
              <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
              <v-btn variant="text" @click="hide()">
                Cancelar
              </v-btn>
          </v-toolbar-items>
          </v-toolbar>
          <v-container >
            
            <v-list lines="two" subheader>
                <v-subheader>Globales</v-subheader>
                <v-row>
                  <v-col cols="4">
                    <v-checkbox v-model="selected.opcions.pallets" label="Tarimas"></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox v-model="selected.opcions.boxes" label="Cajas"></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox v-model="selected.opcions.tags" label="Tags"></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox v-model="selected.opcions.only_new" label="Imprimir solo lo nuevo"></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-select label="Impresoras" :items="printers" v-model="printer_selected"></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-btn class="btn" @click="print()"> Imprimir </v-btn>
                  </v-col>
                </v-row>
            </v-list>
            <v-divider></v-divider>
            <v-list lines="two" subheader>
              <v-subheader>Tags</v-subheader>
              <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
                <v-tab v-for="t in tabs" :key="t">
                  {{ t }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item key="Tarimas">
                  <v-card >
                    <v-data-table
                      :headers="headers_pallets"
                      :items="items.pallets"
                      item-key="id"
                      show-select
                      v-model="selected.pallets"
                      :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
                      class="elevation-1"
                    ></v-data-table>       
                  </v-card>
                </v-tab-item>
                <v-tab-item key="Cajas">
                  <v-card >
                    <v-data-table
                      :headers="headers_boxes"
                      :items="items.boxes"
                      item-key="id"
                      show-select
                      v-model="selected.boxes"
                      :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
                      class="elevation-1"
                    ></v-data-table>       
                  </v-card>
                </v-tab-item>
                <v-tab-item key="Piezas">
                  <v-card >
                    <v-data-table
                      :headers="headers_tags"
                      :items="items.tags"
                      item-key="id"
                      show-select
                      v-model="selected.tags"
                      :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
                      class="elevation-1"
                    ></v-data-table>       
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-list>
          </v-container>
      </v-card>
      </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: [
      'items',
  ],
  data(){
      return {
          rfid_dialog: true,
          tab: null,
          tabs: ['Tarimas', 'Cajas', 'Piezas'],
          selected: {
            pallets: [],
            boxes: [],
            tags: [],
            opcions:{
              pallets: false,
              boxes: false,
              tags: false,
              only_new: true
            }
          },
          printers: [],
          printer_selected: "",
          
          headers_products: [
            { title: 'ID', align: 'start', key: 'id', },
            { title: 'EPC', align: 'end', key: 'epc' },
            { title: 'Sku', align: 'end', key: 'sku' },
            { title: 'Lote', align: 'end', key: 'batch' },
            { title: 'Caducidad', align: 'end', key: 'reference' },
          ],
        }
  },
  computed: {
    headers_pallets () {
      return [
        { text: 'ID', align: "center", value: 'id', },
        { text: 'EPC', align: "center", value: 'epc', },
        { text: 'Tarima', align: "center", value: 'pallet', },
        { text: 'Ubicacion', align: "center", value: 'ubicacion', },
        { text: '# Cajas', align: "center", value: 'cajas', },
        { text: '# Piezas', align: "center", value: 'piezas', },
      ]
    },
    headers_boxes () {
      return [
        { text: 'ID', align: "center", value: 'id', },
        { text: 'EPC', align: "center", value: 'epc', },
        { text: 'Caja', align: "center", value: 'box', },
        // { text: 'Productos', align: "center", value: 'productos', },
        { text: 'Lote', align: "center", value: 'batch', },
        { text: 'Caducidad', align: "center", value: 'reference', },
        { text: 'Tarima', align: "center", value: 'pallet', },
        { text: 'Ubicacion', align: "center", value: 'location', },
        { text: 'Piezas', align: "center", value: 'piezas', },
      ]
    },
    headers_tags () {
      return [
        { text: 'ID', align: "center", value: 'id', },
        { text: 'EPC', align: "center", value: 'epc', },
        { text: 'Producto', align: "center", value: 'product', },
        { text: 'Lote', align: "center", value: 'batch', },
        { text: 'Caducidad', align: "center", value: 'reference', },
        { text: 'Ubicacion', align: "center", value: 'location', },
        { text: 'Tarima', align: "center", value: 'pallet', },
        { text: 'Caja', align: "center", value: 'box', },
      ]
    },
  },
  mounted(){
      this.getPrinters()
  },
  methods: {
      getPrinters(){
        this.$http.get(this.$store.state.printerServiceApi + 'printers/')
            .then((response) => {
              this.printers = response.data.map(p => {return p.name})
            }).catch(error => console.log(error))
      },
      show(){
        console.log("se muestra el componente")
        this.rfid_dialog = true
      },
      hide(){
        console.log("se oculta el componente")
        this.rfid_dialog = false
      },
      print(){
        console.log(this.selected)
        var t = this;
        this.$http.post(this.$store.state.chronosApi + 'rfid/get_tags', this.selected)
            .then((response) => {
              console.log("aqui es donde se imprime")
              console.log(response)
              var print_info = {
                printer: t.printer_selected,
                data: {
                  pallets: response.data.items.pallets,
                  details: response.data.items.boxes,
                  products: response.data.items.products,
                },
                print_type: {
                  tag: t.selected.opcions.tags,
                  box: t.selected.opcions.boxes,
                  pallet: t.selected.opcions.pallets
                }
              }
              console.log(print_info)
              this.$http.post(this.$store.state.printerServiceApi + 'print/', print_info)
              .then((response) => {
                console.log("aqui es donde se imprime")
                console.log(response)
              }).catch(error => console.log(error))
            }).catch(error => console.log(error))
        // var print_info = {}
        // this.$http.post(this.$store.state.printerServiceApi + 'print/', print_info)
        //     .then((response) => {
        //       console.log("aqui es donde se imprime")
        //       console.log(response)
        //     }).catch(error => console.log(error))
      }
  },
  
}
</script>

<style>

</style>