<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('edit_carrier.edit_carrier') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-menu ref="arrival_date" v-model="menu" :close-on-content-click="false" :return-value.sync="carrier.arrival_date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field class="py-0" v-model="carrier.arrival_date" :label="$t('edit_carrier.date_of_arrival')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="carrier.arrival_date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">{{ $t('edit_carrier.cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.arrival_date.save(carrier.arrival_date)">{{ $t('edit_carrier.accept') }}</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">

                                <v-menu ref="arrival_time" v-model="arrival_time_modal" :close-on-content-click="false" :return-value.sync="carrier.arrival_time" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="carrier.arrival_time" :label="$t('edit_carrier.time_of_arrival')" append-outer-icon="schedule" readonly v-on="on"></v-text-field>
                                    </template>
                                    <template>
                                        <v-time-picker v-model="carrier.arrival_time" ampm-in-title>
                                            <v-col justify="space-around" align="center">
                                                <v-btn text color="primary" @click="arrival_time_modal = false">{{ $t('edit_carrier.cancel') }}</v-btn>
                                                <v-btn text color="primary" @click="$refs.arrival_time.save(carrier.arrival_time)">{{ $t('edit_carrier.accept') }}</v-btn>
                                            </v-col>
                                        </v-time-picker>
                                    </template>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.carrier_line" :label="$t('edit_carrier.carrier_line')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.drivers_name" :label="$t('edit_carrier.drivers_name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.tract_number" :label="$t('edit_carrier.tract_number')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.tract_plates" :label="$t('edit_carrier.tract_plates')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.container_number" :label="$t('edit_carrier.container_number')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.box_plates" :label="$t('edit_carrier.box_plates')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.seal_number" :label="$t('edit_carrier.seal_number')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.waybill" :label="$t('edit_carrier.waybill')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="carrier.observations" :label="$t('edit_carrier.observations')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0 px-0">
                                <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :disabled="identification_file" :clearable="false" v-model="carrier.identification_photo" accept="image/*" capture="camera" :label="$t('edit_carrier.identification_photo')"></v-file-input>
                            </v-col>
                            <v-col cols="1" >
                                <v-row justify="end" align="end">
                                    <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('identification')" :disabled="identification_file == false"><v-icon small>download</v-icon></v-btn>
                                    <v-btn outlined color="red" small class="px-0 mx-1" style="min-width:25px !important" :disabled="identification_file == false" @click="deleteFile('identification')"><v-icon  small>delete</v-icon></v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0 px-0">
                                <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :disabled="vehicle_photo_front" :clearable="false" v-model="carrier.vehicle_photo_front" accept="image/*" capture="camera" :label="$t('edit_carrier.vehicle_photo_front')"></v-file-input>
                            </v-col>
                            <v-col cols="1" >
                                <v-row justify="end" align="end">
                                    <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('vehicle_photo_front')" :disabled="vehicle_photo_front == false"><v-icon small>download</v-icon></v-btn>
                                    <v-btn outlined color="red" small class="px-0 mx-1" style="min-width:25px !important" :disabled="vehicle_photo_front == false" @click="deleteFile('vehicle_photo_front')"><v-icon  small>delete</v-icon></v-btn>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0 px-0">
                                <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :disabled="vehicle_photo_back" :clearable="false" v-model="carrier.vehicle_photo_back" accept="image/*" capture="camera" :label="$t('edit_carrier.vehicle_photo_back')"></v-file-input>
                            </v-col>
                            <v-col cols="1" >
                                <v-row justify="end" align="end">
                                    <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('vehicle_photo_back')" :disabled="vehicle_photo_back == false"><v-icon small>download</v-icon></v-btn>
                                    <v-btn outlined color="red" small class="px-0 mx-1" style="min-width:25px !important" :disabled="vehicle_photo_back == false" @click="deleteFile('vehicle_photo_back')"><v-icon  small>delete</v-icon></v-btn>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0 px-0">
                                <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :disabled="vehicle_photo_side" :clearable="false" v-model="carrier.vehicle_photo_side" accept="image/*" capture="camera" :label="$t('edit_carrier.vehicle_photo_side')"></v-file-input>
                            </v-col>
                            <v-col cols="1" >
                                <v-row justify="end" align="end">
                                    <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('vehicle_photo_side')" :disabled="vehicle_photo_side == false"><v-icon small>download</v-icon></v-btn>
                                    <v-btn outlined color="red" small class="px-0 mx-1" style="min-width:25px !important" :disabled="vehicle_photo_side == false" @click="deleteFile('vehicle_photo_side')"><v-icon  small>delete</v-icon></v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0 px-0">
                                <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :disabled="vehicle_photo_seal" :clearable="false" v-model="carrier.vehicle_photo_seal" accept="image/*" capture="camera" :label="$t('edit_carrier.vehicle_photo_seal')"></v-file-input>
                            </v-col>
                            <v-col cols="1" >
                                <v-row justify="end" align="end">
                                    <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('vehicle_photo_seal')" :disabled="vehicle_photo_seal == false"><v-icon small>download</v-icon></v-btn>
                                    <v-btn outlined color="red" small class="px-0 mx-1" style="min-width:25px !important" :disabled="vehicle_photo_seal == false" @click="deleteFile('vehicle_photo_seal')"><v-icon  small>delete</v-icon></v-btn>
                                </v-row>
                            </v-col>
                        </v-row>



                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('edit_carrier.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'carriers.create'">
                                {{ $t('edit_carrier.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>  
        <v-dialog v-model="modalDelete" persistent max-width="90%">
            <v-card >
                <v-card-title class="justify-center">
                    <v-row align="center" justify="center">
                            <h2>
                                {{ $t('upload_files.modal.title') }}
                            </h2>
                    </v-row>
                    <v-btn color="amarillo darken-1" small icon @click="modalDelete = false"><v-icon small>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    
                    <v-row align="center" justify="center" class="py-3 my-3 text-center">
                        <v-col cols="12">
                            <h3>{{ $t('upload_files.modal.continue') }}</h3>
                        </v-col>
                    </v-row>
                    
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn class="mx-3" text @click="modalDelete = false; currentDelete = ''">{{ $t('upload_files.modal.cancel') }}</v-btn>
                        <v-btn color="amarillo" class="color" @click="deleteM">
                            {{ $t('upload_files.modal.delete') }}
                        </v-btn>
                        
                    </div>
                </v-card-text> 
            </v-card>
        </v-dialog>      
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            menu: false,
            arrival_time_modal: false,
            modalDelete: false,
            currentDelete: "",
            current_files: [],
            identification_file: false,
            vehicle_photo_front: false,
            vehicle_photo_back: false,
            vehicle_photo_side: false,
            vehicle_photo_seal: false,
            carrier: {
                arrival_date: "",
                arrival_time: "", 
                carrier_line: "", 
                drivers_name: "", 
                tract_number: "", 
                tract_plates: "", 
                container_number: "", 
                box_plates: "", 
                seal_number: "", 
                waybill: "",
                observations: "",
                identification_photo: [],
                vehicle_photo_front: [],
                vehicle_photo_back: [],
                vehicle_photo_side: [],
                vehicle_photo_seal: [],
                warehouse_id: this.$store.state.warehouseId,
            }, 
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.edit_carrier";
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'carriers/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.current_files = [];
                response.data = response.data.data.attributes
                this.carrier = response.data;

                if(this.carrier.identification_photo != null) {
                    this.identification_file = true;
                    this.current_files.push(this.carrier.identification_photo);

                    this.carrier.identification_photo = new File(["foo"], this.carrier.identification_photo.filename, {
                        type: "text/plain",
                    });
                }
                    
                if(this.carrier.vehicle_photo_front != null) {
                    this.vehicle_photo_front = true;
                    this.current_files.push(this.carrier.vehicle_photo_front);

                    this.carrier.vehicle_photo_front = new File(["foo"], this.carrier.vehicle_photo_front.filename, {
                        type: "text/plain",
                    });
                }

                if(this.carrier.vehicle_photo_back != null) {
                    this.vehicle_photo_back = true;
                    this.current_files.push(this.carrier.vehicle_photo_back);

                    this.carrier.vehicle_photo_back = new File(["foo"], this.carrier.vehicle_photo_back.filename, {
                        type: "text/plain",
                    });
                }

                if(this.carrier.vehicle_photo_side != null) {
                    this.vehicle_photo_side = true;
                    this.current_files.push(this.carrier.vehicle_photo_side);

                    this.carrier.vehicle_photo_side = new File(["foo"], this.carrier.vehicle_photo_side.filename, {
                        type: "text/plain",
                    });
                }

                if(this.carrier.vehicle_photo_seal != null) {
                    this.vehicle_photo_seal = true;
                    this.current_files.push(this.carrier.vehicle_photo_seal);

                    this.carrier.vehicle_photo_seal = new File(["foo"], this.carrier.vehicle_photo_seal.filename, {
                        type: "text/plain",
                    });
                }

            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const formData = new FormData();

            formData.append('arrival_date', this.carrier.arrival_date);
            formData.append('arrival_time', this.carrier.arrival_time);
            formData.append('carrier_line', this.carrier.carrier_line);
            formData.append('drivers_name', this.carrier.drivers_name);
            formData.append('tract_number', this.carrier.tract_number);
            formData.append('tract_plates', this.carrier.tract_plates);
            formData.append('container_number', this.carrier.container_number);
            formData.append('box_plates', this.carrier.box_plates);
            formData.append('seal_number', this.carrier.seal_number);
            formData.append('waybill', this.carrier.waybill);
            formData.append('observations', this.carrier.observations);
            formData.append('warehouse_id', this.carrier.warehouse_id);

            if(this.carrier.identification_photo != null && this.carrier.identification_photo.type != 'text/plain') formData.append('identification_photo', this.carrier.identification_photo);
            if(this.carrier.vehicle_photo_front != null && this.carrier.vehicle_photo_front.type != 'text/plain') formData.append('vehicle_photo_front', this.carrier.vehicle_photo_front);
            if(this.carrier.vehicle_photo_back != null && this.carrier.vehicle_photo_back.type != 'text/plain') formData.append('vehicle_photo_back', this.carrier.vehicle_photo_back);
            if(this.carrier.vehicle_photo_side != null && this.carrier.vehicle_photo_side.type != 'text/plain') formData.append('vehicle_photo_side', this.carrier.vehicle_photo_side);
            if(this.carrier.vehicle_photo_seal != null && this.carrier.vehicle_photo_seal.type != 'text/plain') formData.append('vehicle_photo_seal', this.carrier.vehicle_photo_seal);
            
            // console.log([...formData]) // Print form data displaying all pr

            this.$http.put(this.$store.state.chronosApi + 'carriers/' + this.id, formData,{headers: {Authorization: lsToken}})
            .then((response) => {
                response
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('create_box.responses.success')
                }
                this.$router.push({ name: "CarriersIndex" });  
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
        getFile(type){
            this.$store.state.loaderProps.visible = true;
            this.current_files.forEach(x => {
                if(x.filename.includes(type)){    
                this.$store.state.loaderProps.visible = true;
                    this.$http.get(this.$store.state.chronosApi + 'getSingleFile/' + x.id, { responseType: 'blob'})
                    .then(response => {
                        FileSaver.saveAs(response.data, x.filename)
                        this.$store.state.loaderProps.visible = false;
                    })
                }
            })

            this.$store.state.loaderProps.visible = false;
        },
        deleteFile(type){
            this.modalDelete = true;
            this.currentDelete = type;
        },
        deleteM(){
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;
            var index = null; 
            this.current_files.forEach(x => {
                if(x.filename.includes(this.currentDelete)){
                    index = x.id
                }
            });
            this.$http.delete(this.$store.state.chronosApi + 'appointments/' + index + '/deleteFile', {headers: {Authorization: lsToken}})
            .then(response => {
                if(response.status == 200){
                    this.modalDelete = false;
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('upload_files.responses.deleted')
                    }
                }
                this.current_files[this.currentDelete] = false;
                this.index();
            }).catch((error) => {
                console.log(error);
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('upload_files.responses.error')
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        }
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>