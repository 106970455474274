<template>
  <v-app>
    <component :is="this.$store.state.layout"></component>
  </v-app>
</template>

<script>
import Vue from 'vue';
import "./assets/main.scss";

export default Vue.extend({
  name: 'Chronos_App',

  components: {
    // HelloWorld,
  },

  data(){
    return {
      // component: this.$store.state.layout
    }
  },
});
</script>
<style>
  .uppercase--text {
    text-transform: uppercase;
  }
</style>
