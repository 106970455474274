<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-spacer></v-spacer> 
                <v-col cols="6" align="end" class="pb-3 pt-0">                    
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" @click="show_export_options = true" v-if="show_export_options == false">
                        {{ $t('stock_report.export') }}
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataPdf">
                        PDF
                    </v-btn>
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataExcel">
                        Excel
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="show_export_options = false;">
                        <v-icon small class="px-0">
                            keyboard_arrow_left
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('stock_report.sku'), align: "center", value: 'sku', class: 'text-uppercase' },
                { text: this.$t('stock_report.description'), align: "center", value: 'description', class: 'text-uppercase' },
                { text: this.$t('stock_report.quantity'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('stock_report.damaged'), align: "center", value: 'daamged', class: 'text-uppercase' },
                { text: this.$t('stock_report.batch'), align: "center", value: 'batch', class: 'text-uppercase' },
                { text: this.$t('stock_report.expiration'), align: "center", value: 'expiration', class: 'text-uppercase' }
            ];
        },
        client: function(){
            return this.$store.state.clientId
        },
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            report:{
                initial_date: '',
                final_date: ''
            },
            menu2: false,
            menu3: false,
            showExport: false,
            show_export_options: false,
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.stocks";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.generate();
        },
        generate(){
            this.props.items =[];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'reports/stocksWithoutLocation?warehouse=' + this.$store.state.warehouseId + '&client=' + this.$store.state.clientUserId, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.props.items = response.data.data;
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        exportDataExcel(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'reports/stocksWithoutLocationExport?warehouse=' + this.$store.state.warehouseId + '&client=' + this.$store.state.clientUserId, {headers: {Authorization: lsToken}, responseType:'blob'})
            .then(response => {
                this.$store.state.loaderProps.visible = false; 
                FileSaver.saveAs(response.data, 'Existencias.xlsx');
                this.show_export_options = false;
            })
            .catch(() => {
                this.$store.state.loaderProps.visible = false;
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            })
        },
        exportDataPdf(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'reports/stocksWithoutLocationPDF?warehouse=' + this.$store.state.warehouseId + '&client=' + this.$store.state.clientUserId, {headers: {Authorization: lsToken}, responseType:'blob'})
            .then(response => {
                this.$store.state.loaderProps.visible = false; 
                FileSaver.saveAs(response.data, 'Existencias.pdf');
                this.show_export_options = false;
            })
            .catch(() => {
                this.$store.state.loaderProps.visible = false;
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            })
        }
    }
}
</script>

<style>
</style>