import http from "../plugins/http-common"

export default {
  dischargesReport({year}){
    return http.get(`customs_patches_reports/dischargeReport?year=${year}`);
  },
  balanceReport(){
    return http.get(`customs_patches_reports/balanceReport`);
  },
  expirationReport(){
    return http.get(`customs_patches_reports/expirationReport`);
  },
  annex30({annex}){
    return http.get(`customs_patches_reports/annex30?cve=${annex.cve}&bimester=${annex.bimester}&year=${annex.year}`)
  },
  dischargesReportExport({year}){
    return http.get(`customs_patches_reports/dischargeReportExport?year=${year}`, {responseType: 'blob'})
  },
  getBalanceReportExport(){
    return http.get(`customs_patches_reports/balanceReportExport`, {responseType: 'blob'});
  },
  getExpirationReportExport(){
    return http.get(`customs_patches_reports/expirationReportExport`, {responseType: 'blob'})
  }
}