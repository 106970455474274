<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('create_kit.new') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('create_kit.general_data')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="kit.sku" :label="$t('create_kit.sku')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="kit.description" :label="$t('create_kit.description')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="py-0" v-model="kit.client_id" :items="customers" item-value="id" item-text="name" :label="$t('create_kit.customer')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-form  ref="products" lazy-validation @submit.prevent="addProduct"> 
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('create_kit.products')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="py-0" v-model="product.product_id" :items="products" item-value="id" item-text="sku" :label="$t('create_kit.product')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="product.quantity" :label="$t('create_kit.quantity')" :rules="rules_number"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0" justify="center">
                            <v-col cols="6" justify="end" align="end">
                                <v-btn class="right white--text" outlined color="amarillo" @click="addProduct" >
                                    {{ $t('create_kit.add') }}
                                </v-btn>
                            </v-col>
                        </v-row> 
                    </v-form>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0">
                        <datatable class="custom_table_class" :props="{headers: headers, items: kit.details}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>   
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('create_kit.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission:all="'kit.create|kit_detail.create'">
                                {{ $t('create_kit.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
import Datatable from '../../components/Datatable.vue'
export default {
  components: { Datatable },
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        rules_number(){ return [
                v => v == null || /^(|\d)+$/.test(v) || this.$t('edit_appointment.validations.numeric'),
                v => !!v || this.$t('create_appointment.validations.required_field')
        ]},
        headers(){
            return [
                { text: this.$t('create_kit.sku'), align: "center", value: 'product.sku', class: 'text-uppercase' },
                { text: this.$t('create_kit.quantity'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: '', align: "center", value: 'actions', class: 'text-uppercase', width:'8%' },
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            actions: [
                {
                    name: this.$t('create_kit.remove'),
                    icon: {
                        color: 'error',
                        icon: 'close'
                    },
                    method: 'remove',
                    permission: ''
                },
            ],
            kit: {
                id: '',
                sku: '',
                description: '',
                client_id: '',
                warehouse_id: localStorage.getItem("warehouse_id"),
                details: []
            },
            customers: [],
            products: [],
            product: {
                product_id: '',
                sku: '',
                quantity: '',
                actions: ''
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.create_kit";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.customers = [];
            this.products = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.$http.get(this.$store.state.chronosApi + 'clientsIndex/2', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x =>{
                    this.customers.push({id: x.attributes.id, name: x.attributes.name});
                });
            });
            
            this.$store.state.loaderProps.visible = false;
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.post(this.$store.state.chronosApi + 'kits', this.kit,{headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status == 201){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('create_kit.responses.success')
                    }
                    this.$router.push({ name: "KitsIndex" });  
                }
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        addProduct(){
            if(this.product.product_id != "" && this.product.product_id != undefined){
                this.product.actions = JSON.parse(JSON.stringify(this.actions));
                this.product.sku = this.products.find(x => x.id == this.product.product_id).sku;
                this.kit.details.push({
                    product: {
                        sku: this.product.sku,
                        id: this.product.product_id,
                    },
                    quantity: this.product.quantity,
                    actions: this.product.actions,
                    parameters: {id: this.product.product_id, qty: this.product.quantity}, //always put the last index 
                });

                this.$refs.products.reset();
            } 
        },
        remove(item){
            var index = this.kit.details.indexOf(this.kit.details.find(x => x.product_id == item.id && x.quantity == item.qty));
            this.kit.details.splice(index, 1);
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
        "kit.client_id": function(){
            this.products = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'getProducts/' + this.kit.client_id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x =>{
                    x = x.attributes;
                    this.products.push({id: x.id, sku: x.sku});
                });
               this.$store.state.loaderProps.visible = false;
            });
        }
    }
}
</script>

<style lang="css">
</style>