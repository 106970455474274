<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('edit_location.edit') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="location.name" :label="$t('edit_location.name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="location.stacking_level" :label="$t('edit_location.stacking_level')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <!-- <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="location.warehouse.id" :items="warehouses" :label="$t('edit_location.warehouse')" item-text="name" item-value="id" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row> -->
                            
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="is_bay">
                            <v-col cols="6" class="py-0">
                                <datatable :props="{ headers:headers, items: bay_locations }" @methodHandler="methodHandler"></datatable>
                            </v-col>
                        </v-row>
                    </v-form>

                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="disableLocation" :disabled="!can_disable" v-if="!is_bay">
                                {{ $t('edit_location.disable') }}
                            </v-btn>
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('edit_location.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save">
                                {{ $t('edit_location.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        headers(){
            return [
                { text: this.$t('edit_location.location'), align: "center", value: 'name', class: 'text-uppercase' },
                { text: this.$t('edit_location.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            is_bay: false,
            location: {
                name: '',
                stacking_level: '',
                warehouse: {
                    name: ''
                },
                is_enabled: true
            },
            actions: [
            {
                    name: this.$t('edit_location.disable'),
                    icon: {
                        color: 'gray',
                        icon: 'not_interested'
                    },
                    method: "disableLocationOfList",
                    permission: "location.show",
                    value: false,
                },
            ],
            bay_locations: [],
            can_disable: true,
            // warehouses: []
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.edit_location";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            if(this.id.includes('-')){ // get bay's locations and details
                this.id = this.id.split("-")[0];
                this.is_bay = true;

                this.$http.get(this.$store.state.chronosApi + 'locations-bay/' + this.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    this.is_bay = true;
                    this.location = {
                        id: response.data.location.id,
                        name: response.data.location.name,
                        stacking_level: response.data.location.stacking_level,
                        warehouse: {
                            name: response.data.location.warehouse_name,
                            id: response.data.location.w_id
                        }
                    }

                    response.data.locations.data.forEach( x => {
                        x['attributes']['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x['attributes']['parameters'] = this.bay_locations.length;
                        
                        this.bay_locations.push(x.attributes);
                    })
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.$http.get(this.$store.state.chronosApi + 'locations/' + this.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    response.data = response.data.data.attributes;
                    this.location = response.data;
                    if(this.location.is_enabled == false) this.can_disable = false;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
            }

            // this.$http.get(this.$store.state.chronosApi + 'warehouses', {headers: {Authorization: lsToken}})
            // .then((response) => {
            //     response.data.forEach(x => {
            //         this.warehouses.push({name: x.name, id: x.id});
            //     })
            // }).catch((err) => {
            //     console.log(err);
            // }).finally(() => {
            //     this.$store.state.loaderProps.visible = false;
            // })
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.bay_locations.forEach( x => {
                delete x.actions;
                delete x.current_products;
                delete x.parameters;
                delete x.warehouse;
            })
            if(this.is_bay == true){
                const complete_location = {
                    bay: this.location,
                    locations: this.bay_locations
                }

                this.$http.put(this.$store.state.chronosApi + 'updateBay', complete_location,{headers: {Authorization: lsToken}})
                .then((response) => {
                    response
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('edit_location.responses.success')
                    }
                    this.$router.push({ name: "LocationsIndex" });  
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }else{
                this.$http.put(this.$store.state.chronosApi + 'locations/' + this.id, this.location,{headers: {Authorization: lsToken}})
                .then((response) => {
                    response
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('edit_location.responses.success')
                    }
                    this.$router.push({ name: "LocationsIndex" });  
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
        },
        disableLocation(){
            if(this.location.current_products.length > 0){
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('edit_location.cannot_disable')
                }
            }
            else{
                this.location.is_enabled = false;
                this.can_disable = false;
            }
        }, 
        disableLocationOfList(index){
            this.bay_locations[index]['is_enabled'] = !this.bay_locations[index]['is_enabled'];

            if(this.bay_locations[index]['is_enabled'] == true){
                this.bay_locations[index]['actions'][0].icon.color = "accent";
                this.bay_locations[index]['actions'][0].name = this.$t('edit_location.disable')
            }
            else {
                this.bay_locations[index]['actions'][0].icon.color = "red";
                this.bay_locations[index]['actions'][0].name = this.$t('edit_location.enable')
            }

            console.log(this.bay_locations[index]['actions'][0]);
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>