<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col cols="6" class="py-0">
                    <v-col cols="8" class="py-0 my-0">
                        <client-selector :addAllOption="false" class="py-0 my-0 " ref="clientSelector" @getClients="v => items.clients = v" :only_fulfillment="1" :is_national="2" :cod="1"></client-selector>  
                    </v-col>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="6" align="end" class="py-0">                    
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" @click="show_export_options = true" v-if="show_export_options == false">
                        {{ $t('without_movement.export') }}
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataPdf">
                        PDF
                    </v-btn>
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataExcel">
                        Excel
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="show_export_options = false;">
                        <v-icon small class="px-0">
                            keyboard_arrow_left
                        </v-icon>
                    </v-btn>
                
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="generate">
                        {{ $t('without_movement.generate') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>    
        <search-dialog :uon="current_uon" v-if="current_uon != ''" class="show_search_modal"></search-dialog>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('without_movement.order'), align: "center", value: 'unique_order_number', class: 'text-uppercase' },
                { text: this.$t('without_movement.tracking_number'), align: "center", value: 'shipment_number', class: 'text-uppercase' },
                { text: this.$t('without_movement.parcel'), align: "center", value: 'shipping_service', class: 'text-uppercase' },
                { text: this.$t('without_movement.status'), align: "center", value: 'last_status', class: 'text-uppercase' },
                { text: this.$t('without_movement.pickup'), align: "center", value: 'pickup_date', class: 'text-uppercase' },
                { text: this.$t('without_movement.last_update'), align: "center", value: 'last_updated_at', class: 'text-uppercase' },
                { text: this.$t('without_movement.actions'), align: "center", value: 'actions', class: 'text-uppercase' }
            ];
        },
        client: function(){
            return this.$store.state.clientId
        },
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            report:{
                initial_date: '',
                final_date: ''
            },
            menu2: false,
            menu3: false,
            showExport: false,
            show_export_options: false,
            actions: [
                {
                    name: this.$t('without_movement.detail'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showInfo',
                    permission: "without_movement.index"
                }
            ],
            showSearch: false,
            current_uon: ""
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.without_movement";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
        },
        generate(){
            this.props.items =[];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'trackNTrace/without_movement?client_id=' + this.$store.state.clientId, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x => {
                    x['actions'] = JSON.parse(JSON.stringify(this.actions));
                    x["parameters"] = x.unique_order_number;
                    this.props.items.push(x); 
                });
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        exportDataExcel(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.$http.get(this.$store.state.chronosApi + 'trackNTrace/without_movement_export?client_id=' + this.$store.state.clientId, {headers: {Authorization: lsToken}, responseType:'blob'})
            .then(response => {
                this.$store.state.loaderProps.visible = false; 
                FileSaver.saveAs(response.data, 'without_movement.xlsx');
                this.show_export_options = false;
            })
            .catch(() => {
                this.$store.state.loaderProps.visible = false;
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            })
        },
        exportDataPdf(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'reports/stocksByClientPdf?warehouse=' + this.$store.state.warehouseId + '&client=' + this.$store.state.clientId, {headers: {Authorization: lsToken}, responseType:'blob'})
            .then(response => {
                this.$store.state.loaderProps.visible = false; 
                FileSaver.saveAs(response.data, 'Existencias.pdf');
                this.show_export_options = false;
            })
            .catch(() => {
                this.$store.state.loaderProps.visible = false;
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            })
        },
        showInfo(uon){
            this.current_uon = uon;
            this.showSearch = true;
        },
    }
}
</script>

<style>
</style>