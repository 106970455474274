<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-card-title class="justify-center">
                    <v-spacer></v-spacer>
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('customer_fulfillment.import_shipments') }}</h3>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-card-title>

                <v-card-text>
                    <v-row justify="center" align="center">
                        <v-col cols="10" justify="center" align="center">
                            <v-stepper v-model="stepper">
                            <v-stepper-header>
                                <v-stepper-step :complete="stepper > 1" step="1" color="amarillo">{{$t('customer_fulfillment.select_file')}}</v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step :complete="stepper > 2" step="2" color="amarillo">{{ $t('customer_fulfillment.verify_data') }}</v-stepper-step>
                                <v-divider></v-divider>
                            </v-stepper-header>

                            <v-stepper-items>
                                <v-stepper-content step="1" class="mx-0 px-0">
                                    <v-card flat class="mb-5">
                                        <v-form ref="form2" lazy-validation>
                                            <v-row align="center" justify="center">
                                                <v-col cols="10" justify="center" align="center">
                                                    <client-selector :addAllOption="false"  ref="clientSelector" @getClients="v => items.clients = v" :only_fulfillment="1"></client-selector>  
                                                </v-col>
                                            </v-row>
                                            <v-row class="mx-0 px-0" justify="center" align="center">
                                                <v-col cols="10">
                                                    <v-file-input v-model="inputFile" accept=".csv" :label="$t('customer_fulfillment.select_file')"></v-file-input>
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn class="mx-0" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="stepper = 3">
                                                                <v-icon aria-hidden="false" color="white" small>
                                                                    help
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>{{ $t('customer_fulfillment.help') }}</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-card>
                                    
                                    <div class="d-flex">
                                        <v-spacer></v-spacer>
                                        <v-btn class="right mr-2 white--text" color="amarillo" @click="uploadOrders" >
                                            {{ $t('customer_fulfillment.next') }}
                                        </v-btn>
                                    </div>
                                </v-stepper-content>

                                <v-stepper-content step="2">
                                    <v-card flat class="mb-5">
                                        <v-card-text class="my-0 py-0">
                                            <p class="my-0 py-0">{{readRows2.length}} {{ $t('customer_fulfillment.orders_in_file') }}</p>
                                        </v-card-text>
                                        <v-simple-table v-if="this.readRows2.length > 0" fixed-header height="300px">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th v-for="(th, key) in sTHeaders2" :key="key" class="text-left">{{ th }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, i) in readRows2" :key="i">
                                                        <td v-for="(td, j) in item.split(',')" :key="j">{{ td }}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>

                                    <div class="d-flex">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-3" text @click="stepper = 1; sTHeaders2 = []; readRows2 = []">{{ $t('customer_fulfillment.go_back') }}</v-btn>
                                        <v-btn color="amarillo" class="white--text" @click="modal2 = false; saveOrders()">
                                            {{ $t('customer_fulfillment.save_data') }}
                                        </v-btn>
                                    </div>
                                </v-stepper-content>

                                <v-stepper-content step="3" style="width: 100% !important;text-align: start;">
                                    <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                                    <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                                    <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                                    <p class="py-0 my-0">4.- El archivo de citas debe ser formato CSV.</p> 
                                    <p class="py-0 my-0">5.- Paqueterias permitidas: 99MINUTOS, DHL, FEDEX, HERMES, OTRA, QUIKEN, YEGO.</p> 
                                    <p class="py-0 my-0">6.- Limite permitido para Nombre_cliente es de 35 caracteres.</p> 
                                    <p class="py-0 my-0">7.- Limite permitido para Colonia es de 35 caracteres.</p> 
                                    <p class="py-0 my-0">8.- Limite permitido para Calle es de 25 caracteres.</p> 
                                    <p class="py-0 my-0">9.- Limite permitido para No_Ext es de 5 caracteres.</p> 
                                    <p class="py-0 my-0">10.- Limite permitido para No_Int es de 5 caracteres.</p> 
                                    <p class="py-0 my-0">11.- Limite permitido para Telefono es de 10 caracteres.</p>
                                    <p class="py-0 my-0">12.- Para multiples productos los Sku deben ser separados por un "|".</p>
                                    <p class="py-0 my-0">13.- Para multiples productos las cantidades deben ser separadas por un "|".</p>
                                    <p class="py-0 my-0">14.- Para multiples productos los precio_unitario deben ser separadas por un "|".</p>
                                    <p class="py-0 my-0">15.- Cuando la paquetería lleve el valor OTRA agrega en la columna pickup del final el nombre del servicio externo que entregará la orden, como FedEx, DHL, UPS, Tres Guerras o cualquier otra, con un máximo de 40 caracteres, caso contrario deja la columna vacía.</p>
                                    
                                    <p class="py-0 my-0">Columnas: </p>

                                    <v-simple-table fixed-header>
                                        <template>
                                            <thead>
                                                <tr>
                                                    <th class="text-left" style="padding: 0 5px !important;">Cod</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Número_de_orden</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Paquetería</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Nombre_cliente</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">País</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Estado</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Municipio</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Colonia</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Calle</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">No_Ext</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">No_Int</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Código_Postal</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Correo</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Teléfono</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Comentarios</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Sku</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Cantidad</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">precio_unitario</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Precio_Total</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">Pickup</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>false/true</td>
                                                    <td>N21697</td>
                                                    <td>FEDEX</td>
                                                    <td>Felipe Becerra González</td>
                                                    <td>México</td>
                                                    <td>Guanajuato</td>
                                                    <td>San Francisco del Rincón</td>
                                                    <td>Centro</td>
                                                    <td>Alvaro Obregón</td>
                                                    <td>308</td>
                                                    <td></td>
                                                    <td>36300</td>
                                                    <td>felipbeg@gmail.com</td>
                                                    <td>4767476419</td>
                                                    <td>entre calle "x" y calle "y"</td>
                                                    <td>sku1|sku2</td>
                                                    <td>cantidad1|cantidad2</td>
                                                    <td>precioSku1|precioSku2</td>
                                                    <td>1199.99</td>
                                                    <td>Tres Guerras</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>

                                    <v-row align="center" justify="center" class="my-3">
                                        <v-btn color="amarillo" class="white--text" @click="stepper = 1">
                                            {{ $t('products.go_back') }}
                                        </v-btn>
                                    </v-row>
                                </v-stepper-content>

                                <v-stepper-content step="4">
                                    <v-card flat class="mb-5">
                                        <v-simple-table v-if="this.responses.length > 0" fixed-header height="300px">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" class="text-left">{{ $t('customer_fulfillment.order') }}</th>
                                                        <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" class="text-left">{{ $t('customer_fulfillment.message') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, i) in responses" :key="i">
                                                        <td>{{ item.order }}</td>
                                                        <td>{{ item.message }}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>

                                    <div class="d-flex">
                                        <v-btn class="px-3" text @click="stepper = 1; sTHeaders2 = []; readRows2 = []; modalImport = false; responses = []; index()">{{ $t('customer_fulfillment.close') }}</v-btn>
                                    </div>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    computed:{        
        rules_required(){return [v => !!v || this.$t('new_order.validations.required_field')]},  
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            token: '',
            inputFile: [],
            readFile2: '',
            readRows2: [],
            responses: [],
            clientId: "",
            client: "",
            bearer: "",
            stepper: 1,
        }
    },
    mounted(){
        this.$store.state.module = "headers.create_multiple_orders";
    },
    methods: {
        index(){
        },

        uploadOrders(){
            if(this.$refs.form2.validate()){
                this.createInput2(this.inputFile);
            }
        },
        createInput2(file) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    /* handle a successful result */
                    this.readRows2 = result.split('\r\n');
                    this.sTHeaders2 = this.readRows2[0].split(',');
                    this.readRows2.shift();
                    this.stepper = 2;
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },
        saveOrders(){
            this.$store.state.loaderProps.visible = true
            const orders = [];
            if(this.readRows2.length > 0){
                var w_id = localStorage.getItem('warehouse_id') || 1;
                this.readRows2.forEach((x) => {
                    if(x != ""){
                        const num = x.split(',');
                        let yourDate = new Date();
                        yourDate = yourDate.toISOString().split('T')[0];

                        var order_details = [];
                        var is_valid = true;
                        var message = "";

                        if(
                            (num[15].split('|').length == num[16].split('|').length && num[15].split('|').length == num[17].split('|').length) 
                            || (num[15].split('|').length == num[16].split('|').length 
                                && (num[17] == "" || num[17] == null || num[17] == undefined))){
                            
                            // check the number of products, quantity and unit price 
                            // sku1|sku2 -> //quantity1|quantity2 -> unit_price1|unit_price2
                            for( var i = 0; i < num[15].split('|').length; i++ ){
                                if(num[15].split('|')[i] == "" || num[15].split('|')[i] == null || num[15].split('|')[i] == undefined || 
                                num[16].split('|')[i] == "" || num[16].split('|')[i] == null || num[16].split('|')[i] == undefined || 
                                num[17].split('|')[i] == "" || num[17].split('|')[i] == null || num[17].split('|')[i] == undefined){
                                    is_valid = false;
                                }

                                if(is_valid == true){
                                    order_details.push({
                                        sku: num[15].split('|')[i],
                                        quantity: parseInt(num[16].split('|')[i]),
                                        unit_price: num[17].split('|')[i]
                                    });
                                }
                                // Check if unit price is empty an fill with 1s
                                else {
                                    is_valid = true;
                                    order_details.push({
                                        sku: num[15].split('|')[i],
                                        quantity: parseInt(num[16].split('|')[i]),
                                        unit_price: 1
                                    });
                                }
                            }
                            
                            if(is_valid){
                                orders.push({
                                    cod: (num[0].toString().toLowerCase() == "false" ? false : true),
                                    unique_order_number: num[1],
                                    shipping_service: num[2],
                                    invoice: "---",
                                    date: yourDate,
                                    warehouse_id: parseInt(w_id),
                                    consignee: {
                                        avoid_validations: true,
                                        name: num[3],
                                        country: num[4],
                                        state: num[5],
                                        neighborhood: num[7], 
                                        external_number: num[9],
                                        street: num[8],
                                        internal_number: num[10],
                                        zipcode: num[11],
                                        email: num[12],
                                        phone: num[13],
                                        municipality: num[6],
                                        comments: num[14].length < 3 ? "---" : num[14],
                                    },
                                    order_details: order_details,
                                    total_price: num[18],
                                    external_shipping_service: num[19] ?? ""
                                });
                            }
                            else{
                                this.responses.push({
                                    order: num[1],
                                    message: message
                                });

                                this.$store.state.loaderProps.visible = false;
                            }
                        }
                        else {
                            this.responses.push({
                                order: num[1],
                                message: this.$t("orders.wrong_details")
                            });
                        }
                        
                    }
                })

                if(this.bearer != ""){
                    orders.forEach( x =>{
                        if(x.shipping_service == "OTRA"){ 
                            this.createOtraShipment(x);
                        }
                        else this.createNormalShipment(x);
                    })

                    this.stepper = 4;
                    this.$store.state.loaderProps.visible = false;

                }
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('upload_annex.validations.select_file_first')
                };
            }
        },
        createNormalShipment(x){
            this.$http.post(this.$store.state.chronosApi+'shipment/create', x, { headers: { Authorization: this.bearer } })
            .then((response) => {
                if(response.data.code === 200){
                    var errors = "";
                    if(Object.prototype.hasOwnProperty.call(response.data, "code")){
                        if(response.data.message != "The order has been placed, stock availability will be checked and if confirmed, order status will be updated to READY"){
                            Object.keys(response.data).forEach( x =>{
                                response.data[x].forEach(y => {
                                    errors += x + ": " + y;
                                })
                            })

                            this.responses.push({
                                order: x.unique_order_number,
                                message: errors
                            });
                        }
                        else{
                            this.responses.push({
                                order: x.unique_order_number,
                                message: this.$t("orders.created_successfully")
                            });
                        }
                    }
                    else{
                        if(response.data.code != 200){
                            Object.keys(response.data).forEach( x =>{
                                response.data[x].forEach(y => {
                                    errors += x + ": " + y;
                                })
                            })
                            this.responses.push({
                                order: x.unique_order_number,
                                message: errors
                            });
                        }
                    }
                }
                this.$store.state.loaderProps.visible = false;
            })
            .catch((error) => {
                this.responses.push({
                    order: x.unique_order_number,
                    message: error.response.data.message
                });
                this.$store.state.loaderProps.visible = false;
            });
        },
        createOtraShipment(x){
            const formData = new FormData();
            formData.append('date', x.date);
            formData.append('cod', x.cod);
            formData.append('unique_order_number', x.unique_order_number);
            formData.append('shipping_service', x.external_shipping_service);
            // formData.append('shipment_number', x.shipment_number);

            // Optional files
            // if(x.file.length > 0) formData.append('label', x.file);
            // if(x.attached_files.length > 0)
            //     for(var counter = 0; counter < x.attached_files.length; counter++){
            //         formData.append('attached_files[]', x.attached_files[counter]);
            //     }

            formData.append('invoice', x.invoice);
            formData.append('warehouse_id', x.warehouse_id);
            formData.append('total_price', x.total_price);
            formData.append('order_details', JSON.stringify(x.order_details));

            formData.append('consignee_name', x.consignee.name);
            formData.append('consignee_country', x.consignee.country);
            formData.append('consignee_state', x.consignee.state);
            formData.append('consignee_municipality', x.consignee.municipality);
            formData.append('consignee_neighborhood', x.consignee.neighborhood);
            formData.append('consignee_street', x.consignee.street);
            formData.append('consignee_external_number', x.consignee.external_number);
            formData.append('consignee_internal_number', x.consignee.internal_number);
            formData.append('consignee_zipcode', x.consignee.zipcode);
            formData.append('consignee_email', x.consignee.email);
            formData.append('consignee_phone', x.consignee.phone);
            formData.append('consignee_comments', x.consignee.comments);

            // console.log([...formData]) // Print form data displaying all pr

            this.$http.post(this.$store.state.chronosApi+'shipment/create_with_label', formData, { headers: { Authorization: this.bearer, 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.code === 200){
                    var errors = "";
                    if(Object.prototype.hasOwnProperty.call(response.data, "code")){
                        if(response.data.message != "The order has been placed, stock availability will be checked and if confirmed, order status will be updated to READY"){
                            Object.keys(response.data).forEach( x =>{
                                response.data[x].forEach(y => {
                                    errors += x + ": " + y;
                                })
                            })

                            this.responses.push({
                                order: x.unique_order_number,
                                message: errors
                            });
                        }
                        else{
                            this.responses.push({
                                order: x.unique_order_number,
                                message: this.$t("orders.created_successfully")
                            });
                        }
                    }
                    else{
                        if(response.data.code != 200){
                            Object.keys(response.data).forEach( x =>{
                                response.data[x].forEach(y => {
                                    errors += x + ": " + y;
                                })
                            })
                            this.responses.push({
                                order: x.unique_order_number,
                                message: errors
                            });
                        }
                    }
                }
            })
            .catch((error) => {
                this.responses.push({
                    order: x.unique_order_number,
                    message: error.response.data.message
                });
                this.$store.state.loaderProps.visible = false;
            });
            this.$store.state.loaderProps.visible = true;
        },
        cancel(){
            this.$router.go(-1);
        },
        getClientToken(){
            this.$http.get(this.$store.state.chronosApi + 'getClientToken/'+ this.clientId)
            .then(response => {
                this.client = response.data.client

                this.$http.get(this.$store.state.chronosApi + 'getCurrenAvailableToken/' + this.clientId)
                .then(response2 => {
                    if(response2.data.token != "---"){
                        this.bearer = "Bearer " + response2.data.token;
                        if(response2.data.client.fulfillment == "regular"){
                            this.fulfillment = 0;
                            this.types = 0;
                        } 
                        else if(response2.data.client.fulfillment == "cod"){
                            this.fulfillment = 1;
                            this.types = 1
                        }
                        else if(response2.data.client.fulfillment == "all") this.fulfillment = 3;
                    }
                    else if(response2.data.token == "---"){
                        if(this.client != null && this.client.token != ""){
                            this.$http.post(this.$store.state.chronosApi + 'token/generate', {email: "alejandro@intech.mx", password: "afuerzaniloscalcetines:" + this.client.token}, {headers:{}})
                            .then( response3 => {
                                this.bearer = "Bearer " + response3.data.token;
                            })
                        }
                    }
                })
            })
        },
    },
    watch: {
        '$store.state.clientId': function() {
            this.clientId = this.$store.state.clientId;
            this.getClientToken();
        },
    }
}
</script>

<style>

</style>
