<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('edit_customer.update_fees') }} {{ client_name }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <!-- Settings -->

                        <v-row class="px-5 py-0 mt-2 mb-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-expansion-panels>
                                <v-expansion-panel v-for="(item, i) in settings" :key="i">
                                    <v-expansion-panel-header>
                                        {{$t('create_customer.' + i)}}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                            <v-col cols="6" class="py-0" style="display:flex">
                                                <v-text-field class="py-0" v-model="settings[i].fulfillment" :label="$t('create_customer.fulfillment_quote')" ></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="py-0" style="display:flex">
                                                <v-text-field class="py-0" v-model="settings[i].order_insurance" :label="$t('create_customer.order_insurance_quote')" ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                            <v-col cols="6" class="py-0" style="display:flex">
                                                <v-text-field class="py-0" v-model="settings[i].label" :label="$t('create_customer.label_printing_quote')" ></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="py-0" style="display:flex">
                                                <v-text-field class="py-0" v-model="settings[i].cod_fee" :label="$t('create_customer.cod_collection_quote')" ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                            <v-col cols="6" class="py-0" style="display:flex">
                                                <v-text-field class="py-0" v-model="settings[i].shipping_1kg" :label="$t('create_customer.shiping1kg_quote')" ></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="py-0" style="display:flex">
                                                <v-text-field class="py-0" v-model="settings[i].shipping_2kg" :label="$t('create_customer.shiping2kg_quote')" ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                            <v-col cols="6" class="py-0" style="display:flex">
                                                <v-text-field class="py-0" v-model="settings[i].return_1kg" :label="$t('create_customer.return1kg_quote')" ></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="py-0" style="display:flex">
                                                <v-text-field class="py-0" v-model="settings[i].return_2kg" :label="$t('create_customer.return2kg_quote')" ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                            <v-col cols="6" class="py-0" style="display:flex">
                                                <v-text-field class="py-0" v-model="settings[i].packing_1kg" :label="$t('create_customer.packing1kg_quote')" ></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="py-0" style="display:flex">
                                                <v-text-field class="py-0" v-model="settings[i].packing_2kg" :label="$t('create_customer.packing2kg_quote')" ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                            <v-col cols="6" class="py-0" style="display:flex">
                                                <v-text-field class="py-0" v-model="settings[i].extended_zone" :label="$t('create_customer.ext_zone_quote')" ></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="py-0" style="display:flex">
                                                <v-text-field class="py-0" v-model="settings[i].address_correction" :label="$t('create_customer.address_correction')" ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <!-- <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                            
                                            <v-col cols="6" class="py-0" style="display:flex">
                                            </v-col>
                                        </v-row> -->
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-form>
                    
                    <v-row class="px-5 py-0 mt-2" justify="center" align="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('edit_customer.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'client.update'">
                                {{ $t('edit_customer.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            client_name: "",
            parcel_names: [
                {text: "FEDEX", value: "fedex"}, 
                {text: "99MINUTOS", value: "minutos"}, 
                {text: "QUIKEN", value: "quiken"}, 
                {text: "UPS", value: "ups"}, 
                {text: "YEGO", value: "yego"}, 
                {text: "OTRA", value: "otra"}, 
                {text: "MERQ", value: "merq"}, 
                {text: "JTEXPRESS", value: "jtexpress"},
                {text: "AFIMEX", value: "afimex"},
                {text: "CONTRAENTREGA", value: "contraentrega"}
            ],
            settings: {
                fedex: {
                    shipping_service: 'FEDEX',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    shipping_2kg: 0,
                    return_1kg: 0,
                    return_2kg: 0,
                    packing_1kg: 0,
                    packing_2kg: 0,
                    extended_zone: 0,
                    address_correction: 0,
                    order_insurance: 0
                },
                minutos: {
                    shipping_service: '99MINUTOS',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    shipping_2kg: 0,
                    return_1kg: 0,
                    return_2kg: 0,
                    packing_1kg: 0,
                    packing_2kg: 0,
                    extended_zone: 0,
                    address_correction: 0,
                    order_insurance: 0
                },
                quiken: {
                    shipping_service: 'QUIKEN',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    shipping_2kg: 0,
                    return_1kg: 0,
                    return_2kg: 0,
                    packing_1kg: 0,
                    packing_2kg: 0,
                    extended_zone: 0,
                    address_correction: 0,
                    order_insurance: 0
                },
                ups: {
                    shipping_service: 'UPS',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    shipping_2kg: 0,
                    return_1kg: 0,
                    return_2kg: 0,
                    packing_1kg: 0,
                    packing_2kg: 0,
                    extended_zone: 0,
                    address_correction: 0,
                    order_insurance: 0
                },
                yego: {
                    shipping_service: 'YEGO',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    shipping_2kg: 0,
                    return_1kg: 0,
                    return_2kg: 0,
                    packing_1kg: 0,
                    packing_2kg: 0,
                    extended_zone: 0,
                    address_correction: 0,
                    order_insurance: 0
                },
                otra: {
                    shipping_service: 'OTRA',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    shipping_2kg: 0,
                    return_1kg: 0,
                    return_2kg: 0,
                    packing_1kg: 0,
                    packing_2kg: 0,
                    extended_zone: 0,
                    address_correction: 0,
                    order_insurance: 0
                },
                merq: {
                    shipping_service: 'MERQ',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    shipping_2kg: 0,
                    return_1kg: 0,
                    return_2kg: 0,
                    packing_1kg: 0,
                    packing_2kg: 0,
                    extended_zone: 0,
                    address_correction: 0,
                    order_insurance: 0
                },
                jtexpress: {
                    shipping_service: 'JTEXPRESS',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    shipping_2kg: 0,
                    return_1kg: 0,
                    return_2kg: 0,
                    packing_1kg: 0,
                    packing_2kg: 0,
                    extended_zone: 0,
                    address_correction: 0,
                    order_insurance: 0
                },
                afimex: {
                    shipping_service: 'AFIMEX',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    shipping_2kg: 0,
                    return_1kg: 0,
                    return_2kg: 0,
                    packing_1kg: 0,
                    packing_2kg: 0,
                    extended_zone: 0,
                    address_correction: 0,
                    order_insurance: 0
                },
                contraentrega: {
                    shipping_service: 'CONTRAENTREGA',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    shipping_2kg: 0,
                    return_1kg: 0,
                    return_2kg: 0,
                    packing_1kg: 0,
                    packing_2kg: 0,
                    extended_zone: 0,
                    address_correction: 0,
                    order_insurance: 0
                }
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.edit_customer";
    },
    methods: {
        index(){
            // Get countries
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;

            // Get current customer
            this.$http.get(this.$store.state.chronosApi + 'clients/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.client_name = response.data.data.attributes.name;
                response.data.data.attributes.settings.forEach( x => {
                    x.forEach(y => {
                        var index = this.parcel_names.findIndex(x => {
                            if(x.text == y.shipping_service) return x;
                        })

                        if(y.text == 'fulfillment') {this.settings[this.parcel_names[index].value].fulfillment = y.value; return;}
                        else if(y.text == 'label') {this.settings[this.parcel_names[index].value].label = y.value; return;}
                        else if(y.text == 'cod_fee') {this.settings[this.parcel_names[index].value].cod_fee = y.value; return;}
                        else if(y.text == 'shipping_1kg') {this.settings[this.parcel_names[index].value].shipping_1kg = y.value; return;}
                        else if(y.text == 'shipping_2kg') {this.settings[this.parcel_names[index].value].shipping_2kg = y.value; return;}
                        else if(y.text == 'return_1kg') {this.settings[this.parcel_names[index].value].return_1kg = y.value; return;}
                        else if(y.text == 'return_2kg') {this.settings[this.parcel_names[index].value].return_2kg = y.value; return;}
                        else if(y.text == 'packing_1kg') {this.settings[this.parcel_names[index].value].packing_1kg = y.value; return;}
                        else if(y.text == 'packing_2kg') {this.settings[this.parcel_names[index].value].packing_2kg = y.value; return;}
                        else if(y.text == 'extended_zone') {this.settings[this.parcel_names[index].value].extended_zone = y.value; return;}
                        else if(y.text == 'address_correction') {this.settings[this.parcel_names[index].value].address_correction = y.value; return;}
                        else if(y.text == 'order_insurance') {this.settings[this.parcel_names[index].value].order_insurance = y.value; return;}
                    })
                })
                this.$store.state.loaderProps.visible = false;
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            });
        },
        save(){
            const validate = this.$refs.form.validate()

            if (this.operation_notifications == 0) this.customer.email_to_notify = null;
            
            if(validate){
                this.$store.state.loaderProps.visible = true;

                const lsToken = localStorage.getItem("access_token");
                this.$http.put(this.$store.state.chronosApi + 'clientsFees/' + this.id, { "settings": this.settings }, { headers: { Authorization: lsToken } })
                .then((response) => {
                    if(response.status === 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('create_custom_agent.responses.success')
                        }
                        this.$router.push({ name: "CustomersIndex" });  
                    }
                    this.$store.state.loaderProps.visible = false;
                })
                .catch((response) => {
                    this.$store.state.loaderProps.visible = false;
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('pendings.responses.error')
                        }
                    }
                });
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('create_customer.invalid_form')
                }
            }
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
        
    }
}
</script>

<style lang="css">
</style>