<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('configure_sms.sms') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="validateForm">

                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-checkbox color="amarillo" v-model="sms_required" label="El cliente require enviar SMS a sus compradores para notificar envíos"></v-checkbox>
                            </v-col>
                        </v-row>

                        <br>

                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="sms_required">
                            <v-col cols="6" class="py-0">
                                <label for="">Escribe el texto del mensaje, recuerda que el límite es de 160 caracteres y cada espacio cuentan como 3 de ellos, si deseas agregar una variable como el nombre del cliente o el número de guía arrastra la píldora verde con el nombre del campo</label>
                            </v-col>
                        </v-row>

                        <br>

                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="sms_required">
                            <v-col cols="6" class="py-0">
                                <div
                                  ref="myDiv"
                                  id="editableContent"
                                  contenteditable="true"
                                  class="editable-div"
                                  @dragenter="handleDragEnter"
                                  @dragover="handleDragOver"
                                  @dragleave="handleDragLeave"
                                  @drop="handleDrop"
                                ></div>
                                <v-messages v-if="validationError">{{ validationError }}</v-messages>
                            </v-col>
                        </v-row>
                        <br>
                        <v-row class="px-5 py-0 mt-2 mb-4" justify="center" align="center" v-if="sms_required">
                            <v-col cols="6" class="py-0">
                                <v-chip
                                  v-for="chip in chips"
                                  :key="chip.value"
                                  class="mx-2"
                                  :draggable="true"
                                  @dragstart="handleDragStart(chip.value)"
                                >{{ chip.text }}</v-chip>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0 mt-3" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('configure_sms.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="validateForm" v-permission="'box.create'">
                                {{ $t('configure_sms.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){
            return [
                v => !!v || this.$t('create_appointment.validations.required_field'),
                v => v.toString().length > 0 || this.$t('create_appointment.validations.required_field'),
                v => v.toString().length < 145 || this.$t('create_appointment.validations.required_field'),
            ];
        },
    },
    data() {
        return {
            id: this.$route.params.id,
            chips: [
                { value: "{Shipment.shipping_service}", text: this.$t('configure_sms.shipping_service')}, 
                { value: "{Shipment.shipment_number}", text: this.$t('configure_sms.shipment_number')}, 
                { value: "{Shipment.total}", text: this.$t('configure_sms.total')}
            ],
            draggedChip: null,
            cursorPosition: null,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            sms_required: false,
            sms: {
                template: "",
                warehouse: this.$store.state.warehouseId
            },
            isDragging: false,
            validationError: '', // To store validation error message
            warehouse_id: this.$store.state.warehouseId
        };
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.configure_sms";
    },
    methods: {
        index(){},
        handleDragStart(chip) {
            this.isDragging = true;
            this.draggedChip = chip;
        },
        handleDragEnd(event) {
            console.log(event)
            this.isDragging = false;
        },
        handleDragEnter(event) {
            event.preventDefault();
            if (this.isDragging) {
                const div = this.$refs.myDiv;
                if (div) {
                    div.classList.add('hovered');
                }
            }
        },
        handleDragOver(event) {
            event.preventDefault();
            if (this.isDragging) {
                const div = this.$refs.myDiv;
                if (div) {
                    div.focus();
                    const range = document.caretRangeFromPoint(event.clientX, event.clientY);
                    if (range) {
                        this.cursorPosition = range;
                    }
                    event.preventDefault();
                }
            }
        },
        handleDragLeave(event) {
            event.preventDefault();
            const div = this.$refs.myDiv;
            if (div) {
                div.classList.remove('hovered');
            }
        },
        handleDrop(event) {
            event.preventDefault();
            if (this.draggedChip && this.cursorPosition) {
                this.insertChipAtCaret(this.draggedChip);
                this.draggedChip = null;
            }
        },
        insertChipAtCaret(chipText) {
            const range = this.cursorPosition;
            if (range) {
                range.deleteContents();
                const chip = document.createElement('span');
                chip.className = 'chip';
                chip.setAttribute('contenteditable', 'false');
                chip.textContent = chipText;
                range.insertNode(chip);
                range.setStartAfter(chip);
                range.setEndAfter(chip);
                const sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
            }
        },
        validateForm() {
            const node = document.getElementById('editableContent');
            const textContent = node.textContent.trim();
            if (textContent == null || textContent.toString().length <= 0 || textContent.toString().length > 200) {
                this.validationError = this.$t('create_appointment.validations.required_field');
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.validationError
                }             
            } else {
                this.validationError = '';
                this.save();
            }
        },
        save(){
            var node = document.getElementById('editableContent');
            var textContent = node.textContent;

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            
            this.$http.put(this.$store.state.chronosApi + 'clients/' + this.id + '/sms/template', { warehouse_id: this.warehouse_id, template: textContent}, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.modalDelete = false;
                    this.$router.go(-1);
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        cancel(){
            this.$router.go(-1);
        },
    },
};
</script>

<style>
.editable-div {
  border: 1px solid #000;
  padding: 10px;
  min-height: 50px;
  cursor: text;
  white-space: pre-wrap;
}
.chip {
  display: inline-block;
  background-color: #ddd;
  border-radius: 25px;
  padding: 5px 10px;
  margin: 2px;
  user-select: none;
  pointer-events: none;
}
</style>
