<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col cols="3" class="py-0 my-0">
                    <client-selector :addAllOption="false" class="py-0 my-0 " ref="clientSelector" @getClients="v => items.clients = v" :is_national="2"></client-selector>  
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-menu ref="initial_date" v-model="menu" :close-on-content-click="false" :return-value.sync="initial_date" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field class="py-0" v-model="initial_date" :label="$t('international_operations_report.initial_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                        </template>
                        <v-date-picker v-model="initial_date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">{{ $t('international_operations_report.cancel') }}</v-btn>
                            <v-btn text color="primary" @click="$refs.initial_date.save(initial_date)">{{ $t('international_operations_report.save') }}</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-menu ref="final_date" v-model="menu1" :close-on-content-click="false" :return-value.sync="final_date" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field class="py-0" v-model="final_date" :label="$t('international_operations_report.final_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                        </template>
                        <v-date-picker v-model="final_date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu1 = false">{{ $t('international_operations_report.cancel') }}</v-btn>
                            <v-btn text color="primary" @click="$refs.final_date.save(final_date)">{{ $t('international_operations_report.save') }}</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" @click="showExport = true">
                        {{ $t('international_operations_report.export') }}
                    </v-btn>
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="generate">
                        {{ $t('international_operations_report.generate') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="showExport" persistent max-width="90%" style="width: 500px !important;">
                <v-card style="width:500px !important">
                    <v-card-title class="justify-center mt-4">
                        <v-row align="center" justify="center" class="my-2">
                            <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('international_operations_report.export_data')}}</label>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="6" class="py-0">
                                <v-menu ref="initial_date" v-model="menu2" :close-on-content-click="false" :return-value.sync="report.initial_date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field class="py-0" v-model="report.initial_date" :label="$t('international_operations_report.initial_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="report.initial_date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu2 = false">{{ $t('international_operations_report.cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.initial_date.save(report.initial_date)">{{ $t('international_operations_report.save') }}</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <v-menu ref="final_date" v-model="menu3" :close-on-content-click="false" :return-value.sync="report.final_date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field class="py-0" v-model="report.final_date" :label="$t('international_operations_report.final_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="report.final_date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu3 = false">{{ $t('international_operations_report.cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.final_date.save(report.final_date)">{{ $t('international_operations_report.save') }}</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="showExport = false">{{ $t('international_operations_report.cancel') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="exportData">
                                {{ $t('international_operations_report.export') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('international_operations_report.id_operation'), align: "center", value: 'order', class: 'text-uppercase' },
                { text: this.$t('international_operations_report.customs_broker'), align: "center", value: 'date', class: 'text-uppercase' },
                { text: this.$t('international_operations_report.container'), align: "center", value: 'sku', class: 'text-uppercase' },
                { text: this.$t('international_operations_report.customer'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('international_operations_report.port_arrival'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('international_operations_report.chronos_arrival'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('international_operations_report.petition'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('international_operations_report.notice'), align: "center", value: 'quantity', class: 'text-uppercase' },
            ];
        },
        client: function(){
            return this.$store.state.clientId
        },
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            initial_date: '',
            final_date: '',
            report:{
                initial_date: '',
                final_date: ''
            },
            menu: false,
            menu1: false,
            menu2: false,
            menu3: false,
            showExport: false
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.international_operations_report";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
        },
        generate(){
            this.props.items = [];

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'reports/fulfillment?warehouse='+ this.$store.state.warehouseId + '&client='+ this.$store.state.clientId + '&startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x => {
                    x.products.forEach( y => {
                        this.props.items.push({
                            sku: y.sku, 
                            quantity: y.quantity,
                            date: x.date,
                            order: x.unique_order_number,
                        });
                    })
                })
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        exportData(){

        }
    }
}
</script>

<style>
</style>