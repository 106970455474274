<template>
    <v-dialog v-model="open_modal" persistent max-width="90%" min-width="500px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer></v-spacer>
                {{ $t('customs_patches.customs_documents.modal.title') }}
                <v-spacer></v-spacer>
                <v-btn color="amarillo" small icon @click="stepper2 = 1;$emit('close')"><v-icon small>close</v-icon></v-btn>
            </v-card-title>

            <v-card-text>
                <v-stepper v-model="stepper2">
                    <v-stepper-header>
                        <v-stepper-step :complete="stepper2 > 1" step="1" color="amarillo">{{$t('customs_patches.customs_documents.modal.step_one')}}</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="stepper2 > 2" step="2" color="amarillo">{{ $t('customs_patches.customs_documents.modal.step_two') }}</v-stepper-step>
                        <v-divider></v-divider>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content step="1" class="mx-0 px-0">
                            <v-card flat class="mb-5">
                                <v-form ref="form2" lazy-validation>
                                    <v-row class="mx-3 px-0" justify="center" align="center">
                                        <v-col cols="10">
                                            <v-file-input v-model="inputFile2" accept=".csv" color="amarillo" :label="$t('customs_patches.customs_documents.modal.step_one')"></v-file-input>
                                        </v-col>
                                        <v-col cols="2">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="mx-0" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="stepper2 = 3">
                                                    <v-icon aria-hidden="false" color="white" small>
                                                        help
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('customs_patches.customs_documents.modal.help') }}</span>
                                        </v-tooltip>
                                    </v-col>
                                    </v-row>
                                </v-form>
                            </v-card>
                            
                            <div class="d-flex">
                                <v-spacer></v-spacer>
                                <v-btn class="right mr-2 white--text" color="amarillo" @click="uploadProducts" >
                                    {{ $t('customs_patches.customs_documents.modal.next') }}
                                </v-btn>
                            </div>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-card flat class="mb-5">
                                <v-simple-table v-if="this.readRows2.length > 0" fixed-header height="300px">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th v-for="(th, key) in sTHeaders2" :key="key" class="text-left">{{ th }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, i) in readRows2" :key="i">
                                                <td v-for="(td, j) in item.split(',')" :key="j">{{ td }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card>

                            <div class="d-flex">
                                <v-spacer></v-spacer>
                                <v-btn class="mx-3" text @click="stepper2 = 1; sTHeaders2 = []; readRows2 = []">{{ $t('customs_patches.customs_documents.modal.go_back') }}</v-btn>
                                <v-btn color="amarillo" class="white--text" @click="saveRecords">
                                    {{ $t('customs_patches.customs_documents.modal.save_data') }}
                                </v-btn>
                            </div>
                        </v-stepper-content>

                        <v-stepper-content step="4">
                            <v-card flat class="mb-5">
                                <v-simple-table v-if="feedback_table.rows.length > 0" fixed-header height="300px">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th v-for="(th, key) in feedback_table.headers" :key="key" class="text-left">{{ th }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, i) in feedback_table.rows" :key="i">
                                                <td v-for="(td, j) in item.split(',')" :key="j">{{ td }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card>

                            <div class="d-flex">
                                <v-spacer></v-spacer>
                                <v-btn class="mx-3" text @click="stepper2 = 1; sTHeaders2 = []; readRows2 = [];feedback_table.headers = []; feedback_table.rows = [];inputFile2=null">{{ $t('customs_patches.customs_documents.modal.comeback') }}</v-btn>
                            </div>
                        </v-stepper-content>

                        <v-stepper-content step="3">
                            <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                            <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                            <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                            <p class="py-0 my-0">4.- El archivo de productos debe ser formato CSV.</p> 
                            <p>5.- Si el campo presenta la leyenda 'Opcional' este campo puede ir vacío.</p> 
                            <p class="py-0 my-0">Columnas: </p>
                            <v-simple-table fixed-header>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left" style="padding: 0 5px !important;">sku</th>
                                            <th class="text-left" style="padding: 0 5px !important;">description</th>
                                            <th class="text-left" style="padding: 0 5px !important;">client</th>
                                            <th class="text-left" style="padding: 0 5px !important;">unit</th>
                                            <th class="text-left" style="padding: 0 5px !important;">category</th>
                                            <th class="text-left" style="padding: 0 5px !important;">restock_alert</th>
                                            <th class="text-left" style="padding: 0 5px !important;">weight</th>
                                            <th class="text-left" style="padding: 0 5px !important;">is_national</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>product 1</td>
                                            <td>product description</td>
                                            <td>Shop Online</td>
                                            <td>Kilo</td>
                                            <td>Juguetes</td>
                                            <td>10</td>
                                            <td>0.5</td>
                                            <td>0</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-row align="center" justify="center" class="my-3">
                                <v-btn color="amarillo" class="white--text" @click="stepper2 = 1">
                                    {{ $t('customs_patches.customs_documents.modal.go_back') }}
                                </v-btn>
                            </v-row>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="js">
import { mapGetters } from 'vuex';

export default {
    props: {
        open_modal: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ...mapGetters('customs_patches', [
            'create',
        ]),
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('products.sku'), align: "center", value: 'sku', class: 'text-uppercase' },
                { text: this.$t('products.description'), align: "center", value: 'description', class: 'text-uppercase' },
                { text: this.$t('products.client'), align: "center", value: 'client', class: 'text-uppercase' },
                { text: this.$t('products.stock'), align: "center", value: 'quantity_per_warehouse', class: 'text-uppercase' },
                { text: this.$t('products.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            feedback_table:{
              headers:[],
              rows:[]
            },
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalDelete: false,
            modalImport: false,
            inputFile2: [],
            readFile2: '',
            readRows2: [],
            stepper2: 1,
            sTHeaders2:'',
            idProduct: '',
            actions: [
                {
                    name: this.$t('products.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    link: '',
                    permission: "product.show"
                },
                {
                    name: this.$t('products.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'edit'
                    },
                    link: '',
                    permission: "product.update"
                },
                {
                    name: this.$t('products.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                    permission: "product.delete"
                }
            ],
            props: {
                search: '',
                items: [],
            }
        }
    },
    mounted(){
        this.$store.state.module = "headers.products";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        uploadProducts(){
            if(this.$refs.form2.validate()){
                this.createInput2(this.inputFile2);
            }
        },
        createInput2(file) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    /* handle a successful result */
                    this.readRows2 = result.split('\r\n');
                    this.sTHeaders2 = this.readRows2[0].split(',');
                    this.readRows2.shift();
                    this.stepper2 = 2;
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },
        feedBack(){
        },
        saveRecords(){
            this.modalImport = false;
            // this.stepper2 = 1;
            // this.inputFile2=[];
            const records = [];
            if(this.readRows2.length > 0){
                this.readRows2.forEach((x) => {
                    const data = x.split(',');
                    records.push({
                        "petition": data[0],
                        "payment_date": data[1],
                        "income_date": data[2],
                        "cve_doc": data[3],
                        "regime": data[4],
                        "country": data[5],
                        "customs_document_departure": data[6],
                        "part_number": data[7],
                        "description": data[8],
                        "invoice_quantity": data[9],
                        "invoice_number": data[10],
                        "umc": data[11],
                        "fraction": data[12],
                        "value_usd": data[13],
                        "cod_cove": data[14],
                        "client": data[15],
                        "notice_number": data[16],
                        "umt": data[17],
                    });
                });
                this.$emit('save',records);
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('products.validations.select_file_first')
                };
            }
        }
    },
    watch: {
        open_modal: {
            handler(val){
                if(!val){
                  this.inputFile2=null;
                }
            },

            deep: true
        },
        create: {
            handler(val){
                if(val.success && !val.loading){
                  this.stepper2 = 4;
                  let customs_documents = val.payload.data.map(x=>`${x.customs_document},${x.message},${x.status}`);
                  // let customs_documents = val.payload.data.map(x=>[x.customs_document, x.message, x.status]);
                  if(customs_documents.length){
                    this.feedback_table.headers = ["Pedimento","Mensaje", "Estado"]; 
                    this.feedback_table.rows = customs_documents;
                    // this.readRows2 = [];
                  }
                  this.inputFile2=null;
                }
                if(!val.success && !val.loading){
                  this.inputFile2=null;
                }

            },

            deep: true
        },
    }
}
</script>