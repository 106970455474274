<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col cols="3" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('fulfillment_leirem_in_process.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="9" align="end" class="py-0">
                    <v-row align="end" justify="end" class="px-1 py-3">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="right white--text mr-2" style="max-width: 40px !important; min-width: 40px !important" color="amarillo" @click="downloadMultipleOrderSummary">
                                    <v-icon class="material-icons-outlined" small>downloading</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('fulfillment_leirem_in_process.download_picking_list')}}</span>
                        </v-tooltip>
                    </v-row>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <v-data-table class="px-0 my-2"
                                no-results-text="No hay resultados"
                                :headers="headers"
                                :items="props.items"
                                :search="props.search"
                                calculate-widths
                                :footer-props="{
                                    'items-per-page-text': this.$t('datatable.shown'),
                                    showFirstLastPage: true,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2} ' + this.$t('datatable.results'),
                                    'show-current-page': true,
                                    'items-per-page-options': typeof props.rowsPP === 'undefined' ? [10, 25, 50, 100] : props.rowsPP}"
                                :page="1"
                                :mobile-breakpoint="0"
                                item-key="id"
                                show-select
                                v-model="selected"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                                        <template v-slot:activator="{ on }">
                                            <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                            <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ action.name }}</span>
                                    </v-tooltip>
                                </template>
                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>  
            <v-dialog v-model="modalRestore" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h3 class="text-uppercase">
                                {{ $t('fulfillment_leirem_in_process.restore_modal.restore') }}
                            </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('fulfillment_leirem_in_process.restore_modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalRestore = false">{{ $t('fulfillment_leirem_in_process.no') }}</v-btn>
                            <v-btn color="error" @click="restoreOrder">
                                {{ $t('fulfillment_leirem_in_process.restore_yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalDetail" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('fulfillment_leirem_pendings.order_details.header') }} {{ this.orderToShow.uniqueOrderNumber }}
                            </h4>
                        </v-row>
                    </v-card-title>
                    
                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_leirem_pendings.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.order_number") }}</label> <label>{{ this.orderToShow.uniqueOrderNumber }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.date") }}</label> <label>{{ this.orderToShow.createdAt }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.shipping_service") }}</label> <label>{{ this.orderToShow.shippingService }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.invoice") }}</label> <label>{{ this.orderToShow.invoice }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.client != null"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.client") }}</label> <label>{{ this.orderToShow.client.name }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.cod") }}</label> <label>{{ this.orderToShow.total }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.buyer") }}</label> <label>{{ this.orderToShow.consignee.name }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.state") }}</label> <label>{{ this.orderToShow.consignee.state }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.municipality") }}</label> <label>{{ this.orderToShow.consignee.municipality }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.neighborhood") }}</label> <label>{{ this.orderToShow.consignee.neighborhood }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.street") }}</label> <label>{{ this.orderToShow.consignee.street }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.zip_code") }}</label> <label>{{ this.orderToShow.consignee.zipcode }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_leirem_pendings.products') }}</label></div>
                    <span v-if="orderToShow.shipmentProductDetail != null">
                        <div v-for="(product, i) of orderToShow.shipmentProductDetail.data" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.attributes.product.sku }}</label> <label>{{ product.attributes.quantity }}</label></div>
                    </span>

                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDetail = false">{{ $t('fulfillment_leirem_pendings.go_back') }}</v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalUpdateToSent" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h3 class="text-uppercase">
                                {{ $t('fulfillment_leirem_in_process.mark_as_sent') }}
                            </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('fulfillment_leirem_in_process.sent_message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalUpdateToSent = false; orderToMarkAsSent = ''">{{ $t('fulfillment_leirem_in_process.no') }}</v-btn>
                            <v-btn color="success" @click="markAsSend()">
                                {{ $t('fulfillment_leirem_in_process.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker3"></div>
                </v-card>
            </v-dialog>
        </v-container>        
    </div>
</template>
<script>
import JSZip from 'jszip';
import FileSaver from 'file-saver';
export default {
	computed: {
        headers(){
            return [
                { text: this.$t('fulfillment_leirem_in_process.order'), value: 'uniqueOrderNumber' },
                { text: this.$t('fulfillment_leirem_in_process.customer'), value: 'client'},
                { text: this.$t('fulfillment_leirem_in_process.consignee'), value: 'consigneeName' },
                { text: this.$t('fulfillment_leirem_in_process.shipment_number'), value: 'shipmentNumber' },
                { text: this.$t('fulfillment_leirem_in_process.date'), value: 'date' },
                { text: this.$t('fulfillment_leirem_in_process.actions'), value: 'actions' },
            ];
        },
        productsHeaders(){
            return [
                { text: this.$t('fulfillment_eon_pendings.sku'), value: 'sku', width: '60%' },
                { text: this.$t('fulfillment_eon_pendings.quantity'), value: 'quantity', width: '40%' },
                { text: '', value: 'actions', class: 'text-uppercase', width:'10%' }
            ];
        },  
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            props: {
                search: '',
                items: [],
            },
            selected: [],
            modalDelete: false,
            modalRestore: false,
            modalDetail: false,
            modalUpdateToSent: false,
            orderToDelete: '',
            orderToRestore: '',
            orderToShow: {},
            orderToMarkAsSent: '',
            products: [],
            product: {
                sku: "",
                quantity: ""
            },
            bulk: {
                box: "",
                quantity: ""
            },
            boxes: [],
            actions:[
                {
                    name: this.$t('fulfillment_leirem_in_process.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                },
                {
                    name: this.$t('fulfillments_cod_sent.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'mode_edit'
                    },
                    link: '',
                },
                {
                    name: this.$t('fulfillment_leirem_in_process.restore'),
                    icon: {
                        color: 'accent',
                        icon: 'history'
                    },
                    method: 'askRestore',
                },
                {
                    name: this.$t('fulfillment_leirem_in_process.download_picking_list'),
                    icon: {
                        color: 'accent',
                        icon: 'downloading'
                    },
                    method: 'downloadOrderSummary',
                },
                {
                    name: this.$t('fulfillment_leirem_in_process.download_label'),
                    icon: {
                        color: 'accent',
                        icon: 'label'
                    },
                    method: 'downloadLabel',
                },
                {
                    name: this.$t('fulfillment_leirem_in_process.mark_as_sent'),
                    icon: {
                        color: 'accent',
                        icon: 'local_shipping'
                    },
                    method: 'askUpdateToSend',
                }
            ],
        }
    },
    mounted(){
        this.$store.state.module = "headers.fulfillment_leirem_in_process";
        this.index();
    },
    methods: {
        methodHandler(object, params){
            this[object](params)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.props.items = [];
            this.$http.get(this.$store.state.chronosApi + 'fulfillment/leirem/in_process', {headers: {Authorization: lsToken}})
            .then((response) => {
                // Get Shippment_services
                this.$store.state.loaderProps.visible = true;
                response.data.data.forEach( x => { 
                    x = x.attributes;
                    x.date = x.createdAt.substring(0,10);
                    x['actions'] = JSON.parse(JSON.stringify(this.actions));
                    x['actions'][1].link = "/sent-shipments/" + (x.cod == false? "prepaid" : "cod") + "/edit/" + x.id;

                    x.parameters = x;
                    this.props.items.push(x);
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        showDetails(order){
            this.orderToShow = order;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/getOrderInformation/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data.attributes;
                this.$store.state.loaderProps.visible = false;
                this.orderToShow = response.data;
                this.modalDetail = true;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        askRestore(order){
            this.modalRestore = true;
            this.orderToRestore = order;
        },
        restoreOrder(){
            this.$store.state.loaderProps.visible = true;
            if(this.orderToRestore != ''){
                this.$http.put(this.$store.state.chronosApi + 'shipment/restock/' + this.orderToRestore.id, {},{headers: {}})
                .then((response) => {
                    if(response.data.status === 200) {
                        this.orderToRestore = '';
                        this.modalRestore = false;
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('fulfillments_cod_sent.restored')
                        }
                        this.index();
                    }
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: "error"
                }
            }
        },
        downloadLabel: async function (order){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'fulfillment/leirem/labels/' + order.id, {headers: {Authorization: lsToken}, responseType:'blob' })
            .then(response => {
                let type = response.headers['content-type'].split("/")[1]
                this.$store.state.loaderProps.visible = false; 
                FileSaver.saveAs(response.data, order.uniqueOrderNumber + '.' + type);
            });
        },
        downloadOrderSummary: async function (order){
            this.$store.state.loaderProps.visible = true;

            const orderSummary = await this.getOrdersSummary(order.id);

            FileSaver.saveAs(orderSummary.data, "Order_Summary_" + order.uniqueOrderNumber + ".pdf");
            this.$store.state.loaderProps.visible = false;
        },
        downloadMultipleOrderSummary: async function (){
            if(this.selected.length == 0) return;

            var zip = new JSZip();
            this.$store.state.loaderProps.visible = true;
            var shipments_ids = "";

            for(let i = 0; i < this.selected.length; i++){
                shipments_ids += this.selected[i].id + ",";
                const orderSummary = await this.getOrdersSummary(this.selected[i].id);
                zip.file("Order_Summary_" + this.selected[i].uniqueOrderNumber + ".pdf", orderSummary.data);
            }

            shipments_ids = shipments_ids.substring(0, shipments_ids.length - 1);

            const response = await this.getOrdersSummary(shipments_ids);
            zip.file("Orders_Summary.pdf", response.data);

            zip.generateAsync({type:"blob"}).then(function(content) {
                FileSaver.saveAs(content, "Orders_Summary.zip");
            }, function(err){
                console.log(err)
            })
            this.$store.state.loaderProps.visible = false;
        },
        getOrdersSummary: async function(ids){
            const lsToken = localStorage.getItem("access_token");
            const file = await this.$http.get(this.$store.state.chronosApi + 'shipment/ordersSummary?shipments=' + ids, {headers: {Authorization: lsToken}, responseType:'blob'});
            return file;
        },
        askUpdateToSend(order){
            this.orderToMarkAsSent = order;
            this.modalUpdateToSent = true;
        },
        markAsSend(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            if(this.orderToMarkAsSent != ''){
                this.$http.put(this.$store.state.chronosApi + '/fulfillment/leirem/set_send_status/' + this.orderToMarkAsSent.id, {},{headers: {Authorization: lsToken}})

                .then((response) => {
                    if(response.status === 200) {
                        this.orderToMarkAsSent = '';
                        this.modalUpdateToSent = false;
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('fulfillments_cod_sent.marked_as_send')
                        }
                        this.index();
                    }
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: "error"
                }
            }
        }
    },
    watch:{
        '$route.params.shipping_service': function() {
            location.reload();
        }
    }
}
</script>

<style lang="scss">
    
</style>