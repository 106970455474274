<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-card class="px-0 size">
                <v-card-text>
                    <v-row align="center" justify="center" class="pt-3 mt-3 text-center">
                        <v-col cols="6">
                            <h3>{{ $t('fulfillment_eon_pendings.update_order') }}</h3>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0 my-0">
                            <v-autocomplete class="mt-0 py-0" v-model="orderToUpdate.shipping_service" :items="parcels" :label="$t('fulfillment_eon_pendings.select_parcel')" :rules="rules_required"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0 my-0">
                            <v-text-field class="py-0" v-model="orderToUpdate.unique_order_number" :label="$t('fulfillment_eon_pendings.unique_order_number')" :rules="rules_required"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0 my-0">
                            <v-text-field class="py-0" v-model="orderToUpdate.tracking_number" :label="$t('fulfillment_eon_pendings.tracking_number')"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0 my-0">
                            <v-text-field class="py-0" v-model="orderToUpdate.consignee_name" :label="$t('fulfillment_eon_pendings.buyers_name')"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0 my-0">
                            <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" :clearable="true" multiple v-model="orderToUpdate.label" accept="*" :label="$t('fulfillment_eon_pendings.label')"></v-file-input>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0 my-0">
                            <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" multiple :clearable="true" v-model="orderToUpdate.attached_files" accept="*" :label="$t('fulfillment_eon_pendings.attached_files')"></v-file-input>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 my-0" justify="center" align="center">
                        <v-col cols="2" class="py-0 my-0">
                            <v-autocomplete class="mt-0 py-0" v-model="product.sku" item-text="sku" item-value="sku" :items="products" :label="$t('fulfillment_eon_pendings.select_product')"></v-autocomplete>
                        </v-col>
                        <v-col cols="1" class="py-0 my-0 mx-0 px-0">
                            <v-text-field class="py-0" v-model="product.quantity" :label="$t('fulfillment_eon_pendings.quantity')"></v-text-field>
                        </v-col>
                        <v-col cols="1" class="py-0 my-0 mx-0 px-1">
                            <v-text-field class="py-0" v-model="product.unit_price" :label="$t('fulfillment_eon_pendings.unit_price')"></v-text-field>
                        </v-col>
                        <v-col cols="2" class="py-0 my-0 mx-0" justify="end" align="end">
                            <v-btn color="amarillo" class="white--text" @click="addProductUpdate()">
                                {{ $t('fulfillment_eon_pendings.add') }}
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row class="px-0 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0 my-0 mx-0 px-0">
                            <datatable :income_editable="true" class="custom_table_class" :props="{headers:productsHeaders, items: orderToUpdate.products}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                    <v-row class="px-0 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0 my-0 mx-0 px-0" align="end" justify="end">
                            <v-btn class="mx-3" text @click="cancelUpdateOrder()">{{ $t('fulfillments_prepaid_returns.cancel') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="updateOrder()">
                                {{ $t('fulfillment_eon_pendings.save') }}
                            </v-btn>    
                        </v-col>
                    </v-row>
                </v-card-text> 
            </v-card>
        </v-container>   
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
	computed: {
        productsHeaders(){
            return [
                { text: this.$t('fulfillment_eon_pendings.sku'), value: 'sku', width: '60%' },
                { text: this.$t('fulfillment_eon_pendings.quantity'), value: 'quantity', width: '20%' },
                { text: this.$t('fulfillment_eon_pendings.unit_price'), value: 'unit_price', width: '20%' },
                { text: '', value: 'actions', class: 'text-uppercase', width:'10%' }
            ];
        },  
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
    },
    data(){
        return {
            sending_data: false,
            props: {
                search: '',
                items: [
                ],
            },
            id: this.$route.params.id,
            stepperImport: 1,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            responses: [],
            selected: [],
            client_id: "",
            orderToUpdate: '',
            actions_p: [
                {
                    name: this.$t('edit_shipment.delete_product'),
                    icon: {
                        color: 'error',
                        icon: 'disabled_by_default'
                    },
                    method: 'deleteProduct',
                }
            ],
            shipment: {
                client_id: "",
                warehouse_id: this.$store.state.warehouseId,
                unique_order_number: "",
                shipping_service: "",
                tracking_number: "",
                consignee_name: "",
                label: [],
                attached_files: [],
                products: [],
                total_price: ""
            },
            parcels: [
                "99MINUTOS", "AMAZON", "DHL", "ESTAFETA", "FEDEX", "HERMES", "JTEXPRESS", "MERCADO COLECTA", "MERQ", "PAQUETEXPRESS", "QUIKEN",  "UPS" , "YEGO"
            ],
            customers: [],
            products: [],
            product: {
                sku: "",
                quantity: "",
                unit_price: ""
            },
            bulk: {
                box: "",
                quantity: ""
            }
        }
    },
    async mounted(){
        this.$store.state.module = "headers.fulfillment_eon_pendings";
        this.index();
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        methodHandlerV2(object, params){
            this[object](params)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            this.orderToUpdate = {};

            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_info/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.client_id = response.data.order.client_id;
                this.orderToUpdate = {
                    unique_order_number: response.data.order.unique_order_number,
                    created_at: response.data.order.created_at.substring(0,10),
                    shipping_service: response.data.order.shipping_service,
                    customer: response.data.order.name,
                    consignee_name: response.data.order.consignee_name,
                    tracking_number: response.data.order.tracking_number,
                    client_id: response.data.order.client_id,
                    warehouse_id: response.data.order.warehouse_id,
                    actions: JSON.parse(JSON.stringify(this.actions_p)),
                    parameters: this.product.sku,
                    label: [],
                    attached_files: [],
                    products: [],
                    id: response.data.order.id
                }
                var prod = JSON.parse(response.data.order.products).products;

                prod.forEach(x => {
                    var result = {
                        sku: x.sku,
                        quantity: x.quantity,
                        unit_price: x.unit_price ?? 1,
                        actions: JSON.parse(JSON.stringify(this.actions_p)),
                        parameters: x.sku
                    }

                    result.actions[0].method = "deleteProductUpdate"
                    this.orderToUpdate.products.push(result)
                })

                this.$store.state.loaderProps.visible = false;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
                this.modalUpdate = true;
            })
        },
        orderSupply: async function(order){
            if(this.sending_data) return;
            this.sending_data = true;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const shipment = {
                "external_shipments" : [
                    {
                        "unique_order_number": order.unique_order_number,
                        "client_id": order.client_id,
                        "boxes": {
                            "box_id": parseInt(this.bulk.box),
                            "quantity": parseInt(this.bulk.quantity)
                        }
                    }
                ]
            }

            this.$http.post(this.$store.state.chronosApi + 'external_shipment/process_orders', shipment, {headers: {Authorization: lsToken}})
            .then((response) => {
                response;
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('fulfillment_eon_pendings.filled')
                }
                this.modalDetail = false;
                this.modalSupply = false;
                this.index();
            }).catch((err) => {
                console.log(err);
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('fulfillment_eon_pendings.filled_error')
                }
                this.index();
            }).finally(() => {
                this.sending_data = false;
                this.$store.state.loaderProps.visible = false;
            })
        }, 
        addProductUpdate(){
            if(this.product.sku != "" && this.product.quantity != ""){
                const product = {
                    sku: this.product.sku,
                    quantity: this.product.quantity,
                    unit_price: this.product.unit_price,
                    actions: JSON.parse(JSON.stringify(this.actions_p)),
                    parameters: this.product.sku
                }  
                product.actions[0].method = "deleteProductUpdate"

                this.orderToUpdate.products.push(product);
                this.product.sku = "";
                this.product.quantity = "";
                this.product.unit_price = "";
            }
        },
        deleteProductUpdate(sku){
            const index = this.orderToUpdate.products.findIndex(x => x.sku === sku);
            this.orderToUpdate.products.splice(index, 1);
        },
        updateOrder(){
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;

            if(this.orderToUpdate.attached_files.length == 0 && this.orderToUpdate.label.length == 0){
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('fulfillment_eon_pendings.label_and_attached_files_missing')
                }

                this.$store.state.loaderProps.visible = false;
                return;
            }

            const formData = new FormData();
            formData.append('warehouse_id', this.orderToUpdate.warehouse_id);
            formData.append('unique_order_number', this.orderToUpdate.unique_order_number);
            formData.append('shipping_service', this.orderToUpdate.shipping_service);
            formData.append('tracking_number', this.orderToUpdate.tracking_number);
            formData.append('consignee_name', this.orderToUpdate.consignee_name);
            formData.append('client_id', this.orderToUpdate.client_id);

            if(this.orderToUpdate.label.length != 0) {
                this.orderToUpdate.label.forEach(x => {
                    formData.append('label[]', x);
                })
            }
            
            if(this.orderToUpdate.attached_files.length != 0){
                this.orderToUpdate.attached_files.forEach( x => {
                    formData.append('attached_files[]', x);
                })
            }
            
            var c_products = []
            this.orderToUpdate.products.forEach(x => {
                c_products.push({
                    sku: x.sku,
                    quantity: parseInt(x.quantity),
                    unit_price: x.unit_price
                });
            })

            formData.append('products', JSON.stringify({products: c_products}));
            this.orderToUpdate.attached_files.forEach( x => {
                formData.append('attached_files[]', x);
            })
            // console.log([...formData]) // Print form data displaying all pr

            this.$http.post(this.$store.state.chronosApi + 'external_shipment/update/' + this.orderToUpdate.id, formData, {headers: { Authorization: lsToken } })
            .then((response) => {
                response;
                this.modalUpdate = false;
                this.orderToUpdate = {
                    client_id: "",
                    warehouse_id: this.$store.state.warehouseId,
                    unique_order_number: "",
                    shipping_service: "",
                    tracking_number: "",
                    consignee_name: "",
                    label: [],
                    attached_files: [],
                    products: []
                }

                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('fulfillment_eon_pendings.updated')
                }
                this.$router.go(-1);  
                this.$store.state.loaderProps.visible = false;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancelUpdateOrder(){
            this.$router.go(-1);  
        }
    },
    watch:{
        'client_id': function (){

            const lsToken = localStorage.getItem("access_token");
            this.products = [];

            this.$http.get(this.$store.state.chronosApi + 'productsAndKitsByClient/' + this.client_id, {headers: { Authorization: lsToken } })
            .then((response1) => {
                response1.data = response1.data.data;
                response1.data.forEach( x => {
                    this.products.push(x);
                })
            })
        },
    }
}
</script>

<style lang="scss">
</style>