<template>
    <div>
        <v-card flat>
            <v-card-text class="px-0 py-0">
                <!-- Operations by customer in period -->
                <v-row class="my-0 py-0 mx-8" align="center">
                    <v-col class="py-0 my-0" cols="12">
                        <v-data-table class="px-0 my-2 row-height-20"
                                no-results-text="No hay resultados"
                                :headers="headers"
                                :items="customers_data.items"
                                calculate-widths
                                :hide-default-footer="true"
                                :mobile-breakpoint="0"
                                disable-pagination
                                item-key="id"
                            >
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row class="azul font-weight-bold text-uppercase white--text py-1 my-0 mx-8" justify="center" align="center">
                    <v-col cols="6">
                        {{ $t('dashboard.total_by_operation') }}
                    </v-col>
                    <v-col justify="center" align="center" cols="1">{{ total.income }}</v-col>
                    <v-col justify="center" align="center" cols="1">{{ total.outcome }}</v-col>
                    <v-col justify="center" align="center" cols="1">{{ total.ff }}</v-col>
                    <v-col justify="center" align="center" cols="1">{{ total.extff }}</v-col>
                    <v-col justify="center" align="center" cols="1">{{ total.returns }}</v-col>
                    <v-col justify="center" align="center" cols="1">{{ total.total }}</v-col>
                </v-row>

                <v-row class="mt-6 mb-2 py-0" align="center">
                    <v-col class="px-0 mx-0 py-0 my-0" cols="12">
                        <v-col class="mx-0 px-0 py-0 my-0">
                            <v-card-title class="justify-space-between py-2 px-7 ml-7">
                                <v-row>
                                    <span class="text-uppercase subtitle-1 font-weight-bold" style="font-size:15px !important">{{ $t('dashboard.total_orders_cod_by_client_and_status') }}</span>    
                                    <v-btn x-small class="mx-3" @click="back('total_orders_cod_by_client_and_status')"><v-icon x-small>arrow_back_ios</v-icon></v-btn>
                                    <v-btn x-small @click="next('total_orders_cod_by_client_and_status')"><v-icon x-small>arrow_forward_ios</v-icon></v-btn>
                                </v-row>
                            </v-card-title>
                            <v-card-text class="py-0 mx-0">
                                <bar-stacked-chart :chart-data="ordersCodByCustomer" :options="yearChartOptions" :height="500"></bar-stacked-chart>
                            </v-card-text>
                        </v-col>
                    </v-col>
                </v-row>

                <v-row class="mt-6 mb-2 py-0" align="center">
                    <v-col class="px-0 mx-0 py-0 my-0" cols="12">
                        <v-col class="mx-0 px-0 py-0 my-0">
                            <v-card-title class="justify-space-between py-2 px-7 ml-7">
                                <v-row>
                                    <span class="text-uppercase subtitle-1 font-weight-bold" style="font-size:15px !important">{{ $t('dashboard.total_orders_prepaid_by_client_and_status') }}</span>    
                                    <v-btn x-small class="mx-3" @click="back('total_orders_cod_by_client_and_status')"><v-icon x-small>arrow_back_ios</v-icon></v-btn>
                                    <v-btn x-small @click="next('total_orders_cod_by_client_and_status')"><v-icon x-small>arrow_forward_ios</v-icon></v-btn>
                                </v-row>
                            </v-card-title>
                            <v-card-text class="py-0 mx-0">
                                <bar-stacked-chart :chart-data="ordersPrepaidByCustomer" :options="yearChartOptions" :height="500"></bar-stacked-chart>
                            </v-card-text>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>

import BarStackedChart from './Charts/BarStacked.js'

export default {
    props: [
        'initial_date',
        'final_date',
        'yearChartOptions',
        'colors',
        'load'
    ],
    components: {
		BarStackedChart,
    },
    computed: {
        headers(){
            return [
                { text: this.$t('dashboard.customer'), align: "start", value: 'name', class: 'text-uppercase', width: "50%"},
                { text: this.$t('dashboard.income_movements'), align: "center", value: 'income_movements', class: 'text-uppercase', width: "8.3%" },
                { text: this.$t('dashboard.outcome_movements'), align: "center", value: 'outcome_movements', class: 'text-uppercase', width: "8.3%" },
                { text: this.$t('dashboard.fulfillment'), align: "center", value: 'ff', class: 'text-uppercase', width: "8.3%" },
                { text: this.$t('dashboard.ffext'), align: "center", value: 'extff', class: 'text-uppercase', width: "8.3%" },
                { text: this.$t('dashboard.returns'), align: "center", value: 'returns', class: 'text-uppercase', width: "8.3%" },
                { text: this.$t('dashboard.total'), align: "center", value: 'total', class: 'text-uppercase', width: "8.3%" },
            ];
        } 
    },
    data(){
        return {
            customers_data: {
                items: [],
                search: ""
            },
            total: {
                income: 0,
                outcome: 0,
                ff: 0,
                extff: 0,
                returns: 0,
                total: 0
            },
            change_pending: false,
            ordersCodByCustomer: {},
            ordersPrepaidByCustomer: {}
        }
    },
    
    mounted(){
        if(this.load == 2) this.index();
    },
    methods: {
        async index(){
            if(!this.change_pending){

                this.change_pending = true
                this.$nextTick(async () => {
                    this.$store.state.loaderProps.visible = true;

                    await this.getOperationsByCustomer();
                    await this.getOrdersCodByCustomer();
                    await this.getOrdersPrepaidByCustomer();

                    this.$store.state.loaderProps.visible = false;

                    this.change_pending = false;
                });
            }
        },
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        async getOperationsByCustomer(){
            this.total = {
                income: 0,
                outcome: 0,
                ff: 0,
                extff: 0,
                returns: 0,
                total: 0
            };

            const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi+'operationsByClientInPeriod/' + this.initial_date + '/' + this.final_date, { headers: { Authorization: lsToken } })
			.then((response) => {
                response.data.data.forEach(x => {
                    this.total.income += x.income_movements;
                    this.total.outcome += x.outcome_movements;
                    this.total.ff += x.ff;
                    this.total.extff += x.extff;
                    this.total.returns += x.returns;
                    this.total.total += x.total
                });

                this.customers_data.items = response.data.data;
			})
        },
        async getOrdersCodByCustomer(){
            const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi + 'ordersCodByCustomer/' + this.initial_date + '/' + this.final_date, { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    
                    let counter = 0;
                    const labels = [];

                    response.data.clients.forEach(c => {
                        labels.push({ name: c, color: this.colors[counter++] })
                        if(counter == this.colors.length) counter = 0;
                    });

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: response.data.data.map(x => {
                                var value = 0;
                                
                                x.data.forEach(y => {
                                    if(y.includes(r.name)) {
                                        value = y[1];
                                        return;
                                    }
                                })

                                return value;
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })

                    this.ordersCodByCustomer = {
                        labels: response.data.data.map(t => {
                            return t.date
                        }),
                        datasets: datasets
                    }
				}
			})
        },
        async getOrdersPrepaidByCustomer(){
            const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi + 'ordersPrepaidByCustomer/' + this.initial_date + '/' + this.final_date, { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    
                    let counter = 0;
                    const labels = [];

                    response.data.clients.forEach(c => {
                        labels.push({ name: c, color: this.colors[counter++] })
                        if(counter == this.colors.length) counter = 0;
                    });

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: response.data.data.map(x => {
                                var value = 0;
                                
                                x.data.forEach(y => {
                                    if(y.includes(r.name)) {
                                        value = y[1];
                                        return;
                                    }
                                })

                                return value;
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })

                    this.ordersPrepaidByCustomer = {
                        labels: response.data.data.map(t => {
                            return t.date
                        }),
                        datasets: datasets
                    }
				}
			})
        }
    },
    watch: {
        'initial_date': function(){
            if(this.load == 2) this.index();
        },
        'final_date': function(){
            if(this.load == 2) this.index();
        },
        'load': function (){
            if(this.load == 2 && this.change_pending == false) this.index();
        }
    }
}
</script>

<style>

</style>