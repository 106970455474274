<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" justify="end" align="end">
                <v-col cols="3" class="py-0" justify="center" align="center">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        color="amarillo"
                        style="background-color: transparent; border: 1px solid #9fb700; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="6" align="end" class="py-0">
                    <!-- <v-btn class="right white--text tools-view-height mx-3" outlined color="amarillo" :to="''" >
                        {{ $t('pendings.massive_upload') }}
                        <v-icon class="px-1" small>cloud_upload</v-icon>
                    </v-btn> -->
                    <v-btn class="right white--text tools-view-height" color="amarillo" :to="'/create-appointment/national'" v-permission:all="'appointment.create|n_pending.create'">
                        {{ $t('pendings.new') }}
                        <v-icon class="px-1" small>add</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable class="custom_table_class" :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        headers(){
            //adding column operation in Spanish
            if(this.$store.state.language == 'es') return [
                { text: this.$t('pendings.id'), value: 'id', class: 'text-uppercase' },
                { text: this.$t('pendings.date'), value: 'arrival_date', class: 'text-uppercase' },
                { text: this.$t('pendings.operation'), value: 'operation.description', class: 'text-uppercase' },
                { text: this.$t('pendings.container'), value: 'container_number', class: 'text-uppercase' },
                { text: this.$t('pendings.status'), value: 'status', class: 'text-uppercase' },
                // { text: this.$t('pendings.petition'), value: 'petition', class: 'text-uppercase' },
                { text: this.$t('pendings.actions'), value: 'actions', align: "center", class: 'text-uppercase' },
            ]
            //adding columnm operation in English
            else return [
                { text: this.$t('pendings.id'), value: 'idIndex', class: 'text-uppercase' },
                { text: this.$t('pendings.date'), value: 'arrival_date', class: 'text-uppercase' },
                { text: this.$t('pendings.operation'), value: 'operation.description1', class: 'text-uppercase' },
                { text: this.$t('pendings.container'), value: 'container_number', class: 'text-uppercase' },
                { text: this.$t('pendings.status'), value: 'status', class: 'text-uppercase' },
                { text: this.$t('pendings.actions'), value: 'actions', align: "center", class: 'text-uppercase' },
            ]
        } 
    },
    data(){
        return {
            items: {
                clients: []
            },
            actions: [
                {
                    name: this.$t('pendings.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    link: '/appointment-details',
                    permission: 'customer.show'
                }
            ],
            props: {
                search: '',
                items: [],
            },
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.my_appointments";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'appointmentsByCustomer/0/' + this.$store.state.clientUserId, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    const d2 = new Date((new Date()).toISOString().slice(0, 10));
                    response.data.data.forEach(x =>{
                        x = x.attributes;
                        // x.idIndex = x.id;
                        x.arrival_date = (new Date(x.arrival_date)).toISOString().slice(0, 10);
                        const d1 = new Date(x.arrival_date);
                        
                        if(d1 > d2) x.dotColor = 'blue_dashboard';
                        else if(+d1 === +d2) x.dotColor = 'orange';
                        else x.dotColor = "red";

                        var c_from = "international";
                        if(x.operation.description == "Entrada Nacional" || x.operation.description == "Salida Nacional") c_from = "national"

                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x['actions'][0].link = '/customer_appointment_details/' + x.id + '/' + c_from;
                        
                        this.props.items.push(x);
                    });
                    
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        }
    }
}
</script>

<style lang="scss">

</style>