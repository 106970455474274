<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('edit_gate.edit') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="gate.name" :label="$t('edit_gate.name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="gate.warehouse_id" :items="warehouses" :label="$t('edit_gate.warehouse')" item-text="name" item-value="id" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('edit_gate.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'gate.update'">
                                {{ $t('edit_gate.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            gate:{
                name: '',
                gate_id: '',
                is_rfid: false,
                is_entry: false,
            },
            warehouses: []
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.edit_gate";
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'gates/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data.attributes;
                this.gate = response.data;
                this.gate.warehouse_id = response.data.warehouse.id;
                this.gate.name = response.data.name;
                this.gate.is_entry = response.data.is_entry;
                this.gate.is_rfid = response.data.is_rfid;
            }).catch((err) => {
                console.log(err);
            })

            this.$http.get(this.$store.state.chronosApi + 'warehouses', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.forEach(x => {
                    this.warehouses.push({name: x.name, id: x.id});
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.put(this.$store.state.chronosApi + 'gates/' + this.id, this.gate, {headers: {Authorization: lsToken}})
            .then((response) => {
                response
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('edit_gate.responses.success')
                }
                this.$router.push({ name: "GatesIndex" });  
            }).catch((err) => {
                err
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('edit_gate.responses.success')
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>