<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col sm="2" md="2" class="py-0" justify="center" align="center">
                   
                </v-col>
                <v-spacer></v-spacer> 
                <v-col sm="2" md="2" align="end">
                    <v-btn class="right white--text" color="amarillo" @click="getExpirationReportExport({})">
                        {{ $t('customs_patches.reports_expiration.export') }}
                    </v-btn>
                </v-col>
                
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable  :custom_columns="['customs_document']" :props="{headers:headers, items: expiration.data, search:props.search}" @methodHandler="methodHandler">
                                <template v-slot:item.customs_document="{ item }">
                                    <v-icon v-if="item.existence < 0" small v-on="on" color="red"> fiber_manual_record </v-icon>
                                    {{ item.customs_document }} 
                                </template>
                            </datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('customs_patches_reports', [
            'expiration',
            'expirationExport'
        ]),
        headers(){
            return [
                { text: this.$t('customs_patches.reports_expiration.table.customs_document'), align: "center", value: 'customs_document', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_expiration.table.cve_doc'), align: "center", value: 'cve_doc', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_expiration.table.customs_document_date'), align: "center", value: 'customs_document_date', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_expiration.table.expiration'), align: "center", value: 'expiration', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_expiration.table.invoice_number'), align: "center", value: 'invoice_number', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_expiration.table.part_number'), align: "center", value: 'part_number', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_expiration.table.description'), align: "center", value: 'description', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_expiration.table.fraction'), align: "center", value: 'fraction', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_expiration.table.invoice_quantity'), align: "center", value: 'invoice_quantity', class: 'text-uppercase' },
                { text: this.$t('customs_patches.reports_expiration.table.existence'), align: "center", value: 'existence', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
            }
        }
    },
    watch: {
        expiration: {
            handler(val){
                this.$store.state.loaderProps.visible = val.loading;
            },
            deep: true
        },
        export: {
            handler(val){
                this.$store.state.loaderProps.visible = val.loading;
            },
            deep: true
        },
    },
    mounted(){
        this.$store.state.module = "headers.reports_expiration";
        this.getExpirationReport()
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        ...mapActions('customs_patches_reports', [
            'getExpirationReport',
            'getExpirationReportExport'
        ])
    }
}
</script>

<style>

</style>