<template>
    <v-dialog v-model="showSearch" persistent class="show_search_modal" width="90% !important" max-width="100%">
        <v-card>
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="1">
                    <v-btn @click="showSearch = false" class="right white--text tools-view-height mt-2"  color="amarillo">
                    <v-icon color="white" >close</v-icon>
                </v-btn>
                </v-col>
            </v-row>
            <v-row class="px-0 pt-0 mx-0" align="center" justify="center">
                <v-col cols="5">
                    <v-card class="ml-5 mr-1 size_left_card">
                        <v-row class="ml-5 mr-1 py-0 my-2" justify="center" align="center">
                            <v-col cols="12" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('search.general_data')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.id')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.shipment.id}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.order')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.shipment.unique_order_number}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.shipping_service')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.shipment.shipping_service}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.shipment_number')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.shipment.shipment_number}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.buyer')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.consignee.name}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.state')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.consignee.state}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.neighborhood')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.consignee.neighborhood}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.address')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.consignee.street}}, {{order.consignee.external_number}} {{order.consignee.internal_number}}</label>
                            </v-col>
                        </v-row>
                        <!-- <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.ext_num')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.consignee.external_number}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.int_num')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.consignee.internal_number}}</label>
                            </v-col>
                        </v-row> -->
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.zipcode')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.consignee.zipcode}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.phone')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.consignee.phone}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.references')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.consignee.comments}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.ext_zone')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.shipment.ext_zone}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.packing')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.shipment.sizes}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.order_type')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0" v-if="order.shipment.cod == true">
                                <label style="font-size: 16px;">{{$t('search.is_cod')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0" v-if="order.shipment.cod == false">
                                <label style="font-size: 16px;">{{$t('search.is_prepaid')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center" v-if="order.minutes != null">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.minutes_status')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.minutes.status}} - ({{ order.minutes.date }})</label>
                            </v-col>
                        </v-row>

                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center" v-if="order.minutes != null">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.minutes_comments')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.minutes.comments}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.total_cod')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0">
                                <label style="font-size: 16px;">{{order.shipment.total}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="end" align="end">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;">{{$t('search.products')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0" v-for="product of order.products" :key="product.id">
                                <label style="font-size: 16px;">{{ product.quantity }} - ({{ product.sku }}) - {{ product.description }}</label>
                            </v-col>
                            <v-col cols="7" v-if="order.products.length == 0" class="py-0">
                                <label style="font-size: 16px;"></label>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="7">
                    <v-card class="mr-5 ml-1 mb-5 size_right_top_card">
                        <v-row class="px-0 mr-0 ml-5 py-0 my-2" justify="center" align="center">
                            <v-col cols="12" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('search.payment_details')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="order.shipment.paid_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 14px;">{{$t('search.shipping_service_fee')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0" justify="center" align="end">
                                <label style="font-size: 14px;" v-if="order.shipment.paid_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_at.split('T')[0] }}</label>
                                <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="order.shipment.paid_fulfillment_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 14px;">{{$t('search.fulfillment_fee')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0" justify="center" align="end">
                                <label style="font-size: 14px;" v-if="order.shipment.paid_fulfillment_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_fulfillment_at.split('T')[0] }}</label>
                                <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="order.shipment.paid_label_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 14px;">{{$t('search.label_fee')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0" justify="center" align="end">
                                <label style="font-size: 14px;" v-if="order.shipment.paid_label_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_label_at.split('T')[0] }}</label>
                                <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="order.shipment.paid_extzone_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 14px;">{{$t('search.ext_zone_fee')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0" justify="center" align="end">
                                <label style="font-size: 14px;" v-if="order.shipment.paid_extzone_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_extzone_at.split('T')[0] }}</label>
                                <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="order.shipment.paid_shipment_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 14px;">{{$t('search.shipping_fee')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0" justify="center" align="end">
                                <label style="font-size: 14px;" v-if="order.shipment.paid_shipment_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_shipment_at.split('T')[0] }}</label>
                                <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="order.shipment.paid_cod_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 14px;">{{$t('search.cod_fee')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0" justify="center" align="end">
                                <label style="font-size: 14px;" v-if="order.shipment.paid_cod_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_cod_at.split('T')[0] }}</label>
                                <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="order.shipment.paid_return_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 14px;">{{$t('search.return_fee')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0" justify="center" align="end">
                                <label style="font-size: 14px;" v-if="order.shipment.paid_return_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_return_at.split('T')[0] }}</label>
                                <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 mr-5 ml-5 py-0 mt-1 mb-5" v-bind:class="order.shipment.paid_address_update_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                            <v-col cols="5" class="py-0">
                                <label style="font-size: 14px;">{{$t('search.address_correction_fee')}}</label>
                            </v-col>
                            <v-col cols="7" class="py-0" justify="center" align="end">
                                <label style="font-size: 14px;" v-if="order.shipment.paid_address_update_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_address_update_at.split('T')[0] }}</label>
                                <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card class="mr-5 ml-1 mt-5 size_right_bottom_card">
                        <v-row class="px-0 mr-0 ml-5 py-0 my-2" justify="center" align="center">
                            <v-col cols="12" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('search.delivery_details')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 mr-5 ml-5 py-0 my-1" justify="center" align="center">
                            <v-col cols="6" class="py-0" justify="start" align="start">
                                <label>{{ $t('search.status')}}</label>
                            </v-col>
                            <v-col cols="6" class="py-0" justify="end" align="end">
                                <label>{{ $t('search.date')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 mr-7 ml-7 py-0 my-1" v-for="status of order.statuses" :key="status.id" style="border-bottom: 1px solid #444444; border-radius: 0px;" justify="center" align="center">
                            <v-col cols="6" class="py-0" justify="start" align="start">
                                <label style="font-size: 14px;"> {{status.status}} </label>
                            </v-col>
                            <v-col cols="6" class="py-0" justify="end" align="end">
                                <label style="font-size: 14px;" v-if="status.date == null"> {{ status.created_at.split('T')[0] }} {{ status.created_at.split('T')[1].substring(0, 5) }} </label>
                                <label style="font-size: 14px;" v-else> {{ status.date }} </label>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>


<script>
export default {
    props: [
        "uon",
    ],
    data(){
        return {
            numberFormat: new Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            showSearch: true,
            order: {
                statuses: {},
                shipment: {},
                consignee: {},
                products: [],
                minutes: []
            },
        }
    },
    computed: {

    },
    mounted(){
        this.search();
    },
    methods: {
        search(){
            this.showSearch = true;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/shipmentSearch/' + this.$store.state.clientId + '/uon/' + this.uon, { headers: { Authorization: lsToken } })
            .then((response) => {
                this.$store.state.loaderProps.visible = false;
                this.order.statuses = response.data.statuses;
                this.order.shipment = response.data.shipment;
                this.order.minutes = response.data.minutes;
                this.order.shipment.ext_zone = response.data.extZone == false ? "No Aplica" : "Aplica";
                if(response.data.shipment.width == null || response.data.shipment.height == null || response.data.shipment.length == null) this.order.shipment.sizes = "";
                else this.order.shipment.sizes = response.data.shipment.width + "x" + response.data.shipment.height + "x" + response.data.shipment.length;
                this.order.shipment.total = '$' + this.numberFormat.format(response.data.shipment.total);
                this.order.consignee = response.data.consignee;
                this.order.products = response.data.products;
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        }
    },
    watch:{
        'uon': function(){
            this.search();
        }
    }
}
</script>

<style>
</style>