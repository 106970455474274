<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h2 class="azul--text text-uppercase">{{ $t('order_incidence.incidence_on_return') }}</h2>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="order.unique_order_number" :label="$t('order_incidence.uon')" :rules="rules_required" disabled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="order.shipment_number" :label="$t('order_incidence.shipping_number')" :rules="rules_required" disabled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 13px; font-weight: bold;" class="azul--text">{{$t('order_incidence.warning')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <datatable 
                                    :props="{headers: headers, items: order.products}" 
                                    :income_editable="true" 
                                    @methodHandler="methodHandler"></datatable>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-spacer></v-spacer> 
                        <v-col cols="6">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel" >
                                {{ $t('order_incidence.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" >
                                {{ $t('order_incidence.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('order_incidence.sku'), value: 'sku' },
                { text: this.$t('order_incidence.quantity'), value: 'totalquantity' },
                { text: this.$t('order_incidence.damaged_product'), value: 'damaged_product' },
                { text: this.$t('order_incidence.stolen_product'), value: 'stolen_product' },
                { text: this.$t('order_incidence.incidence_date'), value: 'date' }
            ]
        } 
    },
    data(){
        return {
            order_id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            search: '',
            incidence_types: ["Producto dañado", "Producto perdido/robado"],
            order: {
                unique_order_number: '',
                shipment_number: '',
                products:[]
            },
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.prepaid_returns_incidences";
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'getDetailsOrderReturned/' + this.order_id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.order = {
                    unique_order_number: response.data.shipment.unique_order_number,
                    shipment_number: response.data.shipment.shipment_number,
                    products: []
                }

                response.data.details.forEach( x => {
                    x.totalquantity = x.quantity + x.stolen_product + x.damaged_product;
                    this.order.products.push(x);
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        save(){
            this.order.products.forEach( x => {
                x.description = x.descriptionEdit;
            })

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.patch(this.$store.state.chronosApi + 'updateDetailsOrderReturned', this.order, {headers: {Authorization: lsToken}})
            .then((response) => {
                console.log(response);
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('create_custom_agent.responses.success')
                }

                this.$router.go(-1);
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })

            // console.log(this.order);
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>