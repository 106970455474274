<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5" justify="center" align="center">
                    <v-col cols="4" align="start" justify="start">    
                        <h3 class="azul--text text-uppercase">{{ $t('kits_details.details') }}</h3>
                    </v-col>
                    <v-col cols="2" align="end" justify="end">
                        <v-btn class="right white--text" color="amarillo" @click="cancel" >
                            {{ $t('kits_details.go_back') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0">
                            <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('kits_details.general_data')}}</label>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('kits_details.sku')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.kit.sku }} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('kits_details.description')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.kit.description }} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('kits_details.customer')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.kit.client.name}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                     <v-row class="px-5 py-0 mt-3" justify="center" align="center">
                        <v-col cols="6" class="py-0">
                            <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('kits_details.products')}}</label>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center">
                                <label>{{ $t('kits_details.sku') }}</label>
                                <v-spacer></v-spacer>
                                <label> {{ $t('kits_details.quantity') }} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row v-for="detail of kit_detail" :key="detail.id" class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center">
                                <label>{{ detail.attributes.product.sku }}</label>
                                <v-spacer></v-spacer>
                                <label> {{ detail.attributes.quantity }} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            kit: {
                sku: "",
                description: "",
                client: {
                    name: ""
                },
                kit_detail: []
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.kits";
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.$http.get(this.$store.state.chronosApi + 'kitDetail/' + this.id, {headers: {Authorization: lsToken}})
            .then(response => {
                this.kit.sku = response.data.data.attributes.sku;
                this.kit.description = response.data.data.attributes.description;
                this.kit.client = response.data.data.attributes.client;
                this.kit_detail = response.data.data.attributes.kit_detail

            }).catch((err) => {
                console.log(err);
            }).finally(() => {    
                this.$store.state.loaderProps.visible = false;
            });
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>