<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('users.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text" color="amarillo" :to="'/users/create'" >
                        {{ $t('users.new_user') }}
                        <v-icon class="px-1" small>add</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                    {{ $t('users.modal.delete') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('users.modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('users.no') }}</v-btn>
                            <v-btn color="error" @click="deleteUser">
                                {{ $t('users.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalPass" persistent max-width="90%">
                <v-card style="width:600px">
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center" class="px-3 py-2">
                                <h3 class="text-uppercase">
                                    {{ $t('users.restore') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="pt-3 my-3 text-center">
                            <v-row align="center" justify="center">
                                <v-col cols="10" class="py-0">
                                    <v-text-field v-model="user.password" :append-icon="show ? 'visibility' : 'visibility_off'" :type="show ? 'text' : 'password'" :rules="rules_password" @click:append="show = !show" :label="$t('users.restore_password')"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-row>
                        <v-row align="center" justify="center" class="pb-3 my-3 text-center">
                            <v-row align="center" justify="center">
                                <v-col cols="10" class="py-0">
                                    <v-text-field v-model="password_confirmation" :append-icon="show2 ? 'visibility' : 'visibility_off'" :type="show2 ? 'text' : 'password'" :rules="rules_password" @click:append="show2 = !show2" :label="$t('users.password_confirmation')"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-row>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalPass = false">{{ $t('users.cancel') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="updateUserPassword">
                                {{ $t('users.update') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_password(){return [
            v => !!v || this.$t('create_appointment.validations.required_field'),
            v => v.toString().length >= 8 || this.$t('users.length')
        ]},

        headers(){
            return [
                { text: this.$t('users.id'), align: "center", value: 'id', class: 'text-uppercase' },
                { text: this.$t('users.name'), align: "start", value: 'name', class: 'text-uppercase' },
                { text: this.$t('users.email'), align: "start", value: 'email', class: 'text-uppercase' },
                { text: this.$t('users.role'), align: "start", value: 'role', class: 'text-uppercase' },
                { text: this.$t('users.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalDelete: false,
            modalPass: false,
            idUser: '',
            actions: [
                {
                    name: this.$t('users.change_password'),
                    icon: {
                        color: 'accent',
                        icon: 'lock_reset'
                    },
                    method: 'showPassModal',
                    permission: "user.update"
                },
                {
                    name: this.$t('users.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'edit'
                    },
                    link: '',
                    permission: "user.update"
                },
                {
                    name: this.$t('users.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                    permission: "user.delete"
                }
            ],
            user: {
                password:"",
                email: "",
            },    
            password_confirmation: "",
            show: false,
            show2: false,
            description: '',
            props: {
                search: '',
                items: [],
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.users";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'users', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data = response.data.data
                    response.data.forEach(x =>{
                        x = x.attributes;
                        x.role = x.roles[0].name;
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x.actions[1].link = 'users/edit/' + x.id;
                        x['parameters'] = x;
                        this.props.items.push(x);
                    });
                this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                console.log(response)
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        showPassModal(user){
            this.userToUpdatePass = user;
            this.modalPass = true
        },
        updateUserPassword(){
            if(this.password_confirmation === this.user.password){
                this.user.email = this.userToUpdatePass.email;
                this.$store.state.loaderProps.visible = true;
                console.log(this.userToUpdatePass);
                const lsToken = localStorage.getItem("access_token");
                this.$http.put(this.$store.state.chronosApi + 'userPassword/' + this.userToUpdatePass.id, {user:this.user}, {headers: {Authorization: lsToken}})
                    .then((response) => {
                        response
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('users.updated_success')
                        }
                        if(this.userToUpdatePass.email == this.$store.state.loggedUserEmail){
                            var user = {
                                "user": {
                                    "email": this.$store.state.loggedUserEmail,
                                    "warehouse_id": localStorage.getItem("warehouse_id"),
                                    "roles": []
                                }
                            }

                            const lsToken = localStorage.getItem("access_token")
                            this.$http.delete(this.$store.state.chronosApi + 'users/sign_out', ({ headers: { Authorization: lsToken }, body: user }))
                            .then((response) => {
                                if(response){
                                    localStorage.removeItem('access_token');
                                    this.$router.push("/login")
                                }
                            })
                            .catch(function (error) {
                                error
                                console.log(error);
                            })
                        }
                        else{
                            this.index();
                        }
                    }).catch((err) => {
                        err
                        this.snackbar = {
                            visible: true,
                            color: 'error',
                            text: this.$t('edit_user.responses.success')
                        }
                    }).finally(() => {
                        this.$store.state.loaderProps.visible = false;
                    })
            }
        },
        askDelete(user){
            this.modalDelete = true;
            this.idUser = user.id;
        },
        deleteUser(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'users/' + this.idUser, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.modalDelete = false;
                    this.index();
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        }
    }
}
</script>
<style>
</style>