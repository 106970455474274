<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5" justify="center" align="center">
                    <v-col cols="4" align="start" justify="start">    
                        <h3 class="azul--text text-uppercase">{{ $t('warehouse_details.warehouse_details') }}</h3>
                    </v-col>
                    <v-col cols="2" align="end" justify="end">
                        <v-btn class="right white--text" color="amarillo" @click="cancel" >
                            {{ $t('warehouse_details.go_back') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('warehouse_details.name')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.warehouse.name }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('warehouse_details.country')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.warehouse.country }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('warehouse_details.state')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.warehouse.state }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('warehouse_details.municipality')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.warehouse.municipality }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('warehouse_details.neighborhood')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.warehouse.neighborhood }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('warehouse_details.street')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.warehouse.street }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('warehouse_details.external_number')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.warehouse.external_number }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('warehouse_details.internal_number')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.warehouse.internal_number }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('warehouse_details.zip_code')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.warehouse.zipcode }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row v-for="ss of warehouse.shipping_services" :key="ss.id" class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('warehouse_details.operates') }} {{ ss.attributes.name }}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ss.attributes.work == true ? $t('warehouse_details.yes') : $t('warehouse_details.no')}}  </label>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            warehouse: {
                country: '',
                state: '',
                name: '',
                neighborhood: '',
                municipality: '',
                street: '',
                zipcode: '',
                external_number: '',
                internal_number: '',
                shipping_services: []
            },
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.warehouses";
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'warehouses/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.warehouse = response.data.data.attributes;
                // Get current shipping services
                this.$http.get(this.$store.state.chronosApi + 'shipping_services/', {headers: {Authorization: lsToken}})
                .then((response1) => {
                    response1.data.forEach(x => {
                        var exist = false;
                        this.warehouse.shipping_services.forEach(y => {
                            y = y.attributes;
                            if(y.name == x.name) {
                                exist = true;
                                y.work = true;
                            }
                        })
                        if(exist == false){
                            x.attributes = x;
                            x.work = false;
                            this.warehouse.shipping_services.push(x);
                        }
                    })
                }).catch((err) => {
                    console.log(err);
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>