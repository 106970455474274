<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('create_user.new') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="user.employee_number" :label="$t('create_user.employee_number')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="user.name" :label="$t('create_user.name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="user.paternal_surname" :label="$t('create_user.paternal_surname')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="user.maternal_surname" :label="$t('create_user.maternal_surname')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="user.email" :label="$t('create_user.email')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" :append-icon="show ? 'visibility' : 'visibility_off'" @click:append="show = !show" :type="show ? 'text' : 'password'"  v-model="user.password" :label="$t('create_user.password')" :rules="rules_password"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" :append-icon="show_c ? 'visibility' : 'visibility_off'" @click:append="show_c = !show_c" :type="show_c ? 'text' : 'password'" v-model="user.password_confirmation" :label="$t('create_user.password_confirmation')" :rules="rules_password"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="role_id" :items="roles" :label="$t('create_user.role')" item-text="name" item-value="id" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="required_client == true">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="user.client_id" :items="customers" :label="$t('create_user.client')" item-text="name" item-value="id" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('create_user.warehouses')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-row class="px-0 py-0 my-0" justify="start" align="start">
                                    <v-col cols="6" class="py-0" style="display:flex" v-for="(warehouse, i) of user.warehouses" :key="i">
                                        <v-checkbox class="mt-0" color="info" v-model="warehouse.selected" :label="warehouse.name"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('create_user.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'user.create'">
                                {{ $t('create_user.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        rules_password(){return [
            v => !!v || this.$t('create_user.validations.password'),
            v => v.toString().length >= 8 || this.$t('create_user.validations.password')
        ]}
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            user:{
                name: '',
                employee_number: '',
                paternal_surname: '',
                maternal_surname: '',
                password: '',
                password_confirmation: '',
                roles: [],
                warehouse_id: '',
                warehouses: [],
                client_id: ''
            },
            role_id: '',
            roles: [],
            required_client: false,
            customers: [],
            show: false,
            show_c: false
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.create_user";
    },
    methods: {
        index(){
            this.user.warehouses = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            // 2 -> get all clients, 1 -> get national clients, 0 -> get international clients
            this.$http.get(this.$store.state.chronosApi + 'clientsIndex/2', {headers: {Authorization: lsToken}})
            .then((response) =>{
                response.data = response.data.data;
                response.data.forEach( x => {
                    x = x.attributes;
                    this.customers.push({name: x.name, id: x.id});
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
            
            this.$http.get(this.$store.state.chronosApi + 'warehouses', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.forEach(x => {
                    this.user.warehouses.push({name: x.name, id: x.id, selected: false});
                })
                this.$http.get(this.$store.state.chronosApi + 'roles', {headers: {Authorization: lsToken}})
                .then((response) => {
                    response.data = response.data.data;
                    response.data.forEach(x => {
                        x = x.attributes;
                        var exist = false;
                        x.permissions.forEach( y => {
                            y = y.attributes;
                            if(y.name == "customer.show") { exist = true; return;}
                        });
                        if(exist == true) this.roles.push({name: x.name, id: x.id, required_client: true});
                        else this.roles.push({name:x.name, id: x.id, required_client: false});
                    })
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.user.roles.push({id: this.role_id});
            this.user.warehouses.forEach( x => {
                if(x.selected == true) {
                    this.user.warehouse_id = x.id
                    return;
                }
            })
            if(this.user.warehouse_id == ''){
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('create_user.select_warehouse')
                }
            }
            else{
                if(this.user.password == this.user.password_confirmation){
                    this.$http.post(this.$store.state.chronosApi + 'users', {user:this.user}, {headers: {Authorization: lsToken}})
                    .then((response) => {
                        response
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('create_user.responses.success')
                        }
                        this.$router.push({ name: "UsersIndex" });  
                    }).catch((err) => {
                        err
                        this.snackbar = {
                            visible: true,
                            color: 'error',
                            text: this.$t('create_user.responses.success')
                        }
                    }).finally(() => {
                        this.$store.state.loaderProps.visible = false;
                    })
                }
                else{
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('create_user.wrong_passwords')
                    }
                }
            }
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
        'role_id': function (){
            const role = this.roles.find( x => x.id == this.role_id);
            if(role.required_client == true) this.required_client = true;
            else this.required_client = false;
        }
    }
}
</script>

<style lang="css">
</style>