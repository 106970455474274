<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5" justify="center" align="center">
                    <v-col cols="4" align="start" justify="start">    
                        <h3 class="azul--text text-uppercase">{{ $t('product_refurbish.details') }}</h3>
                    </v-col>
                    <v-col cols="2" align="end" justify="end">
                        <v-btn class="right white--text" color="amarillo" @click="cancel" >
                            {{ $t('product_refurbish.go_back') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_refurbish.sku')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.sku }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_refurbish.description')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.description }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_refurbish.client')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.client.name }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-3">
                                <datatable :props="{ headers:headers, items: locations }" @methodHandler="methodHandler"></datatable>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>    
        </v-container>   
        <v-dialog v-model="modalEdit" persistent max-width="90%">
            <v-card >
                <v-card-title class="justify-center">
                    <v-row align="center" justify="center" class="mt-4">
                        <h3 class="text-uppercase">
                            {{ $t('product_refurbish.create_refurbish') }}
                        </h3>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       <v-row class="px-5 py-0 mt-7 mb-0" justify="center" align="center">
                            <v-col cols="9" class="py-0">
                                <v-text-field class="py-0" v-model="detail_to_update.sku" disabled :label="$t('product_refurbish.sku')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 mt-1 mb-0" justify="center" align="center">
                            <v-col cols="9" class="py-0">
                                <v-text-field class="py-0" v-model="detail_to_update.max_quantity" disabled :label="$t('product_refurbish.max_quantity')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 mt-1 mb-0" justify="center" align="center">
                            <v-col cols="9" class="py-0">
                                <v-text-field class="py-0" v-model="detail_to_update.refurbish" :label="$t('product_refurbish.refurbish')" :rules="numeric_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 mt-1 mb-0" justify="center" align="center" v-if="with_location">
                            <v-col cols="9" class="py-0">
                                <v-autocomplete class="mt-0" v-model="detail_to_update.location_id" item-value="id" item-text="name" :items="racks" :label="$t('edit_appointment.product_details.select_location')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 mt-1 mb-0" justify="center" align="center" v-if="with_location">
                            <v-col cols="9" class="py-0">
                                <v-text-field class="py-0" v-model="detail_to_update.pallet" :label="$t('product_refurbish.pallet')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 mt-1 mb-0" justify="center" align="center" v-if="with_location">
                            <v-col cols="9" class="py-0">
                                <v-text-field class="py-0" v-model="detail_to_update.box" :label="$t('product_refurbish.box')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 mt-1 mb-0" justify="center" align="center" v-if="with_location">
                            <v-col cols="9" class="py-0">
                                <v-text-field class="py-0" v-model="detail_to_update.batch" @keyup="uppercase" :label="$t('product_refurbish.batch')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 mt-1 mb-0" justify="center" align="center" v-if="with_location">
                            <v-col cols="9" class="py-0">
                                <v-text-field class="py-0" v-model="detail_to_update.expiration" @keyup="uppercase" :label="$t('product_refurbish.expiration')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn class="mx-3" text @click="modalEdit = false">{{ $t('product_refurbish.cancel') }}</v-btn>
                        <v-btn color="warning" class="white--text" @click="updateDetail">
                            {{ $t('product_refurbish.save') }}
                        </v-btn>
                    </div>
                </v-card-text> 
                <div class="marker2"></div>
            </v-card>
        </v-dialog>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_refurbish.validations.numbers'),
                v => v <= this.detail_to_update.max_quantity || this.$t('product_refurbish.quantity_higher') + '(' + this.detail_to_update.max_quantity + ')',
            ];
        },
        headers(){
            return [
                { text: this.$t('product_refurbish.location'), align: "center", value: 'location.name', class: 'text-uppercase' },
                { text: this.$t('product_refurbish.pallet'), align: "center", value: 'pallet_per_location.pallet', class: 'text-uppercase' },
                { text: this.$t('product_refurbish.box'), align: "center", value: 'box', class: 'text-uppercase' },
                { text: this.$t('product_refurbish.quantity'), align: "center", value: 'quantity', class: 'text-uppercase' },
                // { text: this.$t('product_refurbish.reserved'), align: "center", value: 'reserved', class: 'text-uppercase' },
                { text: this.$t('product_refurbish.batch'), align: "center", value: 'batch', class: 'text-uppercase' },
                { text: this.$t('product_refurbish.expiration'), align: "center", value: 'reference', class: 'text-uppercase' },
                { text: this.$t('product_refurbish.date'), align: "center", value: 'created_at', class: 'text-uppercase' },
                { text: "", align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            locations: [], // Array of detailed_stocks
            racks: [], // Array of locations
            product: {
                sku: "",
                description: "",
                unit: "",
                barcode: '', 
                height: '', 
                length : '', 
                width: '',
                size_unit: '',
                category:{
                    name: "",
                },
                storage_policy: "",
                weight: "",
                client: {
                    name: ""
                }
            },
            modalEdit: false,
            original_quantity: '',
            with_location: false,
            detail_to_update: {
                id: '',
                sku: '',
                max_quantity: '',
                refurbish: ''
            },
            actions: [
                {
                    name: this.$t('product_refurbish.adjust'),
                    icon: {
                        color: 'accent',
                        icon: 'tune'
                    },
                    method: 'showModalEdit',
                    permission: "refurbish.create"
                }
            ]
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.product_refurbish";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.locations = [];
            this.$http.get(this.$store.state.chronosApi + 'productsRefurbish/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x => {
                    this.product = {
                        sku: x.sku,
                        description: x.description,
                        client: {
                            name: x.client_name
                        }
                    }

                    var detail = {
                        location: { name: x.name },
                        pallet_per_location: { pallet: x.pallet },
                        box: x.box,
                        // epc: (x.attributes.epc == null ? "" : "B-" + x.attributes.id ),
                        batch: x.batch,
                        quantity: x.max_quantity,
                        // reserved: x.reserved,
                        reference: x.reference,
                        created_at: x.created_at.split('T')[0],
                        actions: JSON.parse(JSON.stringify(this.actions)),
                        // actions: actions,
                        parameters: x
                    }
                    this.locations.push(detail);
                });

                // Get available locations
                this.$http.get(this.$store.state.chronosApi + 'getLocations/5/' + response.data.data[0].client_id, {headers: {Authorization: lsToken}})
                .then((response2) => {
                    if(response2.status === 200){
                        response2.data.forEach(x =>{
                            this.racks.push({
                                id: x.id,
                                name: x.name
                            });
                        });
                    }
                });
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        save(){
        },
        showModalEdit(detail){
            this.modalEdit = true;
            this.detail_to_update.product_id = detail.product_id
            this.detail_to_update.id = detail.id;
            this.detail_to_update.sku = detail.sku;
            this.detail_to_update.max_quantity = detail.max_quantity;
            this.detail_to_update.batch = detail.batch;
            this.detail_to_update.expiration = detail.reference
            this.detail_to_update.client_id = detail.client_id;
            this.original_quantity = detail.max_quantity;
        },
        updateDetail(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            if(this.with_location == false){
                this.$http.patch(this.$store.state.chronosApi + 'products/adjustRefurbish/' + this.detail_to_update.id, this.detail_to_update, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status == 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('edit_warehouse.responses.success')
                        }
                        this.$router.go(-1);
                    }
                }).catch((err) => {
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: err.response.data.message
                    }

                    this.with_location = true;
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else {
                this.$http.patch(this.$store.state.chronosApi + 'products/adjustRefurbishWithNewLocation/' + this.detail_to_update.id, this.detail_to_update, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status == 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('edit_warehouse.responses.success')
                        }
                        this.$router.go(-1);
                    }
                }).catch((err) => {
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: err.response.data.message
                    }

                    this.with_location = true;
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            
        },
        cancel(){
            this.$router.go(-1);
        },
        uppercase() {
            if(this.detail_to_update.batch != null){
                this.detail_to_update.batch = this.detail_to_update.batch.toUpperCase();
                this.detail_to_update.batch = this.detail_to_update.batch.replace(/\s/g,'');
            }

            if(this.detail_to_update.expiration != null){
                this.detail_to_update.expiration = this.detail_to_update.expiration.toUpperCase();
                this.detail_to_update.expiration = this.detail_to_update.expiration.replace(/\s/g,'');
            }
        },
    },
    watch:{
        
    }
}
</script>

<style lang="css">
</style>