export const CustomsPatchesReportsTypes = {
    CLEAR : '[CUSTOMS_PATCHES_REPORTS] CLEAR',
  
    DISCHARGES_REQUEST : '[CUSTOMS_PATCHES_REPORTS][DISCHARGES] REQUEST',
    DISCHARGES_SUCCESS : '[CUSTOMS_PATCHES_REPORTS][DISCHARGES] SUCCESS',
    DISCHARGES_FAILURE : '[CUSTOMS_PATCHES_REPORTS][DISCHARGES] FAILURE',

    BALANCE_REPORT_REQUEST : '[CUSTOMS_PATCHES_REPORTS][BALANCE_REPORT] REQUEST',
    BALANCE_REPORT_SUCCESS : '[CUSTOMS_PATCHES_REPORTS][BALANCE_REPORT] SUCCESS',
    BALANCE_REPORT_FAILURE : '[CUSTOMS_PATCHES_REPORTS][BALANCE_REPORT] FAILURE',
    
    EXPIRATION_REPORT_REQUEST : '[CUSTOMS_PATCHES_REPORTS][EXPIRATION_REPORT] REQUEST',
    EXPIRATION_REPORT_SUCCESS : '[CUSTOMS_PATCHES_REPORTS][EXPIRATION_REPORT] SUCCESS',
    EXPIRATION_REPORT_FAILURE : '[CUSTOMS_PATCHES_REPORTS][EXPIRATION_REPORT] FAILURE',

    ANNEX_REQUEST : '[CUSTOMS_PATCHES_REPORTS][ANNEX] REQUEST',
    ANNEX_SUCCESS : '[CUSTOMS_PATCHES_REPORTS][ANNEX] SUCCESS',
    ANNEX_FAILURE : '[CUSTOMS_PATCHES_REPORTS][ANNEX] FAILURE',

    DISCHARGES_EXPORT_REQUEST : '[CUSTOMS_PATCHES_REPORTS][DISCHARGES_EXPORT] REQUEST',
    DISCHARGES_EXPORT_SUCCESS : '[CUSTOMS_PATCHES_REPORTS][DISCHARGES_EXPORT] SUCCESS',
    DISCHARGES_EXPORT_FAILURE : '[CUSTOMS_PATCHES_REPORTS][DISCHARGES_EXPORT] FAILURE',

    BALANCE_EXPORT_REQUEST : '[CUSTOMS_PATCHES_REPORTS][BALANCE_EXPORT] REQUEST',
    BALANCE_EXPORT_SUCCESS : '[CUSTOMS_PATCHES_REPORTS][BALANCE_EXPORT] SUCCESS',
    BALANCE_EXPORT_FAILURE : '[CUSTOMS_PATCHES_REPORTS][BALANCE_EXPORT] FAILURE',

    EXPIRATION_EXPORT_REQUEST : '[CUSTOMS_PATCHES_REPORTS][EXPIRATION_EXPORT] REQUEST',
    EXPIRATION_EXPORT_SUCCESS : '[CUSTOMS_PATCHES_REPORTS][EXPIRATION_EXPORT] SUCCESS',
    EXPIRATION_EXPORT_FAILURE : '[CUSTOMS_PATCHES_REPORTS][EXPIRATION_EXPORT] FAILURE',
}