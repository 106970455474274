<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('services.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height" color="amarillo" :to="'/services/create'" v-permission="'service.create'">
                        {{ $t('services.new') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        headers(){
            return [
                { text: this.$t('services.name'), align: "center", value: 'name', class: 'text-uppercase' },
                { text: this.$t('services.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalDelete: false,
            idBox: '',
            actions: [
                {
                    name: this.$t('services.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'edit'
                    },
                    link: '',
                    permission: "box.show"
                }
            ],
            props: {
                search: '',
                items: [],
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.services";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'services', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data;
                if(response.status === 200){
                    response.data.forEach(x =>{
                        x = x.attributes;
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x.actions[0].link = 'services/edit/' + x.id;
                        x['parameters'] = x;
                        this.props.items.push(x);
                    });
                this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        askDelete(box){
            this.modalDelete = true;
            this.idBox = box.id;
        }
    }
}
</script>

<style>

</style>