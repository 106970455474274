
function getLocalAccessToken() {
  const accessToken = window.localStorage.getItem("access_token");
  return accessToken;
}

function getLocalRefreshToken() {
  const refreshToken = window.localStorage.getItem("refresh_token");
  return refreshToken;
}

function setTokens({access_token ="", refresh_token=""}){
  window.localStorage.setItem("access_token", access_token);
  window.localStorage.setItem("refresh_token", refresh_token);
}

function removeTokens(){
  window.localStorage.removeItem("access_token");
  window.localStorage.removeItem("refresh_token");
}


const token = {
  getLocalAccessToken,
  getLocalRefreshToken,
  removeTokens,
  setTokens
};

export default token;