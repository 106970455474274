<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('agents_and_custom_agents.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="showExport = true">
                        {{ $t('agents_and_custom_agents.export') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="showExport" persistent max-width="90%" style="width: 500px !important;">
                <v-card style="width:500px !important">
                    <v-card-title class="justify-center mt-4">
                        <v-row align="center" justify="center" class="my-2">
                            <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('agents_and_custom_agents.export_data')}}</label>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="6" class="py-0">
                                <v-menu ref="initial_date" v-model="menu2" :close-on-content-click="false" :return-value.sync="report.initial_date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field class="py-0" v-model="report.initial_date" :label="$t('agents_and_custom_agents.initial_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="report.initial_date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu2 = false">{{ $t('agents_and_custom_agents.cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.initial_date.save(report.initial_date)">{{ $t('agents_and_custom_agents.save') }}</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <v-menu ref="final_date" v-model="menu3" :close-on-content-click="false" :return-value.sync="report.final_date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field class="py-0" v-model="report.final_date" :label="$t('agents_and_custom_agents.final_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="report.final_date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu3 = false">{{ $t('agents_and_custom_agents.cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.final_date.save(report.final_date)">{{ $t('agents_and_custom_agents.save') }}</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="showExport = false">{{ $t('agents_and_custom_agents.cancel') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="exportData">
                                {{ $t('agents_and_custom_agents.export') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('agents_and_custom_agents.name'), align: "center", value: 'name', class: 'text-uppercase' },
                { text: this.$t('agents_and_custom_agents.patent'), align: "center", value: 'patent', class: 'text-uppercase' },
                { text: this.$t('agents_and_custom_agents.rfc'), align: "center", value: 'rfc', class: 'text-uppercase' },
                { text: this.$t('agents_and_custom_agents.curp'), align: "center", value: '', class: 'text-uppercase' },
                { text: this.$t('agents_and_custom_agents.neighborhood'), align: "center", value: 'neighborhood', class: 'text-uppercase' },
                { text: this.$t('agents_and_custom_agents.street'), align: "center", value: 'street', class: 'text-uppercase' },
                { text: this.$t('agents_and_custom_agents.zipcode'), align: "center", value: 'zipcode', class: 'text-uppercase' },
                { text: this.$t('agents_and_custom_agents.external_number'), align: "center", value: 'external_number', class: 'text-uppercase' },
                { text: this.$t('agents_and_custom_agents.internal_number'), align: "center", value: 'internal_number', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            report:{
                initial_date: '',
                final_date: ''
            },
            menu2: false,
            menu3: false,
            showExport: false
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.agents_and_custom_agents";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'customs/catalogs/custom-brokers', {headers: {Authorization: lsToken}})
            .then((response) => {
                this.props.items = response.data.data;
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        exportData(){

        }
    }
}
</script>

<style>
</style>