<template>
    <div>
        <v-card flat>
            <v-card-text class="px-0 py-0">
                <v-row class="px-5 py-0 my-0" justify="start" align="start">
                    <v-col cols="3" class="py-0" v-for="(set, i) of loadedDocumentsSets" :key="i">
                        <label style="font-size: 14px; font-weight: bold;" class="azul--text">{{$t('dashboard.documents_load')}} {{ set.name }}</label>
                        <br>
                        <br>
                        <v-col cols="12" class="py-0" style="display:flex" >
                            <doughnut-chart :name="i" :height="400" style="width: 90% !important; margin-bottom: 25px !important;" :chart-data="set" :options="totalAppointmentsOptions"></doughnut-chart>
                        </v-col>
                    </v-col>
                </v-row>

                <v-row class="px-5 py-0 my-0" justify="start" align="start">
                    <v-col cols="3" class="py-0" v-for="(set, i) of loadedInformationSets" :key="i">
                        <label style="font-size: 14px; font-weight: bold;" class="azul--text">{{$t('dashboard.information_load')}} {{ set.name }}</label>
                        <br>
                        <br>
                        <v-col cols="12" class="py-0" style="display:flex" >
                            <doughnut-chart :name="i" :height="400" style="width: 90% !important; margin-bottom: 25px !important;" :chart-data="set" :options="totalAppointmentsOptions"></doughnut-chart>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>

import DoughnutChart from './Charts/Doughnut.js'

export default {
    props: [
        'initial_date',
        'final_date',
        'totalAppointmentsOptions',
        'colors',
        'load'
    ],
    components: {
		DoughnutChart,
    },
    computed: {
        headers(){
            return [
                { text: this.$t('dashboard.customer'), align: "start", value: 'name', class: 'text-uppercase', width: "25%"},
                { text: this.$t('dashboard.income_movements'), align: "center", value: 'income_movements', class: 'text-uppercase', width: "16.6%" },
                { text: this.$t('dashboard.outcome_movements'), align: "center", value: 'outcome_movements', class: 'text-uppercase', width: "16.6%" },
                { text: this.$t('dashboard.fulfillment'), align: "center", value: 'ff', class: 'text-uppercase', width: "16.6%" },
                { text: this.$t('dashboard.ffext'), align: "center", value: 'extff', class: 'text-uppercase', width: "8.3%" },
                { text: this.$t('dashboard.returns'), align: "center", value: 'returns', class: 'text-uppercase', width: "8.3%" },
                { text: this.$t('dashboard.total'), align: "center", value: 'total', class: 'text-uppercase', width: "8.3%" },
            ];
        } 
    },
    data(){
        return {
            customers_data: {
                items: [],
                search: ""
            },
            total: {
                income: 0,
                outcome: 0,
                ff: 0,
                extff: 0,
                returns: 0,
                total: 0
            },

            loadedDocumentsSets: [],
            loadedInformationSets: [],
            change_pending: false,
        }
    },
    mounted(){
        if(this.load == 3) this.index();
    },
    methods: {
        async index(){
            if(!this.change_pending){

                this.change_pending = true
                this.$nextTick(async () => {
                    this.$store.state.loaderProps.visible = true;

                    this.loadedInformationSets = [];
                    this.loadedDocumentsSets = [];

                    await this.getLoadDocumentsByUser();
                    await this.getLoadInformationByUser();
                    
                    this.$store.state.loaderProps.visible = false;

                    this.change_pending = false;
                });
            }
        },
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        async getLoadDocumentsByUser(){
            const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi+'loadDocumentsByUser/' + this.initial_date + '/' + this.final_date, { headers: { Authorization: lsToken } })
			.then((response) => {

                const labels = [
                    { name: this.$t("dashboard.completed"), color: this.colors[9] },
                    { name: this.$t("dashboard.incompleted"), color: this.colors[10] },
                    { name: this.$t("dashboard.empty"), color: this.colors[11] }
                ]
                const datasets = []

                let count = 0;
                let t = this;
                Object.keys(response.data.data).forEach(function (key) {
                    datasets.push([{
                        backgroundColor: labels.map(x => {
                            return x.color
                        }),
                        data: [
                            response.data.data[key].doc_complete, 
                            response.data.data[key].doc_incomplete, 
                            response.data.data[key].empty_doc
                        ],
                        borderColor: 'rgba(0, 0, 0, 0)',
                    }])

                    t.loadedDocumentsSets.push({
                        name: response.data.data[key].user_name,
                        labels: labels.map(x => {
                            return x.name;
                        }),
                        datasets: datasets[count]
                    })
                    count++;
                });
			})
        },
        async getLoadInformationByUser(){
            const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi+'loadInformationByUser/' + this.initial_date + '/' + this.final_date, { headers: { Authorization: lsToken } })
			.then((response) => {

                const labels = [
                    { name: this.$t("dashboard.completed"), color: this.colors[1] },
                    { name: this.$t("dashboard.incompleted"), color: this.colors[6] },
                    { name: this.$t("dashboard.empty"), color: this.colors[3] }
                ]
                const datasets = []

                let count = 0;
                let t = this;
                Object.keys(response.data.data).forEach(function (key) {
                    datasets.push([{
                        backgroundColor: labels.map(x => {
                            return x.color
                        }),
                        data: [
                            response.data.data[key].doc_complete, 
                            response.data.data[key].doc_incomplete, 
                            response.data.data[key].empty_doc
                        ],
                        borderColor: 'rgba(0, 0, 0, 0)',
                    }])

                    t.loadedInformationSets.push({
                        name: response.data.data[key].user_name,
                        labels: labels.map(x => {
                            return x.name;
                        }),
                        datasets: datasets[count]
                    })
                    count++;
                });
			})
        }
    },
    watch: {
        'initial_date': function(){
            if(this.load == 3) this.index();
        },
        'final_date': function(){
            if(this.load == 3) this.index();
        },
        'load': function (){
            if(this.load == 3 && this.change_pending == false) this.index();
        }
    }
}
</script>

<style>

</style>