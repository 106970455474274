<template>
	<v-bottom-navigation class="v-footer" v-model="value" color="amarillo" dark shift >
		<v-btn class="btn" @click="pendings"> {{ $t('footer.pendings') }} </v-btn>
		<v-btn class="btn" @click="on_premises"> {{ $t('footer.in_enclosure') }} </v-btn>
		<v-btn class="btn" @click="processing"> {{ $t('footer.processing') }} </v-btn>
		<v-btn class="btn" @click="output"> {{ $t('footer.output') }} </v-btn>
	</v-bottom-navigation>
</template>

<script>
	export default {
		name: 'BarraInferior',
		data () {
			return {
				value: this.$store.state.bottom
			}
		},
		mounted(){
		},
		methods:{
			pendings(){
				localStorage.setItem('bottom', 0);
				this.$store.state.bottom = 0;
				this.$router.push({ name: 'OperationPendings' });
			},
			on_premises(){
				localStorage.setItem('bottom', 1);
				this.$store.state.bottom = 1;
				this.$router.push({ name: 'OperationOnPremises'});
			},
			processing(){
				localStorage.setItem('bottom', 2);
				this.$store.state.bottom = 2;
				this.$router.push({ name: 'OperationInProcess'});
			},
			output(){
				localStorage.setItem('bottom', 3);
				this.$store.state.bottom = 3;
				this.$router.push({ name: 'PendingOut'});
			}
		}
	};
</script>
<style lang="css">
	.v-footer{
		position: absolute !important;
		height: 35px !important;
		margin: 0 0.75em;
		bottom: 35px !important;
		min-width: calc(100vw - (220px + 0.375em));
		width: calc(100vw - (220px + 0.375em)) !important;
	}
	
	.btn{
		height: 25px !important;
		padding: 0px;
		margin: 0px;
	}

</style>