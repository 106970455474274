<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('attendances.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="modalCheckIn = true;" v-permission="'attendances.create'">
                        {{ $t('attendances.check_in') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                    {{ $t('attendances.delete') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('attendances.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('attendances.no') }}</v-btn>
                            <v-btn color="error" @click="deleteAttendance">
                                {{ $t('attendances.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalCheckIn" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                    {{ $t('attendances.check_in') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="formattendances">
                            <v-row align="center" justify="center" class="py-3 my-3 text-center">
                                <v-col cols="12">
                                    <v-text-field class="py-0 mx-2" v-model="user_code" counter="7" :rules="numeric_required" :label="$t('attendances.user_code')"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalCheckIn = false">{{ $t('attendances.cancel') }}</v-btn>
                            <v-btn class="white--text" color="amarillo" @click="saveCheckIn">
                                {{ $t('attendances.save') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        headers(){
            return [
                { text: this.$t('attendances.date'), align: "center", value: 'attendance_date', class: 'text-uppercase' },
                { text: this.$t('attendances.name'), align: "center", value: 'user.name', class: 'text-uppercase' },
                { text: this.$t('attendances.entry'), align: "center", value: 'entry_time', class: 'text-uppercase' },
                { text: this.$t('attendances.departure'), align: "center", value: 'exit_time', class: 'text-uppercase' },
                { text: this.$t('attendances.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        },
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field'),
                v => v == null || v.toString().length == 0 || v.toString().length == 7 || this.$t('attendances.lenght'),
            ];
        },
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalDelete: false,
            modalCheckIn: false,
            user_code: '',
            id_attendance: '',
            actions: [
                {
                    name: this.$t('attendances.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                    permission: "attendances.delete"
                }
            ],
            props: {
                search: '',
                items: [],
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.attendances";
        
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'attendances', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data.forEach( x => {
                        if(x.entry_time != null) x.entry_time = x.entry_time.split("T")[1].substring(0,8);
                        if(x.exit_time != null) x.exit_time = x.exit_time.split("T")[1].substring(0,8);
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x.parameters = {"id": x.id};
                        this.props.items.push(x);
                    })
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        askDelete(attendances){
            console.log(attendances)
            this.modalDelete = true;
            this.id_attendance = attendances.id;
        },
        deleteAttendance(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'attendances/' + this.id_attendance, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.modalDelete = false;
                    this.index();
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        saveCheckIn(){
            var validate = this.$refs.formattendances.validate();
            if(!validate){
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('attendances.user_code_not_valid')
                }
                return;
            }

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.post(this.$store.state.chronosApi + 'attendances', {"employee_number": this.user_code}, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 201 || response.status === 200){
                    this.modalCheckIn = false;
                    this.user_code = "";
                    this.index();
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: response.response.data.error
                    }
                }
            })
            .finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        }
    }
}
</script>

<style>

</style>