import http from "../plugins/http-common"

export default {
    list({initial_date,final_date }){
        return http.get(`customs_patches?initial_date=${initial_date}&final_date=${final_date}`);
    },
    create(data){
      return http.post("customs_patches", data);
    },
    detail(id){
      return http.get(`customs_patches/${id}`);
    },
    destroy(data){
      return http.delete(`customs_patches?${data}`);
    }
}