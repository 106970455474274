<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col cols="3" class="py-0 my-0">
                    <client-selector :addAllOption="false" class="py-0 my-0 " ref="clientSelector" @getClients="v => items.clients = v" :only_fulfillment="1" :is_national="2" :cod="1"></client-selector>  
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-menu ref="date" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field class="py-0" v-model="date" :label="$t('daily_cutoff.date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                        </template>
                        <v-date-picker v-model="date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">{{ $t('daily_cutoff.cancel') }}</v-btn>
                            <v-btn text color="primary" @click="$refs.date.save(date)">{{ $t('daily_cutoff.save') }}</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" @click="show_export_options = true" v-if="show_export_options == false">
                        {{ $t('stock_report.export') }}
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataPdf">
                        PDF
                    </v-btn>
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataExcel">
                        Excel
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="show_export_options = false;">
                        <v-icon small class="px-0">
                            keyboard_arrow_left
                        </v-icon>
                    </v-btn>

                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="generate">
                        {{ $t('daily_cutoff.generate') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="px-0">
                    <v-col class="py-0">
                        <v-row class="azul font-weight-bold text-uppercase white--text px-2 py-0">
                            <v-col cols="6" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('daily_cutoff.prepared_orders') }}:</span> {{ summary.total }}</v-col>
                            <v-col cols="6" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('daily_cutoff.amount_sent') }}:</span> {{ summary.paid }}</v-col>           
                        </v-row>
                    </v-col>
                </v-card-text>
                <v-card-text class="pt-0 pb-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers: headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>    
        <search-dialog :uon="current_uon" v-if="current_uon != ''" class="show_search_modal"></search-dialog>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('daily_cutoff.order_number'), align: "center", value: 'unique_order_number', class: 'text-uppercase' },
                { text: this.$t('daily_cutoff.tracking_number'), align: "center", value: 'shipment_number', class: 'text-uppercase' },
                { text: this.$t('daily_cutoff.parcel'), align: "center", value: 'shipping_service', class: 'text-uppercase' },
                { text: this.$t('daily_cutoff.cod'), align: "center", value: 'total', class: 'text-uppercase' },
                { text: this.$t('daily_cutoff.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        },
        client: function(){
            return this.$store.state.clientId
        },
    },
    data(){
        return {
            numberFormat: new Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            date: '',
            final_date: '',
            menu: false,
            summary: {
                paid: 0,
                total: 0
            },
            actions: [
                {
                    name: this.$t('daily_cutoff.detail'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showInfo',
                    permission: "daily_cutoff.index"
                }
            ],
            showSearch: false,
            current_uon: "",
            show_export_options: false
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.daily_cutoff";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
        },
        generate(){
            this.props.items = [];
            
            if(this.$store.state.clientId != "" && this.date != ""){
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + 'trackNTrace/daily_cutoff?client_id='+ this.$store.state.clientId + '&date='+ this.date, {headers: {Authorization: lsToken}})
                .then((response) => {
                    this.summary.total = 0;
                    this.summary.paid = 0;
                    response.data.data.forEach(x => {
                        this.summary.total++;
                        this.summary.paid += x.total;
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x["parameters"] = x.unique_order_number;
                        this.props.items.push(x); 
                    });

                    this.summary.paid = '$' + this.numberFormat.format(this.summary.paid);
                })
                .catch((response) => {
                    this.$store.state.loaderProps.visible = false;
                    console.log(response)
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('pendings.responses.error')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportDataExcel(){
            const lsToken = localStorage.getItem("access_token");
            if(this.$store.state.clientId != "" && this.date != ""){
                this.$store.state.loaderProps.visible = true;
                
                this.$http.get(this.$store.state.chronosApi + 'trackNTrace/daily_cutoff?client_id='+ this.$store.state.clientId + '&date='+ this.date, {headers: {Authorization: lsToken}})

                this.$http.get(this.$store.state.chronosApi + 'trackNTrace/daily_cutoff_export?client_id='+ this.$store.state.clientId + '&date='+ this.date, {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'daily_cutoff.xlsx');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportDataPdf(){
            const lsToken = localStorage.getItem("access_token");
            if(this.$store.state.warehouseId != "" && this.$store.state.clientId != "" && this.date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;

                this.$http.get(this.$store.state.chronosApi + 'reports/operationsByClientPdf?warehouse='+ this.$store.state.warehouseId + '&client='+ this.$store.state.clientId + '&startDate='+ this.date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'Operaciones_Por_Cliente.pdf');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        showInfo(uon){
            this.current_uon = uon;
            this.showSearch = true;
        },
    },
    watch: {
        '$store.state.clientId': function(){
            this.props.items = [];
        }
    }
}
</script>

<style>
</style>