<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-0 size">
                <v-card-title class="justify-center">
                    <h2 class="text-uppercase azul--text">{{ $t('edit_shipment.edit_order') }}</h2>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.unique_order_number" disabled :label="$t('edit_shipment.unique_order_number')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-autocomplete v-model="form.external_shipping_service" item-text="text" item-value="value" :label="$t('edit_shipment.shipping_service')" :items="external_services"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.shipment_number" :label="$t('edit_shipment.shipment_number')" :rules="rules_required" ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" :clearable="true" v-model="form.file" accept=".png" :label="$t('edit_shipment.guide_file')"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" multiple :clearable="true" v-model="form.attached_files" accept=".pdf" :label="$t('edit_shipment.attached_files')"></v-file-input>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-0 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel" >
                                {{ $t('create_appointment.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" >
                                {{ $t('create_appointment.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                
            </v-card>
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    computed:{
        
        rules_tn() {
            return [
                v => !!v || this.$t('edit_shipment.validations.required_field'),
            ]
        },       
        consignee_name() {
            return [
                v => !!v || this.$t('edit_shipment.validations.required_field'),
                v => v.toString().length >= 3 && v.toString().length <= 35 || this.$t('edit_shipment.validations.name'),
            ];
        },
        rules_required(){return [v => !!v || this.$t('edit_shipment.validations.required_field')]}, 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            id: this.$route.params.id,
            form: {
                unique_order_number: "",
                external_shipping_service: "",
                shipment_number: "",
                file: [],
                attached_files: [],
            },
            clientId: this.$store.state.clientUserId,
            external_services: [
                "FedEx", 
                "UPS", 
                "DHL", 
                "PaqueteExpress", 
                "99 Minutos", 
                "Estafeta", 
                "Quiken", 
                "AFIMEX", 
                "Mercado Colecta", 
                "Tres Guerras",
                "Redpack",
                "Pickup",
                "J&T",
                "Afimex"
            ],
        }
    },
    mounted(){
        this.$store.state.module = "headers.edit_pendind_order";
        this.index();
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token")

            this.$http.get(this.$store.state.chronosApi+'shipment/getOrderInformation/' + this.id, { headers: { Authorization: this.bearer } })
            .then((response) => {
                if(response.status === 200){
                    this.form.unique_order_number = response.data.data.attributes.uniqueOrderNumber;
                }
                this.$store.state.loaderProps.visible = false;
            })
            .catch((error) => {
                console.log(error)
            })
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token")
            
            if(this.form.file.length == 0 && this.form.attached_files.length == 0){
                this.$store.state.loaderProps.visible = false
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t("edit_shipment.add_some_file")
                }

                return;
            }

            if(this.form.shipment_number == ""){
                this.$store.state.loaderProps.visible = false
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t("edit_shipment.shipment_number_required")
                }

                return;
            }

            if(this.form.external_shipping_service == ""){
                this.$store.state.loaderProps.visible = false
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t("edit_shipment.shipping_service_required")
                }

                return;
            }

            const formData = new FormData();
                formData.append('unique_order_number', this.form.unique_order_number);
                formData.append('shipping_service', this.form.external_shipping_service);
                formData.append('shipment_number', this.form.shipment_number);
                formData.append('external_shipping_service', this.form.external_shipping_service);
                formData.append('shipment_id', this.id)

                // Optional files
                if(this.form.file != []) formData.append('label', this.form.file);
                if(this.form.attached_files.length != [])
                    for(var counter = 0; counter < this.form.attached_files.length; counter++){
                        formData.append('attached_files[]', this.form.attached_files[counter]);
                    }

                // console.log([...formData]) // Print form data displaying all pr

                this.$http.post(this.$store.state.chronosApi+'/shipment/add_label', formData, { headers: { Authorization: lsToken, 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    if(response.data.code === 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: 'Orden actualizada con éxito'
                        }
                    }
                    this.$store.state.loaderProps.visible = false;
                    this.$router.go(-1);
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch: {}
}
</script>

<style>

</style>
