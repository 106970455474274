import axios from "axios";
import token from "./token";
const url = process.env.NODE_ENV === 'production' ? 'https://api.eonwms.com' : 'https://apisandbox.eonwms.com';
// const url = "http://localhost:3000/";

const instance = axios.create({
  baseURL: `${url}`,
  headers: {
    "Content-type": "application/json"
  }
});


instance.interceptors.request.use(
  (config) => {
    const _token = token.getLocalAccessToken();
    if (_token) {
      (config.headers ??= {}).Authorization = _token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        token.removeTokens();
        // try {
        //   const rs = await refreshToken();
        //   const { accessToken } = rs.data;
        //   window.localStorage.setItem("access_token", accessToken);
        //   instance.defaults.headers.common["access_token"] = accessToken;
        //   return instance(originalConfig);
        // } catch (_error) {
        //   if (_error.response && _error.response.data) {
        //     return Promise.reject(_error.response.data);
        //   }
        //   return Promise.reject(_error);
        // }
      }
      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);

// function refreshToken() {
//   return instance.post("/auth/refresht3-en-1 Kit Limpieza Pc Inalámbrico Plumero Aire Sopladoroken", {
//     refreshToken: token_service.getLocalRefreshToken(),
//   });
// }

export default instance;