<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5" justify="center" align="center">
                    <v-col cols="4" align="start" justify="start">    
                        <h3 class="azul--text text-uppercase">{{ $t('customs_patches.customs_documents.detail.title') }}</h3>
                    </v-col>
                    <v-col cols="2" align="end" justify="end">
                        <v-btn class="right white--text" color="amarillo" @click="cancel" >
                            {{ $t('customs_patches.customs_documents.detail.go_back') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       <v-row top v-for="(header, index) in headers" :key="index" class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ header.text}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ nestedValue(header.value,detail.payload) }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>    
        </v-container>           
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { getNestedProperty } from '../../plugins/format-propierties';

export default {
    computed: {
        ...mapGetters('customs_patches', [
            'detail',
        ]),
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        headers(){
            return [
                { text: this.$t('customs_patches.customs_documents.detail.customs_document'), align: "center", value: 'attributes.customs_document', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.customs_document_date'), align: "center", value: 'attributes.customs_document_date', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.income_date'), align: "center", value: 'attributes.income_date', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.doc_key'), align: "center", value: 'attributes.doc_key', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.regime'), align: "center", value: 'attributes.regime', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.origin_country'), align: "center", value: 'attributes.origin_country', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.customs_document_departure'), align: "center", value: 'attributes.customs_document_departure', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.part_number'), align: "center", value: 'attributes.part_number', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.description'), align: "center", value: 'attributes.description', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.invoice_quantity'), align: "center", value: 'attributes.invoice_quantity', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.invoice_number'), align: "center", value: 'attributes.invoice_number', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.umc'), align: "center", value: 'attributes.umc', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.fraction'), align: "center", value: 'attributes.fraction', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.value_in_dollars'), align: "center", value: 'attributes.value_in_dollars', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.codcove'), align: "center", value: 'attributes.codcove', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.client_supplier'), align: "center", value: 'attributes.client_supplier', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.notice_number'), align: "center", value: 'attributes.notice_number', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.detail.umt'), align: "center", value: 'attributes.umt', class: 'text-uppercase' },
            ];
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            locations: [],
            product: {
                client: '',
                category: ''
            },
        }
    },
    
    mounted(){
        this.getDetail(this.id);
        this.$store.state.module = "headers.customs_documents_details";
    },
    methods: {
        nestedValue(key,object){
            return getNestedProperty(key, object)
        },
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
           
        },
        save(){
        },
        relocate(id){
            console.log(id);
        },
        cancel(){
            this.$router.push({ name: "CustomsPatchesIndex" }); 
        },
        ...mapActions('customs_patches', [
            'getDetail',
        ])
    },
    watch: {
        detail: {
            handler(val){
                console.log(val)
                this.$store.state.loaderProps.visible = val.loading;
            },
            deep: true
        },
    },
}
</script>

<style lang="css">
</style>