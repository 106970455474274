import service from "../../../services/customs_patches_reports.service";
import { CustomsPatchesReportsTypes } from "./customs_patches_reports.mutation";
import FileSaver from 'file-saver';
import store from "../../index";

const initial_state = {
  discharge: {
   data:[],
   loading: false,
   success: false,
  },
  dischargeExport: {
    data:[],
    loading: false,
    success: false, 
  },
  balance: {
    data:[],
    loading: false,
    success: false,
  },
  balanceExport: {
    data:[],
    loading: false,
    success: false,
  },
  expiration: {
    data:[],
    loading: false,
    success: false,
  },
  expirationExport: {
    data: [],
    loading: false,
    success: false
  },
  annex: {
    data:[],
    loading: false,
    success: false,
  }
}

const ModuleCustomsPatchesReport = {
  namespaced: true,
  state: () => (initial_state),
  mutations: { 
    // [CustomsPatchesReportsTypes.CLEAR] (state) {
    //   state = initial_state;
    // },
    [CustomsPatchesReportsTypes.DISCHARGES_REQUEST] (state) {
      state.discharge.data = []
      state.discharge.loading = true
      state.discharge.success = false
    },
    [CustomsPatchesReportsTypes.DISCHARGES_SUCCESS] (state, payload) {
      state.discharge.data = payload
      state.discharge.loading = false
      state.discharge.success = true
    },
    [CustomsPatchesReportsTypes.DISCHARGES_FAILURE] (state) {
      state.discharge.data = []
      state.discharge.loading = false
      state.discharge.success = false
    },
    [CustomsPatchesReportsTypes.DISCHARGES_EXPORT_REQUEST] (state) {
      state.dischargeExport.data = []
      state.dischargeExport.loading = true
      state.dischargeExport.success = false
    },
    [CustomsPatchesReportsTypes.DISCHARGES_EXPORT_SUCCESS] (state, payload) {
      state.dischargeExport.data = payload
      state.dischargeExport.loading = false
      state.dischargeExport.success = true
    },
    [CustomsPatchesReportsTypes.DISCHARGES_EXPORT_FAILURE] (state) {
      state.dischargeExport.data = []
      state.dischargeExport.loading = false
      state.dischargeExport.success = false
    },
    [CustomsPatchesReportsTypes.BALANCE_EXPORT_REQUEST] (state) {
      state.balanceExport.data = []
      state.balanceExport.loading = true
      state.balanceExport.success = false
    },
    [CustomsPatchesReportsTypes.BALANCE_EXPORT_SUCCESS] (state, payload) {
      state.balanceExport.data = payload
      state.balanceExport.loading = false
      state.balanceExport.success = true
    },
    [CustomsPatchesReportsTypes.BALANCE_EXPORT_FAILURE] (state) {
      state.balanceExport.data = []
      state.balanceExport.loading = false
      state.balanceExport.success = false
    },
    [CustomsPatchesReportsTypes.BALANCE_REPORT_REQUEST] (state) {
      state.balance.data = []
      state.balance.loading = true
      state.balance.success = false
    },
    [CustomsPatchesReportsTypes.BALANCE_REPORT_SUCCESS] (state, payload) {
      state.balance.data = payload
      state.balance.loading = false
      state.balance.success = true
    },
    [CustomsPatchesReportsTypes.BALANCE_REPORT_FAILURE] (state) {
      state.balance.data = []
      state.balance.loading = false
      state.balance.success = false
    },
    [CustomsPatchesReportsTypes.EXPIRATION_REPORT_REQUEST] (state) {
      state.expiration.data = []
      state.expiration.loading = true
      state.expiration.success = false
    },
    [CustomsPatchesReportsTypes.EXPIRATION_REPORT_SUCCESS] (state, payload) {
      state.expiration.data = payload
      state.expiration.loading = false
      state.expiration.success = true
    },
    [CustomsPatchesReportsTypes.EXPIRATION_REPORT_FAILURE] (state) {
      state.expiration.data = []
      state.expiration.loading = false
      state.expiration.success = false
    },
    [CustomsPatchesReportsTypes.EXPIRATION_EXPORT_REQUEST] (state) {
      state.dischargeExport.data = []
      state.dischargeExport.loading = true
      state.dischargeExport.success = false
    },
    [CustomsPatchesReportsTypes.EXPIRATION_EXPORT_SUCCESS] (state, payload) {
      state.dischargeExport.data = payload
      state.dischargeExport.loading = false
      state.dischargeExport.success = true
    },
    [CustomsPatchesReportsTypes.EXPIRATION_EXPORT_FAILURE] (state) {
      state.dischargeExport.data = []
      state.dischargeExport.loading = false
      state.dischargeExport.success = false
    },
    [CustomsPatchesReportsTypes.ANNEX_REQUEST] (state) {
      state.annex.data = []
      state.annex.loading = true
      state.annex.success = false
    },
    [CustomsPatchesReportsTypes.ANNEX_SUCCESS] (state, payload) {
      state.annex.data = payload
      state.annex.loading = false
      state.annex.success = true
    },
    [CustomsPatchesReportsTypes.ANNEX_FAILURE] (state) {
      state.annex.data = []
      state.annex.loading = false
      state.annex.success = false
    },
  },
  getters: { 
    discharge: state => state.discharge,
    balance: state => state.balance,
    expiration: state => state.expiration,
    annex: state => state.annex,
    dischargeExport: state => state.dischargeExport,
    balanceExport: state => state.balanceExport,
    expirationExport: state => state.expirationExport
  },
  actions:  {
      async getDischargeReport({ commit }, payload) {
        commit(CustomsPatchesReportsTypes.DISCHARGES_REQUEST)
        return service.dischargesReport({year:payload.year})
          .then(response => {
            return response.data;
          })
          .then(async data =>  {
            commit(CustomsPatchesReportsTypes.DISCHARGES_SUCCESS, data.data);
          })
          .catch(error => {
            commit(CustomsPatchesReportsTypes.DISCHARGES_FAILURE, {});
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.errors) {
                  for (const error of error.response.data.errors) {
                    console.log(error)
                  }
                }
                if (error.response.data.message) {
                  // commit('setError', error.response.data.message);
                }
              }
            }
          });
      },
      async dischargesReportExport({ commit }, payload) {
        commit(CustomsPatchesReportsTypes.DISCHARGES_EXPORT_REQUEST)
        store.state.loaderProps.visible = true;
        return service.dischargesReportExport({year:payload.year})
          .then(response => {
            store.state.loaderProps.visible = false;

            return FileSaver.saveAs(response.data, 'Reporte_Descargos.xlsx');
          })
          .then(async data =>  {
            commit(CustomsPatchesReportsTypes.DISCHARGES_EXPORT_SUCCESS, data.data);
          })
          .catch(error => {
            commit(CustomsPatchesReportsTypes.DISCHARGES_EXPORT_FAILURE, {});
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.errors) {
                  for (const error of error.response.data.errors) {
                    console.log(error)
                  }
                }
                if (error.response.data.message) {
                  // commit('setError', error.response.data.message);
                }
              }
            }
          });
      },
      async getBalanceReport({ commit }) {
        commit(CustomsPatchesReportsTypes.BALANCE_REPORT_REQUEST)
        return service.balanceReport()
          .then(response => {
            return response.data;
          })
          .then(async data =>  {
            commit(CustomsPatchesReportsTypes.BALANCE_REPORT_SUCCESS, data.data);
          })
          .catch(error => {
            commit(CustomsPatchesReportsTypes.BALANCE_REPORT_FAILURE, {});
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.errors) {
                  for (const error of error.response.data.errors) {
                    console.log(error)
                  }
                }
                if (error.response.data.message) {
                  // commit('setError', error.response.data.message);
                }
              }
            }
          });
      },
      async getExpirationReport({ commit }) {
        commit(CustomsPatchesReportsTypes.EXPIRATION_REPORT_REQUEST)
        return service.expirationReport()
          .then(response => {
            return response.data;
          })
          .then(async data =>  {
            commit(CustomsPatchesReportsTypes.EXPIRATION_REPORT_SUCCESS, data.data);
          })
          .catch(error => {
            commit(CustomsPatchesReportsTypes.EXPIRATION_REPORT_FAILURE, {});
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.errors) {
                  for (const error of error.response.data.errors) {
                    console.log(error)
                  }
                }
                if (error.response.data.message) {
                  // commit('setError', error.response.data.message);
                }
              }
            }
          });
      },
      async getAnnex30({ commit }, payload){
        commit(CustomsPatchesReportsTypes.ANNEX_REQUEST)
        return service.annex30({annex:payload.annex})
          .then(response => {
            var element = document.createElement('a');
            var bimester = parseInt(payload.annex.bimester) - 36;
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response.data.data.join('\r\n')));
            element.setAttribute('download', "Descargos " + bimester + "° Bimestre " + payload.annex.cve + ".txt");

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
            return response.data;
          })
          .then(async data =>  {
            commit(CustomsPatchesReportsTypes.ANNEX_SUCCESS, data.data);
          })
          .catch(error => {
            commit(CustomsPatchesReportsTypes.ANNEX_FAILURE, {});
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.errors) {
                  for (const error of error.response.data.errors) {
                    console.log(error)
                  }
                }
                if (error.response.data.message) {
                  // commit('setError', error.response.data.message);
                }
              }
            }
          });
      },
      async getBalanceReportExport({ commit }) {
        commit(CustomsPatchesReportsTypes.BALANCE_EXPORT_REQUEST)
        store.state.loaderProps.visible = true;
        return service.getBalanceReportExport()
          .then(response => {
            store.state.loaderProps.visible = false;

            return FileSaver.saveAs(response.data, 'Reporte_saldos.xlsx');
          })
          .then(async data =>  {
            commit(CustomsPatchesReportsTypes.BALANCE_EXPORT_SUCCESS, data.data);
          })
          .catch(error => {
            commit(CustomsPatchesReportsTypes.BALANCE_EXPORT_FAILURE, {});
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.errors) {
                  for (const error of error.response.data.errors) {
                    console.log(error)
                  }
                }
                if (error.response.data.message) {
                  // commit('setError', error.response.data.message);
                }
              }
            }
          });
      },
      async getExpirationReportExport({ commit }) {
        commit(CustomsPatchesReportsTypes.EXPIRATION_EXPORT_REQUEST)
        store.state.loaderProps.visible = true;
        return service.getExpirationReportExport()
          .then(response => {
            store.state.loaderProps.visible = false;

            return FileSaver.saveAs(response.data, 'Reporte_vencimientos.xlsx');
          })
          .then(async data =>  {
            commit(CustomsPatchesReportsTypes.EXPIRATION_EXPORT_SUCCESS, data.data);
          })
          .catch(error => {
            commit(CustomsPatchesReportsTypes.EXPIRATION_EXPORT_FAILURE, {});
            if (error.response) {
              if (error.response.data) {
                if (error.response.data.errors) {
                  for (const error of error.response.data.errors) {
                    console.log(error)
                  }
                }
                if (error.response.data.message) {
                  // commit('setError', error.response.data.message);
                }
              }
            }
          });
      },
   },
}

export default ModuleCustomsPatchesReport;