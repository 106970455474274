<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-card class="px-0 top_bar mb-5">
                <v-card-text class="py-0 my-0">
                    <v-row class="px-0 py-0 my-0" style="max-height: 43px !important; min-height: 43px !important;" align="center">
                        <v-col cols="6" class="py-0 my-0 px-0">
                            <v-tabs v-model="tab"  active-class="customers_active_tab" style="max-height: 43px !important;">
                                <v-tabs-slider class="slider" color="amarillo" style="max-height: 43px !important; margin: 0px !important;"></v-tabs-slider>
                                <v-tab v-for="item in tab_items" :key="item" class="non_selected_tab slider" style="max-height: 43px !important;">
                                    {{ item }}
                                </v-tab>
                            </v-tabs>
                        </v-col>
                        <v-col cols="3" class="py-0 my-0" >
                            <v-text-field
                                v-model="props.search"
                                append-icon="search"
                                single-line
                                hide-details
                                class="py-0 px-2 my-0"
                                color="amarillo"
                                style="background-color: transparent; border: 1px solid #9fb700; border-radius:5px"
                            ></v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2" align="end" class="py-0 px-0" v-if="tab == 0">
                            <!-- <v-btn class="right white--text tools-view-height mx-3" outlined color="amarillo" :to="''" >
                                {{ $t('pendings.massive_upload') }}
                                <v-icon class="px-1" small>cloud_upload</v-icon>
                            </v-btn> -->
                            <v-btn class="right white--text tools-view-height px-2" outlined color="amarillo" @click="exportStock">
                                {{ $t('products.export_stock') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-tabs-items v-model="tab" class="px-0 mx-3 ">
                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_buttons mx-0 px-0" flat>
                        <v-card-text class="py-3">
                            <v-row>
                                <v-col class="py-0">
                                    <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_buttons mx-0 px-0" flat>
                        <v-card-text class="py-3">
                            <v-row>
                                <v-col class="py-0">
                                    <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>

                
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            if(this.tab == 0){
                return [
                    { text: this.$t('products.sku'), align: "center", value: 'sku', class: 'text-uppercase' },
                    { text: this.$t('products.description'), align: "center", value: 'description', class: 'text-uppercase' },
                    { text: this.$t('products.available_stock'), align: "center", value: 'available_stock', class: 'text-uppercase' },
                    { text: this.$t('products.reserved_stock'), align: "center", value: 'reserved_stock', class: 'text-uppercase' },
                    { text: this.$t('products.storage_policy'), align: "center", value: 'storage_policy', class: 'text-uppercase' },
                    { text: this.$t('products.details'), align: "center", value: 'actions', class: 'text-uppercase' },
                ];
            }
            else {
                return [
                    { text: this.$t('kits.sku'), align: "center", value: 'sku', class: 'text-uppercase' },
                    { text: this.$t('kits.description'), align: "center", value: 'description', class: 'text-uppercase' },
                    { text: this.$t('kits.products_included'), align: "center", value: 'products_count', class: 'text-uppercase' },
                    { text: this.$t('kits.details'), align: "center", value: 'actions', class: 'text-uppercase' },
                ];
            }
            
        } 
    },
    data(){
        return {
            tab_items:[this.$t("inventory.products"), this.$t("inventory.kits")],
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            tab: null,
            actions: [
                {
                    name: this.$t('products.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    link: '',
                    permission: "customer.show"
                },
            ],
            props: {
                search: '',
                items: [],
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.my_inventory";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.tab = 0;
        },
        exportStock(){
            if(this.tab == 0){
                this.$store.state.loaderProps.visible = true
                const lsToken = localStorage.getItem("access_token")
                this.$http.get(this.$store.state.chronosApi + 'client_stock/' + this.$store.state.clientUserId, { headers: { Authorization: lsToken }, responseType:'blob' })
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'Productos.xlsx');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
        }
    },
    watch:{
        'tab': function(){
            if(this.tab == 1){
                this.props.items = [];
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + 'kits/customer/' + this.$store.state.clientUserId, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200){
                        response.data = response.data.data;
                        response.data.forEach(x =>{
                            x = x.attributes;
                            x['actions'] = JSON.parse(JSON.stringify(this.actions));
                            x.products_count = x.kit_detail.length;
                            x.actions[0].link = 'customer_kits_details/' + x.id;
                            this.props.items.push(x);
                        });
                    this.$store.state.loaderProps.visible = false;
                    }
                })
                .catch((response) => {
                    this.$store.state.loaderProps.visible = false;
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('pendings.responses.error')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
            }
            else if(this.tab == 0){
                this.props.items = [];
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + 'products/customer/' + this.$store.state.clientUserId, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200){
                        response.data.products.data.forEach(x =>{
                            x = x.attributes;
                            x['actions'] = JSON.parse(JSON.stringify(this.actions));
                            x.actions[0].link = 'customer_products_details/' + x.id;
                            var stock = {
                                sku: x.sku,
                                stock: 0,
                                description: x.description,
                                storage_policy: x.storage_policy,
                                actions: x.actions,
                                parameters: x
                            };

                            x.stock.forEach( y => {
                                y = y.attributes;
                                if(y.warehouse_id == this.$store.state.warehouseId) {
                                    stock.available_stock = (y.quantity - y.reserved);
                                    stock.reserved_stock = y.reserved;
                                    return;
                                }
                            })
                            this.props.items.push(stock);
                        });
                        this.$store.state.loaderProps.visible = false;
                    }
                })
                .catch((response) => {
                    this.$store.state.loaderProps.visible = false;
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('pendings.responses.error')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
            }
        }
    }
}
</script>

<style>

</style>