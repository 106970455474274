<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('kits.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text" color="amarillo" :to="'/kits/create'" v-permission:all="'kit.create|kit_detail.create'">
                        {{ $t('kits.new') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                    {{ $t('kits.modal.delete') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('kits.modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('kits.no') }}</v-btn>
                            <v-btn color="error" @click="deleteLocation">
                                {{ $t('kits.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('kits.sku'), align: "center", value: 'sku', class: 'text-uppercase' },
                { text: this.$t('kits.description'), align: "center", value: 'description', class: 'text-uppercase' },
                { text: this.$t('kits.customer'), align: "center", value: 'client.name', class: 'text-uppercase' },
                { text: this.$t('kits.products_included'), align: "center", value: 'products_count', class: 'text-uppercase' },
                { text: this.$t('kits.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalDelete: false,
            idCustom_agent: '',
            actions: [
                {
                    name: this.$t('kits.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    link: '',
                    permission: 'kit.show|kit_detail.show'
                },
                {
                    name: this.$t('kits.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'edit'
                    },
                    link: '',
                    permission: 'kit.update|kit_detail.update'
                },
                {
                    name: this.$t('kits.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                    permission: 'kit.delete|kit_detail.delete'
                }
            ],
            props: {
                search: '',
                items: [],
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.kits";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'kits', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x =>{
                    x['actions'] = JSON.parse(JSON.stringify(this.actions));
                    x.actions[0].link = 'kits/details/' + x.id;
                    x.actions[1].link = 'kits/edit/' + x.id;
                    x['parameters'] = x;

                    var kit = {
                        sku: x.attributes.sku,
                        description: x.attributes.description,
                        client: {
                            name: x.attributes.client.attributes.name
                        },
                        products_count: x.attributes.kit_detail.length,
                        actions: x.actions
                    }
                    this.props.items.push(kit);
                });
                this.$store.state.loaderProps.visible = false;
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        askDelete(custom_agent){
            this.modalDelete = true;
            this.idCategory = custom_agent.id;
        },
        deleteLocation(){
            console.log(this.idCustom_agent);
        },
        update(custom_agent){
            console.log(custom_agent);
        },
    }
}
</script>

<style>

</style>