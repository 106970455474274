<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('edit_visitor.new_visitor') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-menu ref="date" v-model="menu" :close-on-content-click="false" :return-value.sync="visitor.date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field class="py-0" v-model="visitor.date" :label="$t('edit_visitor.date_of_visit')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="visitor.date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">{{ $t('edit_visitor.cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.date.save(visitor.date)">{{ $t('edit_visitor.accept') }}</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">

                                <v-menu ref="entry_time" v-model="entry_time_modal" :close-on-content-click="false" :return-value.sync="visitor.entry_time" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="visitor.entry_time" :label="$t('edit_visitor.time_of_visit')" append-outer-icon="schedule" readonly v-on="on"></v-text-field>
                                    </template>
                                    <template>
                                        <v-time-picker v-model="visitor.entry_time" ampm-in-title>
                                            <v-col justify="space-around" align="center">
                                                <v-btn text color="primary" @click="entry_time_modal = false">{{ $t('edit_visitor.cancel') }}</v-btn>
                                                <v-btn text color="primary" @click="$refs.entry_time.save(visitor.entry_time)">{{ $t('edit_visitor.accept') }}</v-btn>
                                            </v-col>
                                        </v-time-picker>
                                    </template>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="visitor.visitor_name" :label="$t('edit_visitor.name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="visitor.company" :label="$t('edit_visitor.company')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="visitor.plates" :label="$t('edit_visitor.vehicle_plates')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="visitor.receiver_name" :label="$t('edit_visitor.receiver_name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="visitor.subject" :label="$t('edit_visitor.subject')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="visitor.observations" :label="$t('edit_visitor.observations')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0 px-0">
                                <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :disabled="identification_file" :clearable="false" v-model="visitor.identification_photo" accept="image/*" capture="camera" :label="$t('edit_visitor.identification_photo')"></v-file-input>
                            </v-col>
                            <v-col cols="1" >
                                <v-row justify="end" align="end">
                                    <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('identification')" :disabled="identification_file == false"><v-icon small>download</v-icon></v-btn>
                                    <v-btn outlined color="red" small class="px-0 mx-1" style="min-width:25px !important" :disabled="identification_file == false" @click="deleteFile('identification')"><v-icon  small>delete</v-icon></v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="5" class="py-0 px-0">
                                <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :disabled="vehicle_file" :clearable="false" v-model="visitor.vehicle_photo" accept="image/*" capture="camera" :label="$t('edit_visitor.vehicle_photo')"></v-file-input>
                            </v-col>
                            <v-col cols="1">
                                <v-row justify="end" align="end">
                                    <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('vehicle')" :disabled="vehicle_file == false"><v-icon small>download</v-icon></v-btn>
                                    <v-btn outlined color="red" small class="px-0 mx-1" style="min-width:25px !important" :disabled="vehicle_file == false" @click="deleteFile('vehicle')"><v-icon  small>delete</v-icon></v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('edit_visitor.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'visitors.create'">
                                {{ $t('edit_visitor.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>  
        <v-dialog v-model="modalDelete" persistent max-width="90%">
            <v-card >
                <v-card-title class="justify-center">
                    <v-row align="center" justify="center">
                            <h2>
                                {{ $t('upload_files.modal.title') }}
                            </h2>
                    </v-row>
                    <v-btn color="amarillo darken-1" small icon @click="modalDelete = false"><v-icon small>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    
                    <v-row align="center" justify="center" class="py-3 my-3 text-center">
                        <v-col cols="12">
                            <h3>{{ $t('upload_files.modal.continue') }}</h3>
                        </v-col>
                    </v-row>
                    
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn class="mx-3" text @click="modalDelete = false; currentDelete = ''">{{ $t('upload_files.modal.cancel') }}</v-btn>
                        <v-btn color="amarillo" class="color" @click="deleteM">
                            {{ $t('upload_files.modal.delete') }}
                        </v-btn>
                        
                    </div>
                </v-card-text> 
            </v-card>
        </v-dialog>      
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            menu: false,
            entry_time_modal: false,
            identification_file: false,
            vehicle_file: false,
            modalDelete: false,
            currentDelete: "",
            current_files: [],
            visitor: {
                date: "",
                entry_time: "", 
                departure_time: "", 
                visitor_name: "", 
                company: "", 
                plates: "", 
                receiver_name: "", 
                subject: "", 
                observations: "", 
                warehouse_id: this.$store.state.warehouseId,
                identification_photo: [],
                vehicle_photo: []
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.edit_visitor";
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'visitors/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.current_files = [];
                response.data = response.data.data.attributes
                this.visitor = response.data;

                if(this.visitor.identification_photo != null) {
                    this.identification_file = true;
                    this.current_files.push(this.visitor.identification_photo);

                    this.visitor.identification_photo = new File(["foo"], this.visitor.identification_photo.filename, {
                        type: "text/plain",
                    });
                }
                    
                if(this.visitor.vehicle_photo != null) {
                    this.vehicle_file = true;
                    this.current_files.push(this.visitor.vehicle_photo);

                    this.visitor.vehicle_photo = new File(["foo"], this.visitor.vehicle_photo.filename, {
                        type: "text/plain",
                    });
                }

            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const formData = new FormData();

            formData.append('date', this.visitor.date);
            formData.append('entry_time', this.visitor.entry_time);
            formData.append('departure_time', this.visitor.departure_time);
            formData.append('visitor_name', this.visitor.visitor_name);
            formData.append('company', this.visitor.company);
            formData.append('plates', this.visitor.plates);
            formData.append('receiver_name', this.visitor.receiver_name);
            formData.append('subject', this.visitor.subject);
            formData.append('observations', this.visitor.observations);
            formData.append('warehouse_id', this.visitor.warehouse_id);
            if(this.visitor.identification_photo != null && this.visitor.identification_photo.type != 'text/plain') formData.append('identification_photo', this.visitor.identification_photo);
            if(this.visitor.vehicle_photo != null && this.visitor.vehicle_photo.type != 'text/plain') formData.append('vehicle_photo', this.visitor.vehicle_photo);
            
            // console.log([...formData]) // Print form data displaying all pr

            this.$http.put(this.$store.state.chronosApi + 'visitors/' + this.id, formData,{headers: {Authorization: lsToken}})
            .then((response) => {
                response
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('create_box.responses.success')
                }
                this.$router.push({ name: "VisitorsIndex" });  
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
        getFile(type){
            this.$store.state.loaderProps.visible = true;
            this.current_files.forEach(x => {
                if(x.filename.includes(type)){    
                this.$store.state.loaderProps.visible = true;
                    this.$http.get(this.$store.state.chronosApi + 'getSingleFile/' + x.id, { responseType: 'blob'})
                    .then(response => {
                        FileSaver.saveAs(response.data, x.filename)
                        this.$store.state.loaderProps.visible = false;
                    })
                }
            })

            this.$store.state.loaderProps.visible = false;
        },
        deleteFile(type){
            this.modalDelete = true;
            this.currentDelete = type;
        },
        deleteM(){
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;
            var index = null; 
            this.current_files.forEach(x => {
                if(x.filename.includes(this.currentDelete)){
                    index = x.id
                }
            });
            this.$http.delete(this.$store.state.chronosApi + 'appointments/' + index + '/deleteFile', {headers: {Authorization: lsToken}})
            .then(response => {
                if(response.status == 200){
                    this.modalDelete = false;
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('upload_files.responses.deleted')
                    }
                }
                this.current_files[this.currentDelete] = false;
                this.index();
            }).catch((error) => {
                console.log(error);
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('upload_files.responses.error')
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        }
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>