<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('advanced_fulfillment_supplied.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" class="right white--text tools-view-height mx-1" color="amarillo" @click="downloadOrdersSummary" style="max-width: 40px !important; min-width: 40px !important" >
                                <v-icon class="material-icons-outlined" small>downloading</v-icon>
                            </v-btn>
                        </template>

                        <span>{{$t('advanced_fulfillment_supplied.download_orders_summary')}}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <v-data-table class="px-0 my-2"
                                no-results-text="No hay resultados"
                                :headers="headers"
                                :items="props.items"
                                :search="props.search"
                                calculate-widths
                                :footer-props="{
                                    'items-per-page-text': this.$t('datatable.shown'),
                                    showFirstLastPage: true,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2} ' + this.$t('datatable.results'),
                                    'show-current-page': true,
                                    'items-per-page-options': typeof props.rowsPP === 'undefined' ? [15, 25, 50] : props.rowsPP}"
                                :page="1"
                                :mobile-breakpoint="0"
                                item-key="id"
                                show-select
                                v-model="selected"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                                        <template v-slot:activator="{ on }">
                                            <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                            <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ action.name }}</span>
                                    </v-tooltip>
                                </template>
                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            </v-container>     
            <v-dialog v-model="modalRestore" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h3 class="text-uppercase">
                                {{ $t('fulfillments_cod_in_process.restore_modal.restore') }}
                            </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('fulfillments_cod_in_process.restore_modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalRestore = false">{{ $t('fulfillments_cod_in_process.no') }}</v-btn>
                            <v-btn color="error" @click="restoreOrder">
                                {{ $t('fulfillments_cod_in_process.yes_restore') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalDetail" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('fulfillments_cod_pendings.order_details.header') }} {{ this.orderToShow.uniqueOrderNumber }}
                            </h4>
                        </v-row>
                    </v-card-title>
                    
                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_cod_pendings.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.order_number") }}</label> <label>{{ this.orderToShow.uniqueOrderNumber }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.date") }}</label> <label>{{ this.orderToShow.createdAt }}</label></div>
                    <div class="detail_content1" v-if="shipping_service != 'otra'"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_pendings.shipping_service") }}</label> <label>{{ this.orderToShow.shippingService }}</label></div>
                    <div class="detail_content1" v-if="shipping_service == 'otra'"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_pendings.shipping_service") }}</label> <label>{{ this.orderToShow.externalShippingService }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_pendings.invoice") }}</label> <label>{{ this.orderToShow.invoice }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.client != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.client") }}</label> <label>{{ this.orderToShow.client.name }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.cod") }}</label> <label>{{ this.orderToShow.total }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.buyer") }}</label> <label>{{ this.orderToShow.consignee.name }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.state") }}</label> <label>{{ this.orderToShow.consignee.state }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.municipality") }}</label> <label>{{ this.orderToShow.consignee.municipality }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.neighborhood") }}</label> <label>{{ this.orderToShow.consignee.neighborhood }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.street") }}</label> <label>{{ this.orderToShow.consignee.street }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.zip_code") }}</label> <label>{{ this.orderToShow.consignee.zipcode }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_cod_pendings.products') }}</label></div>
                    <span v-if="orderToShow.shipmentProductDetail != null">
                        <div v-for="(product, i) of orderToShow.shipmentProductDetail.data" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.attributes.product.sku }}</label> <label>{{ product.attributes.quantity }}</label></div>
                    </span>
                    <span v-if="orderToShow.products != null">
                        <div v-for="(product, i) of orderToShow.products" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.sku }}</label> <label>{{ product.quantity }}</label></div>
                    </span>

                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDetail = false">{{ $t('fulfillments_cod_pendings.go_back') }}</v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        headers(){
            return [
                { text: this.$t('advanced_fulfillment_supplied.order'), align: "center", value: 'uniqueOrderNumber', class: 'text-uppercase' },
                { text: this.$t('advanced_fulfillment_supplied.shipment_number'), align: "center", value: 'shipmentNumber', class: 'text-uppercase' },
                { text: this.$t('advanced_fulfillment_supplied.customer'), align: "center", value: 'client.name', class: 'text-uppercase' },
                { text: this.$t('advanced_fulfillment_supplied.date'), align: "center", value: 'date', class: 'text-uppercase' },
                { text: this.$t('advanced_fulfillment_supplied.value'), align: "center", value: 'value', class: 'text-uppercase' },
                { text: this.$t('advanced_fulfillment_supplied.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            shipping_service: this.$route.params.shipping_service,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalDelete: false,
            modalDetail: false,
            modalRestore: false,
            orderToShow: "",
            orderToRestore: "",
            actions: [
                {
                    name: this.$t('advanced_fulfillment_supplied.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                },
                {
                    name: this.$t('advanced_fulfillment_supplied.restore'),
                    icon: {
                        color: 'accent',
                        icon: 'history'
                    },
                    method: 'askRestore',
                }
            ],
            props: {
                search: '',
                items: [],
            },
            selected: [],
            controller_name: "advanced_fulfillment"
        }
    },
    mounted(){
        this.$store.state.module = "headers.advanced_fulfillment_" + this.shipping_service + "_supplied";

        if(this.shipping_service == "eon") this.controller_name = "advanced_external_fulfillment";
        else this.controller_name = "advanced_fulfillment"

        this.index();
    },
    methods: {
        methodHandler(object, params){
            this[object](params)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'v1/' + this.controller_name + '/supplied_orders?page=1&per_page=100&shipping_service=' + this.shipping_service.toUpperCase(), {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data;
                if(response.status === 200){
                    response.data.forEach(x =>{
                        x = x.attributes;
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x.parameters = x;
                        this.props.items.push(x);
                    });
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            })
        },
        downloadOrdersSummary: async function(){
            if(this.selected.length <= 0) return;
            this.$store.state.loaderProps.visible = true;

            var shipments_ids = "";
            this.selected.forEach(image => {
                shipments_ids += image.id + ","; 
            })
            shipments_ids = shipments_ids.substring(0, shipments_ids.length - 1);

            const ordersSummary = await this.getOrdersSummary(shipments_ids);
            await FileSaver.saveAs(ordersSummary.data, "Orders_Summary.pdf");

            this.$store.state.loaderProps.visible = false;
        },
        getOrdersSummary: async function(ids){
            const lsToken = localStorage.getItem("access_token");

            if(this.shipping_service == "eon"){
                const file = await this.$http.get(this.$store.state.chronosApi + 'external_shipment/order_summary/' + ids, {headers: {Authorization: lsToken}, responseType:'blob'});
                return file;
            }
            else {
                const file = await this.$http.get(this.$store.state.chronosApi + 'shipment/ordersSummary?shipments=' + ids, {headers: {Authorization: lsToken}, responseType:'blob'});
                return file;
            }
        },
        showDetails(order){
            this.orderToShow = order;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
 
            if(this.shipping_service == "eon"){
                this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_info/' + order.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    this.orderToShow = {
                        uniqueOrderNumber: response.data.order.unique_order_number,
                        createdAt: response.data.order.created_at.substring(0,10),
                        shippingService: response.data.order.shipping_service,
                        customer: response.data.order.name,
                        buyer: response.data.order.consignee_name,
                        products: JSON.parse(response.data.order.products).products
                    }
                    this.$store.state.loaderProps.visible = false;
                    this.modalDetail = true;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else {
                this.$http.get(this.$store.state.chronosApi + 'shipment/getOrderInformation/' + order.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    response.data = response.data.data.attributes;
                    this.$store.state.loaderProps.visible = false;
                    this.orderToShow = response.data;
                    this.modalDetail = true;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
        },
        askRestore(order){
            this.modalRestore = true;
            this.orderToRestore = order;
        },
        restoreOrder(){
            this.$store.state.loaderProps.visible = true;
            if(this.orderToRestore != ''){
                if(this.shipping_service == "eon"){
                    const lsToken = localStorage.getItem("access_token");
                    this.$http.put(this.$store.state.chronosApi + 'external_shipment/restore/' + this.orderToRestore.id, {headers: {Authorization: lsToken}})
                    .then((response) => {
                        if(response.status === 200) {
                            this.orderToRestore = '';
                            this.modalRestore = false;
                            this.snackbar = {
                                visible: true,
                                color: 'success2',
                                text: this.$t('fulfillments_cod_sent.restored')
                            }
                            this.index();
                        }
                    }).catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        this.$store.state.loaderProps.visible = false;
                    })
                }
                else {
                    this.$http.put(this.$store.state.chronosApi + 'shipment/restock/' + this.orderToRestore.id, {headers: {}})
                    .then((response) => {
                        if(response.data.status === 200) {
                            this.orderToRestore = '';
                            this.modalRestore = false;
                            this.snackbar = {
                                visible: true,
                                color: 'success2',
                                text: this.$t('advanced_fulfillment_sent.restored')
                            }
                            this.index();
                        }
                    }).catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        this.$store.state.loaderProps.visible = false;
                    })
                }
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: "error"
                }
            }
        },
    },
    watch:{
        '$route.params.shipping_service': function() {
            location.reload();
        }
    }
}
</script>

<style>

</style>