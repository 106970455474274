<template >
    <div>
        <v-tooltip top v-for="(action, index) in actions" :key="index">
            <template v-slot:activator="{ on }" >
                <v-btn :loading="action.loading" x-small v-if="action.type === 'method'" class="mx-0" icon @click="action.method(data)" :disabled="action.disabled">
                    <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                </v-btn>
                <v-btn :loading="action.loading" x-small v-else-if="action.type === 'redirect'" icon text :to="action.link(data)" :disabled="action.disabled">
                    <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                </v-btn>
                <v-btn :loading="action.loading" x-small v-else-if="action.type === 'external'" icon text target="_blank" :href="action.link" :disabled="action.disabled">
                    <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                </v-btn>
            </template>
            <span>{{ action.name }}</span>
        </v-tooltip>
    </div>
</template>

<script lang="js">
export default {
    props: {
        actions:{
            type: Array,
            default: () => ([{
                name: '',
                icon: {
                    color: 'accent',
                    icon: 'info'
                },
                link:()=>"",
                permission: "product.show",
                loading:false,
                disabled:false,
                type:'method',
                method:()=>{}
            }])
        },
        data:{
            type: Object
        },
        
    },
    computed: {
        
    },
    data(){
        return {
           
        }
    },
    mounted(){
    },
    methods: {
        methodHandler(){
            this.$emit('click',this.data);
        },
    }
}
</script>