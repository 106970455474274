<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-0 size">
                <div class="main">
                    <v-btn-toggle v-model="panel_active" mandatory class="btn-toggle size"  v-if="from == 'international'">
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 0"><div class="vertical-line"></div></span>{{buttons_international[0].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 1"><div class="vertical-line"></div></span>{{buttons_international[1].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 2"><div class="vertical-line"></div></span>{{buttons_international[2].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 3"><div class="vertical-line"></div></span>{{buttons_international[3].title}}</v-btn>
                        <v-btn plain elevation="0" :disabled="current_operation.format_type == 'Salida'" active-class="button_active" class="button_nav"><span v-if="panel_active == 4"><div class="vertical-line"></div></span>{{buttons_international[4].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 5"><div class="vertical-line"></div></span>{{buttons_international[5].title}}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="cancel" class="white--text mx-9" style="border-radius: 4px; width: 70%" color="amarillo">{{ this.$t('edit_appointment.go_back') }}</v-btn>
                    </v-btn-toggle>
                    <v-btn-toggle v-model="panel_active" mandatory class="btn-toggle size"  v-if="from == 'national'">
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 0"><div class="vertical-line"></div></span>{{buttons_national[0].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 1"><div class="vertical-line"></div></span>{{buttons_national[1].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 2"><div class="vertical-line"></div></span>{{buttons_national[2].title}}</v-btn>
                        <v-btn plain elevation="0" :disabled="current_operation.format_type == 'Salida'" active-class="button_active" class="button_nav"><span v-if="panel_active == 3"><div class="vertical-line"></div></span>{{buttons_national[3].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 4"><div class="vertical-line"></div></span>{{buttons_national[4].title}}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="cancel" class="white--text mx-9" style="border-radius: 4px; width: 70%" color="amarillo">{{ this.$t('edit_appointment.go_back') }}</v-btn>
                    </v-btn-toggle>
                    <div class="separator"></div>
                    <div class="content-scroll">
                        
                        <!-- FORM FOR GENERAL DATA -->
                        <v-form ref="general_data_form" lazy-validation @submit.prevent="save" v-if="panel_active == 0">
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" align="center" justify="center">        
                                    <h2 class="text-uppercase azul--text">{{ $t('edit_appointment.general_data.general_data')}}</h2>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('client_selector.client')}}</label>
                                        <label class="label_width text-right">{{ this.appointment.client_id }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.general_data.date_of_arrival')}}</label>
                                        <label class="label_width text-right"> {{ this.appointment.arrival_date }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="this.from == 'international'">
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.general_data.port_arrival_date') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.eta }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.general_data.operation') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.operation_id }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.general_data.container') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.container_number }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.general_data.description') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.description }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.general_data.comments') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.commentary }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.general_data.aditional_services') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.additional_services }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.general_data.purchase_order') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.purchase_order }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                        
                        <!-- FORM FOR TRANSPORT DETAILS -->
                        <v-form ref="transport_details_form" lazy-validation @submit.prevent="save" v-if="panel_active == 1">
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" align="center" justify="center">        
                                    <h2 class="text-uppercase azul--text">{{ $t('edit_appointment.transport_details.transport_details')}}</h2>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.transport_details.carrier_line') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.carrier_line }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.transport_details.drivers_name') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.drivers_name }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.transport_details.plates') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.license_plate }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.transport_details.traktor') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.tractor }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.transport_details.consignment_note') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.consignment_note }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.transport_details.type_of_cargo') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.cargo_type }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.transport_details.type_of_transport') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.transport_type }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.transport_details.unit_good_conditions') }}</label>
                                        <label class="label_width text-right" v-if="this.appointment.unit_good_conditions == 1">{{ $t('edit_appointment.true') }}</label>
                                        <label class="label_width text-right" v-if="this.appointment.unit_good_conditions == 0">{{ $t('edit_appointment.false') }}</label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="appointment.unit_good_conditions == 0">
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.transport_details.unit_damage_specifications') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.unit_damage_specifications }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.transport_details.seal_well_placed') }}</label>
                                        <label class="label_width text-right" v-if="this.appointment.seal_good_conditions == 1">{{ $t('edit_appointment.true') }}</label>
                                        <label class="label_width text-right" v-if="this.appointment.seal_good_conditions == 0">{{ $t('edit_appointment.false') }}</label>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                        
                        <!-- FORM FOR PRODUCT DETAILS -->
                        <v-form ref="product_details_form" lazy-validation @submit.prevent="save" v-if="panel_active == 2">
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" align="center" justify="center">        
                                    <h2 class="text-uppercase azul--text">{{ $t('edit_appointment.product_details.product_details')}}</h2>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.product_details.type_of_packaging') }}</label>
                                        <label class="label_width text-right">{{ this.appointment.packing_type }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.product_details.punctured_damaged_merchandise') }}</label>
                                        <label class="label_width text-right" v-if="this.appointment.merchandise_good_conditions == 1">{{ $t('edit_appointment.true') }}</label>
                                        <label class="label_width text-right" v-if="this.appointment.merchandise_good_conditions == 0">{{ $t('edit_appointment.false') }}</label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.product_details.packaging_good_condition') }}</label>
                                        <label class="label_width text-right" v-if="this.appointment.packaging_complies_conditions == 1">{{ $t('edit_appointment.true') }}</label>
                                        <label class="label_width text-right" v-if="this.appointment.packaging_complies_conditions == 0">{{ $t('edit_appointment.false') }}</label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center" class="px-5">
                                <v-col class="py-0 px-3" cols="8" align="center" justify="center">
                                    <datatable :props="{headers:headers_sku, items: appointment.products}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                            <br>
                        </v-form>
                        
                        <!-- FORM FOR FOREIGN TRADE -->
                        <v-form ref="foreign_trade_form" lazy-validation @submit.prevent="save" v-if="panel_active == 3 && from == 'international'">
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" align="center" justify="center">        
                                    <h2 class="text-uppercase azul--text">{{ $t('edit_appointment.product_details.product_details')}}</h2>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6" v-if="current_operation.format_type == 'Entrada'">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.foreign_trade.origin_providers')}}</label>
                                        <label class="label_width text-right">{{ this.appointment.origin_supplier_id }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6" v-if="current_operation.format_type == 'Entrada'">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.foreign_trade.patent')}}</label>
                                        <label class="label_width text-right">{{ this.appointment.patent_number }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6" v-if="current_operation.format_type == 'Entrada'">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.foreign_trade.modulation_status')}}</label>
                                        <label class="label_width text-right">{{ this.appointment.modulation_status }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6" v-if="current_operation.format_type == 'Entrada'">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.foreign_trade.modulation_date')}}</label>
                                        <label class="label_width text-right">{{ this.appointment.modulation_date }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6" v-if="current_operation.format_type == 'Entrada'">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.foreign_trade.modulation_time')}}</label>
                                        <label class="label_width text-right">{{ this.appointment.modulation_time }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6" v-if="current_operation.format_type == 'Entrada'">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.foreign_trade.invoice')}}</label>
                                        <label class="label_width text-right">{{ this.appointment.invoice }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6" v-if="current_operation.format_type == 'Entrada'">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.foreign_trade.consignment')}}</label>
                                        <label class="label_width text-right">{{ this.appointment.consignment }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6" v-if="current_operation.format_type == 'Entrada'">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.foreign_trade.invoice')}}</label>
                                        <label class="label_width text-right">{{ this.appointment.invoice }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6" v-if="current_operation.format_type == 'Entrada'">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.foreign_trade.petition')}}</label>
                                        <label class="label_width text-right">{{ this.appointment.petition }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6" v-if="current_operation.format_type == 'Entrada'">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.foreign_trade.notices')}}</label>
                                        <label class="label_width text-right">{{ this.appointment.notices }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                                <v-col cols="8" class="py-0 px-6" v-if="current_operation.format_type == 'Entrada'">
                                    <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                        <label class="label_width" style="font-weight: bold;">{{ $t('edit_appointment.foreign_trade.entry_petition')}}</label>
                                        <label class="label_width text-right">{{ this.appointment.entry_petition }} </label>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>

                        <!-- FORM FOR INCIDENCES -->
                        <v-form ref="incidences_form" lazy-validation @submit.prevent="save" v-if="(from == 'international' && panel_active == 4 && current_operation.format_type == 'Entrada') || (from == 'national' && panel_active == 3 && current_operation.format_type == 'Entrada')">
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" align="center" justify="center">        
                                    <h2 class="text-uppercase azul--text">{{ $t('edit_appointment.incidences.incidences')}}</h2>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center" class="px-5">
                                <v-col class="py-0 px-3" cols="8" align="center" justify="center">
                                    <datatable :props="{headers:headers_incidence, items: incidences}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-form>

                        <!--FORM FOR BINNACLE -->
                        <v-form ref="binnacle_form" lazy-validation @submit.prevent="save" v-if="panel_active == 5 || (from == 'national' && panel_active == 4)">
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" align="center" justify="center">        
                                    <h2 class="text-uppercase azul--text">{{ $t('edit_appointment.binnacle.binnacle')}}</h2>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-col class="py-0" cols="10" align="center" justify="center">
                                    <datatable :props="{headers:headers_status, items: a_status.items, search:a_status.search}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>
                </div>
            </v-card>
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        client: function(){
            return this.$store.state.clientId
        },
        rules_required(){return [v => !!v || this.$t('edit_appointment.validations.required_field')]}, 
        rules_petition(){
            return [
                v => v == null || /^(|\d)+$/.test(v) || this.$t('edit_appointment.validations.numeric'),
                v => v == null || v.toString().length == 0 || v.toString().length == 15 || this.$t('edit_appointment.validations.lenght'),
            ];
        },
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.transport_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
        headers_status: function(){
            return [
                { text: this.$t('edit_appointment.binnacle.status'), value: 'status', class: 'text-uppercase' },
                { text: this.$t('edit_appointment.binnacle.user'), value: 'user.name', class: 'text-uppercase' },
                { text: this.$t('edit_appointment.binnacle.start'), value: 'reg_date', class: 'text-uppercase' },
                { text: this.$t('edit_appointment.binnacle.end'), value: 'end_date', class: 'text-uppercase' },
                { text: this.$t('edit_appointment.binnacle.minutes'), value: 'minutes', class: 'text-uppercase' },
                { text: this.$t('edit_appointment.binnacle.portal'), value: 'portal_name', class: 'text-uppercase' },
            ];
        },
        headers_sku: function(){
            return [
                {text: this.$t('edit_appointment.product_details.sku_t'), value: 'sku_p', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.product_details.quantity'), value: 'quantity', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.product_details.location'), value: 'location', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.product_details.pallet'), value: 'pallet', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.product_details.box'), value: 'box', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.product_details.batch_t'), value: 'batch', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.product_details.expiration'), value: 'expiration', class: 'text-uppercase'},
            ];
        },
        headers_incidence: function(){
            return [
                {text: this.$t('edit_appointment.incidences.type'), value: 'i_type', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.incidences.quantity'), value: 'quantity', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.incidences.description'), value: 'description', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.incidences.actions'), value: 'actions', class: 'text-uppercase', align: 'center', sortable: false}
            ];
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            from: this.$route.params.from,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            checked: [
                {
                    name: this.$t('edit_appointment.product_details.checked'),
                    icon: {
                        color: 'success2',
                        icon: 'check_circle'
                    },
                    link: null,
                }
            ],
            unchecked: [
                {
                    name: this.$t('edit_appointment.product_details.unchecked'),
                    icon: {
                        color: 'warning',
                        icon: 'error'
                    },
                    link: null,
                }
            ],
            actions: [
                {
                    name: this.$t('edit_appointment.product_details.delete_sku'),
                    icon: {
                        color: 'error',
                        icon: 'disabled_by_default'
                    },
                    method: 'deleteSku',
                }
            ],
            incidence_actions: [
                {
                    name: this.$t('edit_appointment.incidences.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    link: '/incidence_detail',
                    permission: "a_incidence.show"
                },
            ],
            buttons_international: [
                { title: this.$t("edit_appointment.left_buttons.general_data"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.transport_details"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.product_details"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.foreign_trade"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.incidences"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.binnacle"), icon: '', link: ''},
            ],
            buttons_national: [
                { title: this.$t("edit_appointment.left_buttons.general_data"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.transport_details"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.product_details"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.incidences"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.binnacle"), icon: '', link: ''},
            ],
            panel_active: 0,
            arrival_date_modal: false,
            eta_date_modal: false, 
            fumigation_date_modal: false,
            modulation_date_modal: false,
            modulation_time_modal: false,
            appointment:{
                // General data
                client_id: "",
                arrival_date: "",
                eta: "",
                operation_id: "",
                container_number: "",
                description: "",
                commentary: "",
                additional_services: "",
                purchase_order: "",
                gate: "",

                // Transport details
                carrier_line: "",
                drivers_name: "",
                license_plate: "",
                box: "",
                tractor: "",
                consignment_note: "",
                cargo_type: "",
                transport_type: "",
                packing_type: "",
                unit_good_conditions: 1,
                unit_damage_specifications: "",
                seal_good_conditions: 1,
                
                // Foreign trade
                origin_supplier_id: "",
                patent_number: "",
                modulation_status: "",
                modulation_date: "",
                modulation_time: "",
                bill_of_lading: "",
                remittance: "",
                petition: "",
                notices: "",
                entry_petition: "",
                merchandise_good_conditions: 1,
                packaging_complies_conditions: 1,

                products: []
            },
            movements: [],
            embalajes: [
                "Cajas de madera",
                "Cajas de plastico",
                "Cajas de carton",
                "Tambores",
                "Tote",
                "Sacos",
                "Emplaye", 
                "Mercancia suelta"
            ],
            product: {
                product_id: "",
                quantity: "",
                location_id: "",
                pallet: "",
                box: "",
                batch: "",
                expiration: ""
            },
            // items: [],
            items_g: [],
            is_complete: false,
            racks: [],
            a_status: {
                items:[],
                search: ''
            },
            origin_providers: [],
            patents: [],
            modulation_statuses: [],
            types_of_transport: [
                "Camioneta 3 1/2",
                "Rabón",
                "Torthon",
                "Contenedor 20'",
                "Contenedor 40'",
                "Caja Seca 40'",
                "Caja Seca 48'",
                "Caja Seca 53'",
                "Otros"
            ],
            types_of_cargo: [
                "Montacarga",
                "Manual",
                "Ninguna"
            ],
            confirmLeave: false,
            incidences: [],
            status_definitions: [],
            current_operation: '',
            initialAppointment: "" //var to verify if appointment has unsaved changes
        }
    },
    mounted(){
        this.$store.state.module = "headers.detail_appointment";
        this.index();
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        getClients(){
            this.$http.get(this.$store.state.chronosApi + 'getAllClients' + '/' + this.$store.state.warehouseId, { headers: this.apiCredentials })
            .then((response) => {
                if(response.status === 200){
                    this.clients = response.data;
                }
            })
            .catch((error) => {
                error
            })
            .finally(() => {
                this.$store.state.loaderProps.visible = false
            })
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.$http.get(this.$store.state.chronosApi + 'getIncidences/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.incidences = [];
                response.data.forEach(x => {
                    if(x.i_type == "less") x.i_type = this.$t("edit_appointment.incidences.less");
                    else if(x.i_type == "more") x.i_type = this.$t("edit_appointment.incidences.more");
                    else if(x.i_type == "damaged") x.i_type = this.$t("edit_appointment.incidences.damaged");
                    x.actions = JSON.parse(JSON.stringify(this.incidence_actions));
                    x['actions'][0].link = '/incidence_detail/' + x.id;
                    this.incidences.push(x);
                });
            });

            this.$http.get(this.$store.state.chronosApi + 'appointments/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data = response.data.data.attributes;
                    // products and Details
                    response.data.detail_orders.forEach(x => {
                        x = x.attributes;
                        var p = {
                            sku_p: x.product.sku,
                            product_id: x.product.id,
                            quantity: x.real ?? x.expected,
                            location: x.location != null? x.location.name: "---",
                            pallet: x.pallet,
                            box: x.box,
                            batch: x.batch,
                            expiration: x.expiration,
                            actions: "",
                            parameters: ""
                        }            
                        this.appointment.products.push(p);
                    });

                    // General Data
                    this.$store.state.clientId                      = response.data.client.id;
                    this.appointment.client_id                      = response.data.client.name ?? null;
                    this.appointment.arrival_date                   = response.data.arrival_date.substring(0, 10) ?? null;
                    if(this.from == 'international' && response.data.eta != undefined && response.data.eta != null)
                        this.appointment.eta                        = response.data.eta.substring(0, 10);
                    this.appointment.operation_id                   = this.$store.state.language == "es" ? response.data.operation.description : response.data.operation.description1
                    this.appointment.additional_services            = response.data.additional_services ?? null;
                    this.appointment.purchase_order                 = response.data.purchase_order ?? null;
                    this.appointment.container_number               = response.data.container_number ?? null;
                    this.appointment.description                    = response.data.description ?? null;
                    this.appointment.commentary                     = response.data.commentary ?? null;
                    this.current_operation                          = response.data.operation;
 
                    // Transport Details
                    this.appointment.carrier_line                   = response.data.carrier_line ?? null;
                    this.appointment.drivers_name                   = response.data.drivers_name ?? null;
                    this.appointment.license_plate                  = response.data.license_plate ?? null;
                    this.appointment.tractor                        = response.data.tractor ?? null;
                    this.appointment.consignment_note               = response.data.consignment_note ?? null;
                    this.appointment.cargo_type                     = response.data.cargo_type ?? null;
                    this.appointment.transport_type                 = response.data.transport_type ?? null;
                    this.appointment.unit_good_conditions           = response.data.unit_good_conditions ?? 1;
                    this.appointment.unit_damage_specifications     = response.data.unit_damage_specifications ?? null;
                    this.appointment.seal_good_conditions           = response.data.seal_good_conditions ?? 1;
                    this.appointment.merchandise_good_conditions    = response.data.merchandise_good_conditions ?? 1;
                    this.appointment.packaging_complies_conditions  = response.data.packaging_complies_conditions ?? 1;
                    this.appointment.packing_type                   = response.data.packing_type ?? null;
                    this.appointment.seal_number                    = response.data.seal_number ?? "";

                    var a_status = response.data.appointment_status;
                    this.$http.get(this.$store.state.chronosApi + 'a_status_definitions', {headers: {Authorization: lsToken}})
                    .then((response) => {
                        if(response.status === 200){
                            this.status_definitions = response.data;

                            a_status.forEach(x => {
                                x = x.attributes
                                // console.log(x)
                                if(this.status_definitions.length > 0){
                                    if(this.$store.state.language == 'es') x.status = this.status_definitions.find(item => item.status == x.status).description;
                                    if(this.$store.state.language == 'en') x.status = this.status_definitions.find(item => item.status == x.status).description1;
                                    if(x.portal_name == undefined || x.portal_name == null){
                                        x.portal_name = "---";
                                    } 

                                    if(x.end_date == null){
                                        x.end_date = '---';
                                        x.minutes = 0;
                                    }

                                    else x.minutes = this.diffTime(x.reg_date, x.end_date);
                                    
                                    this.a_status.items.push(x);
                                }
                            });
                        }
                    });

                    // Foreign Trade
                    if(this.from == 'international'){
                        if(response.data.operation.format_type == 'Entrada' && response.data.origin_supplier) this.appointment.origin_supplier_id     = response.data.origin_supplier.id ?? null;
                        this.appointment.patent_number          = response.data.patent_number ?? null;    
                        this.appointment.modulation_status      = response.data.modulation_status ?? null;
                        this.appointment.modulation_date        = response.data.modulation_date ?? null;
                        if(response.data.modulation_date != null)    
                            this.appointment.modulation_time    = response.data.modulation_time.split("T")[1].substring(0,5);
                        this.appointment.bill_of_lading         = response.data.bill_of_lading ?? null;
                        this.appointment.remittance             = response.data.remittance ?? null;
                        this.appointment.petition               = response.data.petition ?? null;
                        this.appointment.notices                = response.data.notices ?? null;
                        this.appointment.entry_petition         = response.data.entry_petition ?? null;
                    }
                }

                this.$http.get(this.$store.state.chronosApi + 'getLocations/' + response.data.operation.id + '/' + response.data.client.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200){
                        response.data.forEach(x =>{
                            this.racks.push({
                                id: x.id,
                                name: x.name
                            });
                        });
                    }
                });       
            })
            .catch((response) => {              
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('create_appointment.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        
        cancel(){
            this.$router.go(-1);
        },
        uppercase() {
            this.product.lote = this.product.lote.toUpperCase();
            this.product.lote = this.product.lote.replace(/\s/g,'');
            this.product.caducidad = this.product.caducidad.toUpperCase();
            this.product.caducidad = this.product.caducidad.replace(/\s/g,'');
        },
        diffTime(date1, date2){
            const total = parseInt((new Date(date2).getTime() - new Date(date1).getTime()) / 60000);
            if(total == 0) return 1;
            else return total;
        },
    },
    watch:{
        'clients': function() {
            this.index();
        }
    }
}
</script>

<style lang="scss">
</style>