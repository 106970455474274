<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5" justify="center" align="center">
                    <v-col cols="6" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('taxpayer.taxpayer') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('taxpayer.name')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.taxpayer.name }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('taxpayer.business_name')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.taxpayer.businessName }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('taxpayer.rfc')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.taxpayer.rfc }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <!-- <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('taxpayer.rfe')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.taxpayer.rfe }} </label>
                                </v-row>
                            </v-col>
                        </v-row> -->
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('taxpayer.immex')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.taxpayer.immex }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('taxpayer.state')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.taxpayer.state }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('taxpayer.neighborhood')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.taxpayer.neighborhood }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('taxpayer.street')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.taxpayer.street }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('taxpayer.zip_code')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.taxpayer.postalCode }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('taxpayer.external_number')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.taxpayer.externalNumber }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            taxpayer: {
                name: "Operadora Chronos",
                businessName: "Operadora Chronos S.A. de C.V.",
                rfc: "OCR180212JB5",
                rfe: "0013",
                immex: "",
                state: "San Luis Potosí",
                neighborhood: "Logistik II",
                street: "Dubai",
                postalCode: "79526",
                externalNumber: "112"
            },
            warehouses: []
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.taxpayer_data";
    },
    methods: {
        index(){
            // this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'customs/catalogs/taxpayer-data', {headers: {Authorization: lsToken}})
            .then((response) => {
                // this.$store.state.loaderProps.visible = false;
                this.taxpayer = response.data.data;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
            });
        }
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>