<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-5" >
                <v-col cols="1" class="py-0 my-0">
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" @click="cancel()">
                        <v-icon small class="px-0">
                            keyboard_arrow_left
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="5" class="py-0 my-0">
                    <h3>{{ $t('delivered.details_message') }} {{ this.date }} </h3>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" @click="show_export_options = true" v-if="show_export_options == false">
                        {{ $t('stock_report.export') }}
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataPdf">
                        PDF
                    </v-btn>
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataExcel">
                        Excel
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="show_export_options = false;">
                        <v-icon small class="px-0">
                            keyboard_arrow_left
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="px-0">
                    <v-col class="py-0">
                        <v-row class="azul font-weight-bold text-uppercase white--text px-2 py-0">
                            <v-col cols="2" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('delivered.shipments_in_period') }}:</span> {{ summary.shipments }}</v-col>
                            <v-col cols="3" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('delivered.cod_sent') }}:</span> {{ summary.cod }}</v-col>
                            <v-col cols="2" class="py-1"><span class="font-weight-bold text-u1ppercase">{{ $t('delivered.deliveries_in_period') }}:</span> {{ summary.deliveries }}</v-col>
                            <v-col cols="3" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('delivered.cod_delivered') }}:</span> {{ summary.cod_deliveries }}</v-col>
                            <v-col cols="2" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('delivered.delivered') }}:</span> {{ summary.delivered }}</v-col>           
                        </v-row>
                    </v-col>
                </v-card-text>

                <v-card-text class="pt-0 pb-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers: headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('delivered.order'), align: "center", value: 'unique_order_number', class: 'text-uppercase' },
                { text: this.$t('delivered.tracking_number'), align: "center", value: 'shipment_number', class: 'text-uppercase' },
                { text: this.$t('delivered.parcel'), align: "center", value: 'shipping_service', class: 'text-uppercase' },
                { text: this.$t('delivered.total'), align: "center", value: 'total', class: 'text-uppercase' },
                { text: this.$t('delivered.delivered_date'), align: "center", value: 'delivered_date', class: 'text-uppercase' },
                { text: this.$t('delivered.paid'), align: "center", value: 'is_paid', class: 'text-uppercase' },
                { text: this.$t('delivered.paid_at'), align: "center", value: 'paid_at', class: 'text-uppercase' },
            ];
        },
    },
    data(){
        return {
            numberFormat: new Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            date: this.$route.params.date,
            client: this.$route.params.client,
            summary: {
                shipments: 0,
                cod: 0,
                deliveries: 0,
                cod_deliveries: 0,
                delivered: 0,
            },
            show_export_options: false
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.delivered";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.generate();
        },
        generate(){
            this.props.items = [];
            this.summary = {
                shipments: 0,
                cod: 0,
                deliveries: 0,
                cod_deliveries: 0,
                delivered: 0,
            };

            if(this.client != "" && this.data != ""){
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + 'trackNTrace/delivered_detail?client_id='+ this.client + '&date='+ this.date, {headers: {Authorization: lsToken}})
                .then((response) => {
                    this.props.items = Object.keys(response.data.data).map((key) => response.data.data[key]);
                    this.props.items.forEach(x => {
                        x.total = "$" + this.numberFormat.format(x.total);
                        x.is_paid = this.$t('delivered.' + x.is_paid);
                    });

                    this.summary = response.data.summary;
                    this.summary.cod = "$" + this.numberFormat.format(this.summary.cod);
                    this.summary.cod_deliveries = "$" + this.numberFormat.format(this.summary.cod_deliveries);
                })
                .catch((response) => {
                    this.$store.state.loaderProps.visible = false;
                    console.log(response)
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('pendings.responses.error')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportDataExcel(){
            const lsToken = localStorage.getItem("access_token");
            if(this.clientId != "" && this.date != ""){
                this.$store.state.loaderProps.visible = true;
                this.$http.get(this.$store.state.chronosApi + 'trackNTrace/delivered_detail_export?client_id=' + this.client + '&date='+ this.date, {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'DeliveredDetail.xlsx');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportDataPdf(){
            const lsToken = localStorage.getItem("access_token");
            if(this.$store.state.warehouseId != "" && this.$store.state.clientId != "" && this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;

                this.$http.get(this.$store.state.chronosApi + 'reports/operationsByClientPdf?warehouse='+ this.$store.state.warehouseId + '&client='+ this.$store.state.clientId + '&startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'Operaciones_Por_Cliente.pdf');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch: {
        '$store.state.clientId': function(){
            this.props.items = [];
        }
    }
}
</script>

<style>
</style>