<template>
    <div>
        <v-card flat>
            <v-card-text class="px-0 py-0">
                <!-- First 4 charts -->
                <v-row class="my-0 py-0" align="center">
                    <v-col class="my-0 py-0">
                        <v-col class="mx-0 px-0">
                            <v-card-title class="justify-space-between py-2 px-7 ml-7">
                                <v-row>
                                    <span class="text-uppercase subtitle-1 font-weight-bold" style="font-size:15px !important">{{ $t('dashboard.appointments_by_client') }}</span>    
                                    <v-btn x-small class="mx-3" @click="back('appointments_by_client')"><v-icon x-small>arrow_back_ios</v-icon></v-btn>
                                    <v-btn x-small @click="next('appointments_by_client')"><v-icon x-small>arrow_forward_ios</v-icon></v-btn>
                                </v-row>
                            </v-card-title>
                            <v-card-text class="py-0">
                                <bar-stacked-chart :chart-data="appointmentsByClient" :options="yearChartOptions" :height="300"></bar-stacked-chart>
                            </v-card-text>
                        </v-col>
                        <v-col class="px-0 mx-0">
                            <v-card-title class="justify-space-between py-2 px-7 ml-7">
                                <v-row>
                                    <span class="text-uppercase subtitle-1 font-weight-bold" style="font-size:15px !important">{{ $t('dashboard.revenue_by_client') }}</span>
                                    <v-btn x-small class="mx-3" @click="back('revenue_by_client')"><v-icon x-small>arrow_back_ios</v-icon></v-btn>
                                    <v-btn x-small @click="next('revenue_by_client')"><v-icon x-small>arrow_forward_ios</v-icon></v-btn>
                                </v-row>
                            </v-card-title>
                            <v-card-text class="py-0">
                                <bar-stacked-chart :chart-data="revenueByClient" :options="yearChartOptions" :height="300"></bar-stacked-chart>
                            </v-card-text>    
                        </v-col>
                    </v-col>
                    <v-col class="my-0 py-0">
                        <v-col class="px-0 mx-0 my-0 py-0" >
                            <v-col class="px-0 mx-0 my-0 py-0">
                                <v-card-title class="justify-space-between pb-2 pt-0 px-0 ml-11">
                                    <v-col class="py-0 my-0">
                                        <span class="text-uppercase subtitle-1 font-weight-bold">{{ $t('dashboard.total_appointments') }}</span>
                                    </v-col>
                                </v-card-title>
                                <v-card-text class="py-0 ml-11">
                                    <v-row align="center" justify="center">                                
                                        <doughnut-chart :height="220" style="width: 90% !important; margin-left: -100px !important; margin-bottom: 25px !important;" :chart-data="totalAppointments" :options="totalAppointmentsOptions"></doughnut-chart>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-col>
                        <v-col class="my-0 py-0">
                            <v-col class="py-0 my-0">
                                <v-card-title class="justify-space-between py-2 px-7 ml-7">
                                    <v-row>
                                        <span class="text-uppercase subtitle-1 font-weight-bold" style="font-size:15px !important">{{ $t('dashboard.expenses_by_client') }}</span>
                                        <v-btn x-small class="mx-3" @click="back('expenses_by_client')"><v-icon x-small>arrow_back_ios</v-icon></v-btn>
                                        <v-btn x-small @click="next('expenses_by_client')"><v-icon x-small>arrow_forward_ios</v-icon></v-btn>
                                    </v-row>
                                </v-card-title>
                            </v-col>
                            <v-card-text class="py-0 px-0">
                                <bar-stacked-chart class="mx-0" style="width:550px" :chart-data="expensesByClient" :options="yearChartOptions" :height="290"></bar-stacked-chart>
                            </v-card-text>
                        </v-col>
                    </v-col>
                </v-row>

                <!-- Total Movements -->
                <v-row class="my-0 py-0" align="center">
                    <v-col class="px-0 mx-0">
                        <v-col class="mx-0 px-0">
                            <v-card-title class="justify-space-between py-2 px-7 ml-7">
                                <v-row>
                                    <span class="text-uppercase subtitle-1 font-weight-bold" style="font-size:15px !important">{{ $t('dashboard.revenue_by_client_t') }}</span>    
                                    <v-btn x-small class="mx-3" @click="back('total_revenue_by_client')"><v-icon x-small>arrow_back_ios</v-icon></v-btn>
                                    <v-btn x-small @click="next('total_revenue_by_client')"><v-icon x-small>arrow_forward_ios</v-icon></v-btn>
                                </v-row>
                            </v-card-title>
                            <v-card-text class="py-0">
                                <bar-stacked-chart :chart-data="totalRevenueByClient" :options="yearChartOptions" :height="300"></bar-stacked-chart>
                            </v-card-text>
                        </v-col>
                    </v-col>
                    <v-col>
                        <v-col class="mx-0 px-0">
                            <v-card-title class="justify-space-between py-2 px-7 ml-7">
                                <v-row>
                                    <span class="text-uppercase subtitle-1 font-weight-bold" style="font-size:15px !important">{{ $t('dashboard.expenses_by_client_t') }}</span>    
                                    <v-btn x-small class="mx-3" @click="back('total_expenses_by_client')"><v-icon x-small>arrow_back_ios</v-icon></v-btn>
                                    <v-btn x-small @click="next('total_expenses_by_client')"><v-icon x-small>arrow_forward_ios</v-icon></v-btn>
                                </v-row>
                            </v-card-title>
                            <v-card-text class="py-0">
                                <bar-stacked-chart :chart-data="totalExpensesByClient" :options="yearChartOptions" :height="300"></bar-stacked-chart>
                            </v-card-text>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>              
    </div>
</template>

<script>

import DoughnutChart from './Charts/Doughnut.js'
import BarStackedChart from './Charts/BarStacked.js'

export default {
    props: [
        'initial_date',
        'final_date',
        'totalAppointmentsOptions',
        'yearChartOptions',
        'colors',
        'load'
    ],
    components: {
		DoughnutChart,
		BarStackedChart,
    },
    data(){
        return {
            appointmentsByClient: {},
			revenueByClient: {},
			expensesByClient: {},
			totalAppointments: {},
            totalRevenueByClient: {},
            totalExpensesByClient: {},

            // Top left chart variables
            appointments_by_client: [],
            current_appointments_by_client: 0,
            limit_appointments_by_client: 1,

            // Middle left chart variables
            revenue_by_client: [],
            current_revenue_by_client: 0,
            limit_revenue_by_client: 1,

            // Middle right chart variables
            expenses_by_client: [],
            current_expenses_by_client: 0,
            limit_expenses_by_client: 1,

            // Bottom left chart variables
            total_revenue_by_client: [],
            current_total_revenue_by_client: 0,
            limit_total_revenue_by_client: 1,

            // Bottom right chart variables
            total_expenses_by_client: [],
            current_total_expenses_by_client: 0,
            limit_total_expenses_by_client: 1,

            change_pending: false
        }
    },
    mounted(){
    },
    methods: {
        async index(){
            if(!this.change_pending){

                this.change_pending = true
                this.$nextTick(async () => {
                    this.$store.state.loaderProps.visible = true;

                    await this.appointmentsByClientFunction();
                    await this.revenueByClientFunction();
                    await this.totalAppointmentsFunction();
                    await this.expensesByClientFunction();

                    this.$store.state.loaderProps.visible = false;

                    this.change_pending = false;
                });
            }
        },
        async appointmentsByClientFunction(){
			const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi + 'appointmentsbyclient/' + this.initial_date + '/' + this.final_date, { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    this.appointments_by_client = response.data;
                    this.limit_appointments_by_client = Math.ceil(response.data.length / 10);
                    response.data = response.data.slice(0, 10);
                    
                    const labels = [
						{ name: this.$t('dashboard.appointments'), color: this.colors[4] },
					]
                    
                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: response.data.map(y =>{
                                return y.total
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.appointmentsByClient = {
                        labels: response.data.map(t => {
                            return t.nombre
                        }),
                        datasets: datasets
                    }
				}
			})
        },
        async revenueByClientFunction(){
			const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi+'revenuebyclient/' + this.initial_date + '/' + this.final_date, { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    //definition chart 3
                    this.revenue_by_client = response.data;
                    this.total_revenue_by_client = response.data;

                    this.limit_revenue_by_client = Math.ceil(response.data.length / 10);
                    this.limit_total_revenue_by_client = Math.ceil(response.data.length / 10);

                    response.data = response.data.slice(0, 10);

                    const labels = [
						{ name: this.$t('dashboard.complete'), color: this.colors[2] },
						{ name: this.$t('dashboard.in_proces'), color: this.colors[3] },
						{ name: this.$t('dashboard.pendings'), color: this.colors[0] },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: response.data.map(y =>{
                                if(r.name == this.$t('dashboard.complete')) return y.completas
                                if(r.name == this.$t('dashboard.in_proces')) return y.pendientes
                                if(r.name == this.$t('dashboard.pendings')) return y.proceso
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })

                    this.revenueByClient = {
                        labels: response.data.map(t => {
                            return t.nombre
                        }),
                        datasets: datasets
                    }

                    // definition chart 5

                    const labels2 = [
                        {name: this.$t('dashboard.appointments'), color: this.colors[4]},
                    ]

                    const datasets2 = labels2.map(r =>{
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: response.data.map(y =>{
                                return y.total
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)'
                        }
                    });

                    this.totalRevenueByClient = {
                        labels: response.data.map(t => {
                            return t.nombre
                        }),
                        datasets: datasets2
                    }
				}
			})
        },
        async totalAppointmentsFunction(){
			const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi+'totalappointments/' + this.initial_date + '/' + this.final_date, { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    const labels = [
						{ name: 'Entradas Nacionales', color: this.colors[0], value: 'entradasNacionales' },
						{ name: 'Salidas Nacionales', color: this.colors[1], value: 'salidaNacional' },
						{ name: 'Entradas Internacionales', color: this.colors[2], value: 'entradaInternacional' },
						{ name: 'Salidas Internacionales', color: this.colors[3], value: 'salidaInternacional' },
						{ name: 'Crossdock Traspaleo Entrada', color: this.colors[4], value: 'traspaleoEntrada' },
						{ name: 'Crossdock Traspaleo Salida', color: this.colors[5], value: 'traspaleoSalida' },
						{ name: 'Crossdock Documental Entrada', color: this.colors[6], value: 'documentalEntradas' },
						{ name: 'Crossdock Documental Salida', color: this.colors[7], value: 'documentalSalida' },
					]

                    const datasets = [{
						backgroundColor: labels.map(x => {
							return x.color
						}),
						data: labels.map(x => {
							return response.data[0][x.value];
						}),
						borderColor: 'rgba(0, 0, 0, 0)',
					}]

                    this.totalAppointments = {
                        labels: labels.map(x => {
							return x.name + ' ( ' + response.data[0][x.value] + ' )';
						}),
						datasets: datasets
                    }
				}
			})
        },
        async expensesByClientFunction(){
			const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi+'expensesbyclient/' + this.initial_date + '/' + this.final_date, { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){        
                    this.expenses_by_client = response.data;
                    this.total_expenses_by_client = response.data;

                    this.limit_expenses_by_client = Math.ceil(response.data.length / 10);
                    this.limit_total_expenses_by_client = Math.ceil(response.data.length / 10);

                    response.data = response.data.slice(0, 10);

                    // definition chart 2
                    const labels = [
						{ name: this.$t('dashboard.complete'), color: this.colors[2] },
						{ name: this.$t('dashboard.in_proces'), color: this.colors[3] },
						{ name: this.$t('dashboard.pendings'), color: this.colors[0] },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: response.data.map(y =>{
                                if(r.name == this.$t('dashboard.complete')) return y.completas
                                if(r.name == this.$t('dashboard.in_proces')) return y.pendientes
                                if(r.name == this.$t('dashboard.pendings')) return y.proceso
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })

                    this.expensesByClient = {
                        labels: response.data.map(t => {
                            return t.nombre
                        }),
                        datasets: datasets
                    }

                    // definition chart 4
                    const labels2 = [
                        {name: this.$t('dashboard.appointments'), color: this.colors[4]},
                    ]

                    const datasets2 = labels2.map(r =>{
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: response.data.map(a =>{
                                return a.total
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)'
                        }
                    });

                    this.totalExpensesByClient = {
                        labels: response.data.map(g => {
                            return g.nombre
                        }),
                        datasets: datasets2
                    }
				}
			})
        },
        back(chart){
            if(chart == 'appointments_by_client'){
                if(this.current_appointments_by_client > 0){
                    const array = this.appointments_by_client.slice(((this.current_appointments_by_client - 1) * 10), (this.current_appointments_by_client * 10));
                    this.current_appointments_by_client -= 1;
                    const labels = [
                        { name: this.$t('dashboard.appointments'), color: this.colors[4] },
                    ]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: array.map(y =>{
                                return y.total
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.appointmentsByClient = {
                        labels: array.map(t => {
                            return t.nombre
                        }),
                        datasets: datasets
                    }
                }
            }
            else if(chart == 'revenue_by_client'){
                if(this.current_revenue_by_client > 0){
                    const array = this.revenue_by_client.slice(((this.current_revenue_by_client - 1) * 10), (this.current_revenue_by_client * 10));
                    this.current_revenue_by_client -= 1;
                    const labels = [
						{ name: this.$t('dashboard.complete'), color: this.colors[2] },
						{ name: this.$t('dashboard.in_proces'), color: this.colors[3] },
						{ name: this.$t('dashboard.pendings'), color: this.colors[0] },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: array.map(y =>{
                                if(r.name == this.$t('dashboard.complete')) return y.completas
                                if(r.name == this.$t('dashboard.in_proces')) return y.pendientes
                                if(r.name == this.$t('dashboard.pendings')) return y.proceso
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.revenueByClient = {
                        labels: array.map(t => {
                            return t.nombre
                        }),
                        datasets: datasets
                    }
                }
            }
            else if(chart == 'expenses_by_client'){
                if(this.current_expenses_by_client > 0){
                    const array = this.expenses_by_client.slice(((this.current_expenses_by_client - 1) * 10), (this.current_expenses_by_client * 10));
                    this.current_expenses_by_client -= 1;
                    const labels = [
						{ name: this.$t('dashboard.complete'), color: this.colors[2] },
						{ name: this.$t('dashboard.in_proces'), color: this.colors[3] },
						{ name: this.$t('dashboard.pendings'), color: this.colors[0] },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: array.map(y =>{
                                if(r.name == this.$t('dashboard.complete')) return y.completas
                                if(r.name == this.$t('dashboard.in_proces')) return y.pendientes
                                if(r.name == this.$t('dashboard.pendings')) return y.proceso
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.expensesByClient = {
                        labels: array.map(t => {
                            return t.nombre
                        }),
                        datasets: datasets
                    }
                }
            }
            else if(chart == 'total_revenue_by_client'){
                if(this.current_total_revenue_by_client > 0){
                    const array = this.total_revenue_by_client.slice(((this.current_total_revenue_by_client - 1) * 10), (this.current_total_revenue_by_client * 10));
                    this.current_total_revenue_by_client -= 1;
                    const labels = [
						{ name: this.$t('dashboard.appointments'), color: this.colors[4] },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: array.map(y =>{
                                return y.total
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.totalRevenueByClient = {
                        labels: array.map(t => {
                            return t.nombre
                        }),
                        datasets: datasets
                    }
                }
            }
            else if(chart == 'total_expenses_by_client'){
                if(this.current_total_expenses_by_client > 0){
                    const array = this.total_expenses_by_client.slice(((this.current_total_expenses_by_client - 1) * 10), (this.current_total_expenses_by_client * 10));
                    this.current_total_expenses_by_client -= 1;
                    const labels = [
						{ name: this.$t('dashboard.appointments'), color: this.colors[4] },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: array.map(y =>{
                                return y.total
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.totalExpensesByClient = {
                        labels: array.map(t => {
                            return t.nombre
                        }),
                        datasets: datasets
                    }
                }
            }
        },
        next(chart){
            if(chart == 'appointments_by_client'){
                if(this.current_appointments_by_client < this.limit_appointments_by_client -1){
                    this.current_appointments_by_client += 1;
                    const array = this.appointments_by_client.slice((this.current_appointments_by_client * 10), ((this.current_appointments_by_client + 1) * 10));
                    const labels = [
                        { name: this.$t('dashboard.appointments'), color: this.colors[4] },
                    ]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: array.map(y =>{
                                return y.total
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.appointmentsByClient = {
                        labels: array.map(t => {
                            return t.nombre
                        }),
                        datasets: datasets
                    }
                }
            }
            else if(chart == 'revenue_by_client'){
                if(this.current_revenue_by_client < this.limit_revenue_by_client -1){
                    this.current_revenue_by_client += 1;
                    const array = this.revenue_by_client.slice((this.current_revenue_by_client * 10), ((this.current_revenue_by_client + 1) * 10));
                    const labels = [
						{ name: this.$t('dashboard.complete'), color: this.colors[2] },
						{ name: this.$t('dashboard.in_proces'), color: this.colors[3] },
						{ name: this.$t('dashboard.pendings'), color: this.colors[0] },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: array.map(y =>{
                                if(r.name == this.$t('dashboard.complete')) return y.completas
                                if(r.name == this.$t('dashboard.in_proces')) return y.pendientes
                                if(r.name == this.$t('dashboard.pendings')) return y.proceso
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.revenueByClient = {
                        labels: array.map(t => {
                            return t.nombre
                        }),
                        datasets: datasets
                    }
                }
            }
            else if(chart == 'expenses_by_client'){
                if(this.current_expenses_by_client < this.limit_expenses_by_client - 1){
                    this.current_expenses_by_client += 1;
                    const array = this.expenses_by_client.slice((this.current_expenses_by_client * 10), ((this.current_expenses_by_client + 1) * 10));
                    const labels = [
						{ name: this.$t('dashboard.complete'), color: this.colors[2] },
						{ name: this.$t('dashboard.in_proces'), color: this.colors[3] },
						{ name: this.$t('dashboard.pendings'), color: this.colors[0] },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: array.map(y =>{
                                if(r.name == this.$t('dashboard.complete')) return y.completas
                                if(r.name == this.$t('dashboard.in_proces')) return y.pendientes
                                if(r.name == this.$t('dashboard.pendings')) return y.proceso
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.expensesByClient = {
                        labels: array.map(t => {
                            return t.nombre
                        }),
                        datasets: datasets
                    }
                }
            }
            else if(chart == 'total_revenue_by_client'){
                if(this.current_total_revenue_by_client < this.limit_total_revenue_by_client - 1){
                    this.current_total_revenue_by_client += 1;
                    const array = this.total_revenue_by_client.slice((this.current_total_revenue_by_client * 10), ((this.current_total_revenue_by_client + 1) * 10));
                    const labels = [
						{ name: this.$t('dashboard.appointments'), color: this.colors[4] },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: array.map(y =>{
                                return y.total
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.totalRevenueByClient = {
                        labels: array.map(t => {
                            return t.nombre
                        }),
                        datasets: datasets
                    }
                }
            }
            else if(chart == 'total_expenses_by_client'){
                if(this.current_total_expenses_by_client < this.limit_total_expenses_by_client - 1){
                    this.current_total_expenses_by_client += 1;
                    const array = this.total_expenses_by_client.slice((this.current_total_expenses_by_client * 10), ((this.current_total_expenses_by_client + 1) * 10));
                    const labels = [
						{ name: this.$t('dashboard.appointments'), color: this.colors[4] },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: array.map(y =>{
                                return y.total
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.totalExpensesByClient = {
                        labels: array.map(t => {
                            return t.nombre
                        }),
                        datasets: datasets
                    }
                }
            }
        }
    },
    watch: {
        'initial_date': function(){
            if(this.load == 0) this.index();
        },
        'final_date': function(){
            if(this.load == 0) this.index();
        },
        'load': function (){
            if(this.load == 0 && this.change_pending == false) this.index();
        }
    }
}
</script>

<style>

</style>