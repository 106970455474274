<template>
    <div class="px-0">
        <rfid-settings v-if="show_rfid" ref="showSettings" :items="rfid_items" ></rfid-settings>
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-0 size">
                <div class="main">
                    <v-btn-toggle v-model="panel_active" mandatory class="btn-toggle size"  v-if="from == 'international'">
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 0"><div class="vertical-line"></div></span>{{buttons_international[0].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 1"><div class="vertical-line"></div></span>{{buttons_international[1].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 2"><div class="vertical-line"></div></span>{{buttons_international[2].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 3"><div class="vertical-line"></div></span>{{buttons_international[3].title}}</v-btn>
                        <v-btn plain elevation="0" :disabled="current_operation.format_type == 'Salida'" active-class="button_active" class="button_nav"><span v-if="panel_active == 4"><div class="vertical-line"></div></span>{{buttons_international[4].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 5"><div class="vertical-line"></div></span>{{buttons_international[5].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 6"><div class="vertical-line"></div></span>{{buttons_international[6].title}}</v-btn>
                        <!-- <v-btn plain elevation="0" v-if="appointment.is_rfid == true" active-class="button_active" class="button_nav"><span v-if="panel_active == 7"><div class="vertical-line"></div></span>{{buttons_international[7].title}}</v-btn> -->
                        <v-btn plain elevation="0" v-if="is_rfid" active-class="button_active" class="button_nav" @click="print_tags"><span v-if="panel_active == 6"><div class="vertical-line"></div></span>{{buttons_international[7].title}}</v-btn>
                    </v-btn-toggle>
                    <v-btn-toggle v-model="panel_active" mandatory class="btn-toggle size"  v-if="from == 'national'">
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 0"><div class="vertical-line"></div></span>{{buttons_national[0].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 1"><div class="vertical-line"></div></span>{{buttons_national[1].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 2"><div class="vertical-line"></div></span>{{buttons_national[2].title}}</v-btn>
                        <v-btn plain elevation="0" :disabled="current_operation.format_type == 'Salida'" active-class="button_active" class="button_nav"><span v-if="panel_active == 3"><div class="vertical-line"></div></span>{{buttons_national[3].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 4"><div class="vertical-line"></div></span>{{buttons_national[4].title}}</v-btn>
                        <v-btn plain elevation="0" active-class="button_active" class="button_nav"><span v-if="panel_active == 5"><div class="vertical-line"></div></span>{{buttons_national[5].title}}</v-btn>
                        <!-- <v-btn plain elevation="0" v-if="appointment.is_rfid == true" active-class="button_active" class="button_nav"><span v-if="panel_active == 6"><div class="vertical-line"></div></span>{{buttons_national[6].title}}</v-btn> -->
                        <v-btn plain elevation="0" v-if="is_rfid" active-class="button_active" class="button_nav" @click="print_tags"><span v-if="panel_active == 5"><div class="vertical-line"></div></span>{{buttons_national[6].title}}</v-btn>
                    </v-btn-toggle>
                    <div class="separator"></div>
                    <div class="content-scroll">
                        
                        <!-- FORM FOR GENERAL DATA -->
                        <v-form ref="general_data_form" lazy-validation @submit.prevent="save" v-if="panel_active == 0">
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" align="center" justify="center">        
                                    <h2 class="text-uppercase azul--text">{{ $t('edit_appointment.general_data.general_data')}}</h2>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <client-selector class="py-0" ref="clientSelector" :disabled="appointment.products.length > 0" :is_national="from == 'national' ? 1 : 0" @getClients="v => items.clients = v" :rules="rules_required"></client-selector>  
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-menu ref="appointment_date" v-model="arrival_date_modal" :close-on-content-click="false" :return-value.sync="appointment.arrival_date" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field class="py-0" v-model="appointment.arrival_date" :label="$t('edit_appointment.general_data.date_of_arrival')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="appointment.arrival_date" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="arrival_date_modal = false">{{ $t('edit_appointment.cancel') }}</v-btn>
                                            <v-btn text color="primary" @click="$refs.appointment_date.save(appointment.arrival_date)">{{ $t('edit_appointment.accept') }}</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center" v-if="this.from == 'international'">
                                <v-col cols="8" class="py-0">
                                    <v-menu ref="eta_date" v-model="eta_date_modal" :close-on-content-click="false" :return-value.sync="appointment.eta" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field class="py-0" v-model="appointment.eta" :label="$t('edit_appointment.general_data.port_arrival_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="appointment.eta" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="eta_date_modal = false">{{ $t('edit_appointment.cancel') }}</v-btn>
                                            <v-btn text color="primary" @click="$refs.eta_date.save(appointment.eta)">{{ $t('edit_appointment.accept') }}</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-autocomplete class="py-0 mt-0" :disabled="appointment.products.length > 0" v-model="appointment.operation_id" :items="movements" :label="$t('edit_appointment.general_data.operation')" item-value="id" item-text="description" :rules="rules_required"></v-autocomplete> 
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.container_number" :label="$t('edit_appointment.general_data.container')"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.description" :label="$t('edit_appointment.general_data.description')"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.commentary" :label="$t('edit_appointment.general_data.comments')"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.additional_services" :label="$t('edit_appointment.general_data.aditional_services')"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.purchase_order" :label="$t('edit_appointment.general_data.purchase_order')"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                        
                        <!-- FORM FOR TRANSPORT DETAILS -->
                        <v-form ref="transport_details_form" lazy-validation @submit.prevent="save" v-if="panel_active == 1">
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" align="center" justify="center">        
                                    <h2 class="text-uppercase azul--text">{{ $t('edit_appointment.transport_details.transport_details')}}</h2>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.carrier_line" :label="$t('edit_appointment.transport_details.carrier_line')" :disabled="!can_edit"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.drivers_name" :label="$t('edit_appointment.transport_details.drivers_name')" :disabled="!can_edit"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.license_plate" :label="$t('edit_appointment.transport_details.plates')" :disabled="!can_edit"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.tractor" :label="$t('edit_appointment.transport_details.traktor')" :disabled="!can_edit"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.consignment_note" :label="$t('edit_appointment.transport_details.consignment_note')" :disabled="!can_edit"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.seal_number" :label="$t('edit_appointment.transport_details.seal_number')" :disabled="!can_edit"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-autocomplete class="py-0 mt-0" v-model="appointment.cargo_type" :items="types_of_cargo" :label="$t('edit_appointment.transport_details.type_of_cargo')" :disabled="!can_edit"></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-autocomplete class="py-0 mt-0" v-model="appointment.transport_type" :items="types_of_transport" :label="$t('edit_appointment.transport_details.type_of_transport')" :disabled="!can_edit"></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 pb-2" justify="center" align="center">
                                <v-col cols="6" class="pl-3 py-0" justify="left" align="left">
                                    <span >{{ $t('edit_appointment.transport_details.unit_good_conditions') }}:</span>
                                </v-col>
                                <v-col cols="2" class="px-0 py-0" justify="end" align-items="end">    
                                    <v-btn class="radio-left" v-bind:color="appointment.unit_good_conditions == 0 ? 'info' : 'white'" @click="appointment.unit_good_conditions = 0" :disabled="!can_edit">{{ $t('edit_appointment.false') }}</v-btn>
                                    <v-btn class="radio-right" v-bind:color="appointment.unit_good_conditions == 1? 'info' : 'white'" @click="appointment.unit_good_conditions = 1" :disabled="!can_edit">{{ $t('edit_appointment.true') }}</v-btn>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center" v-if="appointment.unit_good_conditions == 0">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.unit_damage_specifications" :label="$t('edit_appointment.transport_details.unit_damage_specifications')" :disabled="!can_edit"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 pb-2" justify="center" align="center">
                                <v-col cols="6" class="pl-3 py-0" justify="left" align="left">
                                    <span >{{ $t('edit_appointment.transport_details.seal_well_placed') }}:</span>
                                </v-col>
                                <v-col cols="2" class="px-0 py-0" justify="end" align-items="end">    
                                    <v-btn class="radio-left" v-bind:color="appointment.seal_good_conditions == 0 ? 'info' : 'white'" @click="appointment.seal_good_conditions = 0" :disabled="!can_edit">{{ $t('edit_appointment.false') }}</v-btn>
                                    <v-btn class="radio-right" v-bind:color="appointment.seal_good_conditions == 1? 'info' : 'white'" @click="appointment.seal_good_conditions = 1" :disabled="!can_edit">{{ $t('edit_appointment.true') }}</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                        
                        <!-- FORM FOR PRODUCT DETAILS -->
                        <v-form ref="product_details_form" lazy-validation @submit.prevent="save" v-if="panel_active == 2">
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" align="center" justify="center">        
                                    <h2 class="text-uppercase azul--text">{{ $t('edit_appointment.product_details.product_details')}}</h2>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-autocomplete v-model="appointment.packing_type" :label="$t('edit_appointment.product_details.type_of_packaging')" :items="embalajes" :disabled="!can_edit"></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 pb-2" justify="center" align="center">
                                <v-col cols="6" class="pl-3 py-0">
                                    <span >{{ $t('edit_appointment.product_details.punctured_damaged_merchandise') }}:</span>
                                </v-col>
                                <v-col cols="2" class="px-0 py-0" justify="end" align-items="end">
                                    <v-btn class="radio-left" v-bind:color="appointment.merchandise_good_conditions == 0 ? 'info' : 'white'" @click="appointment.merchandise_good_conditions = 0" :disabled="!can_edit">{{ $t('edit_appointment.false') }}</v-btn>
                                    <v-btn class="radio-right" v-bind:color="appointment.merchandise_good_conditions == 1? 'info' : 'white'" @click="appointment.merchandise_good_conditions = 1" :disabled="!can_edit">{{ $t('edit_appointment.true') }}</v-btn>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 pb-2" justify="center" align="center">
                                <v-col cols="6" class="pl-3 py-0">
                                    <span >{{ $t('edit_appointment.product_details.packaging_good_condition') }}:</span>
                                </v-col>
                                <v-col cols="2" class="px-0 py-0" justify="end" align-items="end">
                                    <v-btn class="radio-left" v-bind:color="appointment.packaging_complies_conditions == 0 ? 'info' : 'white'" @click="appointment.packaging_complies_conditions = 0" :disabled="!can_edit">{{ $t('edit_appointment.false') }}</v-btn>
                                    <v-btn class="radio-right" v-bind:color="appointment.packaging_complies_conditions == 1? 'info' : 'white'" @click="appointment.packaging_complies_conditions = 1" :disabled="!can_edit">{{ $t('edit_appointment.true') }}</v-btn>
                                </v-col>
                            </v-row>
                        
                            <v-form ref="formsku" lazy-validation @submit.prevent="addSku">
                                <v-row class="px-5 py-0" align="center" justify="center">
                                    <v-col cols="8" class="py-0 mt-6 px-3 mb-2">
                                        <label for="" class="azul--text mt-3" style="font-weight: bold; width:100%; text-align: start">{{$t('edit_appointment.product_details.products')}}</label>
                                    </v-col>
                                </v-row>           
                                <v-row class="px-5 pb-2" justify="center" align="center" v-if="appointment.status != 'Completo' && appointment.status != 'Pendiente Anexo24' && appointment.status != 'Pendiente de salida'">
                                    <v-col cols="5" class="pl-3 py-0">
                                        <span >{{ $t('edit_appointment.product_details.process_by') }}:</span>
                                    </v-col>
                                    <v-col cols="3" class="px-0 py-0" justify="end" align-items="end">
                                        <v-btn class="radio-left" style="font-size: 10px;" v-bind:color="process_type == 'pallet' ? 'info' : 'white'" @click="(process_type = 'pallet')">{{ $t('edit_appointment.product_details.pallet') }}</v-btn>
                                        <v-btn tile  style="font-size: 10px;" v-bind:color="process_type == 'box' ? 'info' : 'white'" @click="(process_type = 'box')">{{ $t('edit_appointment.product_details.box') }}</v-btn>
                                        <v-btn class="radio-right" style="font-size: 10px;" v-bind:color="process_type == 'product' ? 'info' : 'white'" @click="(process_type = 'product')">{{ $t('edit_appointment.product_details.product') }}</v-btn>
                                    </v-col>
                                </v-row>                    
                                <v-row class="px-5 py-0" align="center" justify="center" v-if="appointment.status != 'Completo' && appointment.status != 'Pendiente Anexo24' && appointment.status != 'Pendiente de salida'">
                                    <v-col cols="8" class="py-0 px-3">
                                        <v-autocomplete class="mt-0" v-model="product.product_id" item-value="id" item-text="sku" :items="items_g" :disabled="is_complete " :label="$t('edit_appointment.product_details.select_sku')" :rules="rules_required" ></v-autocomplete> 
                                    </v-col>
                                </v-row>
                                <v-row class="px-5 py-0" align="center" justify="center" v-if="appointment.status != 'Completo' && appointment.status != 'Pendiente Anexo24' && appointment.status != 'Pendiente de salida'">
                                    <v-col cols="4" class="py-0 pr-1">
                                        <v-autocomplete :disabled="is_complete" class="mt-0" v-model="product.location_id" item-value="id" item-text="name" :items="racks" :label="$t('edit_appointment.product_details.select_location')" :rules="rules_required"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="4" class="py-0 pl-1"  v-if="process_type == 'pallet' && current_operation.format_type == 'Entrada'">
                                        <v-text-field :disabled="is_complete" v-model="process_by_pallet.quantity_per_box" @keyup="uppercase" :label="$t('edit_appointment.product_details.quantity_per_box')" ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" class="py-0 pl-1" v-if="process_type == 'product'">
                                        <v-text-field :disabled="is_complete" v-model="product.quantity" :rules="numeric_required" :label="$t('edit_appointment.product_details.quantity')" ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" class="py-0 pl-1" v-if="process_type == 'box' && current_operation.format_type == 'Entrada'">
                                        <v-text-field :disabled="is_complete" v-model="process_by_box.quantity_per_box" :rules="numeric_required" :label="$t('edit_appointment.product_details.quantity_per_box')" ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="px-5 py-0" align="center" justify="center" v-if="appointment.status != 'Completo' && appointment.status != 'Pendiente Anexo24' && appointment.status != 'Pendiente de salida' && current_operation.format_type == 'Entrada'">
                                    <v-col cols="4" class="py-0 pr-1"  v-if="process_type != 'pallet'">
                                        <v-text-field :disabled="is_complete" v-model="product.pallet" :label="$t('edit_appointment.product_details.pallet')" :rules="numeric_required" ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" class="py-0 pr-1" v-if="process_type == 'pallet'">
                                        <v-text-field :disabled="is_complete" v-model="process_by_pallet.pallet_count" :rules="numeric_required" :label="$t('edit_appointment.product_details.pallet_count')" ></v-text-field>
                                    </v-col>

                                    <v-col cols="4" class="py-0 pl-1" v-if="process_type == 'pallet'">
                                        <v-text-field :disabled="is_complete" v-model="process_by_pallet.box_count" :rules="numeric_required" :label="$t('edit_appointment.product_details.box_count')" ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" class="py-0 pl-1" v-if="process_type == 'box'">
                                        <v-text-field :disabled="is_complete" v-model="process_by_box.box_count" :rules="numeric_required" :label="$t('edit_appointment.product_details.box_count')" ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" class="py-0 pl-1" v-if="process_type == 'product'">
                                        <v-text-field :disabled="is_complete" v-model="product.box" :label="$t('edit_appointment.product_details.box')" :rules="numeric_required"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="px-5 py-0" align="center" justify="center" v-if="appointment.status != 'Completo' && appointment.status != 'Pendiente Anexo24' && appointment.status != 'Pendiente de salida' && current_operation.format_type == 'Entrada'">
                                    <v-col cols="4" class="py-0 pr-1">
                                        <v-text-field :disabled="is_complete" v-model="product.batch" @keyup="uppercase" :label="$t('edit_appointment.product_details.batch')" ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" class="py-0 pl-1">
                                        <v-text-field :disabled="is_complete" v-model="product.expiration" @keyup="uppercase" :label="$t('edit_appointment.product_details.expiration')" ></v-text-field>
                                    </v-col>
                                </v-row>
                                
                                <v-row class="px-5 py-0" align="center" justify="center" v-if="appointment.status != 'Completo' && appointment.status != 'Pendiente Anexo24' && appointment.status != 'Pendiente de salida' && current_operation.format_type == 'Salida'">
                                    <v-col cols="4" class="py-0 pr-1">
                                        <v-autocomplete :disabled="is_complete" v-model="product.pallet" :label="$t('edit_appointment.product_details.pallet')" :items="pallets" :rules="numeric_required" :loading="load" v-if="current_operation.format_type == 'Entrada' || (current_operation.format_type == 'Salida' && process_type != 'pallet')"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="4" class="py-0 pl-1" v-if="process_type == 'product'">
                                        <v-autocomplete :disabled="is_complete" v-model="product.box" :label="$t('edit_appointment.product_details.box')" :items="boxes" :rules="numeric_required" :loading="load"></v-autocomplete>
                                    </v-col>
                                    <!-- <v-col cols="4" class="py-0 pl-1" v-if="process_type == 'box'">
                                        <v-text-field :disabled="is_complete" v-model="process_by_box.box_count" :rules="numeric_required" :label="$t('edit_appointment.product_details.box_count')" ></v-text-field>
                                    </v-col> -->
                                </v-row>
                                <v-row class="px-5 py-0" align="center" justify="center" v-if="appointment.status != 'Completo' && appointment.status != 'Pendiente Anexo24' && appointment.status != 'Pendiente de salida' && current_operation.format_type == 'Salida'">
                                    <v-col cols="4" class="py-0 pr-1">
                                        <v-autocomplete :disabled="is_complete" v-model="product.batch" :rules="rules_required" @keyup="uppercase" :label="$t('edit_appointment.product_details.batch')" :items="batches" :loading="load"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="4" class="py-0 pl-1">
                                        <v-autocomplete :disabled="is_complete" v-model="product.expiration" :rules="rules_required" @keyup="uppercase" :label="$t('edit_appointment.product_details.expiration')" :items="expirations" :loading="load"></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-form>
                            <v-row class="px-5 py-0" align="center" justify="center" v-if="appointment.status != 'Completo' && appointment.status != 'Pendiente Anexo24' && appointment.status != 'Pendiente de salida'">
                                <v-col class="mb-1 px-3" cols="8" align="end" justify="end">
                                    <v-btn :disabled="is_complete" outlined class="right white--text" color="amarillo" @click="addSku" >
                                        {{ $t('edit_appointment.add') }}
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <span v-permission="'massive_products.create'">
                                <v-row class="px-5 py-0" align="center" justify="center" v-if="appointment.status != 'Completo' && appointment.status != 'Pendiente Anexo24' && appointment.status != 'Pendiente de salida'">
                                    <v-col class="mb-1 px-3" cols="6" align="end" justify="end">
                                        <v-file-input v-model="skus" color="amarillo" accept=".csv" :clearable="true" :label="$t('edit_appointment.multiple_skus')" :rules="rules_required"></v-file-input>
                                    </v-col>
                                    <v-col class="mb-1 px-3" cols="2" align="end" justify="end">
                                        <v-btn :disabled="is_complete" outlined class="right white--text" color="amarillo" @click="addMultipleSkus" >
                                            {{ $t('edit_appointment.load_skus') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </span>

                            <v-row align="center" justify="center" class="px-5">
                                <v-col class="py-0 px-3" cols="8" align="center" justify="center">
                                    <datatable 
                                    :props="{headers:headers_sku, items: appointment.products}" 
                                    :income_editable="(current_operation.format_type == 'Entrada') && (appointment.status != 'Completo' && appointment.status != 'Pendiente Anexo24' && appointment.status != 'Pendiente de salida')" 
                                    :outcome_editable="!(current_operation.format_type == 'Entrada') && (appointment.status != 'Completo' && appointment.status != 'Pendiente Anexo24' && appointment.status != 'Pendiente de salida')"
                                    @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                            <br>
                        </v-form>
                        
                        <!-- FORM FOR FOREIGN TRADE -->
                        <v-form ref="foreign_trade_form" lazy-validation @submit.prevent="save" v-if="panel_active == 3 && from == 'international'">
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" align="center" justify="center">        
                                    <h2 class="text-uppercase azul--text">{{ $t('edit_appointment.foreign_trade.foreign_trade')}}</h2>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center" v-if="current_operation.format_type == 'Entrada'">
                                <v-col cols="8" class="py-0">
                                    <v-autocomplete v-model="appointment.origin_supplier_id" :label="$t('edit_appointment.foreign_trade.origin_providers')" item-value="id" item-text="name" :items="origin_providers"></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-autocomplete v-model="current_patent" :label="$t('edit_appointment.foreign_trade.patent')" :items="patents" item-value="id" item-text="patent"></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-autocomplete v-model="appointment.modulation_status" :label="$t('edit_appointment.foreign_trade.modulation_status')" :items="modulation_statuses"></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-menu ref="modulation_date" v-model="modulation_date_modal" :close-on-content-click="false" :return-value.sync="appointment.modulation_date" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field class="py-0" v-model="appointment.modulation_date" :label="$t('edit_appointment.foreign_trade.modulation_date')" append-outer-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="appointment.modulation_date" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="modulation_date_modal = false">{{ $t('edit_appointment.cancel') }}</v-btn>
                                            <v-btn text color="primary" @click="$refs.modulation_date.save(appointment.modulation_date)">{{ $t('edit_appointment.accept') }}</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-menu ref="modulation_time" v-model="modulation_time_modal" :close-on-content-click="false" :return-value.sync="appointment.modulation_time" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="appointment.modulation_time" :label="$t('edit_appointment.foreign_trade.modulation_time')" append-outer-icon="schedule" readonly v-on="on"></v-text-field>
                                        </template>
                                        <template>
                                            <v-time-picker v-model="appointment.modulation_time" ampm-in-title>
                                                <v-col justify="space-around" align="center">
                                                    <v-btn text color="primary" @click="modulation_time = false">{{ $t('edit_appointment.cancel') }}</v-btn>
                                                    <v-btn text color="primary" @click="$refs.modulation_time.save(appointment.modulation_time)">{{ $t('edit_appointment.accept') }}</v-btn>
                                                </v-col>
                                            </v-time-picker>
                                        </template>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.bill_of_lading" :label="$t('edit_appointment.foreign_trade.invoice')"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.remittance" :label="$t('edit_appointment.foreign_trade.consignment')"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.petition" :rules="rules_petition" :label="$t('edit_appointment.foreign_trade.petition')"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center" v-if="current_operation.format_type == 'Salida'">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.entry_petition" :rules="rules_petition" :label="$t('edit_appointment.foreign_trade.entry_petition')"></v-text-field>
                                </v-col>
                            </v-row>
                            
                            <br v-if="appointment.status == 'Pendiente Anexo24'">
                            
                            <v-row class="px-5 py-0" justify="center" align="center" v-if="appointment.status == 'Pendiente Anexo24'">
                                <v-col cols="8" align="center" justify="center">        
                                    <h2 class="text-uppercase azul--text">{{ $t('edit_appointment.foreign_trade.load_annex') }}</h2>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center" v-if="appointment.status == 'Pendiente Anexo24'">
                                <v-col cols="8" class="py-0" justify="center" align="center">
                                    <v-btn @click="modalAnnex = true">{{$t('edit_appointment.foreign_trade.select_file')}}</v-btn>
                                </v-col>
                            </v-row>
                            <br v-if="appointment.status == 'Pendiente Anexo24'">
                        </v-form>
                        
                        <!-- FORM FOR INCIDENCES -->
                        <v-form ref="incidences_form" lazy-validation @submit.prevent="save" v-if="(from == 'international' && panel_active == 4 && current_operation.format_type == 'Entrada') || (from == 'national' && panel_active == 3 && current_operation.format_type == 'Entrada')">
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" align="center" justify="center">        
                                    <h2 class="text-uppercase azul--text">{{ $t('edit_appointment.incidences.incidences')}}</h2>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center" class="px-5">
                                <v-col class="py-0 px-3" cols="8" align="center" justify="center">
                                    <datatable :props="{headers:headers_incidence, items: incidences}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-form>

                        <!--FORM FOR BINNACLE -->
                        <v-form ref="binnacle_form" lazy-validation @submit.prevent="save" v-if="(from == 'international' && panel_active == 5) || (from == 'national' && panel_active == 4)">
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" align="center" justify="center">        
                                    <h2 class="text-uppercase azul--text">{{ $t('edit_appointment.binnacle.binnacle')}}</h2>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-col class="py-0" cols="10" align="center" justify="center">
                                    <datatable :props="{headers:headers_status, items: a_status.items, search:a_status.search}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-form>

                        <!-- FORM FOR RFID -->
                        <v-form ref="rfid_form" lazy-validation @submit.prevent="save" v-if="(from == 'international' && panel_active == 7) || (from == 'national' && panel_active ==6)">
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" align="center" justify="center">        
                                    <h2 class="text-uppercase azul--text">{{ $t('edit_appointment.rfid.rfid')}}</h2>
                                </v-col>
                            </v-row>
                            
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-autocomplete class="py-0 mt-0" v-model="rfid.printer" :items="printers" :label="$t('edit_appointment.rfid.printer')" item-value="value" item-text="name"></v-autocomplete> 
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <!-- <v-autocomplete class="py-0 mt-0" v-model="rfid.method" :items="methods" :label="$t('edit_appointment.rfid.method')" item-value="value" item-text="name"></v-autocomplete>  -->
                                    <v-row>
                                        <v-col cols="4">
                                            <v-checkbox class="py-0 mt-0" v-model="rfid.print_type.pallet" label="pallet"></v-checkbox>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-checkbox class="py-0 mt-0" v-model="rfid.print_type.box" label="box"></v-checkbox>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-checkbox class="py-0 mt-0" v-model="rfid.print_type.tag" label="tag"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <!-- <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" class="py-0">
                                    <v-text-field class="py-0" v-model="appointment.container_number" :label="$t('edit_appointment.rfid.container')"></v-text-field>
                                </v-col>
                            </v-row> -->
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" align="end" justify="end">
                                    <v-btn class="right white--text" color="amarillo" @click="printLabels" >
                                        {{ $t('edit_appointment.rfid.print_labels') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>

                        <!-- FORM FOR SERVICES -->
                        <v-form ref="services_form" lazy-validation @submit.prevent="save" v-if="(from == 'international' && panel_active == 6) || (from == 'national' && panel_active == 5)">
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="8" align="center" justify="center">        
                                    <h2 class="text-uppercase azul--text">{{ $t('edit_appointment.services.services')}}</h2>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                                <v-col cols="4" class="py-0">  
                                    <v-autocomplete class="py-0 mt-0" v-model="service.service_id" :items="services" :label="$t('edit_appointment.services.select')" item-value="id" item-text="name"></v-autocomplete> 
                                </v-col>
                                <v-col cols="4" class="py-0">
                                    <v-menu ref="acomplish_date" v-model="menu" :close-on-content-click="false" :return-value.sync="service.acomplish_date" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field class="py-0" v-model="service.acomplish_date" :label="$t('edit_appointment.services.application_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="service.acomplish_date" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="menu = false">{{ $t('edit_appointment.cancel') }}</v-btn>
                                            <v-btn text color="primary" @click="$refs.acomplish_date.save(service.acomplish_date)">{{ $t('edit_appointment.save') }}</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row class="px-5 py-0" justify="center" align="center">
                            <v-col cols="4"></v-col>
                                <v-col cols="4" justify="end" align="end">
                                <v-spacer></v-spacer>
                                    <v-btn :disabled="is_complete" outlined class="right white--text" color="amarillo" @click="addService" >
                                        {{ $t('edit_appointment.add') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                            
                            <v-row align="center" justify="center" class="px-5">
                                <v-col class="py-0 px-3" cols="8" align="center" justify="center">
                                    <datatable :props="{headers:headers_services, items: appointment_services}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                            <br>
                        </v-form>

                        <br>
                        
                        <v-row class="px-5 py-0" justify="end" v-if="panel_active != 4 && !(from == 'national' && panel_active == 3) && panel_active != 6">
                            <v-col cols="6">
                                <v-btn class="right mx-3" color="white" @click="cancel" >
                                    {{ $t('edit_appointment.cancel') }}
                                </v-btn>
                                <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'appointment.update'">
                                    {{ $t('edit_appointment.save') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
        <v-row justify="center">
            <v-dialog v-model="modal_delete_service" persistent max-width="90%">
                <v-card >
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h3>{{ $t('edit_appointment.services.delete') }}</h3>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modal_delete_service = false; service_to_delete = ''">{{ $t('edit_appointment.cancel') }}</v-btn>
                            <v-btn color="error" @click="confirmDeleteService">
                                {{ $t('edit_appointment.services.confirm') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalAnnex" persistent max-width="90%">
                <v-card>
                <v-card-title class="justify-center">
                    <v-spacer></v-spacer>
                    {{ $t('outcome_appointment.upload_petition') }}
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" small icon @click="stepper2 = 1;modalAnnex = false">
                        <v-icon color="amarillo">close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-stepper v-model="stepper2">
                        <v-stepper-header>
                            <v-stepper-step :complete="stepper2 > 1" step="1" color="amarillo">{{$t('outcome_appointment.select_file_petition')}}</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="stepper2 > 2" step="2" color="amarillo">{{ $t('outcome_appointment.verify_data') }}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-card flat class="mb-5">
                                    <v-form ref="form2" lazy-validation>
                                        <v-row>
                                            <v-col>
                                                <v-file-input v-model="inputFile2" color="amarillo" accept=".csv" :label="$t('outcome_appointment.select_file_petition')" :rules="rules_required"></v-file-input>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card>
                                
                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="right white--text" color="amarillo" @click="uploadFilePetition" >
                                        {{ $t('outcome_appointment.next') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-card flat class="mb-5">
                                    <v-card-text>
                                        <p>{{readRows2.length}} {{ $t('outcome_appointment.skus_in_file') }}</p>
                                    </v-card-text>
                                    <v-simple-table v-if="this.readRows2.length > 0" fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th v-for="(th, key) in sTHeaders2" :key="key" class="text-left">{{ th }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in readRows2" :key="i">
                                                    <td v-for="(td, j) in item.split(',')" :key="j">{{ td }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>

                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="mx-3" text @click="stepper2 = 1; sTHeaders2 = []; readRows2 = []">{{ $t('outcome_appointment.go_back') }}</v-btn>
                                    <v-btn color="amarillo" class="white--text" @click="modalAnnex = false;">
                                        {{ $t('outcome_appointment.save_data') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>

export default {
    computed: {
        client: function(){
            return this.$store.state.clientId
        },
        rules_required(){return [v => !!v || this.$t('edit_appointment.validations.required_field')]}, 
        rules_petition(){
            return [
                v => v == null || /^(|\d)+$/.test(v) || this.$t('edit_appointment.validations.numeric'),
                v => v == null || v.toString().length == 0 || v.toString().length == 15 || this.$t('edit_appointment.validations.lenght'),
            ];
        },
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
        headers_status: function(){
            return [
                { text: this.$t('edit_appointment.binnacle.status'), value: 'status', class: 'text-uppercase' },
                { text: this.$t('edit_appointment.binnacle.user'), value: 'user.name', class: 'text-uppercase' },
                { text: this.$t('edit_appointment.binnacle.start'), value: 'reg_date', class: 'text-uppercase' },
                { text: this.$t('edit_appointment.binnacle.end'), value: 'end_date', class: 'text-uppercase' },
                { text: this.$t('edit_appointment.binnacle.minutes'), value: 'minutes', class: 'text-uppercase' },
                { text: this.$t('edit_appointment.binnacle.portal'), value: 'portal_name', class: 'text-uppercase' },
            ];
        },
        headers_sku: function(){
            return [
                {text: this.$t('edit_appointment.product_details.sku_t'), value: 'sku_p', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.product_details.quantity'), value: 'quantity', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.product_details.location'), value: 'location', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.product_details.pallet'), value: 'pallet', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.product_details.box'), value: 'box', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.product_details.batch_t'), value: 'batch', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.product_details.expiration_t'), value: 'expiration', class: 'text-uppercase'},
                {text: '', value: 'actions', class: 'text-uppercase', width:'10%', align: 'center', sortable: false},
            ];
        },
        headers_incidence: function(){
            return [
                {text: this.$t('edit_appointment.incidences.type'), value: 'i_type', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.incidences.quantity'), value: 'quantity', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.incidences.description'), value: 'description', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.incidences.actions'), value: 'actions', class: 'text-uppercase', align: 'center', sortable: false}
            ];
        },
        headers_services: function(){
            return [
                {text: this.$t('edit_appointment.services.service'), value: 'service.name', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.services.date'), value: 'acomplish_date', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.services.performed_by'), value: 'user.name', class: 'text-uppercase'},
                {text: this.$t('edit_appointment.services.status'), value: 'status', class: 'text-uppercase', align: 'center', sortable: false},
                {text: "", value: 'actions', class: 'text-uppercase', align: 'center', sortable: false}
            ];
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            from: this.$route.params.from,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            skus: [],
            checked: [
                {
                    name: this.$t('edit_appointment.services.completed'),
                    icon: {
                        color: 'success2',
                        icon: 'check_circle'
                    },
                    method: 'change_status_service',
                },
                {
                    name: this.$t('edit_appointment.services.delete_service'),
                    icon: {
                        color: 'error',
                        icon: 'disabled_by_default'
                    },
                    method: 'deleteService',
                }
            ],
            unchecked: [
                {
                    name: this.$t('edit_appointment.services.complete'),
                    icon: {
                        color: 'warning',
                        icon: 'error'
                    },
                    method: 'change_status_service',
                },
                {
                    name: this.$t('edit_appointment.services.delete_service'),
                    icon: {
                        color: 'error',
                        icon: 'disabled_by_default'
                    },
                    method: 'deleteService',
                }
            ],
            actions: [
                {
                    name: this.$t('edit_appointment.product_details.delete_sku'),
                    icon: {
                        color: 'error',
                        icon: 'disabled_by_default'
                    },
                    method: 'deleteSku',
                }
            ],
            incidence_actions: [
                {
                    name: this.$t('edit_appointment.incidences.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    link: '/incidence_detail',
                    permission: "a_incidence.show"
                },
                {
                    name: this.$t('edit_appointment.incidences.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'mode_edit'
                    },
                    link: '/incidence_edit',
                    permission: "a_incidence.update"
                },
            ],
            panel_active: 0,
            arrival_date_modal: false,
            eta_date_modal: false, 
            fumigation_date_modal: false,
            modulation_date_modal: false,
            modulation_time_modal: false,
            process_type: "product",
            process_by_pallet: {
                pallet_count: "",
                box_count: "",
                quantity_per_box: "",
            },
            process_by_box: {
                quantity_per_box: "",
                box_count: "",
            },
            appointment:{
                // General data
                client_id: "",
                arrival_date: "",
                eta: "",
                operation_id: "",
                container_number: "",
                description: "",
                commentary: "",
                additional_services: "",
                purchase_order: "",
                gate: "",

                // Transport details
                carrier_line: "",
                drivers_name: "",
                license_plate: "",
                box: "",
                tractor: "",
                consignment_note: "",
                cargo_type: "",
                transport_type: "",
                packing_type: "",
                unit_good_conditions: 1,
                unit_damage_specifications: "",
                seal_good_conditions: 1,
                seal_number: "",
                
                // Foreign trade
                origin_supplier_id: "",
                patent_number: "",
                modulation_status: "",
                modulation_date: "",
                modulation_time: "",
                bill_of_lading: "",
                remittance: "",
                petition: "",
                notices: "",
                entry_petition: "",
                merchandise_good_conditions: 1,
                packaging_complies_conditions: 1,

                products: [],
                is_rfid: false
            },
            buttons_international: [
                { title: this.$t("edit_appointment.left_buttons.general_data"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.transport_details"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.product_details"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.foreign_trade"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.incidences"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.binnacle"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.services"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.rfid"), icon: '', link: ''},
            ],
            buttons_national: [
                { title: this.$t("edit_appointment.left_buttons.general_data"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.transport_details"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.product_details"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.incidences"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.binnacle"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.services"), icon: '', link: ''},
                { title: this.$t("edit_appointment.left_buttons.rfid"), icon: '', link: ''},
            ],
            movements: [],
            embalajes: [
                "Cajas de madera",
                "Cajas de plastico",
                "Cajas de carton",
                "Tambores",
                "Tote",
                "Sacos",
                "Emplaye", 
                "Mercancia suelta"
            ],
            product: {
                product_id: "",
                quantity: "",
                location_id: "",
                pallet: "",
                box: "",
                batch: "",
                expiration: ""
            },
            // items: [],
            items_g: [],
            is_complete: false,
            racks: [],
            a_status: {
                items:[],
                search: ''
            },
            origin_providers: [],
            patents: [],
            modulation_statuses: [
                "Reconocimiento Aduanero",
                "En proceso de Modulación",
                "Desaduanado"
            ],
            types_of_transport: [
                "Camioneta 3 1/2",
                "Rabón",
                "Torthon",
                "Contenedor 20'",
                "Contenedor 40'",
                "Caja Seca 40'",
                "Caja Seca 48'",
                "Caja Seca 53'",
                "Otros"
            ],
            types_of_cargo: [
                "Montacarga",
                "Manual",
                "Ninguna"
            ],
            confirmLeave: false,
            status_definitions: [],
            current_operation: '',
            batches: [],
            boxes: [],
            pallets: [],
            expirations: [],
            incidences: [],
            quantities: [],
            load: false,
            modalAnnex: false,
            inputFile2: [],
            readFile2: '',
            readRows2: [],
            readRows: [],
            sTHeaders2: [],
            service_to_delete: "",
            stepper2: 1,
            can_edit: false,
            current_patent: "",
            pallet_box_count: [],
            printers: [{name: "printer 1", value: "1",}, {name: "printer 2", value: "2",}],
            methods: [
                {name: this.$t("edit_appointment.rfid.pallet"), value: "pallet"}, 
                {name: this.$t("edit_appointment.rfid.box"), value: "box"}, 
                {name: this.$t("edit_appointment.rfid.product"), value: "product"}
            ],
            modal_delete_service: false,
            services: [],
            appointment_services: [],
            menu: false,
            service: {
                service_id: "",
                acomplish_date: "",
                status: "Pending",
                appointment_id: parseInt(this.$route.params.id),
            },
            last_pallet: "",
            rfid: {
                printers: ['primera', 'impresora'],
                is_rfid: false,
                is_entry: false,
                printer: null,
                info: {
                    data: []
                },
                print_type: {
                    pallet: false,
                    box: false,
                    tag: false
                }
            },
            products_buffer: [],
            is_rfid: false,
            show_rfid: false,
            rfid_items: {
                pallets:[],
                boxes: [],
                tags: []
            },
        }
    },
    mounted(){
        this.$store.state.module = "headers.update_appointment";
        this.index();
    },
    methods: {
        print_tags(id){
            console.log(id);
            this.$store.state.loaderProps.visible = true;
            var t = this
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'rfid/appointment/' + this.id, {headers: {Authorization: lsToken}})
            .then(response => {
                console.log("se esta imprimiendo el response de print tags")
                t.rfid_items = response.data.items
                t.show_rfid = true
                
            })
            .catch(err => {console.log(err)})
            .finally(()=>{this.$store.state.loaderProps.visible = false;})
        },
        printLabels(){
            if(this.rfid.printer == null)
            {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: 'Debes seleccionar una impresora'
                }
                return;
            }
            var type_band = Object.values(this.rfid.print_type).some(value => value === true)
            if(!type_band)
            {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: 'Debes seleccionar un modo de impresion'
                }
                return;
            }
            if(this.rfid.data == null)
            {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: 'Esta cita no contiene ningun tag qe se pueda imprimir'
                }
                return;
            }
            this.$http.post(this.$store.state.printerServiceApi + 'print' , this.rfid )
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        getClients(){
            const lsToken = localStorage.getItem("access_token");

            this.$http.get(this.$store.state.chronosApi + 'clientsIndex/2', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data.data.forEach(x => {
                        x = x.attributes;
                        this.clients.push(x);
                    })
                }
            })
            .catch((error) => {
                error
            })
            .finally(() => {
                this.$store.state.loaderProps.visible = false
            })
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            var t = this
            // GET Origin Suppliers
            this.$http.get(this.$store.state.chronosApi + 'origin_suppliers', {headers:{Authorization: lsToken }})
            .then((response) => {
                if(response.data !== '') {    
                    response.data.data.forEach(x => {
                        this.origin_providers.push(x.attributes);
                    });
                }
                else this.origin_providers = [];
            }).catch((error) => {
                console.log(error);
            })

            // GET Services of appointment
            this.appointment_services = [];
            this.$http.get(this.$store.state.chronosApi + 'get_services_from_appointment?apppointment_id=' + this.id, {headers:{Authorization: lsToken }})
            .then((response) => {
                if(response.data !== '') {    
                    response.data.services.data.forEach(x => {
                        if(x.attributes.status == "Pending") x.attributes.actions = JSON.parse(JSON.stringify(this.unchecked));
                        else x.attributes.actions = JSON.parse(JSON.stringify(this.checked));
                        x.attributes.parameters = x.attributes;
                        this.appointment_services.push(x.attributes);
                    });
                }
                else this.appointment_services = [];
            }).catch((error) => {
                console.log(error);
            })

            this.$http.get(this.$store.state.chronosApi + 'customs_brokers', {headers:{Authorization: lsToken }})
            .then((response) => {
                if(response.data !== '') {    
                    response.data.data.forEach(x => {
                        this.patents.push(x.attributes);
                    });
                }
                else this.patents = [];
            }).catch((error) => {
                console.log(error);
            })

            this.$http.get(this.$store.state.chronosApi + 'services', {headers:{Authorization: lsToken }})
            .then((response) => {
                if(response.data !== '') {    
                    response.data.data.forEach(x => {
                        this.services.push(x.attributes);
                    });
                }
                else this.patents = [];
            }).catch((error) => {
                console.log(error);
            })

            this.$http.get(this.$store.state.chronosApi + 'operations', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data.forEach(x =>{
                        if(this.$store.state.language == 'es'){
                            if(this.from == 'international' && (x.description.includes('Inter') || x.description.includes('Crossdock')))
                                this.movements.push({id: x.id, description: x.description, format_type: x.format_type});
                            else if(this.from == 'national' && !x.description.includes('Inter') && !x.description.includes('Crossdock'))
                                this.movements.push({id: x.id, description: x.description, format_type: x.format_type});
                        }
                        else {
                            if(this.from == 'international' && (x.description.includes('Inter') || x.description.includes('Crossdock')))
                                this.movements.push({id: x.id, description: x.description1, format_type: x.format_type});
                            else if(this.from == 'national' && !x.description.includes('Inter') && !x.description.includes('Crossdock'))
                                this.movements.push({id: x.id, description: x.description1, format_type: x.format_type});
                        }
                    });
                }
            });

            this.$http.get(this.$store.state.chronosApi + 'getIncidences/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.incidences = [];
                response.data.forEach(x => {
                    if(x.i_type == "less") x.i_type = this.$t("edit_appointment.incidences.less");
                    else if(x.i_type == "more") x.i_type = this.$t("edit_appointment.incidences.more");
                    else if(x.i_type == "damaged") x.i_type = this.$t("edit_appointment.incidences.damaged");
                    x.actions = JSON.parse(JSON.stringify(this.incidence_actions));
                    x['actions'][0].link = '/incidence_detail/' + x.id;
                    x['actions'][1].link = '/incidence_edit/' + x.id;
                    this.incidences.push(x);
                });
            });

            this.$http.get(this.$store.state.chronosApi + 'appointments/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data = response.data.data.attributes;
                    this.appointment.products = [];

                    // products and Details
                    this.$http.get(this.$store.state.chronosApi + 'getProducts/' + response.data.client.id, {headers: {Authorization: lsToken}})
                    .then((response) => {
                        if(response.status === 200){
                            response.data = response.data.data;
                            response.data.forEach(x =>{
                                x = x.attributes;
                                this.items_g.push({
                                    product_id: x.id,
                                    sku_p: x.sku,
                                    sku: x.sku + ' | ' + x.description, 
                                    id: x.id
                                });
                            });
                        }
                    });

                    response.data.detail_orders.forEach(x => {
                        x = x.attributes;
                        var p = {};
                        if(x.location != null){
                            p = {
                                detail_id: x.id,
                                sku_p: x.product.sku,
                                product_id: x.product.id,
                                quantity: x.real ?? x.expected,
                                location_id: x.location.id,
                                location: x.location.name,
                                pallet: x.pallet,
                                box: x.box,
                                batch: x.batch,
                                expiration: x.expiration,
                                actions: "",
                                parameters: ""
                            }
                        }
                        else {
                            p = {
                                detail_id: x.id,
                                sku_p: x.product.sku,
                                product_id: x.product.id,
                                quantity: x.real ?? x.expected,
                                location_id: "---",
                                location: "---",
                                pallet: x.pallet,
                                box: x.box,
                                batch: x.batch,
                                expiration: x.expiration,
                                actions: "",
                                parameters: ""
                            }
                        }

                        if(response.data.status != 'Completo' && response.data.status != 'Pendiente Anexo24' && response.data.status != 'Pendiente de salida'){
                            p.actions = JSON.parse(JSON.stringify(this.actions));
                            p.parameters = p;
                        }

                        this.appointment.products.push(p);
                    });
                    // General Data
                    this.$store.state.clientId                      = response.data.client.id ?? null;
                    this.appointment.client_id                      = response.data.client.id ?? null;
                    this.appointment.arrival_date                   = response.data.arrival_date.substring(0, 10) ?? null;
                    if(this.from == 'international' && response.data.eta != undefined && response.data.eta != null)
                        this.appointment.eta                        = response.data.eta.substring(0, 10);
                    this.appointment.operation_id                   = response.data.operation.id ?? null;
                    this.appointment.additional_services            = response.data.additional_services ?? null;
                    this.appointment.purchase_order                 = response.data.purchase_order ?? null;
                    this.appointment.container_number               = response.data.container_number ?? null;
                    this.appointment.description                    = response.data.description ?? null;
                    this.appointment.commentary                     = response.data.commentary ?? null;
                    this.appointment.gate                           = response.data.gate ?? null;
                    this.appointment.status                         = response.data.status ?? null; 
                    if(this.appointment.status == "Pendiente de salida" || this.appointment.status == "Completo" || this.appointment.status == "Pendiente Anexo24") this.can_edit = true;
                    this.appointment.seal_number                    = response.data.seal_number ?? null;
                    this.current_operation                          = response.data.operation;
 
                    // Transport Details
                    this.appointment.carrier_line                   = response.data.carrier_line ?? null;
                    this.appointment.drivers_name                   = response.data.drivers_name ?? null;
                    this.appointment.license_plate                  = response.data.license_plate ?? null;
                    this.appointment.tractor                        = response.data.tractor ?? null;
                    this.appointment.consignment_note               = response.data.consignment_note ?? null;
                    this.appointment.cargo_type                     = response.data.cargo_type ?? null;
                    this.appointment.transport_type                 = response.data.transport_type ?? null;
                    this.appointment.unit_good_conditions           = response.data.unit_good_conditions ?? 1;
                    this.appointment.unit_damage_specifications     = response.data.unit_damage_specifications ?? null;
                    this.appointment.seal_good_conditions           = response.data.seal_good_conditions ?? 1;
                    this.appointment.merchandise_good_conditions    = response.data.merchandise_good_conditions ?? 1;
                    this.appointment.packaging_complies_conditions  = response.data.packaging_complies_conditions ?? 1;
                    this.appointment.packing_type                   = response.data.packing_type ?? null;
                    this.appointment.is_rfid                        = response.data.is_rfid ?? false;

                    this.a_status.items = [];
                    var ap_status = response.data.appointment_status;
                    this.$http.get(this.$store.state.chronosApi + 'a_status_definitions', {headers: {Authorization: lsToken}})
                    .then((response) => {
                        if(response.status === 200){
                            this.status_definitions = response.data;

                            ap_status.forEach(x => {
                                x = x.attributes;
                                if(this.status_definitions.length > 0){
                                    if(this.$store.state.language == 'es') x.status = this.status_definitions.find(item => item.status == x.status).description;
                                    else if(this.$store.state.language == 'en') x.status = this.status_definitions.find(item => item.status == x.status).description1;
                                    if(x.portal_name == undefined || x.portal_name == null){
                                        x.portal_name = "---";
                                    } 

                                    if(x.end_date == null){
                                        x.end_date = '---';
                                        x.minutes = 0;
                                    }

                                    else x.minutes = this.diffTime(x.reg_date, x.end_date);
                                    
                                    this.a_status.items.push(x);
                                }
                            });
                        }
                    });

                    // Foreign Trade
                    if(this.from == 'international'){
                        if(response.data.operation.format_type == 'Entrada' && response.data.origin_supplier) this.appointment.origin_supplier_id     = response.data.origin_supplier.id ?? null;
                        this.appointment.patent_number          = response.data.patent_number ?? null;    
                        if(this.appointment.patent_number != null && this.appointment.patent_number != ""){
                            var cb = null;
                            cb = this.patents.find( x => x.patent == this.appointment.patent_number)

                            this.current_patent = cb.id;
                        }
                        this.appointment.modulation_status      = response.data.modulation_status ?? null;
                        this.appointment.modulation_date        = response.data.modulation_date ?? null;
                        if(response.data.modulation_time != null)    
                            this.appointment.modulation_time    = response.data.modulation_time.split("T")[1].substring(0,5);
                        this.appointment.bill_of_lading         = response.data.bill_of_lading ?? null;
                        this.appointment.remittance             = response.data.remittance ?? null;
                        this.appointment.petition               = response.data.petition ?? null;
                        this.appointment.notices                = response.data.notices ?? null;
                        this.appointment.entry_petition         = response.data.entry_petition ?? null;
                    }
                    
                    if(this.current_operation.format_type == 'Entrada' && (this.appointment.status == 'Completo' || this.appointment.status == 'Pendiente Anexo24'))
                    {
                        this.$http.get(this.$store.state.chronosApi + 'rfid/client/is_rfid/' + t.appointment.client_id, {headers: {Authorization: lsToken}})
                        .then(res => {
                            t.is_rfid = res.data.cw.is_rfid
                        }).catch(err => {console.log(err)})
                        t.$http.get(t.$store.state.chronosApi + 'rfid/appointment/' + t.id, {headers: {Authorization: lsToken}}).then(resp=> {
                            t.rfid.info = resp.data.data
                        }).catch(error => {
                            console.log(error)
                        })

                    }
                }

                if(response.data.operation.id % 2 == 1){ // Load racks if movement type is an income    
                    this.$http.get(this.$store.state.chronosApi + 'getLocations/' + response.data.operation.id + '/' + response.data.client.id, {headers: {Authorization: lsToken}})
                    .then((response) => {
                        if(response.status === 200){
                            response.data.forEach(x =>{
                                this.racks.push({
                                    id: x.id,
                                    name: x.name
                                });
                            });
                        }
                    });
                }       
            })
            .finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
            
            // RFID Printers
            // this.$http.get(this.$store.state.rfidApi + 'printers', {headers: {Authorization: lsToken}})
            // .then((response) => {
            //     console.log(response);
            // });  
        },
        change_status_service(service){
            const lsToken = localStorage.getItem("access_token");

            var new_status = "";
            if(service.status == "Pending") new_status = "Completed";
            else new_status = "Pending";

            // GET Services of appointment
            this.$http.put(this.$store.state.chronosApi + 'appointment_services/' + service.id, { "status": new_status }, {headers:{Authorization: lsToken }})
            .then((response) => {
                if(response.status == 200) {    
                    this.index();
                }
                else this.appointment_services = [];
            }).catch((error) => {
                console.log(error);
            })
        },
        cancel(){
            var unsaved = false;
                        
            if(unsaved == true) this.confirmLeave = true;
            this.$router.go(-1);
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            this.appointment.products.forEach(x => {
                delete x.parameters;
                delete x.sku_p;
                delete x.actions;
                x.product_id = parseInt(x.product_id);
                x.location_id = parseInt(x.location_id);
                x.quantity = parseInt(x.quantity);
                x.pallet = x.pallet.toString();
                x.box = x.box.toString();
                if(x.batch != null && x.batch != undefined){
                    if(x.batch == "" || x.batch.trim().length === 0) x.batch = null;
                }
                if(x.expiration != null && x.expiration != undefined){
                    if(x.expiration == "" || x.expiration.trim().length === 0) x.expiration = null;
                }
            })
            const lsToken = localStorage.getItem("access_token");
            this.$http.put(this.$store.state.chronosApi + 'appointments/' + this.id, this.appointment, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status == 200) {
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('edit_appointment.responses.ok')
                    }
                    this.index();
                }
            }).catch((error) => {
                // console.log(error.response);
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: error.response.data.message
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })

            // upload annex 24 file
            if(this.readRows2.length > 0){
                const petitions = [];
                this.readRows2.forEach((x) => {
                    const num = x.split(',');
                    petitions.push({
                        'customs_document': num[0],
                        'customs_document_date': num[1], //fecha pago
                        'income_date': num[2],
                        'cvedoc': num[3],
                        'regime': num[4],
                        'origin_country': num[5],
                        'source_country': num[5],
                        'customs_document_departure': num[6],
                        'part_number': num[7], //sku
                        'description': num[8],
                        'invoice_quantity': num[9],
                        'invoice_number': num[10],
                        'umc': parseInt(num[11]),
                        'fraction': num[12],
                        'value_in_dollars': num[13],
                        'codcove': num[14],
                        'client_supplier': num[15],
                        'notice_number': num[16],
                        'umt': parseInt(num[17]),
                    });
                });

                this.$http.post(this.$store.state.chronosApi +  'uploadAnnex/' + this.id , {'pedimentos': petitions}, {headers: {Authorization: lsToken}})
                .then((response) => {
                    this.$store.state.loaderProps.visible = false;
                    if(response.status === 200){
                        this.stepper2 = 1;
                        this.sTHeaders2 = [];
                        this.readRows2 = [];
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('outcome_appointment.responses.ok')
                        }
                        this.$router.push({ name: 'InternationalProcessed' });
                    }
                })
                .catch((response) => {
                    this.$store.state.loaderProps.visible = false;
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('outcome_appointment.responses.error')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
            }
        },
        // File csv
        addMultipleSkus(){
            if(this.skus != []){
                this.createInput2(this.skus, "skus");
            }
        },
        // File csv
        processSkus(){
            this.appointment.products.forEach( x => {
                this.products_buffer.push(x);
            })

            if(this.readRows.length > 0){
                var count = 0;
                var error_found = false; 
                this.readRows.forEach((x) => {

                    if (error_found == true) return;
                    const num = x.split(',');
                    
                    if(num.length != 7){
                        this.snackbar = {
                            visible: true,
                            color: 'error',
                            text: this.$t('edit_appointment.massive.missing_columns') + ' ' + this.$t('edit_appointment.massive.row') + (count + 2).toString()
                        }
                        error_found = true;
                        return;
                    }

                    const current_location = this.racks.find(x => x.name == num[1]);
                    if(current_location == null) {
                        this.snackbar = {
                            visible: true,
                            color: 'error',
                            text: this.$t('edit_appointment.massive.location_not_found') + num[1].toString() + '. ' + this.$t('edit_appointment.massive.row') + (count + 2).toString()
                        }
                        error_found = true;
                        return;
                    }
                
                    const location_id = this.racks.find(x => x.name == num[1]).id

                    var product_id = "";
                    if(this.items_g.find(item => item.sku_p == num[0])) product_id = this.items_g.find(item => item.sku_p == num[0]).product_id;
                    else {
                        this.snackbar = {
                            visible: true,
                            color: 'error',
                            text: this.$t('edit_appointment.massive.sku_not_found') + num[0].toString() + ' ' + this.$t('edit_appointment.massive.row') + (count + 2).toString()
                        }
                        this.$refs.formsku.reset();

                        error_found = true;
                        return true;
                    }

                    if(num[2].trim() == "" || num[4].trim() == "" || num[3].trim() == "" || num[2] == null || num[4] == null || num[3] == null){
                        this.snackbar = {
                            visible: true,
                            color: 'error',
                            text: this.$t('edit_appointment.massive.pallet_box_quantity_empty')
                        }
                        error_found = true;
                        return;
                    }

                    this.product = {
                        product_id: product_id,
                        quantity: parseInt(num[4]),
                        location_id: location_id,
                        pallet: parseInt(num[2]),
                        box: parseInt(num[3]),
                        batch: num[5],
                        expiration: num[6]
                    }
                    var is_an_error = this.loadSku(count);

                    if(is_an_error == true){ 
                        error_found = true;
                        return;
                    }
                    count++;
                });
                if(error_found == false) this.appointment.products = this.products_buffer;
            }
        },
        // File csv
        loadSku(row){
            var p = {
                sku_p: "",
                product_id: "",
                quantity: "",
                location_id: "",
                location: "",
                pallet: "",
                box: "",
                batch: "",
                expiration: "",
                actions: "",
                parameters: ""
            }
            var t = this;

            Object.keys(t.product).forEach(function (key) {
                var value = t.product[key];  
                p[key] = value;
            });

            p.location = this.racks.find(x => x.id == p.location_id).name
            p.actions = JSON.parse(JSON.stringify(this.actions));
            p.sku_p = this.items_g.find(item => item.id == p.product_id).sku_p;
            p.parameters = p;

            if(!this.appointment.products.find(x => (x.sku_p == p.sku_p
                    && x.product_id == p.product_id 
                    && x.location_id == p.location_id 
                    && x.pallet == p.pallet 
                    && x.box == p.box
                    && (x.batch == p.batch || (x.batch == " " && (p.batch == null || p.batch == "" || p.batch == " ")) || (x.batch == "" && (p.batch == null || p.batch == "" || p.batch == " ")))
                    && (x.expiration == p.expiration || (x.expiration == " " && (p.expiration == null || p.expiration == "" || p.expiration == " ")) || (x.expiration == "" && (p.expiration == null || p.expiration == "" || p.expiration == " "))))))
                this.products_buffer.push(p);
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('edit_appointment.massive.repeated') + ' ' + this.$t('edit_appointment.massive.row') + (row + 2).toString()
                }
                this.$refs.formsku.reset();

                return true;
            }

            this.$refs.formsku.reset();
        },
        addSku(){
            var validate = this.$refs.formsku.validate();
            if(validate){
                if(this.current_operation.format_type == 'Entrada') this.addSkuIncome();
                else if(this.current_operation.format_type == 'Salida') this.addSkuOutcome();
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('edit_appointment.all_fields_required')
                }
            }
        }, 
        addSkuIncome(){
            var p = {
                sku_p: "",
                product_id: "",
                quantity: "",
                location_id: "",
                location: "",
                pallet: "",
                box: "",
                batch: "",
                expiration: "",
                actions: "",
                parameters: ""
            }
            var t = this;
            if(this.process_type == "product"){
                Object.keys(t.product).forEach(function (key) {
                    var value = t.product[key];  
                    p[key] = value;
                });
                p.location = this.racks.find(x => x.id == p.location_id).name
                p.actions = JSON.parse(JSON.stringify(this.actions));
                p.sku_p = this.items_g.find(item => item.id == p.product_id).sku_p;
                p.parameters = p;

                if(!this.appointment.products.find(x => (x.sku_p == p.sku_p
                    && x.product_id == p.product_id 
                    && x.location_id == p.location_id 
                    && x.pallet == p.pallet 
                    && x.box == p.box
                    && (x.batch == p.batch || (x.batch == " " && (p.batch == null || p.batch == "" || p.batch == " ")) || (x.batch == "" && (p.batch == null || p.batch == "" || p.batch == " ")))
                    && (x.expiration == p.expiration || (x.expiration == " " && (p.expiration == null || p.expiration == "" || p.expiration == " ")) || (x.expiration == "" && (p.expiration == null || p.expiration == "" || p.expiration == " "))))))

                    this.appointment.products.push(p);
                else{
                    this.snackbar = {
                        visible: true,
                        color: 'warning',
                        text: this.$t('edit_appointment.responses.repeated')
                    }
                }
                this.$refs.formsku.reset();
            }
            else if(this.process_type == "box"){
                this.addSkusByBox(1, "box");
            }
            else if(this.process_type == "pallet"){
                this.addSkusByBox(this.process_by_pallet.pallet_count, "pallet")
            }
        },
        // Income movements
        addSkusByBox(num_of_pallets, type){
            var count = 0;

            // Load skus by pallet
            if(type == "pallet"){
                this.process_by_box.box_count = this.process_by_pallet.box_count;
                this.process_by_box.quantity_per_box = this.process_by_pallet.quantity_per_box;
                var products_in_location = [];

                this.last_pallet = 0;
                this.appointment.products.forEach(x => {
                    if(x.location_id == this.product.location_id) { 
                        products_in_location.push(x);
                        if(x.pallet > this.last_pallet) {
                            this.last_pallet = parseInt(x.pallet);
                            count = this.last_pallet;
                        }
                    }
                })
            }
            // Load skus by box
            else {
                count = parseInt(this.product.pallet) - 1 
                num_of_pallets = (parseInt(num_of_pallets) + parseInt(this.product.pallet)) - 1;
            }

            var p = {
                sku_p: "",
                product_id: "",
                quantity: "",
                location_id: "",
                location: "",
                pallet: "",
                box: "",
                batch: "",
                expiration: "",
                actions: "",
                parameters: ""
            }
            var t = this;     

            for(count; count < parseInt(num_of_pallets) + this.last_pallet; count++){
                // Define first box and last box in current pallet
                var current = null;
                var init = 0;
                var end = 0;
                this.pallet_box_count = [];
                current = this.pallet_box_count.find( x => (x.pallet == t.product.pallet && x.location == t.product.location_id));
                
                if(current != null && current != undefined){
                    init = current.box;
                    current.box = parseInt(current.box) + parseInt(t.process_by_box.box_count);
                    end = current.box;
                }else{
                    this.pallet_box_count.push({
                        pallet: t.product.pallet,
                        box: this.process_by_box.box_count,
                        location: t.product.location_id
                    })
                    end = parseInt(this.process_by_box.box_count);
                } 

                for(let i = init; i < end; i++){
                    p = {
                        sku_p: "",
                        product_id: "",
                        quantity: t.process_by_box.quantity_per_box,
                        location_id: "",
                        location: "",
                        pallet: "",
                        box: parseInt(i) + 1,
                        batch: "",
                        expiration: "",
                        actions: "",
                        parameters: ""
                    };
                    Object.keys(t.product).forEach(function (key) {
                        var value = t.product[key];  
                        if(key != 'quantity' && key != 'box') p[key] = value;
                        if(key == "pallet") p[key] = count + 1;
                    });
                    p.location = this.racks.find(x => x.id == p.location_id).name
                    p.actions = JSON.parse(JSON.stringify(this.actions));
                    p.sku_p = this.items_g.find(item => item.id == p.product_id).sku_p;
                    p.parameters = p;
                    
                    if(!this.appointment.products.find(x => (x.sku_p == p.sku_p
                        && x.product_id == p.product_id 
                        && x.location_id == p.location_id 
                        && x.pallet == p.pallet 
                        && x.box == p.box
                        && (x.batch == p.batch || (x.batch == " " && (p.batch == null || p.batch == "" || p.batch == " ")) || (x.batch == "" && (p.batch == null || p.batch == "" || p.batch == " ")))
                        && (x.expiration == p.expiration || (x.expiration == " " && (p.expiration == null || p.expiration == "" || p.expiration == " ")) || (x.expiration == "" && (p.expiration == null || p.expiration == "" || p.expiration == " "))))))
                        this.appointment.products.push(p);
                    else{
                        this.snackbar = {
                            visible: true,
                            color: 'warning',
                            text: this.$t('edit_appointment.responses.repeated')
                        }
                    }
                }
            }
            
            // Clean flags
            this.process_by_pallet = {
                pallet_count: "",
                box_count: "",
                quantity_per_box: "",
            };
            this.process_by_box = {
                quantity_per_box: "",
                box_count: "",
           };
            this.$refs.formsku.reset();
        },
        addSkuOutcome(){
            var p = {
                sku_p: "",
                product_id: "",
                quantity: "",
                location_id: "",
                location: "",
                pallet: "",
                box: "",
                batch: "",
                expiration: "",
                actions: "",
                parameters: ""
            }
            var t = this;
            if(this.process_type == "product"){
                Object.keys(t.product).forEach(function (key) {
                    var value = t.product[key];  
                    p[key] = value;
                });
                p.location = this.racks.find(x => x.id == p.location_id).name
                p.actions = JSON.parse(JSON.stringify(this.actions));
                p.sku_p = this.items_g.find(item => item.id == p.product_id).sku_p;
                p.parameters = p;

                if(!this.appointment.products.find(x => (x.sku_p == p.sku_p
                    && x.product_id == p.product_id 
                    && x.location_id == p.location_id 
                    && x.pallet == p.pallet 
                    && x.box == p.box
                    && (x.batch == p.batch || (x.batch == " " && (p.batch == null || p.batch == "" || p.batch == " ")) || (x.batch == "" && (p.batch == null || p.batch == "" || p.batch == " ")))
                    && (x.expiration == p.expiration || (x.expiration == " " && (p.expiration == null || p.expiration == "" || p.expiration == " ")) || (x.expiration == "" && (p.expiration == null || p.expiration == "" || p.expiration == " "))))))
                    this.appointment.products.push(p);
                else{
                    this.snackbar = {
                        visible: true,
                        color: 'warning',
                        text: this.$t('edit_appointment.responses.repeated')
                    }
                }
                this.$refs.formsku.reset();
            }
            else {
                this.addSkusByBoxOutcome();
            }
        },
        addSkusByBoxOutcome(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            // GET Origin Suppliers
            this.$http.get(this.$store.state.chronosApi + 'getStockDetails?product_id=' + this.product.product_id + '&location_id=' + this.product.location_id, {headers:{Authorization: lsToken }})
            .then((response) => {
                if(response.data !== '') {    
                    if(response.data.data.length > 0){
                        response.data.data[0].detailed_stocks.forEach(x => {
                            if(x.quantity <= 0) return;
                            var p = {
                                sku_p: x.product.sku,
                                product_id: x.product.id,
                                quantity: x.quantity,
                                location_id: this.product.location_id,
                                location: "",
                                pallet: x.pallet,
                                box: x.box,
                                batch: x.batch,
                                expiration: x.reference,
                                actions: "",
                                parameters: ""
                            };
                            
                            if(this.process_type == "box" && (x.batch == this.product.batch || (x.batch == null && this.product.batch == " ")) && x.pallet == this.product.pallet && (x.reference == this.product.expiration || (x.reference == null && this.product.expiration == " " && x.quantity > 0))){
                                p.location = this.racks.find(x => x.id == p.location_id).name
                                p.actions = JSON.parse(JSON.stringify(this.actions));
                                p.parameters = p;
                                
                                
                                if(!this.appointment.products.find(x => (x.sku_p == p.sku_p
                                    && x.product_id == p.product_id 
                                    && x.location_id == p.location_id 
                                    && x.pallet == p.pallet 
                                    && x.box == p.box
                                    && (x.batch == p.batch || (x.batch == " " && (p.batch == null || p.batch == "" || p.batch == " ")) || (x.batch == "" && (p.batch == null || p.batch == "" || p.batch == " ")))
                                    && (x.expiration == p.expiration || (x.expiration == " " && (p.expiration == null || p.expiration == "" || p.expiration == " ")) || (x.expiration == "" && (p.expiration == null || p.expiration == "" || p.expiration == " ")))))){
                                    console.log(x, p);
                                    console.log(p);
                                    this.appointment.products.push(p);
                                }
                                else{
                                    this.snackbar = {
                                        visible: true,
                                        color: 'warning',
                                        text: this.$t('edit_appointment.responses.repeated')
                                    }
                                }
                            }
                            else if (this.process_type == "pallet" && (x.batch == this.product.batch || (x.batch == null && this.product.batch == " ")) && (x.reference == this.product.expiration || (x.reference == null && this.product.expiration == " "))){
                                p.location = this.racks.find(x => x.id == p.location_id).name
                                p.actions = JSON.parse(JSON.stringify(this.actions));
                                p.parameters = p;
                                
                                if(!this.appointment.products.find(x => (x.sku_p == p.sku_p
                                    && x.product_id == p.product_id 
                                    && x.location_id == p.location_id 
                                    && x.pallet == p.pallet 
                                    && x.box == p.box
                                    && (x.batch == p.batch || (x.batch == " " && (p.batch == null || p.batch == "" || p.batch == " ")) || (x.batch == "" && (p.batch == null || p.batch == "" || p.batch == " ")))
                                    && (x.expiration == p.expiration || (x.expiration == " " && (p.expiration == null || p.expiration == "" || p.expiration == " ")) || (x.expiration == "" && (p.expiration == null || p.expiration == "" || p.expiration == " "))))))
                                    this.appointment.products.push(p);
                                else{
                                    this.snackbar = {
                                        visible: true,
                                        color: 'warning',
                                        text: this.$t('edit_appointment.responses.repeated')
                                    }
                                }
                            }
                        });
                                        
                        this.$refs.formsku.reset();
                        this.$store.state.loaderProps.visible = false;
                    }
                }
            }).catch((error) => {
                console.log(error);
            })
        },
        addService(){
            const lsToken = localStorage.getItem("access_token");
            this.$http.post(this.$store.state.chronosApi + 'appointment_services', this.service, {headers:{Authorization: lsToken }})
            .then((response) => {
                response
                // GET Origin Suppliers
                this.index();
                // this.$refs.services_form.reset();
            }).catch((error) => {
                console.log(error);
            })
        },
        deleteService(service){
            this.modal_delete_service = true;
            this.service_to_delete = service;
        },
        confirmDeleteService(){
            const lsToken = localStorage.getItem("access_token");

            this.$http.delete(this.$store.state.chronosApi + 'appointment_services/' + this.service_to_delete.id, {headers:{Authorization: lsToken }})
            .then((response) => {
                if(response.status == 204) {    
                    this.modal_delete_service = false;
                    this.index();
                }
                else this.appointment_services = [];
            }).catch((error) => {
                console.log(error);
            })
        },
        deleteSku(item){
            var index = this.appointment.products.findIndex(x => (x.sku_p == item.sku_p && x.quantity == item.quantity && x.location_id == item.location_id && x.pallet == item.pallet && x.box == item.box && x.batch == item.batch && x.expiration == item.expiration));
            if(index >= 0) this.appointment.products.splice(index, 1);
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('edit_appointment.responses.error')
                }
            }
        },
        uppercase() {
            if(this.product.batch != null){
                this.product.batch = this.product.batch.toUpperCase();
                this.product.batch = this.product.batch.replace(/\s/g,'');
            }

            if(this.product.expiration != null){
                this.product.expiration = this.product.expiration.toUpperCase();
                this.product.expiration = this.product.expiration.replace(/\s/g,'');
            }
        },
        diffTime(date1, date2){
            const total = parseInt((new Date(date2).getTime() - new Date(date1).getTime()) / 60000);
            if(total == 0) return 1;
            else return total;
        },
        uploadFilePetition(){
            if(this.$refs.form2.validate()){
                this.createInput2(this.inputFile2, "petitions");
            }
        },
        createInput2(file, type) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    if(type == "skus"){
                        /* handle a successful result */
                        this.readRows = result.split('\r\n');
                        this.sTHeaders2 = this.readRows[0].split(',')
                        this.readRows.shift()
                        this.processSkus();
                    }
                    else{
                         /* handle a successful result */
                        this.readRows2 = result.split('\r\n');
                        this.sTHeaders2 = this.readRows2[0].split(',')
                        this.readRows2.shift()
                        this.stepper2 = 2;
                    }
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        }
    },
    watch:{
        'clients': function() {
            this.index();
        },
        'product.product_id': function() {
            const lsToken = localStorage.getItem("access_token");

            if(this.movements.find(item => item.id == this.appointment.operation_id).format_type == "Salida"){
                this.$http.get(this.$store.state.chronosApi + 'getLocations/' + this.appointment.operation_id + '/' + this.appointment.client_id + '/?product_id='+this.product.product_id , {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200){
                        this.racks = [];
                        response.data.forEach(x =>{
                            this.racks.push({
                                id: x.id,
                                name: x.name
                            });
                        });
                    }
                });
            }

            if(this.movements.find(item => item.id == this.appointment.operation_id).format_type == "Salida" && this.product.product_id != "" && this.product.location_id != "" && this.product.product_id != undefined && this.product.product_id != null && this.product.location_id != undefined){
                this.batches = [];
                this.boxes = [];
                this.pallets = [];
                this.expirations = [];

                this.$http.get(this.$store.state.chronosApi + 'products_per_location/' + this.product.product_id + "/" + this.product.location_id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200){
                        response.data.forEach(x =>{
                            if(x.batch == null || x.batch == undefined) this.batches.push(" ")
                            else this.batches.push(x.batch);
                            if(x.reference == null || x.reference == undefined) this.expirations.push(" ")
                            else this.expirations.push(x.reference);
                            this.boxes.push(x.box.toString());
                            this.pallets.push(x.pallet_per_location.pallet.toString());
                        });
                    }
                });  
            }
        },
        'product.location_id': function() {
            const lsToken = localStorage.getItem("access_token");
            
            if(this.movements.find(item => item.id == this.appointment.operation_id).format_type == "Salida" && this.product.product_id != "" && this.product.location_id != "" && this.product.product_id != undefined && this.product.product_id != null && this.product.location_id != undefined){
                this.batches = [];
                this.boxes = [];
                this.pallets = [];
                this.expirations = [];
                this.quantities = [];
                this.load = true;

                this.$http.get(this.$store.state.chronosApi + 'products_per_location/' + this.product.product_id + "/" + this.product.location_id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200){
                        response.data = response.data.data;                        
                        response.data.forEach(x =>{
                            x = x.attributes;
                            if(x.quantity > 0){
                                if(x.batch == null || x.batch == undefined) this.batches.push(" ")
                                else this.batches.push(x.batch);
                                if(x.reference == null || x.reference == undefined) this.expirations.push(" ")
                                else this.expirations.push(x.reference);
                                this.boxes.push(x.box.toString());
                                if(x.pallet_per_location != null && x.pallet_per_location != undefined) this.pallets.push(x.pallet_per_location.pallet.toString());
                                else this.pallets.push(" ")
                                this.quantities.push(x.quantity);
                            }
                        });
                    }
                }).finally(() =>{
                    this.load = false;
                });  
            }
        },
        'product.box': function(){
            if(this.current_operation.format_type == "Salida" && this.process_type == "product" && this.product.product_id != null){
                var position = -1;
                for(let i = 0; i< this.batches.length; i++){
                    if(this.pallets[i] == this.product.pallet && this.boxes[i] == this.product.box  && this.quantities[i] >= this.product.quantity){
                        position = i;
                        return;
                    }
                }

                if(position == -1){
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('edit_appointment.responses.box')
                    }
                }

                this.product.pallet = this.pallets[position];
                this.product.batch = this.batches[position];
                this.product.expiration = this.expirations[position];
                if(this.product.quantity > this.quantities[position]){
                    this.product.quantity = 0;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('edit_appointment.responses.quantity') + this.quantities[position]
                    }
                }
            }
        },
        'product.batch': function(){
            if(this.current_operation.format_type == "Salida" && this.process_type == "product" && this.product.product_id != null){
                var position = -1;
                for(let i = 0; i< this.batches.length; i++){
                    if(this.pallets[i] == this.product.pallet && this.boxes[i] == this.product.box && this.batches[i] == this.product.batch  && this.quantities[i] >= this.product.quantity){
                        position = i;
                        return;
                    }
                }

                if(position == -1){
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('edit_appointment.responses.batch')
                    }
                }

                this.product.box = this.boxes[position];
                this.product.pallet = this.pallets[position];
                this.product.expiration = this.expirations[position];
                if(this.product.quantity > this.quantities[position]){
                    this.product.quantity = 0;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('edit_appointment.responses.quantity') + this.quantities[position]
                    }
                }
            }
        },
        'product.expiration': function(){
            if(this.current_operation.format_type == "Salida" && this.process_type == "product" && this.product.product_id != null){
                var position = -1;
                for(let i = 0; i< this.expirations.length; i++){
                this.product.quantity = parseInt(this.product.quantity);

                    if(this.pallets[i] == this.product.pallet && this.boxes[i] == this.product.box && (this.batches[i] == this.product.batch || this.product.batch == " ") && (this.expirations[i] == this.product.expiration || this.product.expiration == " ") && parseInt(this.quantities[i]) >= parseInt(this.product.quantity)){
                        position = i;
                        return;
                    }
                }

                if(position == -1){
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('edit_appointment.responses.expiration')
                    }
                }

                this.product.box = this.boxes[position];
                this.product.batch = this.batches[position];
                this.product.pallet = this.pallets[position];
                if(this.product.quantity > this.quantities[position]){
                    this.product.quantity = 0;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('edit_appointment.responses.quantity') + this.quantities[position]
                    }
                }
            }
        },
        'current_patent': function(){
            if(this.current_patent != null && this.current_patent != ""){
                var cb = null;
                cb = this.patents.find( x => x.id == this.current_patent);
                if(cb != null) this.appointment.patent_number = cb.patent;
            }     
        },
        'skus': function(){
            const lsToken = localStorage.getItem("access_token");

            this.$http.get(this.$store.state.chronosApi + 'getLocations/' + this.appointment.operation_id + '/' + this.appointment.client_id + '/?multiple=true', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data.forEach(x =>{
                        this.racks.push({
                            id: x.id,
                            name: x.name
                        });
                    });
                }
            }); 
        },
        'appointment.products': {
            handler: function (values){
                if(values.length > 0)
                    if(values[0].updated != undefined && values[0].index != undefined)
                        this.appointment.products[values[0].index].location = this.racks.find(x => x.id == values[0].location_id).name;
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
</style>