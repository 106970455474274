<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-card class="px-0 py-0 size">
                <v-card-text class="px-0 ">
                    <v-row class="px-5 py-0 mt-3" justify="center" align="center">
                        <v-col cols="8" align="center" justify="center">        
                            <h2 class="text-uppercase azul--text">{{ $t('upload_massive.upload_massive')}}</h2>
                        </v-col>
                    </v-row>
                    <v-card-text class="px-0">
                        <v-row class="px-5 py-5 mt-3">
                            <v-col cols="12" align="center" justify="center">    
                                <h2><v-icon>
                                    description
                                </v-icon>
                                    {{ $t('upload_massive.files') }}</h2>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 pb-3" justify="center" align="center">
                            <v-btn class="white--text" color="amarillo" @click="modal2 = true">
                                <v-icon small class="px-0 mx-0">add</v-icon>
                                {{$t('upload_massive.select_appointment_file')}}
                            </v-btn>
                        </v-row>
                    </v-card-text>
                    <br>
                    <v-row class="px-5 py-0" justify="end">
                        <v-col cols="6">
                            <v-btn class="right mx-3" color="white" @click="cancel" >
                                {{ $t('upload_massive.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="saveAppointments" >
                                {{ $t('upload_massive.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
        <v-dialog v-model="modal2" persistent max-width="90%">
            <v-card>
                <v-card-title class="justify-center">
                    <v-spacer></v-spacer>
                    {{ $t('upload_massive.upload_massive') }}
                    <v-spacer></v-spacer>
                    <v-btn color="amarillo" small icon @click="stepper2 = 1;modal2 = false"><v-icon small>close</v-icon></v-btn>
                </v-card-title>

                <v-card-text>
                    <v-stepper v-model="stepper2">
                        <v-stepper-header>
                            <v-stepper-step :complete="stepper2 > 1" step="1" color="amarillo">{{$t('upload_massive.select_appointment_file')}}</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="stepper2 > 2" step="2" color="amarillo">{{ $t('upload_massive.verify_data') }}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content step="1" class="mx-0 px-0">
                                <v-card flat class="mb-5">
                                    <v-form ref="form2" lazy-validation>
                                        <v-row class="mx-0 px-0" justify="center" align="center">
                                            <v-col cols="10">
                                                <v-file-input v-model="inputFile2" accept=".csv" color="amarillo" :label="$t('upload_massive.select_appointment_file')"></v-file-input>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn class="mx-0" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="stepper2 = 3">
                                                            <v-icon aria-hidden="false" color="white" small>
                                                                help
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('upload_massive.help') }}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card>
                                
                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="right mr-2 white--text" color="amarillo" @click="uploadFilePetition" >
                                        {{ $t('upload_massive.next') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-card flat class="mb-5">
                                    <v-card-text class="my-0 py-0">
                                        <p class="my-0 py-0">{{readRows2.length}} {{ $t('upload_massive.appointments_in_file') }}</p>
                                    </v-card-text>
                                    <v-simple-table v-if="this.readRows2.length > 0" fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th v-for="(th, key) in sTHeaders2" :key="key" class="text-left">{{ th }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in readRows2" :key="i">
                                                    <td v-for="(td, j) in item.split(',')" :key="j">{{ td }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>

                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="mx-3" text @click="stepper2 = 1; sTHeaders2 = []; readRows2 = []">{{ $t('upload_massive.go_back') }}</v-btn>
                                    <v-btn color="amarillo" class="white--text" @click="modal2 = false;">
                                        {{ $t('upload_massive.save_data') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="3">
                                    <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                                    <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                                    <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                                    <p class="py-0 my-0">4.- El archivo de citas debe ser formato CSV.</p> 
                                    <p>5.- Si el campo presenta la leyenda 'Opcional' este campo puede ir vacío.</p> 
                                    <p class="py-0 my-0">Columnas: </p>

                                    <v-simple-table fixed-header>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left" style="padding: 0 5px !important;">nombre_cliente</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">fecha_estimada</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">maniobras</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">comentarios</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">descripcion</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">bol</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">linea_transportista</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">placas</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">tipo_transporte</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">no_sello</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">modulacion_status</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">factura</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">pedimento</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">pedimento_cliente (Opcional)</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">patente</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">remesa</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">fecha_modulacion</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">skus</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">cantidades</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">ubicaciones</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">lotes (Opcional)</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">caducidades (Opcional)</th>
                                                    <th class="text-left" style="padding: 0 5px !important;">proveedor_origen_id</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Shop Online</td>
                                                    <td>2022-10-19</td>
                                                    <td>Crossdock Especial Entrada</td>
                                                    <td>comentarios</td>
                                                    <td>descripcion</td>
                                                    <td>Test1</td>
                                                    <td>linea de transporte</td>
                                                    <td>123HSJ</td>
                                                    <td>Contenedor 40</td>
                                                    <td>1203012</td>
                                                    <td>desaduanado</td>
                                                    <td>factejemplo</td>
                                                    <td>123456789123456</td>
                                                    <td></td>
                                                    <td>patente</td>
                                                    <td>remesa</td>
                                                    <td>2022-05-18</td>
                                                    <td>Producto 1</td>
                                                    <td>1000</td>
                                                    <td>BAHIA 01</td>
                                                    <td>lote1</td>
                                                    <td>Caducidad1</td>
                                                    <td>1</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>

                                    <v-row align="center" justify="center" class="my-3">
                                        <v-btn color="amarillo" class="white--text" @click="stepper2 = 1">
                                            {{ $t('products.go_back') }}
                                        </v-btn>
                                    </v-row>
                                </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modal2: false,
            inputFile2: [],
            readFile2: '',
            readRows2: [],
            stepper2: 1,
            sTHeaders2:''
        }
    },
    mounted(){
        this.$store.state.module = "headers.upload_massive";
    },
    methods: {
        uploadFilePetition(){
            if(this.$refs.form2.validate()){
                this.createInput2(this.inputFile2);
            }
        },
        createInput2(file) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    /* handle a successful result */
                    this.readRows2 = result.split('\r\n');
                    this.sTHeaders2 = this.readRows2[0].split(',');
                    this.readRows2.shift();
                    this.stepper2 = 2;
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },
        saveAppointments(){
            this.$store.state.loaderProps.visible = true
            const appointments = [];
            if(this.readRows2.length > 0){
                this.readRows2.forEach((x) => {
                    const num = x.split(',');
                    appointments.push({
                        'client_name': num[0],
                        'arrival_date': num[1], //fecha pago
                        'operation': num[2],
                        'commentary': num[3],
                        'description': num[4],
                        'container_number': num[5],
                        'transport_line': num[6],
                        'license_plate': num[7], //sku
                        'transport_type': num[8],
                        'seal_number': num[9],
                        'modulation_status': num[10],
                        'bill_of_lading': num[11],
                        'petition': num[12],
                        'entry_petition': num[13],
                        'patent_number': num[14],
                        'consignment_note': num[15],
                        'modulation_date': num[16],
                        'products': num[17],
                        'quantities': num[18],
                        'locations': num[19],
                        'batches': num[20],
                        'expirations': num[21],
                        'origin_supplier': num[22]
                    });
                })
                console.log(appointments)
                const lsToken = localStorage.getItem("access_token")
                this.$http.post(this.$store.state.chronosApi + 'specialMovements', { data: appointments}, { headers: { Authorization: lsToken } })
                .then((response) => {
                    console.log(response);
                    if(response.data.status === 200){
                        this.modal2 = false;
                        this.stepper2 = 1;
                        this.sTHeaders2 = [];
                        this.readRows2 = [];
                        this.inputFile2 = null;
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('upload_massive.responses.ok')
                        };
                    }
                })
                .catch((error) => {
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: "error " + error 
                    };
                })
                .finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('upload_massive.validations.select_file_first')
                };
            }
        },
        downloadInfoPetitionsFile(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token")
            this.$http.get(this.$store.state.chronosApi + 'massive-info', { headers: { Authorization: lsToken }, responseType: 'arraybuffer' })
            .then(response => {
                this.forceFileDownload('Movimientos_Especiales', response);
            })
            .catch(() => {
                this.$store.state.loaderProps.visible = false;
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;    
            })
        },
        forceFileDownload(str, response){
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            this.$store.state.loaderProps.visible = false
            link.href = url
            link.setAttribute('download', str + '.xlsx')
            document.body.appendChild(link)
            link.click()
        },
        cancel(){
            this.$router.go(-1);
        }
    },
}
</script>

<style lang="css">
.size{
    min-height: calc(100vh - 120px) !important;
    position:relative !important;
}
</style>