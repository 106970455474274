<template>
    <div class="py-0 mt-4 mb-8">
        <v-container class="mx-0 my-0" style="padding:0 !important; max-width: 100% !important">
            <v-card class="mt-1 pt-1 pb-10 px-0 size" style="margin-bottom: 35px !important" align="center">
                <v-row class="px-3 py-4 text-center" justify="center" align="center">
                    <v-col cols="6" class="py-1" justify="center" align="center">
                        <span justify="center" class="pr-1 text-uppercase azul--text font-weight-bold">
                            {{ $t('pending_out.check_out') }} 
                        </span> 
                    </v-col>
                </v-row>
                <v-col cols="6" justify="start" align="start" class="px-0">
                    <v-row>
                        <v-col cols="6" class="py-1">
                            <span justify="center" class="pr-1 azul--text text--uppercase font-weight-bold" style="font-size:18px">
                                {{ $t('pending_out.general_data') }} 
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('pending_out.appointment') }}: </span> {{ this.id }} </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('pending_out.client') }}: </span> {{ this.appointment.client.name }} </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('pending_out.estimated_date_of_arriving') }}: </span> {{ this.appointment.arrival_date }} </v-col>
                    </v-row>
                    <v-row>
                         <v-col v-if="$store.state.language == 'en'" cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.operation') }}: </span> {{ this.appointment.operation.description1 }} </v-col>
                         <v-col v-if="$store.state.language == 'es'" cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('process_pendings.operation') }}: </span> {{ this.appointment.operation.description }} </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('pending_out.container') }}: </span> {{ this.appointment.container_number }} </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('pending_out.description') }}: </span> {{ this.appointment.description }} </v-col>
                    </v-row>
                    <v-row>
                         <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('pending_out.comments') }}: </span> {{ this.appointment.commentary }} </v-col>
                    </v-row>
                </v-col>
                <v-col class="py-5 px-0" cols="6" justify="start" align="start">
                    <v-row>
                        <v-col cols="12" class="py-1">
                            <span justify="center" class="pr-1 azul--text font-weight-bold" style="font-size:18px">
                                {{ $t('pending_out.products') }} 
                            </span>
                        </v-col>
                    </v-row>
                    <datatable :props="{headers:headers_sku, items: appointment.products}" @methodHandler="methodHandler"></datatable>   
                </v-col>
                <v-col class="py-5 px-0" cols="6" justify="start" align="start">
                    <v-row align="center" justify="center">
                        <v-col class="mb-1" cols="4" align="center" justify="center">
                            <v-btn color="amarillo" outlined class="white--text" @click="downloadPdf" v-permission:any="'n_pdf.show|n_pdf.index|i_pdf.show|i_pdf.index'" style="width:100%"><v-icon small class="pr-1">file_download</v-icon> {{ $t('pending_out.download_pdf') }}</v-btn>
                        </v-col> 
                        <v-col class="mb-1" cols="5" align="center" justify="center">
                            <v-btn color="amarillo" outlined class="white--text" @click="getLabel" v-permission:any="'n_pdf.show|n_pdf.index|i_pdf.show|i_pdf.index'" style="width:100%"><v-icon small class="pr-1">label</v-icon> {{ $t('pending_out.download_label') }}</v-btn>
                        </v-col>  
                        <v-col class="mb-1" cols="3" align="center" justify="center">
                            <v-btn color="amarillo" class="white--text" @click="update" style="width:100%"><v-icon small class="pr-2">check</v-icon> {{ $t('pending_out.complete') }}</v-btn>
                        </v-col>    
                    </v-row>
                </v-col>
            </v-card>
            <bottom-bar ref="bottombar"></bottom-bar>
        </v-container>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <v-btn color="white" text @click="snackbar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>

import JSZip from 'jszip';
import FileSaver from 'file-saver';

export default {
    computed: {
        headers_sku: function(){
            return [
                {text: this.$t('process_pendings.sku'), value: 'sku_p', class: 'text-uppercase'},
                // {text: this.$t('edit_appointment.description'), value: 'descripcion', class: 'text-uppercase'},
                {text: this.$t('process_pendings.pieces'), value: 'quantity', class: 'text-uppercase'},
                {text: this.$t('process_pendings.location'), value: 'location', class: 'text-uppercase'},
                {text: this.$t('process_pendings.pallet'), value: 'pallet', class: 'text-uppercase'},
                {text: this.$t('process_pendings.box'), value: 'box', class: 'text-uppercase'},
                {text: this.$t('process_pendings.batch'), value: 'batch', class: 'text-uppercase'},
                {text: this.$t('process_pendings.expiration'), value: 'expiration', class: 'text-uppercase'},
            ];
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                timeout: 2000,
                text: ''
            },
            
            appointment:{
                // General data
                client_id: "",
                client: {
                    name:""
                },
                arrival_date: "",
                eta: "",
                operation_id: "",
                operation: {
                    description: "",
                    description1: ""
                },
                container_number: "",
                description: "",
                commentary: "",
                additional_services: "",
                purchase_order: "",

                // Transport details
                carrier_line: "",
                drivers_name: "",
                license_plate: "",
                box: "",
                tractor: "",
                consignment_note: "",
                cargo_type: "",
                transport_type: "",
                
                // Foreign trade
                origin_supplier_id: "",
                patent_number: "",
                modulation_status: "",
                modulation_date: "",
                modulation_time: "",
                bill_of_lading: "",
                remittance: "",
                petition: "",
                notices: "",
                entry_petition: "",
                
                packing_type: "",
                unit_good_conditions: 1,
                unit_damage_specifications: "",
                seal_good_conditions: 1,
                
                products: []
            },
            movements: [],
            items_g: [],
            racks: []
        }
    },
    mounted(){
        this.$store.state.module = "headers.process_output_operation";
        this.index();
    },
    methods: {
        back(){
            this.$router.push({ name: 'Process Index' });
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.$http.get(this.$store.state.chronosApi + 'appointments/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    // products and Details
                    response.data = response.data.data.attributes;
                    response.data.detail_orders.forEach(x => {
                        x = x.attributes;
                        var p = {};
                        if(x.location != null){
                            p = {
                                sku_p: x.product.sku,
                                product_id: x.product.id,
                                quantity: x.real ?? x.expected,
                                location_id: x.location.id,
                                location: x.location.name,
                                pallet: x.pallet,
                                box: x.box,
                                batch: x.batch,
                                expiration: x.expiration,
                                actions: "",
                                parameters: ""
                            }
                        }
                        else {
                            p = {
                                sku_p: x.product.sku,
                                product_id: x.product.id,
                                quantity: x.real ?? x.expected,
                                location_id: "---",
                                location: "---",
                                pallet: x.pallet,
                                box: x.box,
                                batch: x.batch,
                                expiration: x.expiration,
                                actions: "",
                                parameters: ""
                            }
                        }
                                    
                        this.appointment.products.push(p);
                    });

                    // General Data
                    this.$store.state.clientId              = response.data.client.id ?? null;
                    this.appointment.client_id              = response.data.client.id ?? null;
                    this.appointment.client                 = response.data.client ?? null;
                    this.appointment.operation              = response.data.operation ?? null;
                    this.appointment.arrival_date           = response.data.arrival_date.substring(0, 10) ?? null;
                    this.appointment.is_national            = response.data.is_national;
                    if(response.data.is_national == true && response.data.eta != undefined && response.data.eta != null)
                        this.appointment.eta                = response.data.eta.substring(0, 10);
                    this.appointment.operation_id           = response.data.operation.id ?? null;
                    this.appointment.additional_services    = response.data.additional_services ?? null;
                    this.appointment.purchase_order         = response.data.purchase_order ?? null;
                    this.appointment.container_number       = response.data.container_number ?? null;
                    this.appointment.description            = response.data.description ?? null;
                    this.appointment.commentary             = response.data.commentary ?? null;
 
                    // Transport Details
                    this.appointment.carrier_line           = response.data.carrier_line ?? null;
                    this.appointment.drivers_name           = response.data.drivers_name ?? null;
                    this.appointment.license_plate          = response.data.license_plate ?? null;
                    this.appointment.tractor                = response.data.tractor ?? null;
                    this.appointment.consignment_note       = response.data.consignment_note ?? null;
                    this.appointment.cargo_type             = response.data.cargo_type ?? null;
                    this.appointment.transport_type         = response.data.transport_type ?? null;

                    // Foreign Trade
                    if(response.data.is_national == false){
                        this.appointment.origin_supplier_id     = response.data.origin_supplier_id ?? null;
                        this.appointment.patent_number          = response.data.patent_number ?? null;    
                        this.appointment.modulation_status      = response.data.modulation_status ?? null;
                        this.appointment.modulation_date        = response.data.modulation_date ?? null;
                        this.appointment.modulation_time        = response.data.modulation_time ?? null;
                        this.appointment.bill_of_lading         = response.data.bill_of_lading ?? null;
                        this.appointment.remittance             = response.data.remittance ?? null;
                        this.appointment.petition               = response.data.petition ?? null;
                        this.appointment.notices                = response.data.notices ?? null;
                        this.appointment.entry_petition         = response.data.entry_petition ?? null;
                    }
                }

                this.$http.get(this.$store.state.chronosApi + 'getLocations/' + response.data.operation.id + '/' + response.data.client.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200){
                        response.data.forEach(x =>{
                            this.racks.push({
                                id: x.id,
                                name: x.name
                            });
                        });
                    }
                });       
            })
            .catch((response) => {              
                this.$store.state.loaderProps.visible = false;
                console.log(response)
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        update(){
            if(this.appointment.products.length == 0) return;
            // var uncheckedSku = false;
            // this.appointment.products.forEach((x) =>{
            //     if(x.checked == false) uncheckedSku = true;  
            // });

            // if(uncheckedSku && this.appointment.is_national == 0) {
            if(this.appointment.is_national == 0) {
                this.appointment['newStatus'] = "Pendiente Anexo24";
                this.appointment.status = "Pendiente Anexo24";
                this.appointment.gate_id = null;
            }
            else{
                this.appointment['newStatus'] = "Completo";
                this.appointment.status = "Completo"
                this.appointment.gate_id = null;
            }

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.$http.put(this.$store.state.chronosApi + 'setStatus/' + this.$route.params.id, this.appointment, {headers: {Authorization: lsToken}})
            .then((response) => {   
                if(response.status === 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('pending_out.responses.correct')
                    } 
                    this.$router.push({ name: 'OperationPendings' });
                }
            }).catch((error) => {
                console.log(error);
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('pending_out.responses.update')
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });    
        },
        downloadPdf(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'appointments/' + this.id + "/pdf", {headers: {Authorization: lsToken}, responseType: 'arraybuffer'})
            .then((response) => {
                this.forceFileDownloadHistory(response, this.id)
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        forceFileDownloadHistory(response, id){
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            this.$store.state.loaderProps.visible = false
            link.href = url
            link.setAttribute('download', 'Appointment - ' + id + '.pdf')
            document.body.appendChild(link)
            link.click()
        },
        async getLabel(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const response = await this.$http.get(this.$store.state.chronosApi + 'appointments/pallets_information/' + this.id, {headers: {Authorization: lsToken}})
            var zip = new JSZip();
            
            if(response.data.status != 200){
                this.$store.state.loaderProps.visible = false;
                return;
            }

            for(var i = 1; i <= response.data.pallets.length; i++){
                const file = await this.getFileLabel(response.data.appointment, response.data.pallets[i-1]);
                zip.file(response.data.appointment.id + '_' + i + '.png', file.data);
            }

            zip.generateAsync({type:"blob"}).then(function(content) {
                FileSaver.saveAs(content, "Labels_appointment_" + response.data.appointment.id + ".zip")
            }, function(err){
                console.log(err)
            })

            this.$store.state.loaderProps.visible = false;
        },
        async getFileLabel(appointment, pallet){
            const lsToken = localStorage.getItem("access_token");
            const file = await this.$http.get(this.$store.state.chronosApi + 'appointments/get_label/png?pallet=' + pallet.pallet + '&container_number=' + appointment.container_number + '&name=' + appointment.name + '&arrival_date=' + appointment.arrival_date + '&id=' + appointment.id + '&sku=' + pallet.sku + '&batch=' + (pallet.batch ?? 'null') + '&reference=' + (pallet.reference ?? 'null') + '&operation_id=' + appointment.operation_id, {headers: {Authorization: lsToken}, responseType:'blob'});
            return file;
        }
    },
    watch:{
        'clients': function() {
            this.index();
        },
    }
}
</script>