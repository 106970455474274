<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('edit_kit.edit') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('edit_kit.general_data')}}</label>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" disabled v-model="kit.sku" :label="$t('edit_kit.sku')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="kit.description" :label="$t('edit_kit.description')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="py-0" disabled v-model="kit.client.id" :items="customers" item-value="id" item-text="name" :label="$t('edit_kit.customer')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-form  ref="products" lazy-validation @submit.prevent="addProduct"> 
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('edit_kit.products')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="3" class="py-0">
                                <v-autocomplete class="py-0" v-model="product.product_id" :items="products" item-value="id" item-text="sku" :label="$t('edit_kit.product')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-text-field class="py-0" v-model="product.quantity" :label="$t('edit_kit.quantity')" :rules="rules_number"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0" justify="center">
                            <v-col cols="6" justify="end" align="end">
                                <v-btn class="right white--text" outlined color="amarillo" @click="addProduct" >
                                    {{ $t('edit_kit.add') }}
                                </v-btn>
                            </v-col>
                        </v-row> 
                    </v-form>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0">
                        <datatable class="custom_table_class" :props="{headers: headers, items: kit.kit_detail}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>   
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('edit_kit.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save">
                                {{ $t('edit_kit.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        rules_number(){ return [
                v => v == null || /^(|\d)+$/.test(v) || this.$t('edit_appointment.validations.numeric'),
                v => !!v || this.$t('create_appointment.validations.required_field')
        ]},
        headers(){
            return [
                { text: this.$t('edit_kit.sku'), align: "center", value: 'attributes.product.sku', class: 'text-uppercase' },
                { text: this.$t('edit_kit.quantity'), align: "center", value: 'attributes.quantity', class: 'text-uppercase' },
                { text: '', align: "center", value: 'actions', class: 'text-uppercase', width:'8%' },
            ];
        } 
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            actions: [
                {
                    name: this.$t('edit_kit.remove'),
                    icon: {
                        color: 'error',
                        icon: 'close'
                    },
                    method: 'remove',
                },
            ],
            kit: {
                client: "",
                kit_detail: []
            },
            customers: [],
            products: [],
            product: {
                product_id: '',
                sku: '',
                quantity: '',
                actions: ''
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.edit_kit";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.customers = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.$http.get(this.$store.state.chronosApi + 'clientsIndex/2', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x =>{
                    this.customers.push({id: x.attributes.id, name: x.attributes.name});
                });
            });
            this.$http.get(this.$store.state.chronosApi + 'products', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x =>{
                    this.products.push({id: x.attributes.id, sku: x.attributes.sku});
                });
            });

            this.$http.get(this.$store.state.chronosApi + 'kits/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.kit = {
                    sku: response.data.data.attributes.sku,
                    description: response.data.data.attributes.description,
                    client: {
                        id: response.data.data.attributes.client.id
                    },
                    kit_detail: response.data.data.attributes.kit_detail
                }

                this.kit.kit_detail.forEach(x => {
                    x.parameters = {id: x.attributes.product.id, qty: x.attributes.quantity}
                    x.actions = JSON.parse(JSON.stringify(this.actions));
                })
                
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
            this.$store.state.loaderProps.visible = false;
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.kit.kit_detail.forEach(x => {
                delete x.parameters;
            });
            
            this.$http.put(this.$store.state.chronosApi + 'kits/' + this.id, this.kit, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status == 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('edit_kit.updated')
                    }

                    this.$router.push({ name: 'KitsIndex' });
                }
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        addProduct(){
            if(this.product.product_id != "" && this.product.product_id != undefined){
                this.product.actions = JSON.parse(JSON.stringify(this.actions));
                this.product.sku = this.products.find(x => x.id == this.product.product_id).sku;
                

                var product_to_add = JSON.parse(JSON.stringify(
                    {
                        attributes: {
                            product: {
                                sku: this.product.sku,
                                id: this.product.product_id,
                            },
                            quantity: this.product.quantity,
                            actions: this.product.actions,
                            parameters: {id: this.product.product_id, qty: this.product.quantity}, //always put the last index 
                        },
                        parameters: {id: this.product.product_id, qty: this.product.quantity},
                        actions: JSON.parse(JSON.stringify(this.actions))
                    }
                ))
                this.kit.kit_detail.push(product_to_add);

                this.$refs.products.reset();
            } 
        },
        remove(item){
            var index = this.kit.kit_detail.indexOf(this.kit.kit_detail.find(x => x.product.id == item.id && x.product.quantity == item.qty));
            this.kit.kit_detail.splice(index, 1);
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>