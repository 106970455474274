<template >
    <div>
        <v-container fluid style="background-color: #f5f5f5; max-width:100% !important" class="py-0">
            <sidebar-menu ref="sidebarmenu"></sidebar-menu>
            <nav-bar ref="navbar"></nav-bar>
            <br>
            <transition name="fade" mode="out-in">
                <v-main class="pt-9 my-0 mx-0">
                    <router-view @updateFile="updateJSONFile"></router-view>
                </v-main>
            </transition>
            <v-row class="bottom" align="center">
                <v-img height="27px" width="100px" max-width="100px" src="img/logo-grupomerca.png"></v-img>
                <p style=" font-size:12px; color: black !important;" class="py0- my-0"> | {{ $t('footer.brand') }}</p>
                <v-spacer></v-spacer>
            </v-row>
            <loader></loader>
        </v-container>
    </div>
</template>

<script>
  export default {
    computed: {
        
    },
    name: 'dashboard',
    data(){
        return {}
    },
    methods: {
        updateJSONFile(){
            // this.$refs.navbar
            this.$refs.navbar.saveDataInLS()
        }
    }
};
</script>

<style lang="css">
.rounded-lg{
    border-radius: 8px !important;
}
.rounded-lg-top{
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
}
.rounded-lg-bottom{
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}
.v-application--wrap{
    background-color: #f5f5f5;
}
.bottom{
    position: absolute;
    bottom: 5px !important;
    width: 100%;
    margin: 5px 0 !important;
    margin-left: 190px !important;
}
</style>
