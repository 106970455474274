<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col sm="3" md="3" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('fulfillment_leirem_pendings.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="9" align="end" class="py-0">
                    <v-row align="end" justify="end" class="px-1 py-3">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="right white--text mr-2" style="max-width: 40px !important; min-width: 40px !important" color="amarillo" @click="downloadMultiplePickingList">
                                    <v-icon class="material-icons-outlined" small>downloading</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('fulfillment_leirem_pendings.download_picking_list')}}</span>
                        </v-tooltip>
                    </v-row>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <v-data-table class="px-0 my-2"
                                no-results-text="No hay resultados"
                                :headers="headers"
                                :items="props.items"
                                :search="props.search"
                                calculate-widths
                                :footer-props="{
                                    'items-per-page-text': this.$t('datatable.shown'),
                                    showFirstLastPage: true,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2} ' + this.$t('datatable.results'),
                                    'show-current-page': true,
                                    'items-per-page-options': typeof props.rowsPP === 'undefined' ? [10, 25, 50, 100] : props.rowsPP}"
                                :page="1"
                                :mobile-breakpoint="0"
                                item-key="id"
                                show-select
                                v-model="selected"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                                        <template v-slot:activator="{ on }">
                                            <v-btn x-small v-if="!('link' in action)" icon @click="methodHandlerV2(action.method, item.parameters)" :disabled="action.method === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                            <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ action.name }}</span>
                                    </v-tooltip>
                                </template>
                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                    {{ $t('fulfillment_leirem_pendings.modal.delete') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('fulfillment_leirem_pendings.modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('fulfillment_leirem_pendings.no') }}</v-btn>
                            <v-btn color="error" @click="deleteOrder">
                                {{ $t('fulfillment_leirem_pendings.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalDetail" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('fulfillment_leirem_pendings.order_details.header') }} {{ this.orderToShow.uniqueOrderNumber }}
                            </h4>
                        </v-row>
                    </v-card-title>
                    
                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_leirem_pendings.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.order_number") }}</label> <label>{{ this.orderToShow.uniqueOrderNumber }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.date") }}</label> <label>{{ this.orderToShow.createdAt }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.shipping_service") }}</label> <label>{{ this.orderToShow.shippingService }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.invoice") }}</label> <label>{{ this.orderToShow.invoice }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.client != null"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.client") }}</label> <label>{{ this.orderToShow.client.name }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.cod") }}</label> <label>{{ this.orderToShow.total }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.buyer") }}</label> <label>{{ this.orderToShow.consignee.name }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.state") }}</label> <label>{{ this.orderToShow.consignee.state }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.municipality") }}</label> <label>{{ this.orderToShow.consignee.municipality }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.neighborhood") }}</label> <label>{{ this.orderToShow.consignee.neighborhood }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.street") }}</label> <label>{{ this.orderToShow.consignee.street }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillment_leirem_pendings.zip_code") }}</label> <label>{{ this.orderToShow.consignee.zipcode }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_leirem_pendings.products') }}</label></div>
                    <span v-if="orderToShow.shipmentProductDetail != null">
                        <div v-for="(product, i) of orderToShow.shipmentProductDetail.data" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.attributes.product.sku }}</label> <label>{{ product.attributes.quantity }}</label></div>
                    </span>

                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDetail = false">{{ $t('fulfillment_leirem_pendings.go_back') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="orderSupply(orderToShow)">
                                {{ $t('fulfillment_leirem_pendings.supply') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalAlreadyOpen" persistent max-width="90%">
                <v-card >
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h3>{{ $t('fulfillment_leirem_pendings.page_already_open_1') }} Leirem {{ $t('fulfillment_leirem_pendings.page_already_open_2') }}</h3>
                            </v-col>
                        </v-row>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
        </v-container>   
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
import FileSaver from 'file-saver';

export default {
	computed: {
        headers(){
            return [
                { text: this.$t('fulfillment_leirem_pendings.order'), value: 'uniqueOrderNumber' },
                { text: this.$t('fulfillment_leirem_pendings.customer'), value: 'client' },
                { text: this.$t('fulfillment_leirem_pendings.consignee'), value: 'consigneeName' },
                { text: this.$t('fulfillment_leirem_pendings.date'), value: 'createdAt' },
                { text: this.$t('fulfillment_leirem_pendings.time'), value: 'time' },
                { text: this.$t('fulfillment_leirem_pendings.shipping_service'), value: 'externalShippingService' },
                { text: this.$t('fulfillment_leirem_pendings.actions'), value: 'actions'},
            ];
        },
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
    },
    data(){
        return {
            sending_data: false,
            props: {
                search: '',
                items: [
                ],
            },
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            responses: [],
            selected: [],
            modalDelete: false,
            modalDetail: false,
            modalAlreadyOpen: false,
            modalSupply: false,
            modalUpdate: false,
            modalImport: false,
            orderToUpdate: '',
            orderToDelete: '',
            orderToShow: '',
            actions:[
                {
                    name: this.$t('fulfillment_leirem_pendings.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                },
                {
                    name: this.$t('fulfillment_leirem_pendings.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'mode_edit'
                    },
                    link: '',
                },
                {
                    name: this.$t('fulfillment_leirem_pendings.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                },
                {
                    name: this.$t('fulfillment_leirem_pendings.supply'),
                    icon: {
                        color: 'accent',
                        icon: 'send'
                    },
                    link: '',
                }
            ],
            shipment: {
                client_id: "",
                warehouse_id: this.$store.state.warehouseId,
                unique_order_number: "",
                shipping_service: "",
                tracking_number: "",
                consignee_name: "",
                label: [],
                attached_files: [],
                products: []
            },
            bulkProps: {
                items: [],
                search: ""
            },
            parcels: [
                "99MINUTOS", "DHL", "ESTAFETA", "FEDEX", "HERMES", "JTEXPRESS", "MERCADO COLECTA", "MERQ", "PAQUETEXPRESS", "QUIKEN",  "UPS" , "YEGO"
            ],
            customers: [],
            products: [],
            product: {
                sku: "",
                quantity: ""
            },
            bulk: {
                box: "",
                quantity: ""
            },
            boxes: [],
            selected_box: ""
        }
    },
    async mounted(){
        this.$store.state.module = "headers.fulfillment_leirem_pendings";
        await this.checkIfCanOpen("leirem");
        
        if(this.modalAlreadyOpen == false) this.index();
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        methodHandlerV2(object, params){
            this[object](params)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.props.items = [];
            this.selected = [];
            this.boxes = [];
            this.modalBulk = false;
            this.modalBulkResponses = false;
            this.modalSupply = false;

            this.$http.get(this.$store.state.chronosApi + 'fulfillment/leirem/pending', {headers: {Authorization: lsToken}})
            .then((response) => {
                // Get Shippment_services
                this.$store.state.loaderProps.visible = true;
                response.data.data.forEach( x => { 
                    x = x.attributes;

                    x.time = x.createdAt.substring(11,17)
                    x.createdAt = x.createdAt.substring(0,10);
                    x['actions'] = JSON.parse(JSON.stringify(this.actions));
                    x['actions'][1].link = "/pending-shipments/" + (x.cod == false? "prepaid" : "cod") + "/edit/" + x.id;
                    x['actions'][3].link = "/shipments_leirem/supply/" + x.id;
                    x.parameters = x;
                    this.props.items.push(x);
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        showDetails(order){
            this.orderToShow = order;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/getOrderInformation/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data.attributes;
                this.$store.state.loaderProps.visible = false;
                this.orderToShow = response.data;
                this.modalDetail = true;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        askDelete(order){
            this.modalDelete = true;
            this.orderToDelete = order;
        },
        deleteOrder(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'external_shipment/delete/' + this.orderToDelete.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response;
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('fulfillment_leirem_pendings.deleted')
                }
                this.modalDelete = false;
                this.index();
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        checkIfCanOpen(parcel){
            localStorage.open_pages = Date.now();
            window.addEventListener('storage', (e) => {
                if(e.key == "open_pages") localStorage.setItem("page_available_" + parcel, Date.now())
                if(e.key == "page_available_" + parcel) this.modalAlreadyOpen = true;              
            }, false);
        },
        downloadMultiplePickingList: async function (){
            if(this.selected.length == 0) return;
            this.$store.state.loaderProps.visible = true;

            var shipments_ids = "";
            this.selected.forEach(image => {
                shipments_ids += image.id + ","; 
            })
            shipments_ids = shipments_ids.substring(0, shipments_ids.length - 1);

            const response = await this.getPickingList(shipments_ids);

            FileSaver.saveAs(response, "picking_list.pdf");
            this.$store.state.loaderProps.visible = false;
        },
        getPickingList: async function(ids){
            const lsToken = localStorage.getItem("access_token");

            const {data} = await this.$http.get(this.$store.state.chronosApi + '/shipment/specialPickeoList?shipments='+ ids, {headers: {Authorization: lsToken}, responseType:'blob' })
            return data;
        }
    },
    watch:{
    }
}
</script>

<style lang="scss">
</style>