<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <!-- <v-row class="px-5 pt-5" justify="center" align="center">
                    <v-col cols="4" align="start" justify="start">    
                        <h3 class="azul--text text-uppercase">{{ $t('product_details.details') }}</h3>
                    </v-col>
                    <v-col cols="2" align="end" justify="end">
                        <v-btn class="right white--text" color="amarillo" @click="cancel" >
                            {{ $t('product_details.go_back') }}
                        </v-btn>
                    </v-col>
                </v-row> -->
                <!-- <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.sku')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.sku }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.description')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.description }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.client')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.client.name }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.category')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.category.name }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.refill_alert')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.storage_policy }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.weight')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.weight }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.product_national')}}</label>
                                    <v-spacer></v-spacer>
                                    <label v-if="product.description == true"> {{ this.$t('product_details.yes') }} </label>
                                    <label v-else> {{ this.$t('product_details.no') }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-3">
                                <datatable :props="{ headers:headers, items: locations }" @methodHandler="methodHandler"></datatable>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text> -->
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        headers(){
            return [
                { text: this.$t('product_details.location'), align: "center", value: 'location.name', class: 'text-uppercase' },
                { text: this.$t('product_details.pallet'), align: "center", value: 'pallet_per_location.pallet', class: 'text-uppercase' },
                { text: this.$t('product_details.box'), align: "center", value: 'box', class: 'text-uppercase' },
                { text: this.$t('product_details.quantity'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('product_details.lot'), align: "center", value: 'batch', class: 'text-uppercase' },
                { text: this.$t('product_details.expiration'), align: "center", value: 'reference', class: 'text-uppercase' },
                { text: this.$t('product_details.date'), align: "center", value: 'created_at', class: 'text-uppercase' },
                { text: this.$t('product_details.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            locations: [],
            product: {
                client: '',
                category: ''
            },
            actions: [
                {
                    name: this.$t('product_details.relocate'),
                    icon: {
                        color: 'accent',
                        icon: 'edit_location'
                    },
                    method: 'relocate',
                    permission: "product.show"
                }
            ]
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.product_details";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            
            //get Product data
            this.$http.get(this.$store.state.chronosApi + 'products/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.product = response.data;
                this.locations = response.data.detailed_stock;
                this.locations.forEach(x => {
                    x.created_at = x.created_at.split('T')[0];
                    x.parameters = x.id;
                    x['actions'] = JSON.parse(JSON.stringify(this.actions));
                });
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        save(){
        },
        relocate(id){
            console.log(id);
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>