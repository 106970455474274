<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 size pt-3">
                <v-card-text class="px-0 py-0">
                    <v-row class="px-7 py-0 ml-1">
                        <v-col class="pt-3 pb-0" cols="2">
                            <v-autocomplete class="mt-0 py-0 my-0" style="max-height: 40px !important; min-height: 40px !important;" outlined v-model="anio" :items="anios" :label="$t('customers_dashboard.year')"></v-autocomplete> 
                        </v-col>
                        <v-col class="pt-3 pb-0" cols="3">
                            <v-autocomplete class="mt-0" outlined v-model="currentMonth" :items="monthsNames" :label="$t('customers_dashboard.month')"></v-autocomplete> 
                        </v-col>
                    </v-row>
                    <v-row class="my-0 py-0 mx-0 px-0" align="center">
                        <v-col class="px-0 mx-0 my-0 py-0" cols="4">
                            <v-card-title class="justify-space-between py-2 px-7 ml-7">
                                <v-col class="py-0 my-0">
                                    <span class="text-uppercase subtitle-1 font-weight-bold" style="font-size:15px !important">{{ $t('customers_dashboard.orders_by_status') }}</span>
                                </v-col>
                            </v-card-title>
                            <v-card-text class="py-0 ml-3">
                                <doughnut-chart  :chart-data="ordersByStatus" :options="doughnutOptions" :height="300"></doughnut-chart>
                            </v-card-text>
                        </v-col>
                        <v-col cols="8" class="py-0">
                            <v-col cols="12" class="mx-0 px-0">
                                <v-card-title class="justify-space-between pb-2 pt-0 pl-12 mx-0">
                                    <v-row>
                                        <span class="text-uppercase subtitle-1 font-weight-bold mt-7" style="font-size:15px !important">{{ $t('customers_dashboard.orders_by_state') }}</span> 
                                    </v-row>
                                </v-card-title>
                                <v-card-text class="py-0 pl-11">
                                    <bar-stacked-chart :chart-data="ordersByStatusAndState" :options="barOptions" :height="300"></bar-stacked-chart>
                                </v-card-text>
                            </v-col>
                        </v-col>
                    </v-row>

                    <v-row class="my-0 py-0 mx-0 px-0" align="center">
                        <v-col class="px-0 mx-0 my-0 py-0" cols="4">
                            <v-col cols="12" class="mx-0 px-0">
                                <v-card-title class="justify-space-between pb-2 pt-0 pl-12 mx-0">
                                    <v-row>
                                        <span class="text-uppercase subtitle-1 font-weight-bold mt-7" style="font-size:15px !important">{{ $t('customers_dashboard.most_sold_products') }}</span> 
                                        <v-btn x-small class="mx-3 mt-7" @click="back('mostSold')"><v-icon x-small>arrow_back_ios</v-icon></v-btn>
                                        <v-btn x-small class="mt-7" @click="next('mostSold')"><v-icon x-small>arrow_forward_ios</v-icon></v-btn>
                                    </v-row>
                                </v-card-title>
                                <v-card-text class="py-0 pl-11">
                                    <bar-stacked-chart :chart-data="mostSoldProducts" :options="barWithoutHeadersOptions" :height="300"></bar-stacked-chart>
                                </v-card-text>
                            </v-col>
                        </v-col>
                        <v-col cols="8" class="py-0">
                            <v-col cols="12" class="mx-0 px-0">
                                <v-card-title class="justify-space-between pb-2 pt-0 pl-12 mx-0">
                                    <v-row>
                                        <span class="text-uppercase subtitle-1 font-weight-bold mt-7" style="font-size:15px !important">{{ $t('customers_dashboard.average_days_to_delivery_by_state') }}</span> 
                                    </v-row>
                                </v-card-title>
                                <v-card-text class="py-0 pl-11">
                                    <bar-stacked-chart :chart-data="deliveryDaysByState" :options="barWithoutHeadersOptions" :height="300"></bar-stacked-chart>
                                </v-card-text>
                            </v-col>
                        </v-col>
                    </v-row>

                    <v-row class="my-0 py-0 mx-0 px-0" align="center" v-if="this.$store.state.clientUserIsCod == true">
                        <v-col cols="6" class="px-0 mx-0 py-0">
                            <v-col class="px-0 mx-0 my-0 py-0">
                                <v-card-title class="justify-space-between pb-2 pt-0 px-0 ml-11">
                                    <v-col class="py-0 my-0">
                                        <span class="text-uppercase subtitle-1 font-weight-bold">{{ $t('dashboard.cod_collection') }}</span>
                                    </v-col>
                                </v-card-title>
                                <v-card-text class="py-0 ml-11">
                                    <v-row align="center" justify="center">                                
                                        <doughnut-chart :height="300" style="width: 90% !important; margin-left: -100px !important;" :chart-data="codByOrders" :options="doughnutOptions"></doughnut-chart>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-col>

                        <v-col cols="6" class="px-0 mx-0 py-0">
                            <v-col class="px-0 mx-0 my-0 py-0">
                                <v-card-title class="justify-space-between pb-2 pt-0 px-0 ml-11">
                                    <v-col class="py-0 my-0">
                                        <span class="text-uppercase subtitle-1 font-weight-bold">{{ $t('dashboard.cod_collection_total') }}</span>
                                    </v-col>
                                </v-card-title>
                                <v-card-text class="py-0 ml-11">
                                    <v-row align="center" justify="center">                                
                                        <doughnut-chart :height="300" style="width: 90% !important; margin-left: -100px !important;" :chart-data="codByOrdersTotal" :options="doughnutOptions"></doughnut-chart>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card> 
        </v-container>        
    </div>
</template>

<script>
import DoughnutChart from './Charts/Doughnut.js'
import BarStackedChart from './Charts/BarStacked.js'

export default {
	components: {
		DoughnutChart,
		BarStackedChart,
        
        headers(){
            return [
                { text: this.$t('payments.client'), value: 'client_id' },
            ];
        }
    },
    data(){
        return {
            numberFormat: new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
            colors:[
                '#26B5AF',
                '#ECAB51',
                '#F24B5A',
                '#0099DD',
                '#DBF227',
                '#B000FF',
                '#FF3D82',
                '#F6EA04',
                "#259ce6",
                '#27C940',
                '#9B1DDE',
                // '#707070',
                '#FCBC10'
            ],
            ordersByStatus: {},
            ordersByStatusAndState: {},
            mostSoldProducts: {},
            deliveryDaysByState: {},
            anio: 2024,
            anios: [
                2018,
                2019,
                2020,
                2021,
                2022,
                2023,
                2024
            ],
            monthsNames: [],
            currentMonth : 'All',
            initialDate: '',
            finalDate: '',

            limitMostSold: 0,
            currentMostSold: 0,
            mostSold: [],
            
            codByOrders: {},
            codByOrdersTotal: {},
            
            months: [
                { month: 1, es: 'Enero', en: 'January', days: 31},
                { month: 2, es: 'Febrero', en: 'February', days: 29},
                { month: 3, es: 'Marzo', en: 'March', days: 31},
                { month: 4, es: 'Abril', en: 'April',days: 30},
                { month: 5, es: 'Mayo', en: 'May', days: 31},
                { month: 6, es: 'Junio', en: 'June', days: 30},
                { month: 7, es: 'Julio', en: 'July', days: 31},
                { month: 8, es: 'Agosto', en: 'August', days: 31},
                { month: 9, es: 'Septiembre', en: 'September', days: 30},
                { month: 10, es: 'Octubre', en: 'October', days: 31},
                { month: 11, es: 'Noviembre', en: 'November', days: 30},
                { month: 12, es: 'Diciembre', en: 'December', days: 31},
            ],
            doughnutOptions: {
				maintainAspectRatio: false,
                responsive: true,
				cutoutPercentage: 75,
				legend: { display: true, position: 'right', labels: { fontSize: 10, padding: 4, margin: 10} }
			},
            barOptions: {
				scales: {
					xAxes: [{
						stacked: true,
                        padding: 0,
                        ticks: {
                            autoSkip: false,
                            minor: {
                                fontSize: 10
                            },
                            maxRotation: 90,
                            minRotation: 90
                        },
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                        }
					}],
					yAxes: [{
						stacked: true,                
                        padding: 0,
                        ticks: {
                            beginAtZero: true,
                            minor: {
                                fontSize: 10
                            }
                        },
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                        },
					},],
				},
				legend: { display: true, position: 'top'},
				maintainAspectRatio: false,
				cutoutPercentage: 0,
                responsive: true,
                lineTension: 1,
			},
            barWithoutHeadersOptions: {
                scales: {
					xAxes: [{
						stacked: true,
                        padding: 0,
                        ticks: {
                            autoSkip: false,
                            minor: {
                                fontSize: 10
                            },
                            maxRotation: 90,
                            minRotation: 90
                        },
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                        }
					}],
					yAxes: [{
						stacked: true,                
                        padding: 0,
                        ticks: {
                            beginAtZero: true,
                            minor: {
                                fontSize: 10
                            }
                        },
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                        },
					},],
				},
                legend: { display: false },
                tooltips: {
                    callbacks: {
                    label: function(tooltipItem) {
                            return tooltipItem.yLabel;
                        }                    
                    }
                },
				maintainAspectRatio: false,
				cutoutPercentage: 0,
                responsive: true,
                lineTension: 1,
            }
        }
    },
    mounted(){
        this.$store.state.module = "headers.dashboard";
        if(this.$store.state.language == 'es') this.monthsNames = [
                'Todos', 'Enero','Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ];
        else this.monthsNames = [
            'All', 'January','February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const d = new Date();
        this.currentMonth = this.monthsNames[d.getMonth() + 1];
        this.initialDate = this.anio + '-'+ (d.getMonth() + 1).toString().padStart(2, '0') + '-01';
        this.finalDate = this.anio + '-'+ (d.getMonth() + 1).toString().padStart(2, '0') + '-' + this.months[d.getMonth()].days;

        this.index();
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.ordersByStatusFunction();
            this.ordersByStatusAndStateFunction();
            this.mostSoldProductsFunction();
            this.deliveryDaysByStateFunction();
            if(this.$store.state.clientUserIsCod == true){
                this.codByOrdersFunction();
                this.codByOrdersTotalFunction();
            }
        },
        ordersByStatusFunction(){
            this.$store.state.loaderProps.visible = true
			const lsToken = localStorage.getItem("access_token")
			this.$http.get(this.$store.state.chronosApi + 'ordersByStatusByClient/' + this.initialDate + '/' + this.finalDate + '/' + this.$store.state.clientUserId, { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    const labels = [
						{ name: this.$t("customers_dashboard.orders_generated_pendings"), color: this.colors[8], value: 'in_process' },
						{ name: this.$t("customers_dashboard.orders_generated_in_transit"), color: this.colors[9], value: 'in_transit' },
						{ name: this.$t("customers_dashboard.orders_generated_delivered"), color: this.colors[10], value: 'delivered' },
						{ name: this.$t("customers_dashboard.orders_generated_returned"), color: this.colors[11], value: 'returned' },
					]


                    const datasets = [{
						backgroundColor: labels.map(x => {
							return x.color
						}),
						data: labels.map(x => {
							return response.data.shipments[x.value];
						}),
						borderColor: 'rgba(0, 0, 0, 0)',
					}]

                    this.ordersByStatus = {
                        labels: labels.map(x => {
							return x.name + ' ( ' + response.data.shipments[x.value] + ' )';
						}),
						datasets: datasets
                    }
				}
			})
            this.$store.state.loaderProps.visible = false
        },
        ordersByStatusAndStateFunction(){
            this.$store.state.loaderProps.visible = true
			const lsToken = localStorage.getItem("access_token")
			this.$http.get(this.$store.state.chronosApi+'ordersByStatusClientAndState/' + this.initialDate + '/' + this.finalDate + '/' + this.$store.state.clientUserId, { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    //definition chart 3
                    const labels = [
						{ name: this.$t('customers_dashboard.orders_generated_pendings'), color: this.colors[8], value: "in_process" },
						{ name: this.$t('customers_dashboard.orders_generated_in_transit'), color: this.colors[9], value: "in_transit" },
						{ name: this.$t('customers_dashboard.orders_generated_delivered'), color: this.colors[10], value: "delivered" },
						{ name: this.$t('customers_dashboard.orders_generated_returned'), color: this.colors[11], value: "returned" },
					]

					const datasets = labels.map(x => {
						return {
							label: x.name,
							backgroundColor: x.color,
							
                            data: response.data.shipments.map(y => {
                                return y[x.value]
                            }),
							borderColor: 'rgba(0, 0, 0, 0)',
						}
					})

					this.ordersByStatusAndState = {
						labels: response.data.shipments.map(x => {
							return x.state_short_name
						}),
						datasets: datasets
					}

				}
			})
            this.$store.state.loaderProps.visible = false
        },
        mostSoldProductsFunction(){
            this.$store.state.loaderProps.visible = true
			const lsToken = localStorage.getItem("access_token")
			this.$http.get(this.$store.state.chronosApi+'mostSoldProducts/' + this.initialDate + '/' + this.finalDate + '/' + this.$store.state.clientUserId, { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    this.limitMostSold = Math.ceil(response.data.shipments.length / 10);
                    this.mostSold = response.data.shipments;

                    response.data.shipments = response.data.shipments.slice(0, 10);

                    const labels = [
						{ name: this.$t('customers_dashboard.orders_generated_pendings'), color: this.colors[8], value: "in_process" },
					]

					const datasets = labels.map(x => {
						return {
							label: x.name,
							backgroundColor: x.color,
							
                            data: response.data.shipments.map(y => {
                                return y.total
                            }),
							borderColor: 'rgba(0, 0, 0, 0)',
						}
					})

					this.mostSoldProducts = {
						labels: response.data.shipments.map(x => {
							return x.sku
						}),
						datasets: datasets
					}
				}
			})
            this.$store.state.loaderProps.visible = false
        },
        deliveryDaysByStateFunction(){
            this.$store.state.loaderProps.visible = true
			const lsToken = localStorage.getItem("access_token")
			this.$http.get(this.$store.state.chronosApi+'averageDaysBetweenSendAndDelivered/' + this.initialDate + '/' + this.finalDate + '/' + this.$store.state.clientUserId, { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    const labels = [
						{ name: this.$t('customers_dashboard.orders_generated_in_transit'), color: this.colors[9], value: "in_transit" }
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: response.data.shipments.map(y =>{
                                return y.average;
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.deliveryDaysByState = {
                        labels: response.data.shipments.map(t => {
                            return t.state_short_name
                        }),
                        datasets: datasets
                    }
				}
			})
            this.$store.state.loaderProps.visible = false
        },
        codByOrdersFunction(){
            this.$store.state.loaderProps.visible = true
			const lsToken = localStorage.getItem("access_token")
			this.$http.get(this.$store.state.chronosApi+'codByOrdersByClient/' + this.initialDate + '/' + this.finalDate + '/' + this.$store.state.clientUserId, { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    const labels = [
						{ name: this.$t("dashboard.delivered_paid"), color: this.colors[10], value: 'total_paid' },
						{ name: this.$t("dashboard.delivered_not_paid"), color: this.colors[9], value: 'total_pending' },
					]

                    const datasets = [{
						backgroundColor: labels.map(x => {
							return x.color
						}),
						data: labels.map(x => {
							return response.data.shipments[0][x.value];
						}),
						borderColor: 'rgba(0, 0, 0, 0)',
					}]

                    this.codByOrders = {
                        labels: labels.map(x => {
                            if(response.data.shipments[0][x.value] == null) return x.name + ' ( 0 )';
                            else return x.name + ' ( ' + response.data.shipments[0][x.value] + ' )';
						}),
						datasets: datasets
                    }
				}
			})
            this.$store.state.loaderProps.visible = false
        },
        codByOrdersTotalFunction(){
            this.$store.state.loaderProps.visible = true
			const lsToken = localStorage.getItem("access_token")
			this.$http.get(this.$store.state.chronosApi+'codByOrdersTotalByClient/' + this.initialDate + '/' + this.finalDate + '/' + this.$store.state.clientUserId, { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    const labels = [
						{ name: this.$t("dashboard.delivered_paid_total"), color: this.colors[8], value: 'total_paid' },
						{ name: this.$t("dashboard.delivered_not_paid_total"), color: this.colors[11], value: 'total_pending' },
					]

                    const datasets = [{
						backgroundColor: labels.map(x => {
							return x.color
						}),
						data: labels.map(x => {
							return response.data.shipments[0][x.value];
						}),
						borderColor: 'rgba(0, 0, 0, 0)',
					}]

                    this.codByOrdersTotal = {
                        labels: labels.map(x => {
							return x.name + ' ( $' + this.numberFormat.format(response.data.shipments[0][x.value]) + ' )';
						}),
						datasets: datasets
                    }
				}
			})
            this.$store.state.loaderProps.visible = false
        },
        back(chart){
            if(chart == 'mostSold'){
                if(this.currentMostSold > 0){
                    const array = this.mostSold.slice(((this.currentMostSold - 1) * 10), (this.currentMostSold * 10));
                    this.currentMostSold -= 1;
                    const labels = [
						{ name: this.$t('customers_dashboard.orders_generated_pendings'), color: this.colors[8], value: "in_process" },
					]

					const datasets = labels.map(x => {
						return {
							label: x.name,
							backgroundColor: x.color,
							
                            data: array.map(y => {
                                return y.total
                            }),
							borderColor: 'rgba(0, 0, 0, 0)',
						}
					})

					this.mostSoldProducts = {
						labels: array.map(x => {
							return x.sku
						}),
						datasets: datasets
					}
                }
            }
        },
        next(chart){
            if(chart == 'mostSold'){
                if(this.currentMostSold < this.limitMostSold -1){
                    this.currentMostSold += 1;
                    const array = this.mostSold.slice((this.currentMostSold * 10), ((this.currentMostSold + 1) * 10));
                    const labels = [
                        { name: this.$t('customers_dashboard.appointments'), color: this.colors[8] },
                    ]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: array.map(y =>{
                                return y.total
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.mostSoldProducts = {
                        labels: array.map(t => {
                            return t.sku
                        }),
                        datasets: datasets
                    }
                }
            }
        }
    },
    watch: {
        "currentMonth": function() {
            let month = this.monthsNames.indexOf(this.currentMonth);
            if(month == 0){
                this.initialDate = this.anio + '-01-01';
                this.finalDate = this.anio + '-12-31';
            }
            else {
                this.initialDate = this.anio + '-' + (month).toString().padStart(2, '0') + '-01';
                this.finalDate = this.anio + '-' + (month).toString().padStart(2, '0') + '-' + this.months[month -1].days;
            }
            this.index();
        },
        "anio": function() {
            let month = this.monthsNames.indexOf(this.currentMonth);
            if(month == 0){
                this.initialDate = this.anio + '-01-01';
                this.finalDate = this.anio + '-12-31';
            }
            else {
                this.initialDate = this.anio + '-' + (month).toString().padStart(2, '0') + '-01';
                this.finalDate = this.anio + '-' + (month).toString().padStart(2, '0') + '-' + this.months[month-1].days;
            }
            this.index();
        }
    }
}
</script>

<style>
</style>
