<template>
   <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('outcome_appointment.new') }} </h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0 my-3">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field v-model="appointment.entry_petition" :label="$t('outcome_appointment.petition_in')" disabled :rules="rules_petition"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field v-model="appointment.petition" :label="$t('outcome_appointment.petition_out')" :rules="rules_petition" counter="15"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>

                    <v-row class="px-5 py-5 mt-3" >
                        <v-col cols="12" align="center" justify="center">    
                            <h3 class="azul--text text-uppercase">
                                {{ $t('outcome_appointment.files') }}</h3>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 pb-3" justify="center" align="center">
                        <v-btn class="white--text" color="amarillo" @click="modal2 = true">
                            {{$t('outcome_appointment.select_file_petition')}}
                        </v-btn>
                    </v-row>
                </v-card-text>
                
                <br>
                <br>

                <v-row class="px-5 py-0" justify="end">
                        <v-col cols="6">
                            <v-btn class="right mx-3" color="white" :to="'/pendings'" >
                                {{ $t('outcome_appointment.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" >
                                {{ $t('outcome_appointment.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color">{{ snackbar.text }}</v-snackbar>

        <v-row justify="center">
            <v-dialog v-model="modal2" persistent max-width="90%">
                <v-card>
                <v-card-title class="justify-center">
                    <v-spacer></v-spacer>
                    {{ $t('outcome_appointment.upload_petition') }}
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" small icon @click="stepper2 = 1;modal2 = false">
                        <v-icon color="amarillo">close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-stepper v-model="stepper2">
                        <v-stepper-header>
                            <v-stepper-step :complete="stepper2 > 1" step="1" color="amarillo">{{$t('outcome_appointment.select_file_petition')}}</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="stepper2 > 2" step="2" color="amarillo">{{ $t('outcome_appointment.verify_data') }}</v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-card flat class="mb-5">
                                    <v-form ref="form2" lazy-validation>
                                        <v-row>
                                            <v-col>
                                                <v-file-input v-model="inputFile2" color="amarillo" accept=".csv" :label="$t('outcome_appointment.select_file_petition')" :rules="rules_required"></v-file-input>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card>
                                
                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="right white--text" color="amarillo" @click="uploadFilePetition" >
                                        {{ $t('outcome_appointment.next') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-card flat class="mb-5">
                                    <v-card-text>
                                        <p>{{readRows2.length}} {{ $t('outcome_appointment.skus_in_file') }}</p>
                                    </v-card-text>
                                    <v-simple-table v-if="this.readRows2.length > 0" fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th v-for="(th, key) in sTHeaders2" :key="key" class="text-left">{{ th }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in readRows2" :key="i">
                                                    <td v-for="(td, j) in item.split(',')" :key="j">{{ td }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>

                                <div class="d-flex">
                                    <v-spacer></v-spacer>
                                    <v-btn class="mx-3" text @click="stepper2 = 1; sTHeaders2 = []; readRows2 = []">{{ $t('outcome_appointment.go_back') }}</v-btn>
                                    <v-btn color="amarillo" class="white--text" @click="modal2 = false;">
                                        {{ $t('outcome_appointment.save_data') }}
                                    </v-btn>
                                </div>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>
export default {
    computed: {
        rules_petition(){
            return [
                 v => v == null || /^(|\d)+$/.test(v) || this.$t('edit_appointment.validations.numeric'),
                v => v == null || v.toString().length == 0 || v.toString().length == 15 || this.$t('edit_appointment.validations.lenght'),
            ];
        },   
        rules_required(){
            return [v => !!v || this.$t('outcome_appointment.validations.required_field')];
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            modal2: false,
            inputFile2: [],
            readFile2: '',
            readRows2: [],
            stepper2: 1,
            
            appointment:{
                petition: '',
                entry_petition: '',
            },
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
        }
    },
    mounted(){
        this.$store.state.module = "headers.create_exit_part";
        this.index();
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        index(){
            const lsToken = localStorage.getItem("access_token")
            this.$http.get(this.$store.state.chronosApi + 'appointments/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.$store.state.loaderProps.visible = false;
                this.appointment.entry_petition               = response.data.petition;
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        save(){
            const petitions = [];
            this.readRows2.forEach((x) => {
                const num = x.split(',');
                petitions.push({
                    'customs_document': num[0],
                    'customs_document_date': num[1], //fecha pago
                    'income_date': num[2],
                    'cvedoc': num[3],
                    'regime': num[4],
                    'origin_country': num[5],
                    'source_country': num[5],
                    'customs_document_departure': num[6],
                    'part_number': num[7], //sku
                    'description': num[8],
                    'invoice_quantity': num[9],
                    'umc': parseInt(num[11]),
                    'fraction': num[12],
                    'value_in_dollars': num[13],
                    'codcove': num[14],
                    'client_supplier': num[15],
                    'notice_number': num[16],
                    'umt': parseInt(num[17]),
                });
            });

            const formVal = this.$refs.form.validate();
            
            if(formVal && petitions.length > 0 && this.appointment.petition != this.appointment.entry_petition){
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.post(this.$store.state.chronosApi +  'appointment/' + this.id + '/crossdockOutcome', {'appointment': this.appointment, 'pedimentos': petitions}, {headers: {Authorization: lsToken}})
                .then((response) => {
                    this.$store.state.loaderProps.visible = false;
                    if(response.status === 200){
                        this.stepper2 = 1;
                        this.sTHeaders2 = [];
                        this.readRows2 = [];
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('outcome_appointment.responses.ok')
                        }
                        this.$router.push({ name: 'InternationalProcessed' });
                    }
                })
                .catch((response) => {
                    this.$store.state.loaderProps.visible = false;
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('outcome_appointment.responses.error')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
            }
            else{
                this.$store.state.loaderProps.visible = false
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('outcome_appointment.form_validation')
                }
            }
        },
        uploadFilePetition(){
            if(this.$refs.form2.validate()){
                this.createInput2(this.inputFile2);
            }
        },
        createInput2(file) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    /* handle a successful result */
                    this.readRows2 = result.split('\r\n');
                    this.sTHeaders2 = this.readRows2[0].split(',')
                    this.readRows2.shift()
                    this.stepper2 = 2
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        }
    }
}
</script>

<style>

</style>
