<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col cols="3" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('rm_incoming_movements.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-col cols="3" class="py-0" justify="center" align="center">
                    <v-autocomplete v-model="year" class="py-0" :label="$t('no_annex.year')" :items="years"></v-autocomplete>
                </v-col>
                <v-col cols="6" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="showExport = true">
                        {{ $t('rm_incoming_movements.export') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <v-data-table class="px-0 my-2"
                                :headers="headers"
                                :items="props.items"
                                :search="props.search"
                                calculate-widths
                                item-key="id"
                                :items-per-page="items_per_page"
                                hide-default-footer
                            >
                                <template v-slot:footer>
                                    <v-row>
                                        <v-col cols="7"></v-col>
                                        <v-col cols="1" align="end" justify="center">
                                            <v-autocomplete class="py-0 px-0" :label="$t('cm_outcoming_movements.items_per_page')" v-model="items_per_page" :items="items_options"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="4" align="right" justify="right" class="px-0">
                                            <v-pagination v-model="page" color="azul" :length="pages" @input="next" justify="right" align="right"></v-pagination>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="showExport" persistent max-width="90%" style="width: 500px !important;">
                <v-card style="width:500px !important">
                    <v-card-title class="justify-center mt-4">
                        <v-row align="center" justify="center" class="my-2">
                            <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('rm_incoming_movements.export_data')}}</label>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="6" class="py-0">
                                <v-menu ref="initial_date" v-model="menu2" :close-on-content-click="false" :return-value.sync="report.initial_date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field class="py-0" v-model="report.initial_date" :label="$t('rm_incoming_movements.initial_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="report.initial_date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu2 = false">{{ $t('rm_incoming_movements.cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.initial_date.save(report.initial_date)">{{ $t('rm_incoming_movements.save') }}</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <v-menu ref="final_date" v-model="menu3" :close-on-content-click="false" :return-value.sync="report.final_date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field class="py-0" v-model="report.final_date" :label="$t('rm_incoming_movements.final_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="report.final_date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu3 = false">{{ $t('rm_incoming_movements.cancel') }}</v-btn>
                                        <v-btn text color="primary" @click="$refs.final_date.save(report.final_date)">{{ $t('rm_incoming_movements.save') }}</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="showExport = false">{{ $t('rm_incoming_movements.cancel') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="exportData">
                                {{ $t('rm_incoming_movements.export') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('rm_incoming_movements.id'), align: "center", value: 'id', class: 'text-uppercase' },
                { text: this.$t('rm_incoming_movements.sku'), align: "center", value: 'sku', class: 'text-uppercase' },
                { text: this.$t('rm_incoming_movements.quantity'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('rm_incoming_movements.umc'), align: "center", value: 'umc', class: 'text-uppercase' },
                { text: this.$t('rm_incoming_movements.dollars'), align: "center", value: 'dollars', class: 'text-uppercase' },
                { text: this.$t('rm_incoming_movements.receipt_date'), align: "center", value: 'receipt_date', class: 'text-uppercase' },
                { text: this.$t('rm_incoming_movements.id_supplier'), align: "center", value: 'id_supplier', class: 'text-uppercase' },
                { text: this.$t('rm_incoming_movements.n_petition'), align: "center", value: 'n_petition', class: 'text-uppercase' },
                { text: this.$t('rm_incoming_movements.key'), align: "center", value: 'key', class: 'text-uppercase' },
                { text: this.$t('rm_incoming_movements.entry_date'), align: "center", value: 'entry_date', class: 'text-uppercase' },
                { text: this.$t('rm_incoming_movements.origin_country'), align: "center", value: 'origin_country', class: 'text-uppercase' },
                { text: this.$t('rm_incoming_movements.rate'), align: "center", value: 'rate', class: 'text-uppercase' },
                { text: this.$t('rm_incoming_movements.commercial_invoice'), align: "commercial_invoice", value: 'umt', class: 'text-uppercase' },
                { text: this.$t('rm_incoming_movements.electronic_notice'), align: "center", value: 'electronic_notice', class: 'text-uppercase' },
                { text: this.$t('rm_incoming_movements.purchase_order'), align: "center", value: 'purchase_order', class: 'text-uppercase' },
                { text: this.$t('rm_incoming_movements.date'), align: "center", value: 'date', class: 'text-uppercase' }
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            report:{
                initial_date: '',
                final_date: ''
            },
            menu2: false,
            menu3: false,
            showExport: false,
            warehouse_id: this.$store.state.warehouseId,
            years: ["2024", "2023", "2022", "2021", "2020", "2019", "2018", "2017"],
            year: "2024",
            page: 1,
            pages: 5,
            total_items: 500,
            items_per_page: 10,
            items_options: [10,25,50,100],
        }
    },
    mounted(){
        // this.index();
        this.$store.state.module = "headers.rm_incoming_movements";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'customs/reports/entries?warehouse=' + this.warehouse_id + '&startDate=' + this.year + '-01-01&endDate=' + this.year + '-12-31&perPage=' + this.items_per_page + '&page=' + this.page, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x => {
                    x = x.attributes;
                    var item = {
                        id: x.id,
                        umc: x.umc,
                        dollars: x.valueInDollars,
                        receipt_date: x.arrivalDate,
                        id_supplier: x.clientId,
                        n_petition: x.customsNumber,
                        key: x.cvedoc,
                        entry_date: x.arrivalDate,
                        origin_country: x.sourceCountry,
                        rate: "",
                        commercial_invoice: x.invoiceNumber,
                        electronic_notice: x.noticeNumber,
                        purchase_order: x.purchaseOrder,
                        date: x.createdAt
                    };

                    x.detailOrders.forEach( y => {
                        y = y.attributes;
                        item.sku = y.product.sku;
                        item.quantity = y.real;
                        this.props.items.push(item);
                    })
                });
                this.pages = response.data.meta.tota_pages;
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        exportData(){

        },
        next(page) {
            this.page = page;
            this.index();
        },
    },
    watch:{
        'year': function (){
            this.index();
        },
        'items_per_page': function(){
            this.index();
        }
    }
}
</script>

<style>
</style>