<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('orders_pending.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <!-- <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text" color="amarillo" :to="'/orders_pending/create'" v-permission="'gate.index'">
                        {{ $t('orders_pending.new') }}
                    </v-btn>
                </v-col> -->
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                    {{ $t('orders_pending.modal.delete') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('orders_pending.modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('orders_pending.no') }}</v-btn>
                            <v-btn color="error" @click="deleteOrder" v-permission="'orders_pendings.delete'">
                                {{ $t('orders_pending.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('orders_pending.client'), align: "center", value: 'source', class: 'text-uppercase' },
                { text: this.$t('orders_pending.unique_order_number'), align: "center", value: 'unique_order_number', class: 'text-uppercase' },
                { text: this.$t('orders_pending.status'), align: "center", value: 'status', class: 'text-uppercase' },
                { text: this.$t('orders_pending.error'), align: "center", value: 'error_message', class: 'text-uppercase' },
                { text: this.$t('orders_pending.date'), align: "center", value: 'created_at', class: 'text-uppercase' },
                { text: this.$t('orders_pending.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalDelete: false,
            idGate: '',
            actions: [
            {
                    name: this.$t('orders_pending.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'edit'
                    },
                    link: '',
                    permission: "orders_pendings.update"
                },
                {
                    name: this.$t('gates.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                    permission: "orders_pendings.delete"
                }
            ],
            description: '',
            props: {
                search: '',
                items: [],
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.orders_pending";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'orders_pendings', {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.statusText === "OK"){
                    response.data.forEach(x =>{
                        x.unique_order_number = JSON.parse(x.data).unique_order_number;
                        x.created_at = x.created_at.split("T")[0];
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x.actions[0].link = 'stuck_orders/complete/' + x.id;
                        x['parameters'] = x;
                        this.props.items.push(x);
                    });
                this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        askDelete(gate){
            this.modalDelete = true;
            this.idGate = gate.id;
        },
        deleteOrder(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'orders_pendings/' + this.idGate, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.modalDelete = false;
                    this.index();
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        }
    }
}
</script>

<style>

</style>