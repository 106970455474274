<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('create_box.new') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="box.name" :label="$t('create_box.name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="box.length" :label="$t('create_box.length')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="box.width" :label="$t('create_box.width')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="box.height" :label="$t('create_box.height')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="py-0" v-model="box.units" :items="units" :label="$t('create_box.weight')"></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="box.alert" :label="$t('create_box.alert')" :rules="numeric_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 pt-1 mt-0" justify="center" align="center">
                            <v-col cols="6" align="start" justify="start">    
                                <h4 class="azul--text text-uppercase">{{ $t('create_box.warehouses') }}</h4>
                                <label>{{ $t('create_box.select_warehouses') }}</label>
                            </v-col>
                        </v-row> 

                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-row class="px-0 py-0 my-0" justify="start" align="start">
                                    <v-col cols="6" class="py-0" style="display:flex" v-for="warehouse of box.box_per_warehouses" :key="warehouse.id">
                                        <v-checkbox class="mt-0" color="info" v-model="warehouse.work" :label="warehouse.name"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('create_box.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'box.create'">
                                {{ $t('create_box.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            box: {
                name: "",
                length: "",
                width: "",
                height: "",
                units: "",
                box_per_warehouses: [],
                alert: ""
            },
            units: ["CM", "IN"]
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.create_box";
    },
    methods: {
        index(){
            // Get Shippment_services
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'warehouses/', {headers: {Authorization: lsToken}})
            .then((response1) => {
                response1.data.forEach(x => {
                    var exist = false;
                    this.box.box_per_warehouses.forEach(y => {
                        if(y.name == x.name) {
                            exist = true;
                            y.work = true;
                        }
                    })
                    if(exist == false){
                        x.work = false;
                        this.box.box_per_warehouses.push(x);
                    }
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.$http.post(this.$store.state.chronosApi + 'boxes', this.box,{headers: {Authorization: lsToken}})
            .then((response) => {
                response
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('create_box.responses.success')
                }
                this.$router.push({ name: "BoxesIndex" });  
            }).catch((err) => {
                if("name" in err.response.data){
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('create_box.validations.name')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>