<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5" justify="center" align="center">
                    <v-col cols="4" align="start" justify="start">    
                        <h3 class="azul--text text-uppercase">{{ $t('incidence_details.incidence_detail') }}</h3>
                    </v-col>
                    <v-col cols="2" align="end" justify="end">
                        <v-btn class="right white--text" color="amarillo" @click="cancel" >
                            {{ $t('incidence_details.go_back') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('incidence_details.type')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.incidence.type }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('incidence_details.quantity')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.incidence.quantity }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('incidence_details.description')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.incidence.description }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0" align="center" justify="center">
                            <v-col cols="6" class="py-0 mt-3 px-3 mb-2">
                                <label for="" class="azul--text mt-3" style="font-weight: bold; width:100%; text-align: start">{{$t('incidence_details.files')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center">
                                    <v-data-table
                                        no-results-text="No hay resultados"
                                        :headers="headers"
                                        :items="files"
                                        :footer-props="{
                                        'items-per-page-text': this.$t('datatable.shown'),
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-arrow-collapse-left',
                                        lastIcon: 'mdi-arrow-collapse-right',
                                        pageText: this.$t('datatable.of') + ' ' + this.files.length + ' ' + this.$t('datatable.results'),
                                        'items-per-page-options': [10, 25, 50, 100] }"
                                        :page="1"
                                    >
                                        <template #item.name="{ item }">
                                            <a target="_blank" :href="`${item.url}`" style="font-size: 16px; color: #00587d">
                                                {{ item.name }}
                                            </a>
                                        </template>
                                        <v-data-footer :items-per-page-options="[]"></v-data-footer>
                                    </v-data-table>   
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        headers(){
            return [
                { text: this.$t('incidence_details.name'), value: 'name'},
            ];
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            incidence: '',
            files: []
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.incidences";
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.$http.get(this.$store.state.chronosApi + 'a_incidences/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.incidence = response.data.incidence;
                this.incidence.type = this.$t('incidence_details.' + this.incidence.i_type);
                this.files = response.data.files;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>