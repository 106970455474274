<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col cols="3" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('fulfillments_cod_in_process.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="9" align="end" class="py-0 px-0">
                    <v-row justify="end" align="end" class="mr-2 mt-0">
                        <v-btn class="right white--text tools-view-height mx-1" color="amarillo" style="font-size:11px" @click="exportShipments">
                            {{ $t('fulfillments_cod_in_process.export') }}
                            <!-- <v-icon class="material-icons-outlined px-1" small>downloading</v-icon> -->
                        </v-btn>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="right white--text tools-view-height mx-1" color="amarillo" @click="downloadInovices" style="max-width: 40px !important; min-width: 40px !important" >
                                    <v-icon class="material-icons-outlined" small>sticky_note_2</v-icon>
                                </v-btn>
                            </template>

                            <span>{{$t('fulfillments_cod_in_process.download_invoice')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="right white--text tools-view-height mx-1" color="amarillo" @click="downloadLabels" style="max-width: 40px !important; min-width: 40px !important" >
                                    <v-icon class="material-icons-outlined" small>label</v-icon>
                                </v-btn>
                            </template>

                            <span>{{$t('fulfillments_cod_in_process.download_guides')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="right white--text tools-view-height mx-1" color="amarillo" @click="downloadLabelAndInvoice" style="max-width: 40px !important; min-width: 40px !important" >
                                    <v-icon class="material-icons-outlined" small>downloading</v-icon>
                                </v-btn>
                            </template>

                            <span>{{$t('fulfillments_cod_in_process.download_labels_and_invoices')}}</span>
                        </v-tooltip>
                        
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="right white--text tools-view-height mx-1" color="amarillo" @click="downloadOSAndPL" style="max-width: 40px !important; min-width: 40px !important" >
                                    <v-icon class="material-icons-outlined" small>content_paste_search</v-icon>
                                </v-btn>
                            </template>

                            <span>{{$t('fulfillments_cod_in_process.download_os_and_pl')}}</span>
                        </v-tooltip>
                    </v-row>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <v-data-table class="px-0 my-2"
                                no-results-text="No hay resultados"
                                :headers="headers"
                                :items="props.items"
                                :search="props.search"
                                calculate-widths
                                :footer-props="{
                                    'items-per-page-text': this.$t('datatable.shown'),
                                    showFirstLastPage: true,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2} ' + this.$t('datatable.results'),
                                    'show-current-page': true,
                                    'items-per-page-options': typeof props.rowsPP === 'undefined' ? [10, 25, 50, 100] : props.rowsPP}"
                                :page="1"
                                :mobile-breakpoint="0"
                                item-key="id"
                                show-select
                                v-model="selected"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                                        <template v-slot:activator="{ on }">
                                            <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                            <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ action.name }}</span>
                                    </v-tooltip>
                                </template>
                                <!-- Id dot in cod orders in process. INDEX -->
                                <template v-slot:item.labelDownload="{ item }"> 
                                    <td v-if="item.labelDownload == 1" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                        {{ item.idIndex }}
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon class="material-icons-outlined" small color="#a5a5a5"> fiber_manual_record </v-icon> 
                                        {{ item.idIndex }}
                                    </td>
                                </template>

                                <!-- Id dot in cod orders in process. INDEX -->
                                <template v-slot:item.noteDownload="{ item }"> 
                                    <td v-if="item.noteDownload == 1" style="text-align:center">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                        {{ item.idIndex }}
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon class="material-icons-outlined" small color="#a5a5a5"> fiber_manual_record </v-icon> 
                                        {{ item.idIndex }}
                                    </td>
                                </template>
                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h3 class="text-uppercase">
                                {{ $t('fulfillments_cod_in_process.modal.delete') }}
                            </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('fulfillments_cod_in_process.modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('fulfillments_cod_in_process.no') }}</v-btn>
                            <v-btn color="error" @click="deleteOrder">
                                {{ $t('fulfillments_cod_in_process.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalRestore" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h3 class="text-uppercase">
                                {{ $t('fulfillments_cod_in_process.restore_modal.restore') }}
                            </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('fulfillments_cod_in_process.restore_modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalRestore = false">{{ $t('fulfillments_cod_in_process.no') }}</v-btn>
                            <v-btn color="error" @click="restoreOrder">
                                {{ $t('fulfillments_cod_in_process.restore_yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalDetail" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('fulfillments_cod_in_process.order_details.header') }} {{ this.orderToShow.uniqueOrderNumber }}
                            </h4>
                        </v-row>
                    </v-card-title>
                    
                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_cod_in_process.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_cod_in_process.order_number") }}</label> <label>{{ this.orderToShow.uniqueOrderNumber }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_cod_in_process.date") }}</label> <label>{{ this.orderToShow.createdAt }}</label></div>
                    <div class="detail_content1" v-if="shipping_service != 'otra'"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_pendings.shipping_service") }}</label> <label>{{ this.orderToShow.shippingService }}</label></div>
                    <div class="detail_content1" v-if="shipping_service == 'otra'"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_pendings.shipping_service") }}</label> <label>{{ this.orderToShow.externalShippingService }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_pendings.invoice") }}</label> <label>{{ this.orderToShow.invoice }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.client != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_in_process.client") }}</label> <label>{{ this.orderToShow.client.name }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_cod_in_process.cod") }}</label> <label>{{ this.orderToShow.total }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_in_process.buyer") }}</label> <label>{{ this.orderToShow.consignee.name }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_in_process.state") }}</label> <label>{{ this.orderToShow.consignee.state }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_in_process.municipality") }}</label> <label>{{ this.orderToShow.consignee.municipality }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_in_process.neighborhood") }}</label> <label>{{ this.orderToShow.consignee.neighborhood }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_in_process.street") }}</label> <label>{{ this.orderToShow.consignee.street }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_in_process.zip_code") }}</label> <label>{{ this.orderToShow.consignee.zipcode }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_cod_in_process.products') }}</label></div>
                    <span v-if="orderToShow.shipmentProductDetail != null">
                        <div v-for="(product, i) of orderToShow.shipmentProductDetail.data" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.attributes.product.sku }}</label> <label>{{ product.attributes.quantity }}</label></div>
                    </span>
                    
                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn color="amarillo" class="white--text mx-3" @click="modalDetail = false">{{ $t('fulfillments_cod_in_process.go_back') }}</v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
        </v-container>        
    </div>
</template>
<script>
import JSZip from 'jszip';
import FileSaver from 'file-saver';
export default {
	computed: {
        headers(){
            if(this.shipping_service != "otra"){
                return [
                    { text: this.$t('fulfillments_cod_in_process.order'), value: 'uniqueOrderNumber' },
                    { text: this.$t('fulfillments_cod_in_process.client'), value: 'client.name', width:'30%'},
                    { text: this.$t('fulfillments_cod_in_process.date'), value: 'date' },
                    { text: this.$t('fulfillments_cod_in_process.time'), value: 'time' },
                    { text: this.$t('fulfillments_cod_in_process.tracking_number'), value: 'shipmentNumber' },
                    { text: this.$t('fulfillments_cod_in_process.label'), value: 'labelDownload', align: 'center'},
                    { text: this.$t('fulfillments_cod_in_process.invoice'), value: 'noteDownload', align: 'center'},
                    { text: this.$t('fulfillments_cod_in_process.actions'), value: 'actions' },
                ];
            }
            else{
                return [
                    { text: this.$t('fulfillments_cod_in_process.order'), value: 'uniqueOrderNumber' },
                    { text: this.$t('fulfillments_cod_in_process.client'), value: 'client.name', width:'30%'},
                    { text: this.$t('fulfillments_cod_in_process.date'), value: 'date' },
                    { text: this.$t('fulfillments_cod_in_process.time'), value: 'time' },
                    { text: this.$t('fulfillments_cod_in_process.shipping_service'), value: 'externalShippingService'},
                    { text: this.$t('fulfillments_cod_in_process.tracking_number'), value: 'shipmentNumber' },
                    { text: this.$t('fulfillments_cod_in_process.label'), value: 'labelDownload', align: 'center'},
                    { text: this.$t('fulfillments_cod_in_process.invoice'), value: 'noteDownload', align: 'center'},
                    { text: this.$t('fulfillments_cod_in_process.actions'), value: 'actions' },
                ];
            }
        }
    },
    data(){
        return {
            shipping_service: this.$route.params.shipping_service,
            props: {
                search: '',
                items: [],
            },
            selected: [],
            modalDelete: false,
            modalRestore: false,
            modalDetail: false,
            orderToDelete: '',
            orderToRestore: '',
            orderToShow: null,
            actions:[
                {
                    name: this.$t('fulfillments_cod_in_process.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                },
                {
                    name: this.$t('fulfillments_cod_sent.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'mode_edit'
                    },
                    link: '/sent-shipments/cod/edit/10000',
                },
                {
                    name: this.$t('fulfillments_cod_in_process.restore'),
                    icon: {
                        color: 'accent',
                        icon: 'history'
                    },
                    method: 'askRestore',
                },
                {
                    name: this.$t('fulfillments_cod_in_process.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                },
                {
                    name: this.$t('fulfillments_cod_in_process.download_guides'),
                    icon: {
                        color: 'accent',
                        icon: 'label'
                    },
                    method: 'downloadLabel',
                },
                {
                    name: this.$t('fulfillments_cod_in_process.download_invoice'),
                    icon: {
                        color: 'accent',
                        icon: 'description'
                    },
                    method: 'downloadInvoice',
                }
            ]
        }
    },
    mounted(){
        this.$store.state.module = "headers." + this.shipping_service + "_cod_in-process";
        this.index();
    },
    methods: {
        methodHandler(object, params){
            this[object](params)
        },
        index(){
            console.log(this.shipping_service)
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.props.items = [];
            this.$http.get(this.$store.state.chronosApi + 'shipment/getInProcesOrders?warehouse=' + this.$store.state.warehouseId + '&shipping_service=' + this.shipping_service.toUpperCase() + '&cod=true', {headers: {Authorization: lsToken}})
            .then((response) => {
                // Get Shippment_services
                this.$store.state.loaderProps.visible = true;
                response.data.data.forEach( x => { 
                    x = x.attributes;
                    x.date = x.createdAt.substring(0,10);
                    x.time = x.createdAt.substring(11,19);
                    x['actions'] = JSON.parse(JSON.stringify(this.actions));
                    x['actions'][1].link = "/sent-shipments/cod/edit/" + x.id;
                    x.parameters = x;
                    this.props.items.push(x);
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        showDetails(order){
            this.orderToShow = order;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/getOrderInformation/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data.attributes;
                this.$store.state.loaderProps.visible = false;
                this.orderToShow = response.data;
                this.modalDetail = true;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        askDelete(order){
            this.modalDelete = true;
            this.orderToDelete = order;
        },
        askRestore(order){
            this.modalRestore = true;
            this.orderToRestore = order;
        },
        restoreOrder(){
            this.$store.state.loaderProps.visible = true;
            if(this.orderToRestore != ''){
                this.$http.put(this.$store.state.chronosApi + 'shipment/restock/' + this.orderToRestore.id, {headers: {}})
                .then((response) => {
                    if(response.data.status === 200) {
                        this.orderToRestore = '';
                        this.modalRestore = false;
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('fulfillments_cod_sent.restored')
                        }
                        this.index();
                    }
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: "error"
                }
            }
        },
        deleteOrder(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'shipment/deleteOrder/' + this.orderToDelete.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.data.success == true){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('fulfillments_cod_in_process.deleted')
                    }
                    this.modalDelete = false;
                    this.index();
                }
                else{
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('fulfillments_cod_in_process.deleted_failed')
                    }
                    this.modalDelete = false;
                    this.index();
                }
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        async function (order){
            var zip = new JSZip();
            let blobs = [];
            const urls = await this.getLabelUrls(order.id);
            for(let url of urls.data[0].data){
                const blob = await this.getLabelFiles(url, 'label', order.shipmentNumber);
                blobs.push(blob);
            }

            if(this.shipping_service.toUpperCase() == 'OTRA'){ // add to zip all the attached files
                if(urls.data[0].files.length > 0){
                    for(let url of urls.data[0].files){
                        url.name = order.shipmentNumber;
                        const blob = await this.getLabelFiles(url, 'attached_file', urls[0].order.split("-")[1]);
                        blobs.push(blob);
                    }
                }
            }

            if(blobs.length > 0){
                blobs.forEach(x => {
                    zip.file(x.name, x.response.data);
                });    

                this.$store.state.loaderProps.visible = false;
                zip.generateAsync({type:"blob"}).then(function(content) {
                    FileSaver.saveAs(content, order.uniqueOrderNumber)
                }, function(err){
                    console.log(err)
                })
            }
        },
        downloadLabel: async function (order){
            var zip = new JSZip();
            let blobs = [];
            const urls = await this.getLabelUrls(order.id);
            
            if(this.shipping_service.toUpperCase() == 'OTRA' || this.shipping_service.toUpperCase() == 'SWIMPIFY'){ // add to zip all the attached files
                if(urls.data[0].files.length > 0){
                    const url = {
                        name: order.shipmentNumber,
                        url: urls.data[0].files[urls.data[0].files.length - 1].url
                    }
                    const blob = await this.getLabelFiles(url, 'attached_file', order.shipmentNumber);
                    blobs.push(blob);
                }
            }
            else{
                const blob = await this.getLabelFiles(urls.data[0].data[0], 'label', order.shipmentNumber);
                blobs.push(blob);
            }

            if(blobs.length > 0){
                blobs.forEach(x => {
                    zip.file(x.name, x.response.data);
                });    

                this.$store.state.loaderProps.visible = false;
                zip.generateAsync({type:"blob"}).then(function(content) {
                    FileSaver.saveAs(content, order.shipmentNumber)
                }, function(err){
                    console.log(err)
                })
            }
        },
        downloadLabels: async function(){
            this.$store.state.loaderProps.visible = true;
            var zip = new JSZip();
            var shipments_ids = '';
            this.selected.forEach(image => {
                shipments_ids += image.id + ","; 
            })
            shipments_ids = shipments_ids.substring(0, shipments_ids.length - 1);

            await this.multipleDownloadLabel(shipments_ids, zip, 'label');

            this.$store.state.loaderProps.visible = false;
            zip.generateAsync({type:"blob"}).then(function(content) {
                FileSaver.saveAs(content, "Labels.zip")
            }, function(err){
                console.log(err)
            })
        },
        multipleDownloadLabel: async function (orders, zip, type){
            let blobs = [];
            const urls = await this.getLabelsUrls(orders);

            for(let ship of urls.data){
                blobs = [];
                
                const blob = await this.getLabelFiles(ship.data[0], 'label', ship.data[0].split("/")[ship.data[0].split("/").length - 1].split(".")[0]);
                blobs.push(blob);

                if(this.shipping_service.toUpperCase() == 'OTRA'){ // add to zip all the attached files
                    if(ship.files.length > 0){
                        for(let url of ship.files){
                            var name = this.selected.find( x => x.uniqueOrderNumber == ship.order.split("-")[0]).uniqueOrderNumber;
                            
                            if(type == 'labelAndInvoice') name + '-C';

                            const blobpdf = await this.getLabelFiles(url, 'attached_file', name + '-C');
                            blobs.push(blobpdf);
                        }
                    }
                }

                blobs.forEach(x => {
                    zip.file(x.name, x.response.data);
                });
            }
        },
        getLabelFiles: async function(url, type, name){
            const lsToken = localStorage.getItem("access_token");
            if(type == 'label'){
                const blob = await this.$http.get(url, {headers: {Authorization: lsToken}, responseType:'blob' });
                return {response: blob, name: name + '-A.png'};
            } 
            else if(type == 'attached_file'){
                const blob = await this.$http.get(url.url, {headers: {Authorization: lsToken}, responseType:'blob' });
                return {response: blob, name: name + ".pdf"};
            } 
        },
        getLabelsUrls: async function(orders){
            const lsToken = localStorage.getItem("access_token");
            const {data} = await this.$http.get(this.$store.state.chronosApi + 'shipment/getLabels?id=' + orders, {headers: {Authorization: lsToken}});
            return data;
        },
        getLabelUrls: async function (order){
            const lsToken = localStorage.getItem("access_token");

            const {data} = await this.$http.get(this.$store.state.chronosApi + 'shipment/getLabels?id=' + order, {headers: {Authorization: lsToken}});
            return data;
        },
        downloadInvoice(order){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/getNote?id=' + order.id + '&format=PNG', {headers: {Authorization: lsToken}, responseType:'blob' })
            .then((response) => {
                // Get consginee data
                this.$store.state.loaderProps.visible = false; 
                FileSaver.saveAs(response.data, order.shipmentNumber + ".png");
                order.noteDownload = 1;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        downloadInovices(){
            this.$store.state.loaderProps.visible = true;
            var zip = new JSZip();
            let promises = [];
            let responses = [];
            const lsToken = localStorage.getItem("access_token");
            
            this.selected.forEach(image => {
                let image_file = this.$http.get(this.$store.state.chronosApi + 'shipment/getNote?id=' + image.id + '&format=PNG', {headers: {Authorization: lsToken}, responseType:'blob' })
                .then(response => {
                    
                    if(this.shipping_service == "afimex") responses.push({ r: response, name: image.masterShipmentDetail  + '.png'});
                    else responses.push({ r: response, name: image.shipmentNumber  + '.png'});
                    
                }).catch(error => {
                    console.log(error)
                    this.$store.state.loaderProps.visible = false;
                }).finally(() =>{
                    this.$store.state.loaderProps.visible = false;    
                }) 

                image.noteDownload = 1;
                promises.push(image_file);
            })
            Promise.all(promises).then( () => {
                if(responses.length == this.selected.length){
                    responses.forEach(response => {
                        let type = response.r.headers['Content-Type']
                        let blob = new Blob([response.r.data], { type: type, encoding: 'UTF-8' })
                        
                        zip.file(response.name, blob)
                    });    

                    this.$store.state.loaderProps.visible = false;
                    zip.generateAsync({type:"blob"}).then(function(content) {
                        FileSaver.saveAs(content, "notas.zip")
                    }, function(err){
                        console.log(err)
                    })
                }
            })
        },
        downloadLabelAndInvoice: async function(){
            this.$store.state.loaderProps.visible = true;
            var zip = new JSZip();
            let promisesNotes = [];
            let responsesNotes = [];
            const lsToken = localStorage.getItem("access_token");

            var shipments_ids = "";
            this.selected.forEach(image => {
                shipments_ids += image.id + ","; 
            })
            shipments_ids = shipments_ids.substring(0, shipments_ids.length - 1);

            // Get labels
            await this.multipleDownloadLabel(shipments_ids, zip, 'labelAndInvoice');
            
            const pickeoList = await this.getPickeoList(shipments_ids);
            zip.file("Lista_de_pickeo.pdf", pickeoList.data);

            const ordersSummary = await this.getOrdersSummary(shipments_ids);
            zip.file("Resumen_de_ordenes.pdf", ordersSummary.data);

            this.selected.forEach(image => {

                let image_file = this.$http.get(this.$store.state.chronosApi + 'shipment/getNote?id=' + image.id + '&format=PNG', {headers: {Authorization: lsToken}, responseType:'blob' })
                .then(response => {
                    if(this.shipping_service == "afimex") responsesNotes.push({ r: response, name: image.masterShipmentDetail  + '-B.png'});
                    else responsesNotes.push({ r: response, name: image.shipmentNumber  + '-B.png'});
                }).catch(error => {
                    console.log(error)
                })

                promisesNotes.push(image_file) 
                image.label = 1;
                image.note = 1;
            })

            Promise.all(promisesNotes).then( () => {            
                responsesNotes.forEach(response => {
                    let type = response.r.headers['Content-Type']
                    let blob = new Blob([response.r.data], { type: type, encoding: 'UTF-8' })
                    
                    zip.file(response.name, blob)
                });    

                this.$store.state.loaderProps.visible = false;
                zip.generateAsync({type:"blob"}).then(function(content) {
                    FileSaver.saveAs(content, "LabelsAndInvoices.zip")
                }, function(err){
                    console.log(err)
                })
            })
        },
        downloadOSAndPL: async function()
        {
            if(this.selected.length == 0) return;
            this.$store.state.loaderProps.visible = true;
            var zip = new JSZip();
            var shipments_ids = "";
            this.selected.forEach(image => {
                shipments_ids += image.id + ","; 
            })
            shipments_ids = shipments_ids.substring(0, shipments_ids.length - 1);
            
            const pickeoList = await this.getPickeoList(shipments_ids);
            zip.file("Lista_de_pickeo.pdf", pickeoList.data);

            const ordersSummary = await this.getOrdersSummary(shipments_ids);
            zip.file("Resumen_de_ordenes.pdf", ordersSummary.data);

            this.$store.state.loaderProps.visible = false;
            zip.generateAsync({type:"blob"}).then(function(content) {
                FileSaver.saveAs(content, "Picking_List-Order_Summary.zip")
            }, function(err){
                console.log(err)
            })
        },
        getInvoiceFiles: async function(id){
            const lsToken = localStorage.getItem("access_token");
            const invoice = await this.$http.get(this.$store.state.chronosApi + 'shipment/getNote?id=' + id + '&format=PNG', {headers: {Authorization: lsToken}, responseType:'blob' })
            return invoice;
        },
        exportShipments: async function(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const data = await this.$http.get(this.$store.state.chronosApi + 'shipment/exportInProcessOrders?warehouse=' + this.$store.state.warehouseId + '&shipping_service=' + this.shipping_service.toUpperCase() + "&cod=true", {headers: {Authorization: lsToken}})

            var rows = data.data.split('\n');
            var content = [];
            rows.forEach(r => {
                content.push(r.split(','));
            })

            let csvContent = "data:text/csv;charset=utf-8," + content.map(e => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            var downloadLink = document.createElement("a");
            downloadLink.href = encodedUri;

            downloadLink.download = "Procesados - "+ new Date().toISOString().slice(0, 10) + ".csv";
            downloadLink.click();
            this.$store.state.loaderProps.visible = false;
        },
        getPickeoList: async function(ids){
            const lsToken = localStorage.getItem("access_token");
            const file = await this.$http.get(this.$store.state.chronosApi + 'shipment/pickeoList?shipments=' + ids, {headers: {Authorization: lsToken}, responseType:'blob'});
            return file;
        },
        getOrdersSummary: async function(ids){
            const lsToken = localStorage.getItem("access_token");
            const file = await this.$http.get(this.$store.state.chronosApi + 'shipment/ordersSummary?shipments=' + ids, {headers: {Authorization: lsToken}, responseType:'blob'});
            return file;
        }
    },
    watch:{
        '$route.params.shipping_service': function() {
            location.reload();
        }
    }
}
</script>

<style lang="scss">
    
</style>