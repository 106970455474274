<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('edit_shipment_sent.edit') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="order.unique_order_number" :label="$t('edit_shipment_sent.uon')" :rules="rules_required" disabled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="order.shipment_number" :label="$t('edit_shipment_sent.tracking_guide')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="order.invoice" :label="$t('edit_shipment_sent.invoice')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="order.shipping_price" :label="$t('edit_shipment_sent.total')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-spacer></v-spacer> 
                        <v-col cols="6">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('edit_shipment_sent.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save">
                                {{ $t('edit_shipment_sent.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            id: this.$route.params.id,
            order: {
                shipping_service: '',
                unique_order_number: '',
                shipment_number: '',
                invoice: '',
                shipping_price: '',
            },
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers." + this.order.shipping_service + "_prepaid_edit_sent_order";
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/getOrderInformation/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data.attributes;
                this.order.unique_order_number = response.data.uniqueOrderNumber;
                this.order.shipment_number = response.data.shipmentNumber;
                this.order.shipping_service = response.data.shippingService;
                this.order.shipping_price = response.data.shippingPrice;
                this.order.invoice = response.data.invoice;
                this.$store.state.module = "headers." + this.order.shipping_service.toLowerCase() + "_prepaid_edit_sent_order";
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        save(){
            if(this.order.shipment_number != ""){
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.put(this.$store.state.chronosApi + 'shipment/update_information/' + this.id, this.order, {headers: {Authorization: lsToken}})
                .then((response) => {
                    response
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('edit_shipment.success')
                    }
                    this.$router.go(-1);  
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>