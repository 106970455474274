<template>
    <div class="py-0 mt-4 mb-8">
        <v-container class="mx-0 my-0" style="padding:0 !important; max-width: 100% !important">
            
            <!-- Add unexpected product -->
            <v-card v-if="newProduct && !newIncidence" class="mt-1 pt-1 pb-10 px-0 size" style="margin-bottom: 35px !important" align="center">
                <v-row align="end" class="px-4 pb-3" justify="center">
                    <h3 class="px-4 mt-5">{{ $t('end_process.add_unexpected_product') }}</h3>
                </v-row>
                <v-form ref="form2" lazy-validation @submit.prevent="update">
                    <v-row class="px-3 py-0" justify="center" align="center">
                        <v-col cols="6" class="py-0">
                            <v-autocomplete v-model="product.product_id" item-value="id" item-text="sku" :items="items_g" :label="$t('end_process.product.sku')" :rules="rules_required"></v-autocomplete> 
                        </v-col>
                    </v-row>
                    <v-row class="px-3 py-0" justify="center" align="center">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="product.quantity" :rules="rules_required" :label="$t('end_process.product.pieces')" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="px-3 py-0" justify="center" align="center">
                        <v-col cols="6" class="py-0">
                            <v-autocomplete  v-model="product.location_id" :rules="rules_required" item-value="id" item-text="name" :items="racks" :label="$t('end_process.product.location')"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="px-3 py-0" justify="center" align="center" v-if="current_operation.format_type == 'Entrada'">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="product.batch" @keyup="uppercase" :label="$t('end_process.product.batch')" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="px-3 py-0" justify="center" align="center" v-if="current_operation.format_type == 'Entrada'">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="product.pallet" :label="$t('end_process.product.pallet')" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="px-3 py-0" justify="center" align="center" v-if="current_operation.format_type == 'Entrada'">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="product.box" :label="$t('end_process.product.box')" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="px-3 py-0" justify="center" align="center" v-if="current_operation.format_type == 'Entrada'">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="product.expiration" @keyup="uppercase" :label="$t('end_process.product.expiration')" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="px-3 py-0" align="center" justify="center" v-if="current_operation.format_type == 'Salida'">
                        <v-col cols="6" class="py-0">
                            <v-autocomplete v-model="product.pallet" :label="$t('edit_appointment.product_details.pallet')" :items="pallets" :rules="numeric_required" :loading="load"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="px-3 py-0" align="center" justify="center" v-if="current_operation.format_type == 'Salida'">
                        <v-col cols="6" class="py-0">
                            <v-autocomplete v-model="product.box" :label="$t('edit_appointment.product_details.box')" :items="boxes" :rules="numeric_required" :loading="load"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="px-3 py-0" align="center" justify="center" v-if="current_operation.format_type == 'Salida'">
                        <v-col cols="6" class="py-0">
                            <v-autocomplete v-model="product.batch" @keyup="uppercase" :label="$t('edit_appointment.product_details.batch')" :items="batches" :loading="load"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="px-3 py-0" align="center" justify="center" v-if="current_operation.format_type == 'Salida'">
                        <v-col cols="6" class="py-0">
                            <v-autocomplete v-model="product.expiration" @keyup="uppercase" :label="$t('edit_appointment.product_details.expiration')" :items="expirations" :loading="load"></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-form>  
                <v-row align="center" justify="center">
                    <v-col class="mb-1" cols="6" align="end" justify="end">
                        <v-btn color="white" class="mr-2" @click="cancel_prod">{{ $t('end_process.cancel') }}</v-btn>
                        <v-btn color="amarillo" class="white--text" @click="addProduct">{{ $t('end_process.add') }}</v-btn>
                    </v-col> 
                </v-row>
            </v-card>

            <!-- Confirm Products -->
            <v-card class="mt-1 pt-1 pb-10 px-0 size" style="margin-bottom: 35px !important" align="center" v-if="!newProduct">
                <v-row class="px-3 py-4 text-center" justify="center" align="center">
                    <v-col cols="6" class="py-1" justify="center" align="center">
                        <span justify="center" class="pr-1 secondary--text azul--text text-uppercase font-weight-bold" style="font-size:18px">
                            {{ $t('end_process.process_container') }} 
                        </span> 
                    </v-col>
                </v-row>
                <v-col cols="6" justify="start" align="start" class="px-0">
                    <v-row>
                        <v-col cols="6" class="py-1">
                            <span justify="center" class="pr-1 azul--text text--uppercase font-weight-bold" style="font-size:18px">
                                {{ $t('end_process.general_data') }} 
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('end_process.appointment') }}: </span> {{ this.id }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('end_process.client') }}: </span> {{ this.appointment.client.name }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('end_process.estimated_date_of_arriving') }}: </span> {{ this.appointment.arrival_date }} </v-col>
                    </v-row><v-row>
                        <v-col v-if="$store.state.language == 'es'" cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('register_in_process.operation') }}: </span> {{ this.appointment.operation.description }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="$store.state.language == 'en'" cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('register_in_process.operation') }}: </span> {{ this.appointment.operation.description1 }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('end_process.container') }}: </span> {{ this.appointment.container_number }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('end_process.description') }}: </span> {{ this.appointment.description }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('end_process.comments') }}: </span> {{ this.appointment.commentary }} </v-col>
                    </v-row>
                </v-col>
                <v-col class="py-5 px-0" cols="6" justify="start" align="start">
                    <v-row v-if="rfid.is_rfid && !rfid.is_entry" justify="center" align="center">
                        <v-sheet  height="100%">
                        <div class="d-flex fill-height justify-center align-center">
                            <div class="text-h2" style="line-height: 5rem; font-weight: bold; font-size: 36px !important;">
                                SALIDA POR RFID
                            </div>
                        </div>
                        </v-sheet>
                    </v-row>
                    <v-row>
                        <v-col cols="8" class="py-1">
                            <span justify="center" class="pr-1 azul--text font-weight-bold" style="font-size:18px">
                                {{ $t('register_in_process.products') }} 
                            </span>
                        </v-col>
                        <v-col cols="4" class="py-1">
                            <v-btn color="amarillo" :disabled="appointment.products.length < 30" class="white--text mr-2" @click="checkAll"> {{ $t('register_in_process.check_all') }}</v-btn>
                            
                        </v-col>
                    </v-row>
                    <datatable :props="{ headers:headers_sku, items: appointment.products }" @methodHandler="methodHandler" :income_editable="1"></datatable>
                    <v-col class="pb-1 px-0" cols="12" align="center" justify="center">
                        <v-btn color="azul" outlined class="white--text" @click="newProduct = true;" style="width:100%">{{ $t('end_process.add_unexpected_product') }}</v-btn>
                    </v-col>
                    <br>
                    <v-row align="center" justify="center">
                        <v-col class="mb-1 px-0" cols="2" align="center" justify="center">
                            <v-btn color="white" class="black--text" @click="back" style="width:100%">{{ $t('end_process.cancel') }}</v-btn>
                        </v-col> 
                        <v-col class="mb-1 px-0" cols="4" align="center" justify="center">
                            <v-btn color="amarillo" outlined class="white--text mr-2" @click="toOnPremises"> {{ $t('register_in_process.to_on_premises') }}</v-btn>
                        </v-col> 
                        <v-col class="mb-1" cols="6" align="center" justify="center">
                            <v-btn color="amarillo" class="white--text" @click="update" style="width:100%">{{ $t('end_process.ready_to_go') }}</v-btn>
                        </v-col> 
                    </v-row>   
                </v-col>
            </v-card>

            <v-dialog v-model="newIncidence" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center" class="my-2">
                            <h3 class="text-uppercase">
                                {{ $t('end_process.damaged_product') }}
                            </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-0 px-0 mt-0 text-center">
                            <v-col cols="10">
                                <label style="font-size: 13px; font-weight: bold;" class="azul--text">{{$t('end_process.warning')}}</label>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="py-0 px-8 my-0 text-center">
                            <v-col cols="10">
                                <v-text-field v-model="incidence.damaged" :rules="numeric_required" :label="$t('end_process.products_diference')" ></v-text-field>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn color="white" class="mr-2" @click="newIncidence = false;">{{ $t('end_process.cancel') }}</v-btn>
                            <v-btn color="orange" class="white--text" @click="addIncidence">{{ $t('end_process.save') }}</v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker2"></div>
                </v-card>
            </v-dialog>
            <bottom-bar ref="bottombar"></bottom-bar>
        </v-container>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import io from 'socket.io-client'

export default {
    computed: {
        headers_sku: function(){
            var arr = [
                {text: this.$t('process_pendings.sku'), value: 'sku_p', class: 'text-uppercase'},
                // {text: this.$t('edit_appointment.description'), value: 'descripcion', class: 'text-uppercase'},
                {text: this.$t('process_pendings.pieces'), value: 'quantity', class: 'text-uppercase'},
                {text: this.$t('process_pendings.location'), value: 'location', class: 'text-uppercase'},
                {text: this.$t('process_pendings.pallet'), value: 'pallet', class: 'text-uppercase'},
                {text: this.$t('process_pendings.box'), value: 'box', class: 'text-uppercase'},
                {text: this.$t('process_pendings.batch'), value: 'batch', class: 'text-uppercase'},
                {text: this.$t('process_pendings.expiration'), value: 'expiration', class: 'text-uppercase'},
                {text: '', value: 'actions', class: 'text-uppercase', width:"90px"},
            ];
            if(this.rfid.is_rfid && !this.rfid.is_entry)
                arr.splice(2,0,{text: 'Read', value: 'read', class: 'text-uppercase'})
            return arr;
        },
        rules_required(){return [v => !!v || v == 0 || this.$t('create_appointment.validations.required_field')];},
        numeric_required(){
            return [
                v => (/^(0|[1-9][0-9]{0,9})$/).test(v) || this.$t('register_arrivals.validations.numbers'),
                v => !!v || v == 0 || this.$t('edit_appointment.validations.required_field')
            ];
        },
    },
    data(){
        return {
            id: this.$route.params.id,
            rfid: {
                printers: [],
                is_rfid: false,
                is_entry: false,
                printer_selected: null,
                print_type: {
                    pallet: false,
                    box: false,
                    tag: false
                }
            },
            actions: [ // Income movements
                {
                    name: this.$t('end_process.checked'),
                    icon: {
                        color: 'success2',
                        icon: 'done'
                    },
                    method: "check()",
                    permission: "operation.update"
                },
                {
                    name: this.$t('end_process.add_incidence'),
                    icon: {
                        color: 'warning',
                        icon: 'warning'
                    },
                    method: "incidenceNew",
                    permission: "a_incidence.create"
                },
                {
                    name: this.$t('end_process.already_checked'),
                    icon: {
                        color: 'success2',
                        icon: 'check_circle'
                    },
                    value: false,
                }
            ],
            actions_s: [ // Outcome movements (Salidas)
            {
                    name: this.$t('end_process.checked'),
                    icon: {
                        color: 'success2',
                        icon: 'done'
                    },
                    method: "check()",
                    permission: "operation.update"
                },
                {
                    name: this.$t('end_process.already_checked'),
                    icon: {
                        color: 'success2',
                        icon: 'check_circle'
                    },
                    value: false,
                },
                {
                    name: 'rfid',
                    icon: {
                        color: 'white',
                        icon: 'call_split'
                    },
                    method: "add",
                    value: true,
                }
            ],
            snackbar: {
                visible: false,
                color: 'primary',
                timeout: 2000,
                text: ''
            },
            appointment:{
                // General data
                client_id: "",
                is_rfid: false,
                is_entry: false,
                client: {
                    name:""
                },
                arrival_date: "",
                eta: "",
                operation_id: "",
                operation: {
                    description: "",
                    description1: ""
                },
                status: "",
                container_number: "",
                description: "",
                commentary: "",
                additional_services: "",
                purchase_order: "",
                is_national: "",
                products: [],
                products_length: "",
            },
            newProduct: false,
            newIncidence: false,
            incidence: {
                damaged: 0,
            },
            product: {
                pallet: '', 
                product_id: '',
                quantity: '',
                location_id: '',
                batch: '',
                expiration: '',
                box: ''
            },
            currentItemIncidenceIndex: '',
            items_g: [],
            racks: [],
            batches: [],
            boxes: [],
            pallets: [],
            expirations: [],
            quantities: [],
            current_operation: "",
            load: false,
        }
    },
    beforeDestroy() {
        // Desconecta de Socket.IO cuando el componente se destruye
        if (this.socket) {
        this.socket.disconnect()
        }
    },
    mounted(){
        localStorage.setItem('bottom', 2);
        this.$store.state.module = "headers.end_process_operation";
        this.$store.state.bottom = 2;
        this.index();
    },
    methods: {
        create_connection(gateChannel){
            //this.headers_sku.splice(2,0,{text: 'Read', value: 'read', class: 'text-uppercase'})
            this.socket = io(this.$store.state.rfidApi) 
            this.socket.on('connect', () => {
            })
            this.socket.emit('joinGate', gateChannel + '');
            this.socket.on('event', (data) => {
                // console.log('llegaron datos del socket')
                // console.log(data)
                this.appointment.details = data.products.data.details
                this.appointment.tags = data.products.data.tags
                this.appointment.is_rfid = true
                this.appointment.products.forEach(element => {
                    element.read = 0
                    element.is_another = false // cuando el producto solo es distinto en tarima pero en operacion es lo mismo se utiliza esta bandera
                });
                data.products.data.details.forEach(element => {
                    element.used = false // cuando el producto solo es distinto en tarima pero en operacion es lo mismo se utiliza esta bandera
                });
                this.appointment.products = this.appointment.products.filter(f => f.quantity!=0)
                // primer recorrido verifica que todo sea exactamente igual incluyendo tarima e indicando que ya se utilizo el item para que no se repitan
                data.products.data.details.forEach(detail => {
                    this.appointment.products.forEach(product => {
                        if(product.product_id == detail.product && product.location_id == detail.location && product.batch == detail.batch && product.expiration == detail.reference && product.box == detail.box && product.pallet == detail.pallet)
                        {
                            detail.used=true
                            product.read = detail.quantity
                            product.detailed_stock_id = detail.id
                            if(product.read == product.quantity)
                                product.actions[1].value = true
                        }
                    });
                });
                // segundo ciclo sin tomar en cuenta los que ya se usaron y ahora se checa todo menos la tarima
                data.products.data.details.forEach(detail => {
                    this.appointment.products.forEach(product => {
                        if(detail.used == false && product.product_id == detail.product && product.location_id == detail.location && product.batch == detail.batch && product.expiration == detail.reference && product.box == detail.box )
                        {
                            detail.used=true
                            product.read = detail.quantity
                            product.pallet = detail.pallet
                            product.detailed_stock_id = detail.id
                            product.actions[2].icon.color = "warning"
                            product.is_another = true
                            if(product.read == product.quantity)
                                product.actions[1].value = true
                        }
                    });
                });
                // los que sobraron se agregan como excedente
                data.products.data.details.forEach(detail => {
                    if(detail.used == false)
                    {
                        
                        var last = this.appointment.products.push({
                            batch: detail.batch,
                            box: detail.box,
                            expiration: detail.reference,
                            location: detail.location_name,
                            location_id: detail.location,
                            pallet: detail.pallet,
                            product_id: detail.product,
                            quantity: 0,
                            read: detail.quantity,
                            sku_p: detail.sku,
                            actions: JSON.parse(JSON.stringify(this.actions_s)),
                            parameters: this.appointment.products.length
                        })

                        var last_product = this.appointment.products[ last - 1 ]
                        last_product.actions[0].method = "check";
                        last_product.actions[2].icon.color = "error"                        
                        last_product.actions[2].icon.icon = "add"
                    }
                })
            })
        },
        back(){
            this.$router.push({ name: 'OperationOnPremises' });
        },
        methodHandler(object){
            console.log("metodo padre")
            console.log(object)
            this[object.methodName](object.parameters);
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'appointments/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data = response.data.data.attributes;
                    // products and Details
                    var details = response.data.detail_orders;
                    var operation = response.data.operation;
                    
                    // console.log('la informacion general es ')
                    // console.log(response.data)
                    // General Data
                    this.rfid.is_rfid                       = response.data.is_rfid ?? null;
                    this.$store.state.clientId              = response.data.client.id ?? null;
                    this.appointment.client_id              = response.data.client.id ?? null;
                    this.appointment.client                 = response.data.client ?? null;
                    this.appointment.operation              = response.data.operation ?? null;
                    this.appointment.arrival_date           = response.data.arrival_date.substring(0, 10) ?? null;
                    if(response.data.is_national == true && response.data.eta != undefined && response.data.eta != null)
                        this.appointment.eta                = response.data.eta.substring(0, 10);
                    this.appointment.operation_id           = response.data.operation.id ?? null;
                    this.appointment.statuis                = response.data.status;
                    this.current_operation                  = response.data.operation;
                    this.appointment.additional_services    = response.data.additional_services ?? null;
                    this.appointment.purchase_order         = response.data.purchase_order ?? null;
                    this.appointment.container_number       = response.data.container_number ?? null;
                    this.appointment.description            = response.data.description ?? null;
                    this.appointment.commentary             = response.data.commentary ?? null;
                    this.appointment.status                 = response.data.status ?? null;
                    this.appointment.is_national            = response.data.is_national ?? false;
                    this.appointment.is_rfid                = response.data.is_rfid ?? false;
                    
                    if(this.appointment.operation.format_type == "Entrada" && this.appointment.is_rfid){
                        this.appointment.is_entry = true
                        this.rfid.is_entry = true;
                        this.rfid.is_rfid = true;
                    }
                    
                    if(this.rfid.is_rfid && !this.rfid.is_entry) this.create_connection("gate-room-" + response.data.gate.id)
                    
                    this.$http.get(this.$store.state.chronosApi + 'getProducts/' + response.data.client.id, {headers: {Authorization: lsToken}})
                    .then((response) => {
                        this.appointment.products = [];
                        if(response.status === 200){
                            response.data = response.data.data
                            response.data.forEach(x =>{
                                x = x.attributes;
                                this.items_g.push({
                                    sku_p: x.sku,
                                    sku: x.sku + ' | ' + x.description, 
                                    id: x.id
                                });
                            });
                            
                            details.forEach(x => {
                                x = x.attributes;
                                var ac = "";
                                if(operation.format_type == "Salida") ac = this.actions_s;
                                else if( operation.format_type == "Entrada") ac = this.actions;
                                var p = {};
                                if(x.location != null){
                                    p = {
                                        sku_p: x.product.sku,
                                        product_id: x.product.id,
                                        quantity: x.real ?? x.expected,
                                        damaged: 0,
                                        location_id: parseInt(x.location.id),
                                        location: x.location.name,
                                        pallet: parseInt(x.pallet),
                                        box: x.box,
                                        read: 0,
                                        is_another: false,
                                        batch: x.batch,
                                        expiration: x.expiration,
                                        actions: JSON.parse(JSON.stringify(ac)),
                                        parameters: this.appointment.products.length
                                    }
                                }
                                else {
                                    p = {
                                        sku_p: x.product.sku,
                                        product_id: x.product.id,
                                        quantity: x.real ?? x.expected,
                                        damaged: 0,
                                        location_id: "---",
                                        location: "---",
                                        pallet: parseInt(x.pallet),
                                        box: x.box,
                                        read: 0,
                                        is_another: false,
                                        batch: x.batch,
                                        expiration: x.expiration,
                                        actions: JSON.parse(JSON.stringify(ac)),
                                        parameters: this.appointment.products.length
                                    }
                                }
                                // console.log(this.rfid)
                                // if(!this.rfid.is_rfid && (this.$store.state.loggedUserEmail == 'alejandro@intech.mx' || this.$store.state.loggedUserEmail == 'yuz@chronosmexico.com' || this.$store.state.loggedUserEmail == 'develop@intech.com')) {                                    
                                //     if(operation.format_type == "Salida") p.actions[1].value = true;
                                //     else if( operation.format_type == "Entrada") p.actions[2].value = true;
                                // }

                                p.actions[0].method = "check";
                                            
                                this.appointment.products.push(p);
                            });
                        }
                    });
                }

                // this.$http.get(this.$store.state.chronosApi + 'getLocations/' + response.data.operation.id + '/' + response.data.client.id + '/?multiple=true', {headers: {Authorization: lsToken}})
                // .then((response) => {
                //     console.log(response);
                //     if(response.status === 200){
                //         response.data.forEach(x =>{
                //             this.racks.push({
                //                 id: x.id,
                //                 name: x.name
                //             });
                //         });
                //     }
                // });       
            })
            .catch((response) => {              
                this.$store.state.loaderProps.visible = false;
                console.log(response)
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        update(){
            var allOk = true;
            if(this.appointment.products.length <= 0){
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('end_process.empty_skus')
                } 
                allOk = false;
            }

            if(this.appointment.operation.format_type == "Entrada"){
                this.appointment.products.forEach(x => {
                    if(x.actions[2].value == false) allOk = false;
                })
            }else{
                this.appointment.products.forEach(x => {
                    if(x.actions[1].value == false) allOk = false;
                })
            }
            if(!this.rfid.is_entry && this.rfid.is_rfid){
                this.appointment.is_rfid = 1
                this.appointment.products.forEach(x => {
                    if(x.actions[1].value == false) allOk = false;
                })
            }
            if(allOk){
                const lsToken = localStorage.getItem("access_token");
                this.appointment.products = this.appointment.products.filter(product => product.quantity > 0);
                this.appointment.status = 'Pendiente de salida';
                this.appointment.products_length = this.appointment.products.length;
                this.$store.state.loaderProps.visible = true;
                
                const formData = this.obj2FormData(this.appointment);
                // console.log([...formData]) // Print form data displaying all pr
                this.$http.post(this.$store.state.chronosApi + 'confirmProducts/' + this.id, formData, {headers: {Authorization: lsToken, contentType: 'multipart/form-data'}})
                .then((response) => {   
                    if(response.status === 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('end_process.responses.correct')
                        } 
                        this.$router.push({ name: 'PendingOut' });
                    }
                }).catch((error) => {
                    console.log(error);
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('end_process.responses.update')
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('end_process.responses.skus')
                }
            }
        },
        addProduct(){
            var i = {
                pallet: '', 
                product_id: '',
                quantity: '',
                location_id: '',
                location: '',
                batch: '',
                expiration: '',
                box: '', 
                damaged: 0,
                incidence: null,
                sku_p: '',
                actions: '',
                parameters: '',
            };

            var t = this;
            Object.keys(t.product).forEach(function (key) {
                var value = t.product[key]; 
                if(key == "quantity" || key == "location_id" || key == "product_id") i[key] = parseInt(value);
                else if(key == 'batch' || key == 'expiration'){
                    if(value == "" || value == " " || value == undefined || value == null) i[key] = null;
                    else i[key] = value;
                }
                else i[key] = value;
            });

            i.location = this.racks.find(item => item.id = this.product.location_id).name
            i.sku_p = this.items_g.find(i => i.id == this.product.product_id).sku_p
            if(this.current_operation.format_type == "Salida"){
                i.actions = JSON.parse(JSON.stringify(this.actions_s)),
                i.actions[0].method = "check";
            }else{
                i.actions = JSON.parse(JSON.stringify(this.actions)),
                i.actions[0].method = "check";
            }
            i.parameters = this.appointment.products.length
            i.damaged = 0;
            this.appointment.products.push(i);
            this.$refs.form2.reset();
            this.newProduct = false;
        },
        cancel_prod(){
            this.$refs.form2.reset();

            this.newProduct = false;
        },
        addIncidence(){
            if(this.incidence.damaged <= this.appointment.products[this.currentItemIncidenceIndex].quantity){
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('end_process.responses.store')
                }; 
                this.appointment.products[this.currentItemIncidenceIndex].damaged = this.incidence.damaged;
                this.incidence.damaged = 0;
                this.appointment.products[this.currentItemIncidenceIndex].actions[2].value = true;
                this.appointment.products[this.currentItemIncidenceIndex].actions[1].value = true;
                // this.appointment.products[this.currentItemIncidenceIndex].actions[1].name = this.$t('end_process.already_exist_incidence');
                // this.appointment.products[this.currentItemIncidenceIndex].actions[2].name = this.$t('end_process.already_checked_with_incidence');
                this.newIncidence = false;
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('end_process.responses.quantity_exception')
                };
                this.incidence.damaged = 0;
            }
            
        },
        incidenceNew(i){
            this.currentItemIncidenceIndex = i;
            this.newIncidence = true;
            this.incidence.damaged = this.appointment.products[this.currentItemIncidenceIndex].damaged;
        },
        uppercase() {
            if(this.product.batch != null){
                this.product.batch = this.product.batch.toUpperCase();
                this.product.batch = this.product.batch.replace(/\s/g,'');
            }

            if(this.product.expiration != null){
                this.product.expiration = this.product.expiration.toUpperCase();
                this.product.expiration = this.product.expiration.replace(/\s/g,'');
            }
        },
        add(i) {
            if(this.rfid.is_rfid && !this.rfid.is_entry)
            {
                if(this.appointment.products[i].actions[2].icon.icon == 'add')
                {
                    this.appointment.products[i].actions[1].value = true;
                    if(this.appointment.products[i].quantity > 0)
                        this.appointment.products[i].quantity = 0
                    else
                        this.appointment.products[i].quantity = this.appointment.products[i].read
                }
            }
        },
        check(i) {
            if(this.rfid.is_rfid && !this.rfid.is_entry)
            {
                if(this.appointment.products[i].quantity < this.appointment.products[i].read)
                    this.appointment.products[i].actions[1].value = !this.appointment.products[i].actions[1].value;
                else
                    return
                
            }
            else
            if(this.appointment.operation.format_type == "Entrada") this.appointment.products[i].actions[2].value = !this.appointment.products[i].actions[2].value;
            else this.appointment.products[i].actions[1].value = !this.appointment.products[i].actions[1].value;
        },
        checkAll(){
            if(this.rfid.is_rfid && !this.rfid.is_entry) return
            for(var count = 0; count < this.appointment.products.length; count++){
                this.check(count);
            }
        },
        obj2FormData(obj){
            var formData = new FormData();
            var createFormData = function(obj, subKeyStr = ''){
                for(let i in obj){
                    let value          = obj[i];
                    let subKeyStrTrans = subKeyStr ? subKeyStr + '[' + i + ']' : i;
                    if(!subKeyStrTrans.includes('actions') && subKeyStrTrans != 'operation' && subKeyStrTrans != 'client'){
                        if(typeof(value) === 'string' || typeof(value) === 'number') formData.append(subKeyStrTrans, value);
                        else if(value instanceof File) formData.append(subKeyStrTrans, value);
                        else if(value instanceof Blob) formData.append(subKeyStrTrans, value);
                        else if(typeof(value) === 'object') createFormData(value, subKeyStrTrans);    
                    }
                }
            }

            createFormData(obj);

            return formData;
        },
        toOnPremises(){
            this.appointment.newStatus = 'En recinto';
            this.appointment.status = 'En recinto';
            this.appointment.gate_id = null;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.put(this.$store.state.chronosApi + 'setStatus/' + this.id, this.appointment, {headers: {Authorization: lsToken}})
            .then((response) => {   
                if(response.status === 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('register_in_process.responses.correct')
                    } 
                    this.$router.push({ name: 'OperationOnPremises' });
                }
            }).catch((error) => {
                console.log(error);
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('register_in_process.responses.update')
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        }
    },
    watch:{
        'clients': function() {
            this.index();
        },
        'product.product_id': function() {
            const lsToken = localStorage.getItem("access_token");
            if(this.current_operation.format_type == "Salida"){
                this.$http.get(this.$store.state.chronosApi + 'getLocations/' + this.appointment.operation_id + '/' + this.appointment.client_id + '/?product_id='+this.product.product_id , {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200){
                        this.racks = [];
                        response.data.forEach(x =>{
                            this.racks.push({
                                id: x.id,
                                name: x.name
                            });
                        });
                    }
                });
            } else {
                this.$http.get(this.$store.state.chronosApi + 'getLocations/' + this.appointment.operation_id + '/' + this.appointment.client_id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200){
                        this.racks = [];
                        response.data.forEach(x =>{
                            this.racks.push({
                                id: x.id,
                                name: x.name
                            });
                        });
                    }
                });
            }
        },
        'product.location_id': function() {
            const lsToken = localStorage.getItem("access_token");
            if(this.current_operation.format_type == "Salida" && this.product.product_id != "" && this.product.location_id != "" && this.product.product_id != undefined && this.product.product_id != null && this.product.location_id != undefined && this.product.location_id != undefined){
                this.batches = [];
                this.boxes = [];
                this.pallets = [];
                this.expirations = [];
                this.load = true;

                this.$http.get(this.$store.state.chronosApi + 'products_per_location/' + this.product.product_id + "/" + this.product.location_id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200){
                        response.data = response.data.data;                        
                        response.data.forEach(x =>{
                            x = x.attributes;
                            if(x.quantity > 0){
                                if(x.batch == null || x.batch == undefined) this.batches.push(" ")
                                else this.batches.push(x.batch);
                                if(x.reference == null || x.reference == undefined) this.expirations.push(" ")
                                else this.expirations.push(x.reference);
                                this.boxes.push(x.box.toString());
                                if(x.pallet_per_location != null && x.pallet_per_location != undefined) this.pallets.push(x.pallet_per_location.pallet.toString());
                                else this.pallets.push(" ")
                                this.quantities.push(x.quantity);
                            }
                        });
                    }
                }).finally(() =>{
                    this.load = false;
                });  
            }
        },
        'product.pallet': function(){
            if(this.current_operation.format_type == "Salida"){
                var i = this.pallets.indexOf(this.product.pallet, 0);
                this.product.box = this.boxes[i];
                this.product.batch = this.batches[i];
                this.product.expiration = this.expirations[i];   
            }
        },
        'product.box': function(){
            if(this.current_operation.format_type == "Salida"){
                var i = this.boxes.indexOf(this.product.box, 0);
                this.product.pallet = this.pallets[i];
                this.product.batch = this.batches[i];
                this.product.expiration = this.expirations[i];
            }
        },
        'product.batch': function(){
            if(this.current_operation.format_type == "Salida"){
                var i = this.batches.indexOf(this.product.batch, 0)
                this.product.box = this.boxes[i];
                this.product.pallet = this.pallets[i];
                this.product.expiration = this.expirations[i];
            }
        },
        'product.expiration': function(){
            if(this.current_operation.format_type == "Salida"){
                var i = this.expirations.indexOf(this.product.expiration, 0)
                this.product.box = this.boxes[i];
                this.product.batch = this.batches[i];
                this.product.pallet = this.pallets[i];
            }
        }
    }
}
</script>