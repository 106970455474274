<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('liverpool_confirmation.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="acceptOrders">
                        {{ $t('liverpool_confirmation.confirm_orders') }}
                        <v-icon class="px-1" small>add</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <v-data-table class="px-0 my-2"
                                no-results-text="No hay resultados"
                                :headers="headers"
                                :items="props.items"
                                :search="props.search"
                                calculate-widths
                                :footer-props="{
                                    'items-per-page-text': this.$t('datatable.shown'),
                                    showFirstLastPage: true,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2} ' + this.$t('datatable.results'),
                                    'show-current-page': true,
                                    'items-per-page-options': typeof props.rowsPP === 'undefined' ? [10, 25, 50, 100] : props.rowsPP}"
                                :page="1"
                                :mobile-breakpoint="0"
                                item-key="order_id"
                                show-select
                                v-model="selected"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                                        <template v-slot:activator="{ on }">
                                            <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                            <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ action.name }}</span>
                                    </v-tooltip>
                                </template>
                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDetail" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('liverpool_confirmation.order_details.header') }} {{ this.orderToShow.uniqueOrderNumber }}
                            </h4>
                        </v-row>
                    </v-card-title>
                    
                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('liverpool_confirmation.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("liverpool_confirmation.order_number") }}</label> <label>{{ this.orderToShow.uniqueOrderNumber }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("liverpool_confirmation.date") }}</label> <label>{{ this.orderToShow.createdAt }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("liverpool_confirmation.shipping_service") }}</label> <label>{{ this.orderToShow.shippingService }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.client != null"><label style="font-weight:bold">{{ $t("liverpool_confirmation.client") }}</label> <label>{{ this.orderToShow.client.name }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("liverpool_confirmation.cod") }}</label> <label>{{ this.orderToShow.total }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("liverpool_confirmation.buyer") }}</label> <label>{{ this.orderToShow.consignee.name }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("liverpool_confirmation.state") }}</label> <label>{{ this.orderToShow.consignee.state }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("liverpool_confirmation.municipality") }}</label> <label>{{ this.orderToShow.consignee.municipality }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("liverpool_confirmation.neighborhood") }}</label> <label>{{ this.orderToShow.consignee.neighborhood }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("liverpool_confirmation.street") }}</label> <label>{{ this.orderToShow.consignee.street }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("liverpool_confirmation.zip_code") }}</label> <label>{{ this.orderToShow.consignee.zipcode }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('liverpool_confirmation.products') }}</label></div>
                    <span v-if="orderToShow.shipmentProductDetail != null">
                        <div v-for="(product, i) of orderToShow.shipmentProductDetail.data" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.attributes.product.sku }}</label> <label>{{ product.attributes.quantity }}</label></div>
                    </span>

                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDetail = false">{{ $t('liverpool_confirmation.go_back') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="supplyOrder(orderToShow)">
                                {{ $t('liverpool_confirmation.supply') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
        </v-container>   
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
	computed: {
        headers(){
            return [
                { text: this.$t('liverpool_confirmation.id'), value: 'order_id' },
                { text: this.$t('liverpool_confirmation.total'), value: 'total_price' },
                { text: this.$t('liverpool_confirmation.customer'), value: 'customer' },
                { text: this.$t('liverpool_confirmation.actions'), value: 'actions' },
            ];
        }
    },
    data(){
        return {
            shipping_service: this.$route.params.shipping_service,
            sending_data: false,
            props: {
                search: '',
                items: [
                ],
            },
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            selected: [],
            modalDetail: false,
            orderToShow: '',
            actions:[
                {
                    name: this.$t('liverpool_confirmation.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                }
            ]
        }
    },
    mounted(){
        this.$store.state.module = "headers." + this.shipping_service + "_cod_pendings";
        this.index();
    },
    methods: {
        methodHandler(object, params){
            this[object](params)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.props.items = [];
            this.selected = [];
            this.$http.get(this.$store.state.chronosApi + 'liverpool/orders?paginate=true&startDate=2023-01-01&orderState=SHIPPING', {headers: {Authorization: lsToken}})
            .then((response) => {
                console.log(response);
                // Get Shippment_services
                this.$store.state.loaderProps.visible = true;
                response.data.data.forEach( x => { 
                    x['actions'] = JSON.parse(JSON.stringify(this.actions));
                    x.parameters = x;
                    x.customer = "Qvene";
                    this.props.items.push(x);
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        showDetails(order){
            this.orderToShow = order;
            // this.$store.state.loaderProps.visible = true;
            // const lsToken = localStorage.getItem("access_token");
            // this.$http.get(this.$store.state.chronosApi + 'shipment/getOrderInformation/' + order.id, {headers: {Authorization: lsToken}})
            // .then((response) => {
            //     response.data = response.data.data.attributes;
            //     this.$store.state.loaderProps.visible = false;
            //     this.orderToShow = response.data;
            //     this.modalDetail = true;
            // }).catch((err) => {
            //     console.log(err);
            // }).finally(() => {
            //     this.$store.state.loaderProps.visible = false;
            // })
        },
        acceptOrders(){
            if(this.selected.length > 0){
                var orders = [];
                this.selected.forEach(x => {
                    orders.push({
                        order_id: x.order_id,
                        customer: {
                            billing_address: {
                                city: "",
                                company: "",
                                country: "",
                                country_iso_code: "",
                                firstname: "",
                                lastname: "",
                                phone: "",
                                state: "",
                                street_1: "",
                                street_2: "",
                                zip_code: ""
                            },
                            locale: "es_MX",
                            shipping_address: {
                                additional_info: null,
                                city: "Tlaquiltenango",
                                company: null,
                                country: "MX",
                                country_iso_code: "MEX",
                                firstname: "Ma",
                                lastname: "Estrada",
                                phone: "734-1202940",
                                state: "MORELOS",
                                street_1: "Calle:Bugambilias,Numero:31,",
                                street_2: "Colonia:GABRIEL TEPEPA,Municipio:TLAQUILTENANGO,entre calle:Juan Aldama,y calle:Heroes de nacozari",
                                zip_code: "62980"
                            }
                        },
                        order_lines: [
                            {
                                product_sku: "1102783162",
                                quantity: 1
                            }
                        ],
                        order_state: "SHIPPING"
                    })
                })
                console.log(orders);
                // this.$http.post(this.$store.state.chronosApi + 'liverpool/orders/confirm', { orders: orders }, {})
                // .then((response) => {
                //     console.log(response);
                //     // Get Shippment_services
                //     this.snackbar = {
                //         visible: true,
                //         color: 'success2',
                //         text: this.$t('liverpool_confirmation.accepted')
                //     }

                //     this.index();
                // }).catch((err) => {
                //     console.log(err);
                // }).finally(() => {
                //     this.$store.state.loaderProps.visible = false;
                // })
            } else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('liverpool_confirmation.must_select')
                }
            }
        }
    },
    watch:{
        // 'selected': function() {
        //     console.log(this.selected)
        // }
    }
}
</script>

<style lang="scss">
</style>