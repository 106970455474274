<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5" justify="center" align="center">
                    <v-col cols="4" align="start" justify="start">    
                        <h3 class="azul--text text-uppercase">{{ $t('visitor_details.details') }}</h3>
                    </v-col>
                    <v-col cols="2" align="end" justify="end">
                        <v-btn class="right white--text" color="amarillo" @click="cancel" >
                            {{ $t('visitor_details.go_back') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <!-- <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0">
                            <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('visitor_details.general_data')}}</label>
                        </v-col>
                    </v-row> -->
                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('visitor_details.date_of_visit')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.visitor.date }} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('visitor_details.time_of_visit')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.visitor.entry_time }} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('visitor_details.name')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.visitor.visitor_name}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('visitor_details.company')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.visitor.company}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('visitor_details.vehicle_plates')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.visitor.plates}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('visitor_details.receiver_name')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.visitor.receiver_name}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('visitor_details.subject')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.visitor.subject}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('visitor_details.observations')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.visitor.observations}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="5" class="py-0 px-3">
                            <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :disabled="true" :clearable="false" v-model="visitor.identification_photo" accept="image/*" capture="camera" :label="$t('edit_visitor.identification_photo')"></v-file-input>
                        </v-col>
                        <v-col cols="1" >
                            <v-row justify="end" align="end">
                                <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('identification')" :disabled="identification_file == false"><v-icon small>download</v-icon></v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="5" class="py-0 px-3">
                            <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :disabled="true" :clearable="false" v-model="visitor.vehicle_photo" accept="image/*" capture="camera" :label="$t('edit_visitor.vehicle_photo')"></v-file-input>
                        </v-col>
                        <v-col cols="1">
                            <v-row justify="end" align="end">
                                <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('vehicle')" :disabled="vehicle_file == false"><v-icon small>download</v-icon></v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            menu: false,
            entry_time_modal: false,
            identification_file: false,
            vehicle_file: false,
            modalDelete: false,
            currentDelete: "",
            current_files: [],
            visitor: {
                date: "",
                entry_time: "", 
                departure_time: "", 
                visitor_name: "", 
                company: "", 
                plates: "", 
                receiver_name: "", 
                subject: "", 
                observations: "", 
                warehouse_id: this.$store.state.warehouseId,
                identification_photo: [],
                vehicle_photo: []
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.visitors";
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.$http.get(this.$store.state.chronosApi + 'visitors/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.current_files = [];
                response.data = response.data.data.attributes
                this.visitor = response.data;

                if(this.visitor.identification_photo != null) {
                    this.identification_file = true;
                    this.current_files.push(this.visitor.identification_photo);

                    this.visitor.identification_photo = new File(["foo"], this.visitor.identification_photo.filename, {
                        type: "text/plain",
                    });
                }
                    
                if(this.visitor.vehicle_photo != null) {
                    this.vehicle_file = true;
                    this.current_files.push(this.visitor.vehicle_photo);

                    this.visitor.vehicle_photo = new File(["foo"], this.visitor.vehicle_photo.filename, {
                        type: "text/plain",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        cancel(){
            this.$router.go(-1);
        },
        getFile(type){
            this.$store.state.loaderProps.visible = true;
            this.current_files.forEach(x => {
                if(x.filename.includes(type)){    
                this.$store.state.loaderProps.visible = true;
                    this.$http.get(this.$store.state.chronosApi + 'getSingleFile/' + x.id, { responseType: 'blob'})
                    .then(response => {
                        FileSaver.saveAs(response.data, x.filename)
                        this.$store.state.loaderProps.visible = false;
                    })
                }
            })

            this.$store.state.loaderProps.visible = false;
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>