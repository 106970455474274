<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="mx-3 pt-4 pb-2" >
                <v-col sm="6" md="2" class="py-0 my-0">
                    <client-selector :addAllOption="false" class="py-0 mb-1" ref="clientSelector" @getClients="v => items.clients = v" :is_national="2"></client-selector>
                </v-col>
                <v-col sm="6" md="2" class="py-0 my-0">
                    <v-autocomplete class="py-0 mb-1" item-value="value" item-text="name" v-model="search.criterion" :items="criterions" :label="$t('search.criterion')"></v-autocomplete>
                </v-col>
                <v-col sm="6" md="2" class="py-0 my-0">
                    <v-text-field class="py-0 mb-1" v-model="search.value" :label="$t('search.data_to_search')"></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6" align="end" class="py-0" justify="center">

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" class="right white--text mr-2" :disabled="!can_mark_damaged" style="max-width: 40px !important; min-width: 40px !important" color="amarillo" @click="damagedProduct">
                                <v-icon class="material-icons-outlined" small>error_outline</v-icon>
                            </v-btn>
                        </template>

                        <span>{{$t('search.damaged_stolen')}}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" class="right white--text mr-2" :disabled="!can_download_label" style="max-width: 40px !important; min-width: 40px !important" color="amarillo" @click="downloadLabel">
                                <v-icon class="material-icons-outlined" small>label</v-icon>
                            </v-btn>
                        </template>

                        <span>{{$t('search.download_label')}}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" class="right white--text mr-2" style="max-width: 40px !important; min-width: 40px !important" color="amarillo" @click="downloadInvoice">
                                <v-icon class="material-icons-outlined" small>description</v-icon>
                            </v-btn>
                        </template>

                        <span>{{$t('search.download_invoice')}}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" class="right white--text mr-2" style="max-width: 40px !important; min-width: 40px !important" color="amarillo" @click="editShipment">
                                <v-icon class="material-icons-outlined" small>edit</v-icon>
                            </v-btn>
                        </template>

                        <span>{{$t('search.edit')}}</span>
                    </v-tooltip>

                    <v-btn class="right white--text mr-2" color="amarillo" @click="searchOrder">
                        {{ $t('search.search') }}
                        <v-icon class="px-1" small>search</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="size_buttons">
                <v-row class="px-0 pt-6 mx-0" align="center" justify="center">
                    <!-- GENERAL DATA -->
                    <v-col cols="6">
                        <v-card class="ml-5 mr-1 size_left_card">
                            <v-row class="ml-5 mr-1 py-0 my-2" justify="center" align="center">
                                <v-col cols="12" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('search.general_data')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.order')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.shipment.unique_order_number}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.shipping_service')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.shipment.shipping_service}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.shipment_number')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.shipment.shipment_number}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.buyer')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.name}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.state')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.state}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.neighborhood')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.neighborhood}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.address')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.street}}, {{order.consignee.external_number}} {{order.consignee.internal_number}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.ext_num')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.external_number}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.int_num')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.internal_number}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.zipcode')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.zipcode}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.phone')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.phone}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.references')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.comments}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.ext_zone')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.shipment.ext_zone}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.packing')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.shipment.sizes}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.order_type')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0" v-if="order.shipment.cod == true">
                                    <label style="font-size: 16px;">{{$t('search.is_cod')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0" v-if="order.shipment.cod == false">
                                    <label style="font-size: 16px;">{{$t('search.is_prepaid')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0" v-if="search.criterion == 'fulfillment_eon'">
                                    <label style="font-size: 16px;">{{$t('search.is_prepaid')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center" v-if="order.minutes != null">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.minutes_status')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.minutes.status}} - ({{ order.minutes.date }})</label>
                                </v-col>
                            </v-row>

                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center" v-if="order.minutes != null">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.minutes_comments')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.minutes.comments}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.total_cod')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.shipment.total}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="end" align="end">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.products')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0" v-for="product of order.products" :key="product.id">
                                    <label style="font-size: 16px;">{{ product.quantity }} - ({{ product.sku }}) - {{ product.description }}</label>
                                </v-col>
                                <v-col cols="7" v-if="order.products.length == 0" class="py-0">
                                    <label style="font-size: 16px;"></label>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>

                    <!-- DELIVERY DETAILS -->
                    <v-col cols="6">
                        <v-card class="mr-5 ml-1 size_left_card">
                            <v-row class="px-0 mr-0 ml-5 py-0 my-2" justify="center" align="center">
                                <v-col cols="12" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('search.delivery_details')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" justify="center" align="center">
                                <v-col cols="6" class="py-0" justify="start" align="start">
                                    <label>{{ $t('search.status')}}</label>
                                </v-col>
                                <v-col cols="6" class="py-0" justify="end" align="end">
                                    <label>{{ $t('search.date')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-7 ml-7 py-0 my-1" v-for="status of order.statuses" :key="status.id" style="border-bottom: 1px solid #444444; border-radius: 0px;" justify="center" align="center">
                                <v-col cols="6" class="py-0" justify="start" align="start">
                                    <label style="font-size: 14px;"> {{status.status}} </label>
                                </v-col>
                                <v-col cols="6" class="py-0" justify="end" align="end">
                                    <label style="font-size: 14px;" v-if="status.date == null"> {{ status.created_at.includes("T")? status.created_at.split('T')[0]: status.created_at.split(' ')[0] }} {{ status.created_at.includes("T")? status.created_at.split('T')[1].substring(0, 5) : status.created_at.split(' ')[1].substring(0, 5) }} </label>
                                    <label style="font-size: 14px;" v-else> {{ status.date }} </label>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>

import JSZip from 'jszip';
import FileSaver from 'file-saver';
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        client: function(){
            return this.$store.state.clientId
        }
    },
    data(){
        return {
            numberFormat: new Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            search: {
                value: '',
                client_id: '',
                criterion: '',
            },
            can_download_label: true,
            can_mark_damaged: false,
            order: {
                statuses: {},
                shipment: {},
                consignee: {},
                products: [],
                minutes: [],
                charge: {}
            },
            states: [],
            municipalities: [],
            value: true,
            criterions: [
                { name: this.$t("search.tracking_number"), value: "tn" }, 
                { name: this.$t("search.uon"), value: "uon" }, 
                { name: this.$t("search.fulfillment_eon"), value: "fulfillment_eon" }
            ]
        }
    },
    mounted(){
        this.$store.state.clientId = null;
        this.index();
        this.$store.state.module = "headers.search";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.states = [];
            this.states_load = true;
            this.$http.get("https://locations.intech.mx/api/getStates/MX", {})
            .then((response) => {
                if(response.status === 200){
                    this.states = response.data
                    this.states_load = false;
                }
            }).finally(() => {
                this.states_load = false;
            });
        },
        searchOrder(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/shipmentSearch/' + this.search.client_id + '/' + this.search.criterion + '/' + this.search.value, { headers: { Authorization: lsToken } })
            .then((response) => {
                if(response.data.success === true){
                    this.$store.state.loaderProps.visible = false;
                    
                    if(this.search.criterion != "fulfillment_eon"){
                        this.order.statuses = response.data.statuses;
                        this.order.shipment = response.data.shipment;
                        this.order.minutes = response.data.minutes;
                        this.order.charge = response.data.charge;
                        var now = new Date();
                        this.order.statuses.forEach(x => {
                            if(x.status == "READY"){
                                var ready_date = new Date(x.created_at.split('T')[0].replace( /(\d{4})-(\d{2})-(\d{2})/, "$3/$1/$2")).getTime() + (90 * 24 * 60 * 60 * 1000)
                                if (now > ready_date) this.can_download_label = false;
                            }
                            else if(x.status == "RETURNED TO CHRONOS") this.can_mark_damaged = true;
                        })

                        this.order.shipment.ext_zone = response.data.extZone == false ? "No Aplica" : "Aplica";
                        this.order.shipment.insurance = response.data.shipment.cod == true && response.data.shipment.total >= 6500 ? "Aplica" : "No Aplica";
                        if(response.data.shipment.width == null || response.data.shipment.height == null || response.data.shipment.length == null) this.order.shipment.sizes = "";
                        else this.order.shipment.sizes = response.data.shipment.width + "x" + response.data.shipment.height + "x" + response.data.shipment.length;
                        this.order.shipment.total = '$' + this.numberFormat.format(response.data.shipment.total);
                        this.order.consignee = response.data.consignee;
                        this.order.products = response.data.products;
                    } else {
                        this.order.shipment.unique_order_number = response.data.shipment.unique_order_number;
                        this.order.shipment.shipment_number = response.data.shipment.tracking_number;
                        this.order.shipment.shipping_service = response.data.shipment.shipping_service;
                        this.order.shipment.sizes = response.data.shipment.name;
                        this.order.consignee.name = response.data.shipment.consignee_name;
                    
                        this.order.statuses = JSON.parse(response.data.shipment.status);
                        this.order.products = JSON.parse(response.data.shipment.products).products;
                    }
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        downloadLabel: async function (){
            var zip = new JSZip();
            let blobs = [];
            const urls = await this.getLabelUrls(this.order.shipment.id);

            if(this.order.shipment.shipping_service.toUpperCase() == 'OTRA' || this.order.shipment.shipping_service.toUpperCase() == 'SWIMPIFY'){ // add to zip all the attached files
                if(urls.data[0].files.length > 0){
                    const url = {
                        name: this.order.shipment.shipment_number,
                        url: urls.data[0].files[urls.data[0].files.length - 1].url
                    }
                    const blob = await this.getLabelFiles(url, 'attached_file');
                    blobs.push(blob);
                }
            }
            else{
                const blob = await this.getLabelFiles(urls.data[0].data[0], 'label');
                blobs.push(blob);
            }

            if(blobs.length > 0){
                blobs.forEach(x => {
                    zip.file(x.name, x.response.data);
                });

                this.$store.state.loaderProps.visible = false;
                var t = this;
                zip.generateAsync({type:"blob"}).then(function(content) {
                    FileSaver.saveAs(content, t.order.shipment.unique_order_number)
                }, function(err){
                    console.log(err)
                })
            }
        },
        downloadInvoice(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/getNote?id=' + this.order.shipment.id + '&format=PNG', {headers: {Authorization: lsToken}, responseType:'blob' })
            .then((response) => {
                // Get consginee data
                this.$store.state.loaderProps.visible = false;
                FileSaver.saveAs(response.data, this.order.shipment.shipment_number + ".png");
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        getLabelFiles: async function(url, type){
            const lsToken = localStorage.getItem("access_token");
            if(type == 'label'){
                const blob = await this.$http.get(url, {headers: {Authorization: lsToken}, responseType:'blob' });
                return {response: blob, name: url.split("/")[5].split('.')[0] + '.png'};
            }
            else if(type == 'attached_file'){
                const blob = await this.$http.get(url.url, {headers: {Authorization: lsToken}, responseType:'blob' });
                return {response: blob, name: url.name + ".pdf"};
            }
        },
        getLabelUrls: async function (order){
            const lsToken = localStorage.getItem("access_token");

            const {data} = await this.$http.get(this.$store.state.chronosApi + 'shipment/getLabels?id=' + order, {headers: {Authorization: lsToken}});
            return data;
        },
        editShipment(){
            var type = this.order.shipment.cod == false ? "prepaid" : "cod"
            if(this.order.charge.last_status == "PLACED"){
                this.$router.push('pending-shipments/' + type + '/edit/' + this.order.shipment.id)
            }
            else if(this.order.charge.last_status != "PLACED"){
                this.$router.push('sent-shipments/' + type + '/edit/' + this.order.shipment.id)
            }
        },
        damagedProduct(){
            if(this.order.shipment.cod == false) this.$router.push("/shipments/prepaid/incidence/" + this.order.shipment.id);
            else this.$router.push("/shipments/cod/incidence/" + this.order.shipment.id);
        }
    },
    watch: {
        '$store.state.clientId': function (){
            this.search.client_id = this.$store.state.clientId
        },
    }
}
</script>
<style>
</style>