import Vue from 'vue';
import VueI18n from 'vue-i18n';
import es from './assets/i18n/es.json';
import en from './assets/i18n/en.json';

Vue.use(VueI18n)

export default new VueI18n({
    locale: localStorage.getItem('language') || 'es',
    messages: {
        en: en,
        es: es
    },
    silentTranslationWarn: true
});