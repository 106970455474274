<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-col cols="12" class="py-0">
                <v-row class="px-3 pt-4 pb-2" >
                <v-col sm="2" md="2" class="py-0 px-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('customs_patches.customs_documents.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="padding-left: 10px;border: rgb(217, 217, 217) 1px solid; border-radius:5px;min-height: 40px;"
                    ></v-text-field>
                </v-col>
                <v-col sm="2" md="2" class="py-0 px-0">
                    <alternativeDateRangePicker
                        :label="$t('customs_patches.customs_documents.initial_date')"
                        :valueInput="dateRange.start_date"
                        :rule="[]"
                        @valueChange="(v) => {dateRange.start_date = v; getIndex({initial_date:dateRange.start_date,final_date:dateRange.end_date})}"
                        :errorMessages="[]"
                        class="mb-4 py-0 px-2 mt-0"
                        :locale="locale"
                    ></alternativeDateRangePicker>
                </v-col>
                <v-col sm="2" md="2" class="py-0 px-0">
                    <alternativeDateRangePicker
                        :label="$t('customs_patches.customs_documents.final_date')"
                        :valueInput="dateRange.end_date"
                        :rule="[]"
                        @valueChange="(v) => {dateRange.end_date = v; getIndex({initial_date:dateRange.start_date,final_date:dateRange.end_date})}"
                        :errorMessages="[]"
                        class="mb-4 py-0 px-2 mt-0"
                        :locale="locale"
                    ></alternativeDateRangePicker>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="2" align="end" class="py-0 px-0">
                    <v-btn class="right white--text" color="amarillo" @click="modal_delete_bulk = true" :disabled="!selected.length">
                        {{ $t('customs_patches.customs_documents.delete_bulk') }}
                    </v-btn>
                </v-col>
                <v-col cols="2" align="end" class="py-0 px-0">
                    <v-btn class="right white--text" color="amarillo" @click="modal_import = true" >
                        {{ $t('customs_patches.customs_documents.upload') }}
                    </v-btn>
                </v-col>
            </v-row>
            </v-col>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable  show-select v-model="selected" :props="{headers:headers ,items: list.payload.data, search:props.search}" @methodHandler="methodHandler"
                            
                            >
                                <template v-slot:other-actions="slotProps">
                                       <datatable-action
                                            :actions="actions"
                                            :data="slotProps.item"
                                        ></datatable-action>
                                </template>
                            </datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
        <import-modal :open_modal="modal_import" @close=" () =>modal_import = false" @save="saveRecords"></import-modal>
        <v-dialog v-model="modal_delete_bulk" persistent max-width="90%">
            <v-card >
                <v-card-title class="justify-center">
                    <v-row align="center" justify="center">
                            <h3 class="text-uppercase">
                                {{ $t('customs_patches.customs_documents.modal_delete_bulk.delete') }}
                            </h3>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row align="center" justify="center" class="py-3 my-3 text-center">
                        <v-col cols="12">
                            <h5>{{ $t('customs_patches.customs_documents.modal_delete_bulk.message',{num: selected.length }) }}</h5>
                        </v-col>
                    </v-row>
                    
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn class="mx-3" text @click="modal_delete_bulk = false">{{ $t('products.no') }}</v-btn>
                        <v-btn color="error" @click="destroyInBulk" v-permission="'product.delete'">
                            {{ $t('products.yes') }}
                        </v-btn>
                    </div>
                </v-card-text> 
                <div class="marker"></div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase">
                                {{ $t('customs_patches.customs_documents.modal_delete.delete') }}
                            </h3>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row align="center" justify="center" class="py-3 my-3 text-center">
                        <v-col cols="12">
                            <h5>{{ $t('customs_patches.customs_documents.modal_delete.message') }}</h5>
                        </v-col>
                    </v-row>
                    
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('products.no') }}</v-btn>
                        <v-btn color="error" @click="destroyRecord" v-permission="'product.delete'">
                            {{ $t('products.yes') }}
                        </v-btn>
                    </div>
                </v-card-text> 
                <div class="marker"></div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Utils from '../../helpers/Utils';
import ImportModal from "./Import.vue";

export default {
    components: {
        'import-modal': ImportModal,
    },
    computed: {
        ...mapGetters( [
            'language',
        ]),
        locale() {
            return this.language === 'en'? 'en-US' : 'es-MX'
        },
        ...mapGetters('customs_patches', [
            'list',
            'create',
            'destroy'
        ]),
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('customs_patches.customs_documents.table.id'), align: "center", value: 'attributes.id', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.table.customs_document'), align: "center", value: 'attributes.customs_document', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.table.doc_key'), align: "center", value: 'attributes.doc_key', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.table.customs_document_date'), align: "center", value: 'attributes.customs_document_date', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.table.part_number'), align: "center", value: 'attributes.part_number', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.table.invoice_quantity'), align: "center", value: 'attributes.invoice_quantity', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.table.invoice_number'), align: "center", value: 'attributes.invoice_number', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.table.fraction'), align: "center", value: 'attributes.fraction', class: 'text-uppercase' },
                { text: this.$t('customs_patches.customs_documents.table.actions'), align: "center", value: '_actions', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            selected: [],
            modal_import:false,
            modal_delete_bulk:false,
            dateRange: {start_date: Utils.getStartDate(), end_date: new Date().toISOString().split('T')[0]},
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalDelete: false,
            id_record: '',
            actions: [
                {
                    type:"redirect",
                    name: this.$t('products.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    // link: ()=>`/customers`,
                    link: (data)=>`/customs_patches/details/${data.id}`,
                    permission: "customs_patch.show"
                },
                {
                    type:"method",
                    name: this.$t('products.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: (data)=>{this.askDelete(data)},
                    permission: "customs_patch.delete"
                }
            ],
            props: {
                search: '',
            }
        }
    },
    watch: {
        selected: {
            handler(val){
                console.log(val);
            },
            deep: true
        },
        list: {
            handler(val){
                this.$store.state.loaderProps.visible = val.loading;
            },
            deep: true
        },
        destroy: {
            handler(val){
                this.$store.state.loaderProps.visible = val.loading;
                if(val.success && !val.loading){
                  this.getIndex({initial_date:this.dateRange.start_date,final_date:this.dateRange.end_date})
                  this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('customs_patches.customs_documents.upload_success')
                  }
                }
                if(!val.success && !val.loading){
                  this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('customs_patches.customs_documents.upload_failure')
                  }
                }

            },

            deep: true
        },
        create: {
            handler(val){
                this.$store.state.loaderProps.visible = val.loading;
                if(val.success && !val.loading){
                  this.getIndex({initial_date:this.dateRange.start_date,final_date:this.dateRange.end_date})
                  this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('customs_patches.customs_documents.upload_success')
                  }
                  let customs_documents = val.payload.data.filter(x=>x.status!=200).map(x=>x.customs_document).toString();
                  if(customs_documents.length){
                      this.snackbar = {
                          visible: true,
                          color: 'warning',
                          text: `${this.$t('customs_patches.customs_documents.repeated_records')}: ${customs_documents}`
                      };
                  }
                  
                }
                if(!val.success && !val.loading){
                  this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('customs_patches.customs_documents.upload_failure')
                  }
                }

            },

            deep: true
        },
    },
    mounted(){
        this.$store.state.module = "headers.customs_patches";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        askDelete(record){
            this.modalDelete = true;
            this.id_record = record.id;
        },
        destroyRecord(){
            this.modalDelete = false;
            this.deleteRecord([this.id_record]);
        },
        destroyInBulk(){
            const records = this.selected.map(x => x.id)
            this.modal_delete_bulk = false;
            this.deleteRecord(records);
        },
        update(record){
            console.log(record);
        },
        saveRecords(data){
          this.storeData(data);
        },
        ...mapActions('customs_patches', [
            'getIndex',
            'storeData',
            'deleteRecord',
        ])
    }
}
</script>

<style>

</style>