<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2">
                <v-col sm="3" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('pendings.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-col sm="3" md="4" class="py-0" justify="center" align="center">
                    <v-autocomplete v-model="filters.format_type" :label="$t('no_annex.format_type')" item-value="value" item-text="text" :items="format_types"></v-autocomplete>
                </v-col>
                <v-col sm="3" md="4" class="py-0" justify="center" align="center">
                    <v-autocomplete v-model="filters.year" :label="$t('no_annex.year')" :items="years"></v-autocomplete>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                 <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3>
                                    {{ $t('processed.modal.delete') }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('processed.delete_message1') }}, {{ $t('processed.delete_message2') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('processed.no') }}</v-btn>
                            <v-btn color="error" @click="deleteAppointment" v-permission:all="'n_processed.delete|appointment.delete'">
                                {{ $t('processed.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>

import JSZip from 'jszip';
import FileSaver from 'file-saver';

export default {
    computed: {
        headers(){
            //adding column operation in Spanish
            if(this.$store.state.language == 'es') return [
                { text: this.$t('pendings.id'), value: 'id', class: 'text-uppercase' },
                { text: this.$t('pendings.client'), value: 'client.name', class: 'text-uppercase' },
                { text: this.$t('pendings.date'), value: 'arrival_date', class: 'text-uppercase' },
                { text: this.$t('pendings.movement_type'), value: 'operation.description', class: 'text-uppercase' },
                { text: this.$t('pendings.container'), value: 'container_number', class: 'text-uppercase' },
                { text: this.$t('pendings.description'), value: 'description', class: 'text-uppercase' },
                { text: this.$t('pendings.comments'), value: 'commentary', class: 'text-uppercase' },
                { text: this.$t('pendings.user'), value: 'user.name', class: 'text-uppercase' },
                { text: this.$t('pendings.actions'), value: 'actions', align: "center", class: 'text-uppercase' },
            ]
            //adding columnm operation in English
            else return [
                { text: this.$t('pendings.id'), value: 'id', class: 'text-uppercase' },
                { text: this.$t('pendings.client'), value: 'client.name', class: 'text-uppercase' },
                { text: this.$t('pendings.date'), value: 'arrival_date', class: 'text-uppercase' },
                { text: this.$t('pendings.movement_type'), value: 'operation.description1', class: 'text-uppercase' },
                { text: this.$t('pendings.container'), value: 'container_number', class: 'text-uppercase' },
                { text: this.$t('pendings.description'), value: 'description', class: 'text-uppercase' },
                { text: this.$t('pendings.comments'), value: 'commentary', class: 'text-uppercase' },
                { text: this.$t('pendings.user'), value: 'user.name', class: 'text-uppercase' },
                { text: this.$t('pendings.actions'), value: 'actions', align: "center", class: 'text-uppercase' },
            ]
        } 
    },
    data(){
        return {
            items: {
                clients: []
            },
            actions: [
                {
                    name: this.$t('pendings.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    link: '/appointment-details/10000',
                    permission: 'appointment.show|detail_appointment.show'
                },
                {
                    name: this.$t('pendings.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'mode_edit'
                    },
                    link: '/edit-appointment/10000',
                    permission: 'appointment.update|detail_appointment.update'
                },
                {
                    name: this.$t('pendings.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                    permission: 'appointment.delete|detail_appointment.delete'
                },
                {
                    name: this.$t('processed.files'),
                    icon: {
                        color: 'accent',
                        icon: 'folder'
                    },
                    link: '/upload-files-national/',
                    permission: 'appointment.file|n_processed.file'
                },
                {
                    name: this.$t('processed.download_pdf'),
                    icon: {
                        color: 'accent',
                        icon: 'sticky_note_2'
                    },
                    method: 'download',
                    permission: 'n_pdf.show|n_pdf.index' //preguntar por pdf permission
                },
                {
                    name: this.$t('processed.download_label'),
                    icon: {
                        color: 'accent',
                        icon: 'label'
                    },
                    method: 'getLabel',
                    permission: 'n_pdf.show|n_pdf.index' //preguntar por pdf permission
                }
            ],
            props: {
                search: '',
                items: [],
            },
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            format_types: [
                {value: "Income", text: this.$t("processed.income")},
                {value: "Outcome", text: this.$t("processed.outcome")},
            ],
            filters: {
                format_type: "Income",
                year: "2024",
            },
            years: ["2024", "2023", "2022", "2021", "2020", "2019", "2018"],
            cutoffs: [],
            modalDelete: false,
            idToDelete: null,
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.n_processed";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'appointmentsby/Procesadas/1?format_type=' + this.filters.format_type + '&year=' + this.filters.year, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    // const d2 = new Date((new Date()).toISOString().slice(0, 10));
                    response.data.data.forEach(x =>{
                        x = x.attributes;
                        
                        // x.idIndex = x.id;
                        x.arrival_date = (new Date(x.arrival_date)).toISOString().slice(0, 10);
                        // const d1 = new Date(x.arrival_date);
                        
                        // if(d1 > d2) x.dotColor = 'blue_dashboard';
                        // else if(+d1 === +d2) x.dotColor = 'orange';
                        // else x.dotColor = "red";

                        
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x['actions'][0].link = '/appointment-details/' + x.id + '/national';
                        x['actions'][1].link = '/edit-appointment/' + x.id + '/national';
                        x['actions'][3].link = "/upload-files-national/" + x.id;
                        
                        if(x.document_management == null || x.document_management == undefined) x['actions'][3].icon.color = 'azul';
                        else {
                            // var required_files = typeof x.document_management.required_files == "string" ? JSON.parse(x.document_management.required_files) : x.document_management.required_files
                            // var count_rf = 0;
                            // for (var key in required_files) {
                            //     if(required_files[key] == true) count_rf++; 
                            // }
                            // if(count_rf > x.documents_blobs.length) x['actions'][3].icon.color = 'warning';
                            // else if(count_rf <= x.documents_blobs.length) x['actions'][3].icon.color = 'success2';
                                                        
                            x['actions'][3].icon.color = 'success2';

                            // if(x.document_management.inconvenient == true) x['actions'][3].icon.color = 'purple'
                        }

                        x.parameters = x.id;
                        this.props.items.push(x);
                    });
                    
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        askDelete(x){
            this.idToDelete = x;
            this.modalDelete = true;
        },
        deleteAppointment(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'appointments/' + this.idToDelete, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.modalDelete = false;
                    this.index();
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('processed.responses.deleted')
                        }
                    }
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch(() => {
                this.$store.state.loaderProps.visible = false;
                this.modalDelete = false;
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('processed.responses.error')
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        download(i){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'appointments/' + i + "/pdf", {headers: {Authorization: lsToken}, responseType: 'arraybuffer'})
            .then((response) => {
                this.forceFileDownloadHistory(response, i)
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        forceFileDownloadHistory(response, id){
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            this.$store.state.loaderProps.visible = false
            link.href = url
            link.setAttribute('download', 'Appointment - ' + id + '.pdf')
            document.body.appendChild(link)
            link.click()
        },
        async getLabel(appointment){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const response = await this.$http.get(this.$store.state.chronosApi + 'appointments/pallets_information/' + appointment, {headers: {Authorization: lsToken}})
            var zip = new JSZip();
            
            if(response.data.status != 200){
                this.$store.state.loaderProps.visible = false;
                return;
            }

            for(var i = 1; i <= response.data.pallets.length; i++){
                const file = await this.getFileLabel(response.data.appointment, response.data.pallets[i-1]);
                zip.file(response.data.appointment.id + '_' + i + '.png', file.data);
            }

            zip.generateAsync({type:"blob"}).then(function(content) {
                FileSaver.saveAs(content, "Labels_appointment_" + response.data.appointment.id + ".zip")
            }, function(err){
                console.log(err)
            })

            this.$store.state.loaderProps.visible = false;
        },
        async getFileLabel(appointment, pallet){
            const lsToken = localStorage.getItem("access_token");
            const file = await this.$http.get(this.$store.state.chronosApi + 'appointments/get_label/png?pallet=' + pallet.pallet + '&container_number=' + appointment.container_number + '&name=' + appointment.name + '&arrival_date=' + appointment.arrival_date + '&id=' + appointment.id + '&sku=' + pallet.sku + '&batch=' + (pallet.batch ?? 'null') + '&reference=' + (pallet.reference ?? 'null') + '&operation_id=' + appointment.operation_id, {headers: {Authorization: lsToken}, responseType:'blob'});
            return file;
        }
    },
    watch:{
        'filters.format_type': function (){
            this.index();
        },
        'filters.year': function (){
            this.index();
        }
    }
}
</script>

<style>

</style>