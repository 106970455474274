<template>
    <div class="px-0">
        <rfid-settings v-if="show_rfid" ref="showSettings" :items="rfid_items" ></rfid-settings>
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5" justify="center" align="center">
                    <v-col cols="4" align="start" justify="start">    
                        <h3 class="azul--text text-uppercase">{{ $t('product_details.details') }}</h3>
                    </v-col>
                    <v-col v-if="is_rfid" cols="1" align="end" justify="end">
                        <v-btn class="right white--text" color="blue" @click="print_tags" >
                            RFID
                        </v-btn>
                    </v-col>
                    <v-col cols="2" align="end" justify="end">
                        <v-btn class="right white--text" color="amarillo" @click="cancel" >
                            {{ $t('product_details.go_back') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.sku')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.sku }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.barcode')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.barcode }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.description')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.description }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.client')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.client.name }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.category')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.category.name }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.refill_alert')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.storage_policy }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.unit')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.unit }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.size_unit')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.size_unit }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.height')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.height }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.length')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.length }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.width')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.width }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('product_details.weight')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.product.weight }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-3">
                                <datatable :props="{ headers:headers, items: locations }" @methodHandler="methodHandler"></datatable>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>    
        </v-container>   
        <v-dialog v-model="modalEdit" persistent max-width="90%">
            <v-card >
                <v-card-title class="justify-center">
                    <v-row align="center" justify="center" class="mt-4">
                        <h3 class="text-uppercase">
                            {{ $t('product_details.create_damaged') }}
                        </h3>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       <v-row class="px-5 py-0 mt-7 mb-0" justify="center" align="center">
                            <v-col cols="9" class="py-0">
                                <v-text-field class="py-0" v-model="detail_to_update.sku" disabled :label="$t('edit_detailed_stock.sku')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 mt-1 mb-0" justify="center" align="center">
                            <v-col cols="9" class="py-0">
                                <v-text-field class="py-0" v-model="detail_to_update.quantity" disabled :label="$t('edit_detailed_stock.quantity')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 mt-1 mb-0" justify="center" align="center">
                            <v-col cols="9" class="py-0">
                                <v-text-field class="py-0" v-model="detail_to_update.damaged" :label="$t('edit_detailed_stock.damaged')" :rules="numeric_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 mt-1 mb-0" justify="center" align="center">
                            <v-col cols="9" class="py-0">
                                <v-checkbox class="mt-0" color="info" v-model="detail_to_update.quarantine" :label="$t('product_details.quarantine')"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-form>
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn class="mx-3" text @click="modalEdit = false">{{ $t('product_details.cancel') }}</v-btn>
                        <v-btn color="warning" class="white--text" @click="updateDetail">
                            {{ $t('product_details.save') }}
                        </v-btn>
                    </div>
                </v-card-text> 
                <div class="marker2"></div>
            </v-card>
        </v-dialog>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => v <= this.detail_to_update.quantity || this.$t('product_details.quantity_higher') + '(' + this.detail_to_update.quantity + ')',
            ];
        },
        headers(){
            return [
                { text: this.$t('product_details.location'), align: "center", value: 'location.name', class: 'text-uppercase' },
                { text: this.$t('product_details.pallet'), align: "center", value: 'pallet_per_location.pallet', class: 'text-uppercase' },
                { text: this.$t('product_details.box'), align: "center", value: 'box', class: 'text-uppercase' },
                { text: this.$t('product_details.quantity'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('product_details.reserved'), align: "center", value: 'reserved', class: 'text-uppercase' },
                { text: this.$t('product_details.batch'), align: "center", value: 'batch', class: 'text-uppercase' },
                { text: this.$t('product_details.expiration'), align: "center", value: 'reference', class: 'text-uppercase' },
                { text: this.$t('product_details.date'), align: "center", value: 'created_at', class: 'text-uppercase' },
                { text: "", align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            locations: [],
            product: {
                sku: "",
                description: "",
                unit: "",
                barcode: '', 
                height: '', 
                length : '', 
                width: '',
                size_unit: '',
                category:{
                    name: "",
                },
                storage_policy: "",
                weight: "",
                client: {
                    name: ""
                }
            },
            is_rfid: false,
            show_rfid: false,
            rfid_items: {
                pallets:[],
                boxes: [],
                tags: []
            },
            modalEdit: false,
            id_detail_to_update: '',
            original_quantity: '',
            detail_to_update: {
                sku: '',
                damaged: '',
            },
            rfid_actions:[
                {
                    name: this.$t('product_details.relocate'),
                    icon: {
                        color: 'accent',
                        icon: 'edit_location'
                    },
                    method: 'relocate',
                    permission: "product.show"
                },
                {
                    name: this.$t('rfid.print'),
                    icon: {
                        color: 'accent',
                        icon: 'sell'
                    },
                    method: 'print_tags',
                    permission: "product.show"
                }
            ],
            actions: [
                {
                    name: this.$t('product_details.adjust'),
                    icon: {
                        color: 'accent',
                        icon: 'tune'
                    },
                    method: 'showModalEdit',
                    permission: "damaged.create"
                }
            ]
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.product_details";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.locations = [];
            //get Product data
            // var t = this
            this.$http.get(this.$store.state.chronosApi + 'products/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                // this.$http.get(this.$store.state.chronosApi + 'rfid/client/is_rfid/' + response.data.data.attributes.client.attributes.id, {headers: {Authorization: lsToken}})
                // .then(res => {
                //     t.is_rfid = res.data.cw.is_rfid
                // }).catch(err => {console.log(err)})
                
                this.product = {
                    sku: response.data.data.attributes.sku,
                    description: response.data.data.attributes.description,
                    barcode: response.data.data.attributes.barcode,
                    unit: response.data.data.attributes.unit,
                    size_unit: response.data.data.attributes.size_unit,
                    width: response.data.data.attributes.width,
                    length: response.data.data.attributes.length,
                    height: response.data.data.attributes.height,
                    category:{
                        name: response.data.data.attributes.category.attributes.name,
                    },
                    storage_policy: response.data.data.attributes.storage_policy,
                    weight: response.data.data.attributes.weight,
                    client: {
                        name: response.data.data.attributes.client.attributes.name
                    }
                },

                response.data.data.attributes.detailed_stock.forEach(x => {
                    if(x.attributes.quantity > 0 || x.attributes.reserved > 0){
                        if(x.attributes.location.warehouse_id == this.$store.state.warehouseId){
                            var ppl = null;
                            if(x.attributes.pallet_per_location != null && x.attributes.pallet_per_location != undefined) ppl = x.attributes.pallet_per_location.pallet;
                            // var actions = x.attributes.epc == null ? JSON.parse(JSON.stringify(this.actions)) : JSON.parse(JSON.stringify(this.rfid_actions))
                            var detail = {
                                location: { name: x.attributes.location.name },
                                pallet_per_location: { pallet: ppl },
                                box: x.attributes.box,
                                // epc: (x.attributes.epc == null ? "" : "B-" + x.attributes.id ),
                                batch: x.attributes.batch,
                                quantity: x.attributes.quantity,
                                reserved: x.attributes.reserved,
                                reference: x.attributes.reference,
                                created_at: x.attributes.created_at.split('T')[0],
                                actions: JSON.parse(JSON.stringify(this.actions)),
                                // actions: actions,
                                parameters: x.attributes.id
                            }
                            this.locations.push(detail);
                        }
                    }
                });
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        save(){
        },
        print_tags(id){
            console.log(id);
            this.$store.state.loaderProps.visible = true;

            var t = this
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'rfid/product/' + this.id, {headers: {Authorization: lsToken}})
            .then(response => {
                console.log("se esta imprimiendo el response de print tags")
                console.log(response)
                t.rfid_items = response.data.items
                t.show_rfid = true
                
            })
            .catch(err => {console.log(err)})
            .finally(()=>{this.$store.state.loaderProps.visible = false;})
        },
        showModalEdit(detail_id){
            this.id_detail_to_update = detail_id;
            this.modalEdit = true;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'products/showDetailedStock/' + this.id_detail_to_update  , {headers: {Authorization: lsToken}})
            .then((response) => {
                this.detail_to_update = response.data.data;
                this.detail_to_update.damaged = '';
                this.detail_to_update.quarantine = false;
                this.original_quantity = response.data.data.quantity;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        updateDetail(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.patch(this.$store.state.chronosApi + 'products/adjustDetailedStock/' + this.id_detail_to_update, this.detail_to_update, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status == 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('edit_warehouse.responses.success')
                    }
                    this.$router.go(-1);
                }
            }).catch((err) => {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: err.response.data.message
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
        
    }
}
</script>

<style lang="css">
</style>