<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('create_custom_agent.new') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.name" :label="$t('create_custom_agent.name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.patent" :label="$t('create_custom_agent.patent')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.rfc" :label="$t('create_custom_agent.rfc')" :rules="rules_rfc"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="custom_agent.country" :loading="countries_load" :items="countries" :label="$t('create_custom_agent.country')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="custom_agent.state" :loading="states_load" :items="states" :label="$t('create_custom_agent.state')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="custom_agent.municipality" :loading="municipalities_load" :items="municipalities" :label="$t('create_custom_agent.municipality')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.neighborhood" :label="$t('create_custom_agent.neighborhood')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.street" :label="$t('create_custom_agent.street')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="2" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.external_number" :label="$t('create_custom_agent.ext_number')" :rules="rules_required"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.internal_number" :label="$t('create_custom_agent.int_number')"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.zipcode" :label="$t('create_custom_agent.zip_code')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel" >
                                {{ $t('create_custom_agent.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'customs_broker.create'">
                                {{ $t('create_custom_agent.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        rules_rfc(){
            return [v => !!v  && (v.toString().length == 12 || v.toString().length == 13) || this.$t('create_custom_agent.validations.rfc')];
        },
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            custom_agent: {
                name: '',
                patent: '',
                rfc: '',
                country: '',
                state: '',
                municipality: '',
                neighborhood: '',
                street: '',
                zipcode: '',
                external_number: '',
                internal_number: '',
                warehouse_id: this.$store.state.warehouseId
            },
            states: [],
            countries: [],
            municipalities: [],
            countries_load: false,
            states_load: false,
            municipalities_load: false
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.create_custom_agent";
    },
    methods: {
        index(){
            console.log("OKOKOK")
            this.countries_load = true;
            this.$http.get("https://locations.intech.mx/api/getCountries", {})
            .then((response) => {
                this.countries = response.data
                this.countries_load = false;
            }).finally(() => {
                this.countries_load = false;
            });
        },
        save(){
            const lsToken = localStorage.getItem("access_token");
            var validate = this.$refs.form.validate()
            if(validate){
                this.$store.state.loaderProps.visible = true;
                this.$http.post(this.$store.state.chronosApi + 'customs_brokers', this.custom_agent,{headers: {Authorization: lsToken}})
                .then((response) => {
                    response
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('create_custom_agent.responses.success')
                    }
                    this.$router.push({ name: "CustomAgentsIndex" });  
                }).catch((err) => {
                    if("name" in err.response.data){
                        this.snackbar = {
                            visible: true,
                            color: 'error',
                            text: this.$t('create_custom_agent.validations.name')
                        }
                    }
                    if("patent" in err.response.data){
                        this.snackbar = {
                            visible: true,
                            color: 'error',
                            text: this.$t('create_custom_agent.validations.patent')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('create_custom_agent.responses.incomplete')
                }
            }
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
        "custom_agent.country": function(){
            this.states = [];
            this.states_load = true;
            this.$http.get("https://locations.intech.mx/api/getStates/"+this.custom_agent.country, {})
            .then((response) => {
                if(response.status === 200){
                    this.states = response.data
                    this.states_load = false;
                }
            }).finally(() => {
                this.states_load = false;
            });
        },
        "custom_agent.state": function(){
            this.municipalities = [];
            this.municipalities_load = true;
            this.$http.get("https://locations.intech.mx/api/getCities/" + this.custom_agent.country + "/" + this.custom_agent.state, {})
            .then((response) => {
                if(response.status === 200){
                    response.data.forEach(x => {
                        this.municipalities.push(x.text);
                    })
                    this.municipalities_load = false;
                }
            }).finally(() => {
                this.municipalities_load = false;
            });
        }
    }
}
</script>

<style lang="css">
</style>