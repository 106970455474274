<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('fulfillment_leirem_prepare.supply_order') }} {{ shipment.uon }} </h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-row class="px-0 py-0 mt-0 mb-1" justify="center" align="center" v-if="shipment.with_batch && shipment.with_batch.length">
                        <v-col cols="5" justify="center" align="center" class="px-1 py-1 text-uppercase" style="text-align: left; background-color: #9fb700 !important; color: white; font-weight: 500;">
                                {{ $t('fulfillment_leirem_prepare.product_with_batch') }}
                            </v-col>
                            <v-col cols="2" justify="center" align="center" class="px-1 py-1 text-uppercase" style="text-align: center; background-color: #9fb700 !important; color: white; font-weight: 500;">
                                {{ $t('fulfillment_leirem_prepare.supplied') }}
                            </v-col>
                    </v-row>

                    <span v-for="(product, i) of shipment.with_batch" :key="i">
                        <v-row class="px-1 py-2" justify="center" align="center" >
                            <v-col cols="5" justify="center" align="center" class="px-0 py-1" style="border-bottom: 1px solid black; text-align: left;">
                                <v-icon small :class="product.color != 'accent' ? '' : 'material-icons-outlined'" :color="product.color"> fiber_manual_record </v-icon>{{ product.name }}
                            </v-col>
                            <v-col cols="2" justify="center" align="center" class="px-0 py-1" style="border-bottom: 1px solid black;">
                                {{ product.discount }} / {{ product.quantity }}
                                <v-icon v-if="product.discount != product.quantity" class="material-icons-outlined" small color="green" >check_circle</v-icon>
                                <v-icon v-else class="material-icons" small color="green" >check_circle</v-icon>

                                <v-icon class="material-icons-outlined" small color="warning" @click="methodHandler(product, 'checkBatches')">list</v-icon>
                            </v-col>
                        </v-row>
                    </span>

                    <v-row class="px-0 py-0 mb-1" justify="center" align="center" v-if="shipment.without_batch && shipment.without_batch.length">
                        <v-col cols="5" justify="center" align="center" class="px-1 py-1 text-uppercase" style="text-align: left; background-color: #9fb700 !important; color: white; font-weight: 500;">
                            {{ $t('fulfillment_leirem_prepare.product_without_batch') }}
                        </v-col>
                        <v-col cols="2" justify="center" align="center" class="px-1 py-1 text-uppercase" style="text-align: center; background-color: #9fb700 !important; color: white; font-weight: 500;">
                            {{ $t('fulfillment_leirem_prepare.supplied') }}
                        </v-col>
                    </v-row>

                    <span v-for="(product, i) of shipment.without_batch" :key="i + shipment.with_batch.length">
                        <v-row class="px-1 py-2" justify="center" align="center" >
                            <v-col cols="5" justify="center" align="center" class="px-0 py-1 text-uppercase" style="border-bottom: 1px solid black; text-align: left;">
                                {{ product.name }}
                            </v-col>
                            <v-col cols="2" justify="center" align="center" class="px-0 py-1 text-uppercase" style="border-bottom: 1px solid black;">
                                {{ product.discount }} / {{ product.quantity }}
                                <v-icon v-if="product.discount != product.quantity" class="material-icons-outlined" small color="green" @click="methodHandler(product, 'markReady')">check_circle</v-icon>
                                <v-icon v-else class="material-icons" small color="green">check_circle</v-icon>
                            </v-col>
                        </v-row>
                    </span>

                    <v-row class="px-0 py-0 mb-1" justify="center" align="center" >
                            <v-col cols="7" justify="center" align="center" class="px-1 py-1 text-uppercase" style="text-align: left; background-color: #9fb700 !important; color: white; font-weight: 500;">
                                {{ $t('fulfillment_leirem_prepare.box') }}
                            </v-col>
                    </v-row>
                    
                    <v-row class="px-0 py-0 my-0" justify="center" align="center">
                        <v-col cols="4" class="my-0 py-0 mx-0 px-0">
                            <v-autocomplete class="my-0 pr-1 pl-0 pt-2 pb-0" v-model="box.id" item-value="id" item-text="name" :items="boxes" :label="$t('fulfillment_leirem_prepare.select_box')"></v-autocomplete> 
                        </v-col>
                        <v-col cols="3" class="my-0 py-0 px-0 mx-0">
                            <v-text-field class="pl-1 pr-0 pt-2 my-0 pb-0" v-model="box.quantity" :label="$t('fulfillment_leirem_prepare.box_quantity')" :rules="numeric_required"></v-text-field>
                        </v-col>
                    </v-row>

                    <br>
                    <br>
                    
                    <v-row class="px-5 py-0" justify="center" align="center">
                        <v-col class="px-0 mx-0" cols="7" justify="end" align="end">
                            <v-row justify="end" align="end">
                                <v-btn class="mx-3" outlined color="amarillo" @click="cancel()" v-permission="'appointment.create'">
                                    {{ $t('fulfillment_leirem_prepare.cancel') }}
                                </v-btn>
                                <v-btn class="right white--text" :disabled="!all_scanned" color="amarillo" @click="save" v-permission="'appointment.create'">
                                    {{ $t('fulfillment_leirem_prepare.supply') }}
                                </v-btn> 
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>
        <v-dialog v-model="alert_modal" persistent max-width="90%">
            <v-card >
                <v-card-title class="justify-center">
                    <v-row align="center" justify="center">
                            <h3 class="text-uppercase" style="color: #00587d;">
                                {{ $t('fulfillment_leirem_prepare.' + modal_type) }}
                            </h3>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row align="center" justify="center" class="py-3 my-3 text-center">
                        <v-col cols="12">
                            <h4>{{ $t('fulfillment_leirem_prepare.' + modal_type + '_message') }} </h4>
                        </v-col>
                    </v-row>
                    
                    <div class="d-flex" style="justify-content: center;">
                        <v-btn color="orange" class="white--text" @click="closeModal">
                            {{ $t('fulfillment_leirem_prepare.accept') }}
                        </v-btn>
                        
                    </div>
                </v-card-text> 
                <div class="marker2"></div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="modal_batches" persistent max-width="90%">
            <v-card>
                <v-card-title class="justify-center">
                    <v-row align="center" justify="center" class="mb-1">
                            <h3 class="text-uppercase mt-4" style="color: #00587d;">
                                {{ $t('fulfillment_leirem_prepare.remove_batch') }}
                            </h3>
                    </v-row>
                </v-card-title>

                <v-card-text class="mt-3">
                    <v-row class="px-1 py-2" justify="center" align="center">
                        <v-col cols="7" justify="start" align="start" class="px-0 py-0 text-uppercase" >
                            <v-text-field
                                v-model="search"
                                append-icon="search"
                                :label="$t('fulfillment_leirem_pendings.search')"
                                single-line
                                hide-details
                                class="mb-4 py-0 px-2 mt-0 tools-view-height"
                                style="background-color: white; border: 1px solid black; border-radius:5px"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-row class="px-1 pb-1" justify="center" align="center" v-for="product of product_batch_list" :key="product.batch">
                        <v-col cols="5" justify="start" align="start" class="px-0 py-1 text-uppercase" >                            
                            <p v-if="product.match" style="color: red;" class="my-0">{{ product.sku }} - <b>{{ product.batch }}</b></p>
                            <p v-else class="my-0">{{ product.sku }} - {{ product.batch }}</p>
                        </v-col>
                        <v-col cols="2" justify="end" align="end" class="px-0 py-1 text-uppercase">
                            <v-icon class="material-icons-outlined" small color="red" @click="methodHandler(product, 'removeBatch')">cancel</v-icon>
                        </v-col>
                    </v-row>

                    <div class="d-flex my-4" style="justify-content: center;">
                        <v-btn color="orange" class="white--text mt-2" @click="closeBatchesModal">
                            {{ $t('fulfillment_leirem_prepare.accept') }}
                        </v-btn>
                        
                    </div>
                </v-card-text> 
                <div class="marker2"></div>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.visible" :timeout="snackbar.timeout??4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>

export default {
    computed: {
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            shipment: {},
            box: {
                id: "",
                quantity: "",
            },
            boxes: [],
            barcode: "",
            last: false,
            order_ids: [],
            products: [],
            alert_modal: false,
            modal_type: '',
            all_scanned: false,
            products_added: false,
            product_batch_list: [],
            modal_batches: false,
            search: "",
        }
    },
    async mounted(){
        this.index();
        let self = this; 

        const keyPressHandler = async (e) => {
            if(self.last == true && /^\S+$/.test(e.key)) { 
                self.barcode = e.key;
                self.last = false;
            }
            else if(e.code == "Enter") {
                self.last = true;
                await self.updateList();
            }
            else if(/^\S+$/.test(e.key)) self.barcode += e.key;
        };

        window.addEventListener ('keypress', keyPressHandler, { capture: true });
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.$http.get(this.$store.state.chronosApi + 'boxes', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x => {
                    x = x.attributes;
                    this.boxes.push(x)
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })

            this.$http.get(this.$store.state.chronosApi + 'fulfillment/leirem/product_details/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.shipment = response.data.data;

                this.shipment.with_batch.forEach(x => {
                    x.discount = 0;
                    x.color = "accent";
                    x.parameters = x;
                })

                this.shipment.without_batch.forEach(x => {
                    x.discount = 0;
                    x.parameters = x;
                })

                this.$store.state.loaderProps.visible = false;
            })
            .catch((error) => {
                this.$store.state.loaderProps.visible = false;
                if(error.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: error.response.data.error
                    }
                }
            });
        },
        methodHandler(object, subject){
            if(subject == "markReady") this["markAsReady"](object.parameters);
            else if(subject == "checkBatches") this["checkBatches"](object.parameters);
            else if(subject == "removeBatch") this["removeBatch"](object)
        },
        save: async function(){
            const lsToken = localStorage.getItem("access_token");

            if(this.box.id == "" || this.box.quantity == ""){
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('fulfillment_leirem_prepare.box_or_quaantity_empty')
                }
                return;
            }

            const products = await this.getProductsFormated();
            if(products == "error") return;
            else this.products = this.products.concat(products);
            this.$store.state.loaderProps.visible = true;

            this.$http.post(this.$store.state.chronosApi + 'fulfillment/leirem/process_order', {
                "shipment_id": this.shipment.shipment_id,
                "boxes": {
                    "box_id": this.box.id,
                    "quantity": this.box.quantity
                },
                "products": this.products
            },{headers: {Authorization: lsToken}})
            .then((response) => {
                this.$store.state.loaderProps.visible = false;
                response;
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: response.data.message
                }
                this.$router.go(-1);
            })
            .catch((error) => {
                this.$store.state.loaderProps.visible = false;
                this.snackbar = {
                    timeout: 12000,
                    visible: true,
                    color: 'error',
                    text: error.response.data.error
                }
            })
        },
        closeModal(){
            this.alert_modal = false;
        },
        checkSkuBatch: async function(batch, sku){
            const lsToken = localStorage.getItem("access_token");
            
            if(this.$store.state.loaderProps.visible == false) this.$store.state.loaderProps.visible = true;

            let result = "Error";
            await this.$http.get(this.$store.state.chronosApi + 'fulfillment/leirem/search_product?sku=' + sku +'&batch=' + batch, {headers: {Authorization: lsToken}})
            .then((response) => {
                response;
                result = "Success";
            }).catch((err) => {
                err;
                result = "Error";
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })

            return result;
        },
        updateList: async function(){
            let discounted = false; // product discount
            let found = false;

            this.barcode = this.barcode.toString().replaceAll("Enter", "");
            for (let i = 0; i < this.shipment.with_batch.length; i++){
                if(discounted == true) break;
                this.shipment.with_batch[i].color = "accent";

                const response = await this.checkSkuBatch(this.barcode.toString(), this.shipment.with_batch[i].sku);
                if(response == "Success"){
                    found = true;

                    if(this.shipment.with_batch[i].discount < this.shipment.with_batch[i].quantity){

                        this.products.push({
                            "product_id": this.shipment.with_batch[i].product_id,
                            "sku": this.shipment.with_batch[i].sku,
                            "quantity": 1,
                            "detailed_stock_id": this.shipment.with_batch[i].detailed_stock_id,
                            "batch": this.barcode.toString()
                        });

                        this.shipment.with_batch[i].discount++;
                        this.shipment.with_batch[i].color = 'green';
                        discounted = true;
                    }
                }
            }

            this.checkIfAllAreScanned();

            if(!found){
                this.modal_type = "not_expected_product"
                this.alert_modal = true;
                return;
            }
            if(!discounted && found){
                this.modal_type = "excedent"
                this.alert_modal = true;
                return;
            }
        },
        cancel(){
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;

            this.$http.delete(this.$store.state.chronosApi + 'v1/advanced_fulfillment/release_orders/' + this.orders_code, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.$store.state.loaderProps.visible = false;
                response;
            })
            .catch((error) => {
                this.$store.state.loaderProps.visible = false;
                console.log(error)
            })

            this.$router.go(-1);
        },
        markAsReady(product){
            console.log(product)
            this.shipment.without_batch.forEach(x => {
                if(product.product_id == x.product_id){
                    x.discount = x.quantity;
                }
            })

            this.checkIfAllAreScanned();
            this.$forceUpdate();
        },
        checkIfAllAreScanned(){
            let all_are_scanned = true;
            this.shipment.with_batch.forEach(x => {
                if(x.discount < x.quantity) {
                    all_are_scanned = false;
                }
            })

            this.shipment.without_batch.forEach(x => {
                if(x.discount < x.quantity) {
                    all_are_scanned = false;
                }
            })

            this.all_scanned = all_are_scanned;
            this.$forceUpdate();
        },
        checkBatches(product){
            this.product_batch_list = [];
            this.product_batch_list = this.products.filter(x => {
                return x.detailed_stock_id == product.detailed_stock_id
            })

            this.modal_batches = true;
        },
        removeBatch(product){
            console.log(product);
            let index = this.products.findIndex(x => {
                return x.detailed_stock_id == product.detailed_stock_id && x.batch == product.batch;
            });

            this.products.splice(index, 1);


            let index2 = this.product_batch_list.findIndex( y => {
                return y.detailed_stock_id == product.detailed_stock_id && y.batch == product.batch;
            })

            this.product_batch_list.splice(index2, 1);

            this.shipment.with_batch.forEach(w => {
                if(w.detailed_stock_id == product.detailed_stock_id) {
                    w.discount--;
                    return;
                }
            });

            this.$forceUpdate();
        },
        closeBatchesModal(){
            this.product_batch_list = [];
            this.modal_batches = false;
        },
        getProductsFormated(){
            let products = []

            this.shipment.with_batch.forEach( x => {
                if(x.quantity != x.discount){
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('fulfillment_leirem_prepare.sku_not_scanned')
                    }

                    return "error";
                }
            })

            this.shipment.without_batch.forEach( x => {
                if(x.quantity != x.discount){
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('fulfillment_leirem_prepare.sku_not_scanned')
                    }

                    return "error";
                }

                products.push(
                    {
                        "product_id": x.product_id,
                        "sku": x.sku,
                        "quantity": x.quantity,
                        "batch": null
                    }
                )
            })

            if (this.products_added == false){ 
                this.products_added = true;
                return products;
            }
            else return [];
        }
    },
    watch:{
        '$route.params.shipping_service': function() {
            location.reload();
        },
        'search': function (){
            this.product_batch_list.forEach(x => {
                x.match = false;
                if(x.batch.includes(this.search) && this.search != "") x.match = true;
            })
        }
    }
}
</script>

<style lang="css">
</style>