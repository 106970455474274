<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('edit_custom_agent.edit') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.name" :label="$t('edit_custom_agent.name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.patent" :label="$t('edit_custom_agent.patent')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.rfc" :label="$t('create_custom_agent.rfc')" :rules="rules_rfc"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="custom_agent.country" :items="countries" :label="$t('create_custom_agent.country')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="custom_agent.state" :items="states" :label="$t('create_custom_agent.state')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.neighborhood" :label="$t('create_custom_agent.neighborhood')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.street" :label="$t('create_custom_agent.street')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="2" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.external_number" :label="$t('create_custom_agent.ext_number')" :rules="rules_required"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.internal_number" :label="$t('create_custom_agent.int_number')"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="py-0">
                                <v-text-field class="py-0" v-model="custom_agent.zipcode" :label="$t('create_custom_agent.zip_code')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('edit_custom_agent.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'customs_broker.update'">
                                {{ $t('edit_custom_agent.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        rules_rfc(){
            return [v => !!v  && (v.toString().length == 12 || v.toString().length == 13) || this.$t('create_custom_agent.validations.rfc')];
        },
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            custom_agent: {
                rfc: '',
                country: '',
                state: '',
                neighborhood: '',
                street: '',
                zipcode: '',
                external_number: '',
                internal_number: '',
            },
            states: [],
            countries: []
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.edit_custom_agent";
    },
    methods: {
        index(){
            this.countries_load = true;
            this.$http.get("https://locations.intech.mx/api/getCountries", {})
            .then((response) => {
                this.countries = response.data
                this.countries_load = false;
            }).finally(() => {
                this.countries_load = false;
            });

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'customs_brokers/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.custom_agent = response.data.data.attributes;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        save(){
            const lsToken = localStorage.getItem("access_token");
            var validate = this.$refs.form.validate()
            if(validate){
                this.$store.state.loaderProps.visible = true;
                this.$http.put(this.$store.state.chronosApi + 'customs_brokers/' + this.id, this.custom_agent,{headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status == 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('create_custom_agent.responses.success')
                        }
                        this.$router.push({ name: "CustomAgentsIndex" });  
                    }
                }).catch((err) => {
                    console.log(err.data);

                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('create_custom_agent.responses.incomplete')
                }
            }
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
        "custom_agent.country": function(){
            this.states = [];
            this.states_load = true;
            this.$http.get("https://locations.intech.mx/api/getStates/"+this.custom_agent.country, {})
            .then((response) => {
                if(response.status === 200){
                    this.states = response.data
                    this.states_load = false;
                }
            }).finally(() => {
                this.states_load = false;
            });
        },
        "custom_agent.state": function(){
            this.municipalities = [];
            this.municipalities_load = true;
            this.$http.get("https://locations.intech.mx/api/getCities/" + this.custom_agent.country + "/" + this.custom_agent.state, {})
            .then((response) => {
                if(response.status === 200){
                    response.data.forEach(x => {
                        this.municipalities.push(x.text);
                    })
                    this.municipalities_load = false;
                }
            }).finally(() => {
                this.municipalities_load = false;
            });
        }
    }
}
</script>

<style lang="css">
</style>