<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('create_location.new') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label>{{$t('create_location.select_location_type')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-radio-group class="my-0" v-model="location.l_type" row>
                                    <v-radio v-for="t of location_types" color="info" :key="t.label" :label="t.label" :value="t.value"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="location.l_type == 'bay'">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="location.locations_number" :label="$t('create_location.locations_number')" :rules="numeric_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="location.name" :label="$t('create_location.name')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="location.l_type == 'bay'">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="location.stacking_level" :label="$t('create_location.stacking_level')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <!-- <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="location.warehouse_id" :items="warehouses" :label="$t('create_location.warehouse')" item-text="name" item-value="id" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row> -->
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('create_location.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save">
                                {{ $t('create_location.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            location: {
                name: '',
                stacking_level: 1,
                warehouse_id: this.$store.state.warehouseId,
                locations_number: 1,
                l_type: 'storage'
            },
            location_types: [
                { label: this.$t('create_location.storage'), value: 'storage' },
                { label: this.$t('create_location.bay'), value: 'bay' },
                // { label: this.$t('create_location.fulfillment'), value: 'fulfillment' }
            ],
            warehouses: []
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.create_location";
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'warehouses', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.forEach(x => {
                    this.warehouses.push({name: x.name, id: x.id});
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        save(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.post(this.$store.state.chronosApi + 'locations', this.location,{headers: {Authorization: lsToken}})
            .then((response) => {
                response
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('create_location.responses.success')
                }
                this.$router.push({ name: "LocationsIndex" });  
            }).catch((err) => {
                if(err.response.data.message == "Name has taken for location in this warehouse"){
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('create_location.validations.name')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>