<template>
    <div>
        <v-card flat>
            <v-card-text class="px-0 py-0">
                <!-- Orders cod generated -->
                <v-row class="my-0 py-0" align="center">
                    <v-col cols="4" class="px-0 mx-0 py-0">
                        <v-col class="px-0 mx-0 my-0 py-0">
                            <v-card-title class="justify-space-between pb-2 pt-0 px-0 ml-11">
                                <v-col class="py-0 my-0">
                                    <span class="text-uppercase subtitle-1 font-weight-bold" style="font-size:15px !important">{{ $t('dashboard.orders_cod_generated_by_status_in_period') }}</span>
                                </v-col>
                            </v-card-title>
                            <v-card-text class="py-0 ml-11">
                                <v-row align="center" justify="center">                                
                                    <doughnut-chart :height="300" :chart-data="totalOrdersCodGeneratedByStatus" :options="totalAppointmentsOptions"></doughnut-chart>
                                </v-row>
                            </v-card-text>
                        </v-col>
                    </v-col>
                    <v-col cols="8" class="py-0">
                        <v-col cols="12" class="mx-0 px-0">
                            <v-card-title class="justify-space-between pb-2 pt-0 pl-12 mx-0">
                                <v-row>
                                    <span class="text-uppercase subtitle-1 font-weight-bold mt-7" style="font-size:15px !important">{{ $t('dashboard.orders_cod_generated_by_status_and_state_in_period') }}</span> 
                                </v-row>
                            </v-card-title>
                            <v-card-text class="py-0 pl-11">
                                <bar-stacked-chart :chart-data="totalOrdersCodGeneratedByStatusAndstate" :options="yearChartOptions" :height="300" :width="700"></bar-stacked-chart>
                            </v-card-text>
                        </v-col>
                    </v-col>
                </v-row>

                <!-- Orders prepaid generated -->
                <v-row class="my-0 py-0" align="center">
                    <v-col cols="4" class="px-0 mx-0 py-0">
                        <v-col class="px-0 mx-0 my-0 py-0">
                            <v-card-title class="justify-space-between pb-2 pt-0 px-0 ml-11">
                                <v-col class="py-0 my-0">
                                    <span class="text-uppercase subtitle-1 font-weight-bold" style="font-size:15px !important">{{ $t('dashboard.orders_prepaid_generated_by_status_in_period') }}</span>
                                </v-col>
                            </v-card-title>
                            <v-card-text class="py-0 ml-11">
                                <v-row align="center" justify="center">                                
                                    <doughnut-chart :height="300" :chart-data="totalOrdersPrepaidGeneratedByStatus" :options="totalAppointmentsOptions"></doughnut-chart>
                                </v-row>
                            </v-card-text>
                        </v-col>
                    </v-col>
                    <v-col cols="8" class="py-0">
                        <v-col cols="12" class="mx-0 px-0">
                            <v-card-title class="justify-space-between pt-0 pb-2 pl-12 mx-0">
                                <v-row>
                                    <span class="text-uppercase subtitle-1 font-weight-bold mt-7" style="font-size:15px !important">{{ $t('dashboard.orders_prepaid_generated_by_status_and_state_in_period') }}</span> 
                                </v-row>
                            </v-card-title>
                            <v-card-text class="py-0 pl-11">
                                <bar-stacked-chart :chart-data="totalOrdersPrepaidGeneratedByStatusAndstate" :options="yearChartOptions" :height="300" :width="700"></bar-stacked-chart>
                            </v-card-text>
                        </v-col>
                    </v-col>
                </v-row>

                <v-row class="my-0 py-0" align="center">
                    <v-col class="px-0 mx-0" cols="6">
                        <v-col class="mx-0 px-0">
                            <v-card-title class="justify-space-between py-2 px-7 ml-7">
                                <v-row>
                                    <span class="text-uppercase subtitle-1 font-weight-bold" style="font-size:15px !important">{{ $t('dashboard.total_orders_cod_by_client_and_status') }}</span>    
                                    <v-btn x-small class="mx-3" @click="back('total_orders_cod_by_client_and_status')"><v-icon x-small>arrow_back_ios</v-icon></v-btn>
                                    <v-btn x-small @click="next('total_orders_cod_by_client_and_status')"><v-icon x-small>arrow_forward_ios</v-icon></v-btn>
                                </v-row>
                            </v-card-title>
                            <v-card-text class="py-0 ml-4">
                                <bar-stacked-chart :chart-data="totalOrdesCodByStatusAndClient" :options="yearChartOptions" :height="300"></bar-stacked-chart>
                            </v-card-text>
                        </v-col>
                    </v-col>
                    <v-col class="px-0 mx-0" cols="6">
                        <v-col class="mx-0 px-0">
                            <v-card-title class="justify-space-between py-2 px-7 ml-7">
                                <v-row>
                                    <span class="text-uppercase subtitle-1 font-weight-bold" style="font-size:15px !important">{{ $t('dashboard.total_orders_prepaid_by_client_and_status') }}</span>    
                                    <v-btn x-small class="mx-3" @click="back('total_orders_prepaid_by_client_and_status')"><v-icon x-small>arrow_back_ios</v-icon></v-btn>
                                    <v-btn x-small @click="next('total_orders_prepaid_by_client_and_status')"><v-icon x-small>arrow_forward_ios</v-icon></v-btn>
                                </v-row>
                            </v-card-title>
                            <v-card-text class="py-0">
                                <bar-stacked-chart :chart-data="totalOrdesPrepaidByStatusAndClient" :options="yearChartOptions" :height="300"></bar-stacked-chart>
                            </v-card-text>
                        </v-col>
                    </v-col>
                </v-row>

                <v-row class="my-0 py-0" align="center">
                    <v-col cols="6" class="px-0 mx-0 py-0">
                        <v-col class="px-0 mx-0 my-0 py-0">
                            <v-card-title class="justify-space-between pb-2 pt-0 px-0 ml-11">
                                <v-col class="py-0 my-0">
                                    <span class="text-uppercase subtitle-1 font-weight-bold">{{ $t('dashboard.cod_collection') }}</span>
                                </v-col>
                            </v-card-title>
                            <v-card-text class="py-0 ml-11">
                                <v-row align="center" justify="center">                                
                                    <doughnut-chart :height="300" style="width: 90% !important; margin-left: -100px !important;" :chart-data="codByOrders" :options="totalAppointmentsOptions"></doughnut-chart>
                                </v-row>
                            </v-card-text>
                        </v-col>
                    </v-col>

                    <v-col cols="6" class="px-0 mx-0 py-0">
                        <v-col class="px-0 mx-0 my-0 py-0">
                            <v-card-title class="justify-space-between pb-2 pt-0 px-0 ml-11">
                                <v-col class="py-0 my-0">
                                    <span class="text-uppercase subtitle-1 font-weight-bold">{{ $t('dashboard.cod_collection_total') }}</span>
                                </v-col>
                            </v-card-title>
                            <v-card-text class="py-0 ml-11">
                                <v-row align="center" justify="center">                                
                                    <doughnut-chart :height="300" style="width: 90% !important; margin-left: -100px !important;" :chart-data="codByOrdersTotal" :options="totalAppointmentsOptions"></doughnut-chart>
                                </v-row>
                            </v-card-text>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>

import DoughnutChart from './Charts/Doughnut.js'
import BarStackedChart from './Charts/BarStacked.js'

export default {
    props: [
        'initial_date',
        'final_date',
        'totalAppointmentsOptions',
        'yearChartOptions',
        'colors',
        'load'
    ],
    components: {
		DoughnutChart,
		BarStackedChart,
    },
    data(){
        return {
            numberFormat: new Intl.NumberFormat('en-us', {minimumFractionDigits: 2, maximumFractionDigits: 2}),

            totalOrdersCodGeneratedByStatus: {},
            totalOrdersCodGeneratedByStatusAndstate: {},
            totalOrdersPrepaidGeneratedByStatus: {},
            totalOrdersPrepaidGeneratedByStatusAndstate: {},
            totalOrdesCodByStatusAndClient: {},
            totalOrdesPrepaidByStatusAndClient: {},
            codByOrders: {},
            codByOrdersTotal: {},

            total_orders_cod_by_client_and_status: [], // total orders cod by status and client
            currenttotal_orders_cod_by_client_and_status: 0, // total orders cod by status and client
            limittotal_orders_cod_by_client_and_status: 1,

            total_orders_prepaid_by_client_and_status: [], // total orders prepaid by status and client
            currenttotal_orders_prepaid_by_client_and_status: 0, // total orders prepaid by status and client
            limittotal_orders_prepaid_by_client_and_status: 1,

            change_pending: false
        }
    },
    mounted(){
        if(this.load == 1) this.index();
    },
    methods: {
        async index(){
            if(!this.change_pending){

                this.change_pending = true
                this.$nextTick(async () => {
                    this.$store.state.loaderProps.visible = true;

                    await this.totalOrdersCodGeneratedByStatusFunction();
                    await this.totalOrdersCodGeneratedByStatusAndstateFunction();
                    await this.totalOrdersPrepaidGeneratedByStatusFunction();
                    await this.totalOrdersPrepaidGeneratedByStatusAndstateFunction();
                    await this.totalOrdersCodByStatusAndClientFunction();
                    await this.totalOrdersPrepaidByStatusAndClientFunction();
                    await this.codByOrdersFunction();
                    await this.codByOrdersTotalFunction();
                    
                    this.$store.state.loaderProps.visible = false;

                    this.change_pending = false;
                });
            }
        },
        async totalOrdersCodGeneratedByStatusFunction(){
			const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi+'generatedOrdersByStatus/' + this.initial_date + '/' + this.final_date + '/1', { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    const labels = [
						{ name: this.$t("dashboard.orders_generated_in_process"), color: this.colors[8], value: 'in_process' },
						{ name: this.$t("dashboard.orders_generated_in_transit"), color: this.colors[5], value: 'in_transit' },
						{ name: this.$t("dashboard.orders_generated_delivered"), color: this.colors[10], value: 'delivered' },
						{ name: this.$t("dashboard.orders_generated_returned"), color: this.colors[0], value: 'returned' },
					]

                    const datasets = [{
						backgroundColor: labels.map(x => {
							return x.color
						}),
						data: labels.map(x => {
							return response.data.shipments[x.value];
						}),
						borderColor: 'rgba(0, 0, 0, 0)',
					}]

                    this.totalOrdersCodGeneratedByStatus = {
                        labels: labels.map(x => {
							return x.name + ' ( ' + response.data.shipments[x.value] + ' )';
						}),
						datasets: datasets
                    }
				}
			})
        },
        async totalOrdersCodGeneratedByStatusAndstateFunction(){
			const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi+'generatedOrdersByStatusAndState/' + this.initial_date + '/' + this.final_date + '/1', { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    // console.log(response.data.shipments);
                    //definition chart 3
                    const labels = [
						{ name: this.$t('dashboard.orders_generated_in_process'), color: this.colors[8], value: "in_process" },
						{ name: this.$t('dashboard.orders_generated_in_transit'), color: this.colors[5], value: "in_transit" },
						{ name: this.$t('dashboard.orders_generated_delivered'), color: this.colors[10], value: "delivered" },
						{ name: this.$t('dashboard.orders_generated_returned'), color: this.colors[0], value: "returned" },
					]

					const datasets = labels.map(x => {
						return {
							label: x.name,
							backgroundColor: x.color,
							
                            data: response.data.shipments.map(y => {
                                return y[x.value]
                            }),
							borderColor: 'rgba(0, 0, 0, 0)',
						}
					})

					this.totalOrdersCodGeneratedByStatusAndstate = {
						labels: response.data.shipments.map(x => {
							return x.state_short_name
						}),
						datasets: datasets
					}

				}
			})
        },
        async totalOrdersPrepaidGeneratedByStatusFunction(){
			const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi+'generatedOrdersByStatus/' + this.initial_date + '/' + this.final_date + '/0', { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    const labels = [
						{ name: this.$t("dashboard.orders_generated_in_process"), color: this.colors[8], value: 'in_process' },
						{ name: this.$t("dashboard.orders_generated_in_transit"), color: this.colors[5], value: 'in_transit' },
						{ name: this.$t("dashboard.orders_generated_delivered"), color: this.colors[10], value: 'delivered' },
						{ name: this.$t("dashboard.orders_generated_returned"), color: this.colors[0], value: 'returned' },
					]

                    const datasets = [{
						backgroundColor: labels.map(x => {
							return x.color
						}),
						data: labels.map(x => {
							return response.data.shipments[x.value];
						}),
						borderColor: 'rgba(0, 0, 0, 0)',
					}]

                    this.totalOrdersPrepaidGeneratedByStatus = {
                        labels: labels.map(x => {
							return x.name + ' ( ' + response.data.shipments[x.value] + ' )';
						}),
						datasets: datasets
                    }
				}
			})
        },
        async totalOrdersPrepaidGeneratedByStatusAndstateFunction(){
			const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi+'generatedOrdersByStatusAndState/' + this.initial_date + '/' + this.final_date + '/0', { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    // console.log(response.data.shipments);
                    //definition chart 3
                    const labels = [
						{ name: this.$t('dashboard.orders_generated_in_process'), color: this.colors[8], value: "in_process" },
						{ name: this.$t('dashboard.orders_generated_in_transit'), color: this.colors[5], value: "in_transit" },
						{ name: this.$t('dashboard.orders_generated_delivered'), color: this.colors[10], value: "delivered" },
						{ name: this.$t('dashboard.orders_generated_returned'), color: this.colors[0], value: "returned" },
					]

					const datasets = labels.map(x => {
						return {
							label: x.name,
							backgroundColor: x.color,
							
                            data: response.data.shipments.map(y => {
                                return y[x.value]
                            }),
							borderColor: 'rgba(0, 0, 0, 0)',
						}
					})

					this.totalOrdersPrepaidGeneratedByStatusAndstate = {
						labels: response.data.shipments.map(x => {
							return x.state_short_name
						}),
						datasets: datasets
					}

				}
			})
        },
        async totalOrdersCodByStatusAndClientFunction(){
			const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi+'generatedOrdersByStatusAndClient/' + this.initial_date + '/' + this.final_date + '/1', { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    this.limitChart6 = Math.ceil(response.data.shipments.length / 10);
                    this.chart6 = response.data.shipments;
                    response.data.shipments = response.data.shipments.slice(0, 10);

                    const labels = [
                        { name: this.$t('dashboard.orders_generated_in_process'), color: this.colors[8], value: "in_process" },
						{ name: this.$t('dashboard.orders_generated_in_transit'), color: this.colors[5], value: "in_transit" },
						{ name: this.$t('dashboard.orders_generated_delivered'), color: this.colors[10], value: "delivered" },
						{ name: this.$t('dashboard.orders_generated_returned'), color: this.colors[0], value: "returned" },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: response.data.shipments.map(y =>{
                                if(r.name == this.$t('dashboard.orders_generated_in_process')) return y.in_process
                                if(r.name == this.$t('dashboard.orders_generated_in_transit')) return y.in_transit
                                if(r.name == this.$t('dashboard.orders_generated_delivered')) return y.delivered
                                if(r.name == this.$t('dashboard.orders_generated_returned')) return y.returned_to_chronos
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.totalOrdesCodByStatusAndClient = {
                        labels: response.data.shipments.map(t => {
                            return t.name
                        }),
                        datasets: datasets
                    }
				}
			})
        },
        async totalOrdersPrepaidByStatusAndClientFunction(){
			const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi+'generatedOrdersByStatusAndClient/' + this.initial_date + '/' + this.final_date + '/0', { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    this.limitChart7 = Math.ceil(response.data.shipments.length / 10);
                    this.chart7 = response.data.shipments;
                    response.data.shipments = response.data.shipments.slice(0, 10);

                    const labels = [
                        { name: this.$t('dashboard.orders_generated_in_process'), color: this.colors[8], value: "in_process" },
						{ name: this.$t('dashboard.orders_generated_in_transit'), color: this.colors[5], value: "in_transit" },
						{ name: this.$t('dashboard.orders_generated_delivered'), color: this.colors[10], value: "delivered" },
						{ name: this.$t('dashboard.orders_generated_returned'), color: this.colors[0], value: "returned" },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: response.data.shipments.map(y =>{
                                if(r.name == this.$t('dashboard.orders_generated_in_process')) return y.in_process
                                if(r.name == this.$t('dashboard.orders_generated_in_transit')) return y.in_transit
                                if(r.name == this.$t('dashboard.orders_generated_delivered')) return y.delivered
                                if(r.name == this.$t('dashboard.orders_generated_returned')) return y.returned_to_chronos
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.totalOrdesPrepaidByStatusAndClient = {
                        labels: response.data.shipments.map(t => {
                            return t.name
                        }),
                        datasets: datasets
                    }
				}
			})
        },
        async codByOrdersFunction(){
			const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi+'codByOrders/' + this.initial_date + '/' + this.final_date, { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    const labels = [
						{ name: this.$t("dashboard.delivered_paid"), color: this.colors[10], value: 'total_paid' },
						{ name: this.$t("dashboard.delivered_not_paid"), color: this.colors[9], value: 'total_pending' },
					]

                    const datasets = [{
						backgroundColor: labels.map(x => {
							return x.color
						}),
						data: labels.map(x => {
							return response.data.shipments[0][x.value];
						}),
						borderColor: 'rgba(0, 0, 0, 0)',
					}]

                    this.codByOrders = {
                        labels: labels.map(x => {
                            if(response.data.shipments[0][x.value] == null) return x.name + ' ( 0 )';
                            else return x.name + ' ( ' + response.data.shipments[0][x.value] + ' )';
						}),
						datasets: datasets
                    }
				}
			})
        },
        async codByOrdersTotalFunction(){
            const lsToken = localStorage.getItem("access_token")
			await this.$http.get(this.$store.state.chronosApi+'codByOrdersTotal/' + this.initial_date + '/' + this.final_date, { headers: { Authorization: lsToken } })
			.then((response) => {
                if(response.status === 200){
                    const labels = [
						{ name: this.$t("dashboard.delivered_paid_total"), color: this.colors[8], value: 'total_paid' },
						{ name: this.$t("dashboard.delivered_not_paid_total"), color: this.colors[11], value: 'total_pending' },
					]

                    const datasets = [{
						backgroundColor: labels.map(x => {
							return x.color
						}),
						data: labels.map(x => {
							return response.data.shipments[0][x.value];
						}),
						borderColor: 'rgba(0, 0, 0, 0)',
					}]

                    this.codByOrdersTotal = {
                        labels: labels.map(x => {
							return x.name + ' ( $' + this.numberFormat.format(response.data.shipments[0][x.value]) + ' )';
						}),
						datasets: datasets
                    }
				}
			})
        },
        back(chart){
            if(chart == 'total_orders_cod_by_client_and_status'){
                if(this.currenttotal_orders_cod_by_client_and_status > 0){
                    const array = this.total_orders_cod_by_client_and_status.slice(((this.currenttotal_orders_cod_by_client_and_status - 1) * 10), (this.currenttotal_orders_cod_by_client_and_status * 10));
                    this.currenttotal_orders_cod_by_client_and_status -= 1;
                    const labels = [
                        { name: this.$t('dashboard.orders_generated_in_process'), color: this.colors[8], value: "in_process" },
						{ name: this.$t('dashboard.orders_generated_in_transit'), color: this.colors[5], value: "in_transit" },
						{ name: this.$t('dashboard.orders_generated_delivered'), color: this.colors[10], value: "delivered" },
						{ name: this.$t('dashboard.orders_generated_returned'), color: this.colors[0], value: "returned" },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: array.map(y =>{
                                if(r.name == this.$t('dashboard.orders_generated_in_process')) return y.in_process
                                if(r.name == this.$t('dashboard.orders_generated_in_transit')) return y.in_transit
                                if(r.name == this.$t('dashboard.orders_generated_delivered')) return y.delivered
                                if(r.name == this.$t('dashboard.orders_generated_returned')) return y.returned_to_chronos
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.totalOrdesCodByStatusAndClient = {
                        labels: array.map(t => {
                            return t.name
                        }),
                        datasets: datasets
                    }
                }
            }
            else if(chart == 'total_orders_prepaid_by_client_and_status'){
                if(this.currenttotal_orders_prepaid_by_client_and_status > 0){
                    const array = this.total_orders_prepaid_by_client_and_status.slice(((this.currenttotal_orders_prepaid_by_client_and_status - 1) * 10), (this.currenttotal_orders_prepaid_by_client_and_status * 10));
                    this.currenttotal_orders_prepaid_by_client_and_status -= 1;
                    const labels = [
                        { name: this.$t('dashboard.orders_generated_in_process'), color: this.colors[8], value: "in_process" },
						{ name: this.$t('dashboard.orders_generated_in_transit'), color: this.colors[5], value: "in_transit" },
						{ name: this.$t('dashboard.orders_generated_delivered'), color: this.colors[10], value: "delivered" },
						{ name: this.$t('dashboard.orders_generated_returned'), color: this.colors[0], value: "returned" },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: array.map(y =>{
                                if(r.name == this.$t('dashboard.orders_generated_in_process')) return y.in_process
                                if(r.name == this.$t('dashboard.orders_generated_in_transit')) return y.in_transit
                                if(r.name == this.$t('dashboard.orders_generated_delivered')) return y.delivered
                                if(r.name == this.$t('dashboard.orders_generated_returned')) return y.returned_to_chronos
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.totalOrdesPrepaidByStatusAndClient = {
                        labels: array.map(t => {
                            return t.name
                        }),
                        datasets: datasets
                    }
                }
            }
        },
        next(chart){
            if(chart == 'total_orders_cod_by_client_and_status'){
                if(this.currenttotal_orders_cod_by_client_and_status < this.limittotal_orders_cod_by_client_and_status -1){
                    this.currenttotal_orders_cod_by_client_and_status += 1;
                    const array = this.total_orders_cod_by_client_and_status.slice((this.currenttotal_orders_cod_by_client_and_status * 10), ((this.currenttotal_orders_cod_by_client_and_status + 1) * 10));
                    const labels = [
                        { name: this.$t('dashboard.orders_generated_in_process'), color: this.colors[8], value: "in_process" },
						{ name: this.$t('dashboard.orders_generated_in_transit'), color: this.colors[5], value: "in_transit" },
						{ name: this.$t('dashboard.orders_generated_delivered'), color: this.colors[10], value: "delivered" },
						{ name: this.$t('dashboard.orders_generated_returned'), color: this.colors[0], value: "returned" },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: array.map(y =>{
                                if(r.name == this.$t('dashboard.orders_generated_in_process')) return y.in_process
                                if(r.name == this.$t('dashboard.orders_generated_in_transit')) return y.in_transit
                                if(r.name == this.$t('dashboard.orders_generated_delivered')) return y.delivered
                                if(r.name == this.$t('dashboard.orders_generated_returned')) return y.returned_to_chronos
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.totalOrdesCodByStatusAndClient = {
                        labels: array.map(t => {
                            return t.name
                        }),
                        datasets: datasets
                    }
                }
            }
            else if(chart == 'total_orders_prepaid_by_client_and_status'){
                if(this.currenttotal_orders_prepaid_by_client_and_status < this.limittotal_orders_prepaid_by_client_and_status -1){
                    this.currenttotal_orders_prepaid_by_client_and_status += 1;
                    const array = this.total_orders_prepaid_by_client_and_status.slice((this.currenttotal_orders_prepaid_by_client_and_status * 10), ((this.currenttotal_orders_prepaid_by_client_and_status + 1) * 10));
                    const labels = [
                        { name: this.$t('dashboard.orders_generated_in_process'), color: this.colors[8], value: "in_process" },
						{ name: this.$t('dashboard.orders_generated_in_transit'), color: this.colors[5], value: "in_transit" },
						{ name: this.$t('dashboard.orders_generated_delivered'), color: this.colors[10], value: "delivered" },
						{ name: this.$t('dashboard.orders_generated_returned'), color: this.colors[0], value: "returned" },
					]

                    const datasets = labels.map(r => {
                        return {
                            label: r.name,
                            backgroundColor: r.color,
                            data: array.map(y =>{
                                if(r.name == this.$t('dashboard.orders_generated_in_process')) return y.in_process
                                if(r.name == this.$t('dashboard.orders_generated_in_transit')) return y.in_transit
                                if(r.name == this.$t('dashboard.orders_generated_delivered')) return y.delivered
                                if(r.name == this.$t('dashboard.orders_generated_returned')) return y.returned_to_chronos
                            }),
                            borderColor: 'rgba(0, 0, 0, 0)',
                        }
                    })
                    this.totalOrdesPrepaidByStatusAndClient = {
                        labels: array.map(t => {
                            return t.name
                        }),
                        datasets: datasets
                    }
                }
            }
        }
    },
    watch: {
        'initial_date': function(){
            if(this.load == 1) this.index();
        },
        'final_date': function(){
            if(this.load == 1) this.index();
        },
        'load': function (){
            if(this.load == 1 && this.change_pending == false) this.index();
        }
    }
}
</script>

<style>

</style>