<template>
    <div class="px-0">
        <rfid-settings v-if="show_rfid" ref="showSettings" :items="rfid_items" ></rfid-settings>
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5" justify="center" align="center">
                    <v-col cols="4" align="start" justify="start">    
                        <h3 class="azul--text text-uppercase">{{ $t('locations_details.details') }}</h3>
                    </v-col>
                    <v-col v-if="is_rfid" cols="1" align="end" justify="end">
                        <v-btn class="right white--text" color="blue" @click="print_tags" >
                            RFID
                        </v-btn>
                    </v-col>
                    <v-col cols="2" align="end" justify="end">
                        <v-btn class="right white--text" color="amarillo" @click="cancel" >
                            {{ $t('locations_details.go_back') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('locations_details.name')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.location.name }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('locations_details.stacking_level')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.location.stacking_level }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('locations_details.warehouse')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.location.warehouse.name }} </label>
                                </v-row>
                            </v-col>
                        </v-row>


                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="!is_bay">
                            <v-col cols="6" class="py-0 px-3">
                                <datatable :props="{ headers:headers, items: products }" @methodHandler="methodHandler"></datatable>
                            </v-col>
                        </v-row>

                        <br>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="is_bay">
                            <v-col cols="6" class="py-0">
                                <v-expansion-panels>
                                <v-expansion-panel v-for="(item, i) in bay_locations" :key="i">
                                    <v-expansion-panel-header>
                                        {{item.attributes.name}}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-col cols="12" class="py-0 px-0 mx-0 my-0">
                                            <datatable :hide_pagination="true" :props="{ headers:headers, items: item.attributes.current_products, rowsPP:[-1] }" @methodHandler="methodHandler"></datatable>
                                        </v-col>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        headers(){
            return [
                { text: this.$t('locations_details.product'), align: "center", value: 'sku', class: 'text-uppercase' },
                { text: this.$t('locations_details.pallet'), align: "center", value: 'pallet', class: 'text-uppercase' },
                { text: this.$t('locations_details.box'), align: "center", value: 'box', class: 'text-uppercase' },
                { text: this.$t('locations_details.quantity'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('locations_details.reserved'), align: "center", value: 'reserved', class: 'text-uppercase' },
                { text: this.$t('locations_details.batch'), align: "center", value: 'batch', class: 'text-uppercase' },
                { text: this.$t('locations_details.expiration'), align: "center", value: 'reference', class: 'text-uppercase' },
                { text: "", align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            is_bay: false,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            location: {
                name: '',
                stacking_level: '',
                warehouse: {
                    name: ''
                }
            },
            bay_locations: [],
            products: [],
            is_rfid: false,
            show_rfid: false,
            rfid_items: {
                pallets:[],
                boxes: [],
                tags: []
            },
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.warehouses";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            if(this.id.includes('-')){ // get bay's locations and details
                this.id = this.id.split("-")[0];

                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + 'locations-bay/' + this.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    this.is_bay = true;
                    this.location = {
                        name: response.data.location.name,
                        stacking_level: response.data.location.stacking_level,
                        warehouse: {
                            name: response.data.location.warehouse_name
                        }
                    }

                    this.bay_locations = response.data.locations.data;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else { // get location detail
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + 'rfid/location/is_rfid/' + this.id, {headers: {Authorization: lsToken}})
                .then(res => {
                    this.is_rfid = res.data.cw.is_rfid
                }).catch(err => {console.log(err)})
                this.$http.get(this.$store.state.chronosApi + 'locations/' + this.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    response.data = response.data.data.attributes;
                    this.location = response.data;
                    this.products = this.location.current_products;
                    console.log(this.location.current_products);
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
        },
        cancel(){
            this.$router.go(-1);
        },
        print_tags(id){
            console.log(id);
            this.$store.state.loaderProps.visible = true;
            var t = this
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'rfid/location/' + this.id, {headers: {Authorization: lsToken}})
            .then(response => {
                console.log("se esta imprimiendo el response de print tags")
                console.log(response)
                t.rfid_items = response.data.items
                t.show_rfid = true
                
            })
            .catch(err => {console.log(err)})
            .finally(()=>{this.$store.state.loaderProps.visible = false;})
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>