<template>
    <div class="py-0 mt-4 mb-8">
        <v-container class="mx-0 my-0" style="padding:0 !important; max-width: 100% !important">
            <v-card class="mt-1 pt-1 pb-10 px-0 size" style="margin-bottom: 35px !important">
                <v-row align="end" class="px-4 pb-3" justify="center">
                    <h3 class="px-4 pt-4 azul--text" style="font-size:25px">{{ $t('register_in_portal.register_in_portal') }}</h3>
                </v-row>
                <v-row class="mx-3 py-0 my-0" align="center" justify="center">
                    <v-col cols="6" justify="center" align="center">
                        <v-autocomplete class="px-3" v-model="selected" :items="appointments"  :label="$t('register_in_portal.select_appointment')"></v-autocomplete>
                    </v-col>
                </v-row>
                
                <v-row class="mx-3 py-0 my-0" align="center" justify="center">
                    <v-btn color="amarillo" outlined :disabled="!isSelected" class="white--text mr-4" @click="toPending">
                        {{ $t('register_in_portal.to_pending') }}
                    </v-btn>
                    <v-btn color="amarillo" class="white--text" @click="update" :disabled="!isSelected">
                        {{ $t('register_in_portal.put_in_portal') }}
                    </v-btn>
                </v-row>
                <v-row class="mx-3 py-0 my-0">
                    <v-col align="center" justify="center">
                        <v-btn color="white" class="black--text mx-1" @click="cancel">
                            {{ $t('register_in_portal.cancel') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <bottom-bar ref="bottombar"></bottom-bar>
        </v-container>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                timeout: 2000,
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            isSelected: false,
            portal_id: this.$route.params.id,
            selected:'',
            appointments: [],
            portals: [],
        }
    },
    mounted(){
        this.index();
        localStorage.setItem('bottom', 1);
        this.$store.state.module = "headers.in_portal_operation";
        this.$store.state.bottom = 1;
    },
    methods: {
        back(){
            this.$router.push({ name: 'OperationOnPremises' });
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        index(){
            this.props.items = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'appointmentsOperation/En recinto', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x =>{
                    x = x.attributes;
                    if(x.status == 'En recinto'){ 
                        x.value = x.id + ' | '+ this.$t('register_in_portal.client') + ': ' + x.client.name + ' | '+ this.$t('register_in_portal.container') + ': ' + x.container_number + ' | '+ this.$t('register_in_portal.movement_type') + ': ' + x.operation.description;
                        this.appointments.push(x.value)
                        this.props.items.push(x);
                    }
                });
            })
            .catch((error) => {
                console.log(error);
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('register_in_portal.responses.error')
                }
            }).finally(() => {    
                this.$store.state.loaderProps.visible = false;
            });
        },
        update(){
            if(this.portal_id != '' && this.selected != ''){
                const appointment = {
                    gate_id: this.portal_id,
                    status: 'En rampa',
                    newStatus: 'En rampa',
                }

                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.put(this.$store.state.chronosApi + 'setStatus/' + this.selected.split('|')[0].trim(), appointment, {headers: {Authorization: lsToken}})
                .then((response) => {   
                    if(response.status === 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('register_in_portal.responses.correct')
                        } 
                        this.$router.push({ name: 'OperationOnPremises' });
                    }
                }).catch((error) => {
                    console.log(error);
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('register_in_portal.responses.update')
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('register_in_portal.responses.empty')
                }
            }
        },
        toPending(){
            if(this.selected != ''){ 
                const appointment = {
                    gate_id: null,
                    status: 'Pendiente',
                    newStatus: 'Pendiente',
                }

                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.put(this.$store.state.chronosApi + 'setStatus/' + this.selected.split('|')[0].trim(), appointment, {headers: {Authorization: lsToken}})
                .then((response) => {   
                    if(response.status === 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('register_in_portal.responses.correct')
                        } 
                        this.$router.push({ name: 'OperationPendings' });
                    }
                }).catch((error) => {
                    console.log(error);
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('register_in_portal.responses.update')
                    }
                }).finally(() => {
                this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('register_in_portal.responses.empty')
                }
            }
        },
        cancel(){
            this.$router.go(-1);
        }
    },
    watch:{
        'selected': function() {
            this.isSelected = true;
        },
    }
}
</script>

<style lang="css">
.size{
    min-height: calc(100vh - 120px) !important;
    position:relative !important;
}
</style>