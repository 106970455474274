<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5" justify="center" align="center">
                    <v-col cols="4" align="start" justify="start">    
                        <h3 class="azul--text text-uppercase">{{ $t('customer_details.customer_details') }}</h3>
                    </v-col>
                    <v-col cols="2" align="end" justify="end">
                        <v-btn class="right white--text" color="amarillo" @click="cancel" >
                            {{ $t('customer_details.go_back') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       
                        <!-- General data -->
                       
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('customer_details.general_data')}}</label>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.tradename')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.customer.name }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.business_name')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.customer.social_reason }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.country')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.customer.country }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.state')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.customer.state }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.neighborhood')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.customer.neighborhood }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.street')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.customer.street }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.ext_number')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.customer.external_number }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.int_number')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.customer.internal_number }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.zip_code')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.customer.zipcode }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.contact_phone')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.customer.contact_phone }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.customer_service_phone')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.customer.service_phone }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.email')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.customer.email }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <v-col cols="6" class="px-0 mx-0">
                                        <label>{{ $t('customer_details.token')}}</label>
                                    </v-col>
                                    <v-col cols="6" class="px-0 mx-0">
                                        <label> {{ this.customer.token }} </label>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!-- Fiscal Data -->

                        <v-row class="px-5 py-0 mt-5 mb-1" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('customer_details.fiscal_data')}}</label>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.client_imports')}}</label>
                                    <v-spacer></v-spacer>
                                    <label v-if="customer.is_national == 1"> {{ this.$t('customer_details.yes') }} </label>
                                    <label v-else> {{ this.$t('customer_details.no') }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label v-if="customer.is_national == 1">{{ $t('customer_details.ssn')}}</label>
                                    <label v-if="customer.is_national == 0">{{ $t('customer_details.immex')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.customer.immex_ssn }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                       <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label v-if="customer.is_national == 1">{{ $t('customer_details.idc')}}</label>
                                    <label v-if="customer.is_national == 0">{{ $t('customer_details.rfe')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.customer.rfe_idc }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        
                        <!-- Warehouses -->

                        <v-row class="px-5 py-0 mt-5 mb-1" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('customer_details.warehouse')}}</label>
                            </v-col>
                        </v-row>
                        <v-row v-for="warehouse of customer.clients_warehouse" :key="warehouse.id" class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ warehouse.name }}</label>
                                    <v-spacer></v-spacer>
                                    <label>{{ $t('customer_details.yes')}}</label>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!-- fulfillment -->
                        
                        <v-row class="px-5 py-0 mt-5 mb-1" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('customer_details.fulfillment')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.client_fulfillment')}}</label>
                                    <v-spacer></v-spacer>
                                    <label v-if="customer.fulfillment != 'none'"> {{ this.$t('customer_details.yes') }} </label>
                                    <label v-else> {{ this.$t('customer_details.no') }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.fulfillment_type')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.$t('customer_details.' + this.customer.fulfillment ) }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.shipments_notifications')}}</label>
                                    <v-spacer></v-spacer>
                                    <label v-if="customer.shipments_notifications == 1"> {{ this.$t('customer_details.yes') }} </label>
                                    <label v-else> {{ this.$t('customer_details.no') }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row v-for="shipments of customer.shipping_accounts" :key="shipments.id" class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{$t('customer_details.fulfillment_with')}}{{ shipments.name }}</label>
                                    <v-spacer></v-spacer>
                                    <label>{{ $t('customer_details.yes')}}</label>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!-- Notifications -->

                        <v-row class="px-5 py-0 mt-5 mb-1" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('customer_details.operation_notifications')}}</label>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.operation_notifications')}}</label>
                                    <v-spacer></v-spacer>
                                    <label v-if="operation_notifications == 1"> {{ this.$t('customer_details.yes') }} </label>
                                    <label v-else> {{ this.$t('customer_details.no') }} </label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ $t('customer_details.email_to_notify')}}</label>
                                    <v-spacer></v-spacer>
                                    <label> {{ this.customer.email_to_notify }} </label>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="customer.arrival_ad">
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ this.$t('customer_details.create') }}</label>
                                    <v-spacer></v-spacer>
                                    <label>{{ this.$t('customer_details.yes') }}</label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="customer.in_out_ad">
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ this.$t('customer_details.on_premises') }}</label>
                                    <v-spacer></v-spacer>
                                    <label>{{ this.$t('customer_details.yes') }}</label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="customer.ramping_ad">
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ this.$t('customer_details.ramping') }}</label>
                                    <v-spacer></v-spacer>
                                    <label>{{ this.$t('customer_details.yes') }}</label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="customer.start_process_ad">
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ this.$t('customer_details.in_process') }}</label>
                                    <v-spacer></v-spacer>
                                    <label>{{ this.$t('customer_details.yes') }}</label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="customer.end_process_ad">
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ this.$t('customer_details.end_process') }}</label>
                                    <v-spacer></v-spacer>
                                    <label>{{ this.$t('customer_details.yes') }}</label>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" v-if="customer.exit_truck_ad">
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                    <label>{{ this.$t('customer_details.end_operation') }}</label>
                                    <v-spacer></v-spacer>
                                    <label>{{ this.$t('customer_details.yes') }}</label>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            countries: [],
            states: [],
            municipalities: [],
            countries_load: false,
            states_load: false,
            municipalities_load: false,
            customer: {
                name: "",
                social_reason: "",
                country: "",
                state: "",
                neighborhood: "",
                street: "",
                zipcode: "",
                external_number: "",
                internal_number: "",
                municipality: "",
                contact_phone: "",
                service_phone: "",
                email: "",
                is_national: 1,
                immex_ssn: '',
                rfe_idc: '',
                warehouses: [],
                fulfillment: '',
                shipments_notifications: 1,
                shipping_accounts: [],
                email_to_notify: '',
                in_out_ad: 0,
                arrival_ad: 0,
                ramping_ad: 0,
                start_process_ad: 0,
                end_process_ad: 0,
                exit_truck_ad:0
            },
            operation_notifications: 0,
            fulfillment : 0,
            fullfillment_types: [
                {label: this.$t("customer_details.cod"), value: 'cod'},
                {label: this.$t("customer_details.regulars"), value: 'regular'},
                {label: this.$t("customer_details.both"), value: 'all'}
            ],
            warehouses: [],
            shipping_accounts: []
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.customer_details";
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            // Get warehouses
            this.$http.get(this.$store.state.chronosApi + 'warehouses', {headers: {Authorization: lsToken}})
            .then((response) => {
                this.warehouses = response.data;
                // Get shipping_acounts
                this.$http.get(this.$store.state.chronosApi + 'shipping_accounts', {headers: {Authorization: lsToken}})
                .then((response) => {
                    this.shipping_accounts = response.data;

                    this.$http.get(this.$store.state.chronosApi + 'clients/' + this.id, {headers: {Authorization: lsToken}})
                    .then((response) => {
                        this.customer = response.data.data.attributes;
                        if(this.customer.fulfillment != "none") this.fulfillment = 1;
                        this.customer.clients_warehouse.forEach(x => {
                            this.warehouses.forEach(w =>{
                                if(w.id == x.warehouse_id){
                                    x.name = w.name;
                                    return;
                                }
                            });
                        })
                        if(this.customer.arrival_ad == true || this.customer.ramping_ad == true || this.customer.start_process_ad == true || this.customer.end_process_ad == true || this.customer.in_out_ad == true || this.customer.exit_truck_ad == true ){
                            this.operation_notifications = 1;
                        }
                    }).catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        this.$store.state.loaderProps.visible = false;
                    })
                }).catch((err) => {
                    console.log(err);
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>