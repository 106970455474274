<template>
    <div class="px-0">
        <v-container class="px-0 py-1 my-0" style="max-width: 100% !important">
            <v-row class="px-0 py-0 my-1 mx-3" style="max-height: 43px !important; min-height: 43px !important;" align="center">
                <v-col cols="3" class="py-0 my-0 px-0" >
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        single-line
                        hide-details
                        class="py-0 px-2 my-0"
                        color="amarillo"
                        style="background-color: transparent; border: 1px solid #9fb700; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn class="right white--text tools-view-height mr-2" outlined color="amarillo" @click="modalImport = true;" v-permission:any="'customer_fulfillment_eon.show|customer_all_access.show'">
                    {{ $t('customer_fulfillment.import_shipments') }}
                    <v-icon class="px-1" small>cloud_upload</v-icon>
                </v-btn>
                <v-btn class="right white--text tools-view-height" color="amarillo" @click="modalNewOrder = true;" v-permission:any="'customer_fulfillment_eon.show|customer_all_access.show'">
                    {{ $t('fulfillment_eon_pendings.new_order') }}
                    <v-icon class="px-1" small>add</v-icon>
                </v-btn>
                <!-- <v-btn class="right white--text tools-view-height" color="amarillo" :to="'customer_fulfillment_create_order'">
                    {{ $t('orders.create') }}
                </v-btn> -->
            </v-row>
            <v-card class="px-0 top_bar mb-3">
                <v-card-text class="py-0 my-0">
                    <v-row class="px-0 py-0 my-0" style="max-height: 43px !important; min-height: 43px !important;" align="center">
                        <v-col cols="8" class="py-0 my-0 px-0">
                            <v-tabs v-model="tab"  active-class="customers_active_tab" style="max-height: 43px !important;">
                                <v-tabs-slider class="slider" color="amarillo" style="margin: 0px !important;"></v-tabs-slider>
                                <v-tab v-for="item in tab_items" :key="item" class="non_selected_tab slider" style="max-height: 43px !important;">
                                    {{ item }}
                                </v-tab>
                            </v-tabs>
                        </v-col>
                        
                        <v-spacer></v-spacer>

                        <!-- <v-col cols="2" class="py-0 my-0 px-0" v-if="fulfillment == 3">
                            <v-tabs v-model="types"  active-class="customers_active_tab" style="max-height: 43px !important;">
                                <v-tabs-slider class="slider" color="amarillo" style="max-height: 43px !important; margin: 0px !important;"></v-tabs-slider>
                                <v-tab v-for="item in types_items" :key="item" class="non_selected_tab slider" style="max-height: 43px !important;">
                                    {{ item }}
                                </v-tab>
                            </v-tabs>
                        </v-col> -->
                    </v-row>
                </v-card-text>
            </v-card>

            <v-tabs-items v-model="tab" class="px-0 mx-3 ">
                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" flat>
                        <v-card-text class="px-0 mx-1">
                            <v-row class="px-0 mx-0">
                                <v-col class="py-0 px-0 mx-0">
                                    <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" flat>
                        <v-card-text class="px-0 mx-1">
                            <v-row>
                                <v-col class="py-0">
                                    <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" flat>
                        <v-card-text class="px-0 mx-1">
                            <v-row>
                                <v-col class="py-0">
                                    <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" flat>
                        <v-card-text class="px-0 mx-1">
                            <v-row>
                                <v-col class="py-0">
                                    <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" flat>
                        <v-card-text class="px-0 mx-1">
                            <v-row>
                                <v-col class="py-0">
                                    <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <!-- <v-tab-item class="px-0 mx-0">
                    <v-card class="size_tab mx-0 px-0" flat>
                        <v-card-text class="px-0 mx-1">
                            <v-row>
                                <v-col class="py-0">
                                    <datatable :props="{headers:headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item> -->
            </v-tabs-items>

            <v-dialog v-model="modalDetail" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('customer_fulfillment.order_details.header') }} {{ this.orderToShow.unique_order_number }}
                            </h4>
                        </v-row>
                    </v-card-title>
                    
                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('customer_fulfillment.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("customer_fulfillment.order_number") }}</label> <label>{{ this.orderToShow.unique_order_number }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("customer_fulfillment.shipping_service") }}</label> <label>{{ this.orderToShow.shipping_service }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("customer_fulfillment.date") }}</label> <label>{{ this.orderToShow.created_at }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("customer_fulfillment.customer") }}</label> <label>{{ this.orderToShow.customer }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("customer_fulfillment.buyer") }}</label> <label>{{ this.orderToShow.buyer }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('customer_fulfillment.products') }}</label></div>
                    <span>
                        <div v-for="(product, i) of orderToShow.products" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.sku }}</label> <label>{{ product.quantity }}</label></div>
                    </span>

                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDetail = false">{{ $t('customer_fulfillment.go_back') }}</v-btn>
                            
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalImport" persistent max-width="90%" >
                <v-card style="min-width: 650px !important">
                    <v-card-title class="justify-center">
                        {{ $t('fulfillment_eon_pendings.import_orders') }}
                    </v-card-title>
                    <v-stepper v-model="stepperImport">
                        <v-stepper-items>
                            <v-stepper-content step="1" class="mx-0 px-0">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="10">
                                            <v-row class="py-0 my-0" justify="center" align="center">
                                                <v-col cols="10" class="py-0 my-0">
                                                    <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" :clearable="true" v-model="importOrders.file" accept=".csv" :rules="rules_required" :label="$t('fulfillment_eon_pendings.shipments_file')"></v-file-input>
                                                </v-col>
                                            </v-row>
                                            <v-row class="py-0 my-0" justify="center" align="center">
                                                <v-col cols="10" class="py-0 my-0">
                                                    <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" :clearable="true" v-model="importOrders.labels" accept=".zip" :rules="rules_required" :label="$t('fulfillment_eon_pendings.labels_file')"></v-file-input>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn class="mx-0" small color="amarillo" fab dark v-bind="attrs" v-on="on" @click="stepperImport = 3">
                                                        <v-icon class="material-icons-outlined" aria-hidden="false" color="white" small>
                                                            help
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('fulfillments_prepaid_returns.help') }}</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card-text> 
                                <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                    <v-col cols="11" class="py-0 my-0 mx-0 px-0" align="end" justify="end">
                                        <v-btn class="mx-3" text @click="modalImport = false">{{ $t('fulfillments_prepaid_returns.cancel') }}</v-btn>
                                        <v-btn color="amarillo" class="white--text" @click="uploadOrders()">
                                            {{ $t('fulfillment_eon_pendings.save') }}
                                        </v-btn>    
                                    </v-col>
                                </v-row>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-card flat class="mb-5">
                                    <v-simple-table v-if="this.responses.length > 0" fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" class="text-left">{{ $t('fulfillments_prepaid_returns.order') }}</th>
                                                    <th style="background-color:#c1d500; font-weight:bold !important; color:black !important" class="text-left">{{ $t('fulfillments_prepaid_returns.message') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, i) in responses" :key="i">
                                                    <td>{{ item.order }}</td>
                                                    <td>{{ item.message }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>

                                <div class="d-flex">
                                    <v-btn class="px-3" text @click="stepperImport = 1; modalImport = false;">{{ $t('fulfillments_prepaid_returns.close') }}</v-btn>
                                </div>
                            </v-stepper-content>

                            <v-stepper-content step="3">
                                    <p class="py-0 my-0">Archivo de ordenes</p> 

                                    <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                                    <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                                    <p class="py-0 my-0">3.- En caso de que la orden contenga dos o más SKUs diferentes, debe separar cada uno de ellos con el caracter pipe ( | ) en la columna correspondiente, lo mismo aplicará para la columna donde se indica la cantidad de productos.</p> 
                                    <p class="py-0 my-0">4.- El archivo debe ser formato CSV.</p> 
                                    <p class="py-0 my-0">5.- Los archivos contenidos en el .zip de las etiquetas deben tener por nombre el numero de orden al que pertenecen.</p> 
                                    <p class="py-0 my-0">6.- El archivo .zip debe conterner todos los archivos de etiqueta sin carpetas.</p> 
                                    <p class="py-0 my-0">7.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 

                                    <p class="py-0 my-0">Columnas: </p>
                                
                                    <v-simple-table fixed-header>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                   <th class="text-left" style="padding: 0 5px !important;">orden</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">paqueteria</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">guia</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">cliente</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">sku</th>
                                                   <th class="text-left" style="padding: 0 5px !important;">cantidad</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                  <td>OrdenPrueba001</td>
                                                    <td>FEDEX</td>
                                                    <td>4889312354</td>
                                                    <td>Josúe René Galindo</td>
                                                    <td>THISISSKU01|THISISSKU2</td>
                                                    <td>2|1</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    
                                    <v-row align="center" justify="center" class="my-3">
                                        <v-btn color="amarillo" class="white--text" @click="stepperImport = 1">
                                            {{ $t('products.go_back') }}
                                        </v-btn>
                                    </v-row>
                                </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                    <div class="marker1"></div>

                </v-card>
            </v-dialog>

            <v-dialog v-model="modalNewOrder" persistent max-width="90%">
                <v-card style="min-width: 650px !important">
                    <v-card-text>
                        <v-row align="center" justify="center" class="pt-3 mt-3 text-center">
                            <v-col cols="12">
                                <h3>{{ $t('fulfillment_eon_pendings.new_order') }}</h3>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-autocomplete class="mt-0 py-0" v-model="shipment.shipping_service" :items="parcels" :label="$t('fulfillment_eon_pendings.select_parcel')" :rules="rules_required"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-text-field class="py-0" v-model="shipment.unique_order_number" :label="$t('fulfillment_eon_pendings.unique_order_number')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-text-field class="py-0" v-model="shipment.tracking_number" :label="$t('fulfillment_eon_pendings.tracking_number')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-text-field class="py-0" v-model="shipment.consignee_name" :label="$t('fulfillment_eon_pendings.buyers_name')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" multiple :clearable="true" v-model="shipment.label" accept="*" :label="$t('fulfillment_eon_pendings.label')"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" multiple :clearable="true" v-model="shipment.attached_files" accept="*" :label="$t('fulfillment_eon_pendings.attached_files')"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-text-field class="py-0" v-model="shipment.total_price" :label="$t('fulfillment_eon_pendings.total_price')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="4" class="py-0 my-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product.sku" item-text="sku" item-value="sku" :items="products" :label="$t('fulfillment_eon_pendings.select_product')"></v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0 my-0 mx-0 px-0">
                                <v-text-field class="py-0" v-model="product.quantity" :label="$t('fulfillment_eon_pendings.quantity')"></v-text-field>
                            </v-col>
                            <v-col cols="3" class="py-0 my-0 mx-0" justify="end" align="end">
                                <v-btn color="amarillo" class="white--text" @click="addProduct()">
                                    {{ $t('fulfillment_eon_pendings.add') }}
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row class="px-0 py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0 mx-0 px-0">
                                <datatable :income_editable="true" class="custom_table_class" :props="{headers:productsHeaders, items: shipment.products}" @methodHandler="methodHandler"></datatable>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0 my-0" justify="center" align="center">
                            <v-col cols="11" class="py-0 my-0 mx-0 px-0" align="end" justify="end">
                                <v-btn class="mx-3" text @click="cancelNewOrder()">{{ $t('fulfillments_prepaid_returns.cancel') }}</v-btn>
                                <v-btn color="amarillo" class="white--text" @click="saveNewOrder()">
                                    {{ $t('fulfillment_eon_pendings.save') }}
                                </v-btn>    
                            </v-col>
                        </v-row>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            return [
                { text: this.$t('orders.order'), align: "center", value: 'uniqueOrderNumber', class: 'text-uppercase', width: "20%" },
                { text: this.$t('orders.tracking_guide'), align: "center", value: 'trackingNumber', class: 'text-uppercase', width: "20%"},
                { text: this.$t('orders.created_at'), align: "center", value: 'createdAt', class: 'text-uppercase', width: "10%" },
                { text: this.$t('orders.parcel'), align: "center", value: 'shippingService', class: 'text-uppercase', width: "10%" },
                // { text: this.$t('orders.destination'), align: "center", value: 'consignee.state', class: 'text-uppercase', width: "15%" },
                { text: this.$t('orders.status'), align: "center", value: 'coloredStatus', class: 'text-uppercase', width: "15%" },
                { text: this.$t('orders.detail'), align: "center", value: 'actions', class: 'text-uppercase', width: "10%" },
            ];
        },
        productsHeaders(){
            return [
                { text: this.$t('fulfillment_eon_pendings.sku'), value: 'sku', width: '60%' },
                { text: this.$t('fulfillment_eon_pendings.quantity'), value: 'quantity', width: '40%' },
                { text: '', value: 'actions', class: 'text-uppercase', width:'10%' }
            ];
        },  
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
    },
    data(){
        return {
            clientId: this.$store.state.clientUserId,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            tab: null,
            tab_items: [this.$t("orders.pendings"), this.$t("orders.in_transit"), this.$t("orders.delivered"), this.$t("orders.returned"), this.$t("orders.all")],
            // tab_items: [this.$t("orders.all"), this.$t("orders.in_process"), this.$t("orders.in_transit"), this.$t("orders.delivered"), this.$t("orders.returned"), this.$t("orders.exceptions")],
            modalDetail: false,
            orderToShow: '',
            modalNewOrder: false,
            shipment: {
                client_id: this.$store.state.clientUserId,
                warehouse_id: this.$store.state.warehouseId,
                unique_order_number: "",
                shipping_service: "",
                tracking_number: "",
                consignee_name: "",
                label: [],
                attached_files: [],
                products: []
            },

            parcels: [
                "99MINUTOS", "DHL", "ESTAFETA", "FEDEX", "HERMES", "JTEXPRESS", "MERCADO COLECTA", "MERQ", "PAQUETEXPRESS", "QUIKEN",  "UPS" , "YEGO"
            ],
            modalImport: false, 
            stepperImport: 1,
            bearer: "",

            types: 0,
            fulfillment: 3,
            types_items: [this.$t('orders.regular'), this.$t('orders.cod')],
            actions: [
                {
                    name: this.$t('products.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                    permission: "customer.show"
                },
                {
                    name: this.$t('orders.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'edit'
                    },
                    method: 'askUpdate',
                    permission: "advanced_customer_fulfillment.show|customer_all_access.show"
                },
            ],
            importOrders: {
                file: null,
                labels: null,
                client_id: this.$store.state.clientUserId
            },
            products: [],
            product: {
                sku: "",
                quantity: ""
            },
            responses: [],
            props: {
                search: '',
                items: [],
            },
            actions_p: [
                {
                    name: this.$t('edit_shipment.delete_product'),
                    icon: {
                        color: 'error',
                        icon: 'disabled_by_default'
                    },
                    method: 'deleteProduct',
                }
            ],
        }
    },
    mounted(){
        this.index();
        this.getClientToken();
        this.$store.state.module = "headers.my_orders";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.tab = 0;
            this.getOrders("PLACED");
            this.shipment.client_id = this.$store.state.clientUserId;
            this.importOrders.client_id = this.$store.state.clientUserId;

            const lsToken = localStorage.getItem("access_token");
            this.products = [];

            this.$http.get(this.$store.state.chronosApi + 'productsAndKitsByClient/' + this.shipment.client_id, {headers: { Authorization: lsToken } })
            .then((response1) => {
                response1.data = response1.data.data;
                response1.data.forEach( x => {
                    this.products.push(x);
                })
            })
        },
        getOrders(status){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.props.items = [];
            this.$http.get(this.$store.state.chronosApi + '/external_shipment/customer_get_orders/ ' + this.clientId + '?status=' + status, {headers: {Authorization: lsToken}})
            .then((response) => {
                // Get Shippment_services
                this.$store.state.loaderProps.visible = true;
                this.props.items = [];
                response.data.data.forEach( x => {
                    x = x.attributes;
                    if(x.trackingNumber == "null") x.trackingNumber = "";
                    x.coloredStatus = x.lastStatus.status;

                    if(x.lastStatus.status.toLowerCase() == "placed" || x.lastStatus.status.toLowerCase() == "ready"){
                        x.coloredStatus = this.$t("orders.pending");
                        x.color = 'blue';
                    }
                    else if(x.lastStatus.status.toLowerCase() != "placed" && x.lastStatus.status.toLowerCase() != "ready" && x.lastStatus.status.toLowerCase() != "returned to chronos" && x.lastStatus.status.toLowerCase() != "delivered"){
                        x.coloredStatus = this.$t("orders.in_transit");
                        x.color = "green";
                    }
                    else if(x.lastStatus.status.toLowerCase() == "delivered"){
                        x.coloredStatus = this.$t("orders.order_delivered");
                        x.color = "purple";
                    }
                    else if(x.lastStatus.status.toLowerCase() == "returned to chronos"){
                        x.coloredStatus = this.$t("orders.returned");
                        x.color = "orange";
                    }
                    
                    x.createdAt = x.createdAt.substring(0,10);
                    x['actions'] = JSON.parse(JSON.stringify(this.actions));
                    x['actions'][1].link = "/external_shipments/edit_pending/" + x.id
                    x.parameters = x;
                    this.props.items.push(x);
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        showDetails(order){
            this.orderToShow = order;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_info/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.$store.state.loaderProps.visible = false;
                this.orderToShow = {
                    unique_order_number: response.data.order.unique_order_number,
                    created_at: response.data.order.created_at.substring(0,10),
                    shipping_service: response.data.order.shipping_service,
                    customer: response.data.order.name,
                    buyer: response.data.order.consignee_name,
                    products: JSON.parse(response.data.order.products).products
                }
                this.modalDetail = true;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        uploadOrders(){
            if(this.importOrders.client_id != "" && this.importOrders.file != null && this.importOrders.labels != null){
                const lsToken = localStorage.getItem("access_token");
                this.$store.state.loaderProps.visible = true;
                const formData = new FormData();
                formData.append('client_id', this.importOrders.client_id);
                formData.append('file', this.importOrders.file);
                formData.append('labels', this.importOrders.labels);

                this.$http.post(this.$store.state.chronosApi + 'external_shipment/create/bulk', formData, {headers: { Authorization: lsToken } })
                .then((response) => {
                    this.responses = response.data;
                    this.stepperImport = 2
                    this.importOrders = {
                        client_id: "",
                        file: [],
                        labels: []
                    }
                    this.index();
                    this.$store.state.loaderProps.visible = false;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            } else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('fulfillment_eon_pendings.all_fields_are_required')
                }
            }
        },
        saveNewOrder(){
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;
            
            if(this.shipment.attached_files.length == 0 && this.shipment.label.length == 0){
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('fulfillment_eon_pendings.label_and_attached_files_missing')
                }

                this.$store.state.loaderProps.visible = false;
                return;
            }

            const formData = new FormData();
            formData.append('warehouse_id', this.shipment.warehouse_id);
            formData.append('unique_order_number', this.shipment.unique_order_number);
            formData.append('shipping_service', this.shipment.shipping_service);
            formData.append('tracking_number', this.shipment.tracking_number);
            formData.append('consignee_name', this.shipment.consignee_name);
            formData.append('client_id', this.shipment.client_id);
            formData.append('skip_client', true);
            formData.append('total', parseFloat(this.shipment.total_price));

            var c_products = []
            this.shipment.products.forEach(x => {
                c_products.push({
                    sku: x.sku,
                    quantity: parseInt(x.quantity),
                    unit_price: x.unit_price ?? "",
                });
            })

            formData.append('products', JSON.stringify({products: c_products}));

            if(this.shipment.label.length != 0) {
                this.shipment.label.forEach(x => {
                    formData.append('label[]', x);
                })
            }
            
            if(this.shipment.attached_files.length != 0){
                this.shipment.attached_files.forEach( x => {
                    formData.append('attached_files[]', x);
                })
            }
            // console.log([...formData]) // Print form data displaying all pr

            this.$http.post(this.$store.state.chronosApi + 'external_shipment/create', formData, {headers: { Authorization: lsToken } })
            .then((response) => {
                response;
                this.modalNewOrder = false;
                this.shipment = {
                    client_id: "",
                    warehouse_id: this.$store.state.warehouseId,
                    unique_order_number: "",
                    shipping_service: "",
                    tracking_number: "",
                    consignee_name: "",
                    label: [],
                    attached_files: [],
                    products: [],
                    total_price: ""
                }
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('fulfillment_eon_pendings.created')
                }
                this.index();
                this.$store.state.loaderProps.visible = false;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        getClientToken(){
            this.$http.get(this.$store.state.chronosApi + 'getClientToken/'+ this.clientId)
            .then(response => {
                this.client = response.data.client

                this.$http.get(this.$store.state.chronosApi + 'getCurrenAvailableToken/' + this.clientId)
                .then(response2 => {
                    if(response2.data.token != "---"){
                        this.bearer = "Bearer " + response2.data.token;
                        if(response2.data.client.fulfillment == "regular"){
                            this.fulfillment = 0;
                            this.types = 0;
                        } 
                        else if(response2.data.client.fulfillment == "cod"){
                            this.fulfillment = 1;
                            this.types = 1
                        }
                        else if(response2.data.client.fulfillment == "all") this.fulfillment = 3;
                    }
                    else if(response2.data.token == "---"){
                        if(this.client != null && this.client.token != ""){
                            this.$http.post(this.$store.state.chronosApi + 'token/generate', {email: "alejandro@intech.mx", password: "afuerzaniloscalcetines:" + this.client.token}, {headers:{}})
                            .then( response3 => {
                                this.bearer = "Bearer " + response3.data.token;
                            })
                        }
                    }
                })
            })
        },
        deleteProduct(sku){
            const index = this.shipment.products.findIndex(x => x.sku === sku);
            this.shipment.products.splice(index, 1);
        },
        cancelNewOrder(){
            this.modalNewOrder = false;

            this.shipment = {
                warehouse_id: this.$store.state.warehouseId,
                unique_order_number: "",
                shipping_service: "",
                tracking_number: "",
                consignee_name: "",
                label: [],
                attached_files: [],
                products: []
            }
        },
        addProduct(){
            if(this.product.sku != "" && this.product.quantity != ""){
                const product = {
                    sku: this.product.sku,
                    quantity: this.product.quantity,
                    actions: JSON.parse(JSON.stringify(this.actions_p)),
                    parameters: this.product.sku
                }  

                this.shipment.products.push(product);
                this.product.sku = "";
                this.product.quantity = "";
            }
        }, 
    },
    watch:{
        'tab': function(){
            var status = "all";
            if(this.tab == 4) status = 'all';
            else if(this.tab == 0) status = 'PLACED';
            else if(this.tab == 1) status = 'SEND';
            else if(this.tab == 2) status = 'DELIVERED';
            else if(this.tab == 3) status = 'RETURNED TO CHRONOS';
            else status = 'all';

            this.getOrders(status);
        },
        '$store.state.clientId': function (){
            this.importOrders.client_id = this.$store.state.clientUserId;
        }
    }
}
</script>

<style>

</style>