<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1">
                <v-col cols="8" class="py-0" justify="center" align="center">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('digital_heritage.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <!-- <v-col cols="4" class="py-0" justify="center" align="center">
                    <v-btn @click="cancelRequest">cancel</v-btn>
                    <v-btn @click="index">reload</v-btn>
                </v-col> -->
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <v-data-table class="px-0 my-2"
                                no-results-text="No hay resultados"
                                :headers="headers"
                                :items="props.items"
                                :search="props.search"
                                calculate-widths
                                :footer-props="{
                                    'items-per-page-text': this.$t('datatable.shown'),
                                    showFirstLastPage: true,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    'show-current-page': true,
                                    pageText: this.$t('datatable.of') + ' ' + this.props.items.length + ' ' + this.$t('datatable.results'),
                                    'items-per-page-options': typeof props.rowsPP === 'undefined' ? [10, 25, 50, 100] : props.rowsPP}"
                                :page="1"
                                :mobile-breakpoint="0"
                                item-key="uon"
                            >
                                <template #[`item.actions`]="{ item }">
                                    <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                                        <template v-slot:activator="{ on }">
                                            <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                                                <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                            <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                                                <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ action.name }}</span>
                                    </v-tooltip>
                                </template>
                                <!-- Dot for petition file . INDEX -->
                                <template #[`item.petition`]="{ item }"> 
                                    <td> {{ item.petition }} </td>
                                </template>

                                <!-- Dot for invoice file . INDEX -->
                                <template #[`item.documents.petition`]="{ item }"> 
                                    <td v-if="item.documents.petition == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for invoice file . INDEX -->
                                <template #[`item.documents.invoice`]="{ item }"> 
                                    <td v-if="item.documents.invoice == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for packing_list file . INDEX -->
                                <template #[`item.documents.packing_list`]="{ item }"> 
                                    <td v-if="item.documents.packing_list == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for doda file . INDEX -->
                                <template #[`item.documents.doda`]="{ item }"> 
                                    <td v-if="item.documents.doda == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for acuse_cove file . INDEX -->
                                <template #[`item.documents.acuse_cove`]="{ item }"> 
                                    <td v-if="item.documents.acuse_cove == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for edocument file . INDEX -->
                                <template #[`item.documents.edocument`]="{ item }"> 
                                    <td v-if="item.documents.edocument == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for simplified_petition file . INDEX -->
                                <template #[`item.documents.simplified_petition`]="{ item }"> 
                                    <td v-if="item.documents.simplified_petition == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for acuse_edocument file . INDEX -->
                                <template #[`item.documents.acuse_edocument`]="{ item }"> 
                                    <td v-if="item.documents.acuse_edocument == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for rectification_petition file . INDEX -->
                                <template #[`item.documents.rectification_petition`]="{ item }"> 
                                    <td v-if="item.documents.rectification_petition == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for rrnas file . INDEX -->
                                <template #[`item.documents.rrnas`]="{ item }"> 
                                    <td v-if="item.documents.rrnas == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for expense_account file . INDEX -->
                                <template #[`item.documents.expense_account`]="{ item }"> 
                                    <td v-if="item.documents.expense_account == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for virtual_transfer_notice file . INDEX -->
                                <template #[`item.documents.virtual_transfer_notice`]="{ item }"> 
                                    <td v-if="item.documents.virtual_transfer_notice == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for consolidated_notice file . INDEX -->
                                <template #[`item.documents.consolidated_notice`]="{ item }"> 
                                    <td v-if="item.documents.consolidated_notice == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon>
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for certificate_of_origin_of_merchandise file . INDEX -->
                                <template #[`item.documents.certificate_of_origin_of_merchandise`]="{ item }"> 
                                    <td v-if="item.documents.certificate_of_origin_of_merchandise == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for manifestation_value file . INDEX -->
                                <template #[`item.documents.manifestation_value`]="{ item }"> 
                                    <td v-if="item.documents.manifestation_value == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for spreadsheet file . INDEX -->
                                <template #[`item.documents.spreadsheet`]="{ item }"> 
                                    <td v-if="item.documents.spreadsheet == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for cove file . INDEX -->
                                <template #[`item.documents.cove`]="{ item }"> 
                                    <td v-if="item.documents.cove == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for validation_links file . INDEX -->
                                <template #[`item.documents.validation_links`]="{ item }"> 
                                    <td v-if="item.documents.validation_links == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon>
                                    </td>
                                </template>

                                <!-- Dot for other file . INDEX -->
                                <template #[`item.documents.other`]="{ item }"> 
                                    <td v-if="item.documents.other == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>

                                <!-- Dot for Petition Customer file . INDEX -->
                                <template #[`item.documents.petition_customer`]="{ item }"> 
                                    <td v-if="item.documents.petition_customer == true" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon small> fiber_manual_record </v-icon> 
                                    </td>
                                </template>
                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
    </div>
</template>

<script>
export default {
    computed: {
        headers(){
            return [
                { text: this.$t('digital_heritage.petition'), value: 'petition', class: 'text-uppercase' },
                { text: this.$t('digital_heritage.01'), value: 'documents.petition', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.02'), value: 'documents.invoice', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.03'), value: 'documents.packing_list', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.04'), value: 'documents.doda', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.05'), value: 'documents.acuse_cove', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.06'), value: 'documents.edocument', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.07'), value: 'documents.simplified_petition', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.08'), value: 'documents.acuse_edocument', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.09'), value: 'documents.rectification_petition', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.10'), value: 'documents.rrnas', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.11'), value: 'documents.expense_account', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.12'), value: 'documents.virtual_transfer_notice', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.13'), value: 'documents.consolidated_notice', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.14'), value: 'documents.certificate_of_origin_of_merchandise', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.15'), value: 'documents.manifestation_value', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.16'), value: 'documents.spreadsheet', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.17'), value: 'documents.cove', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.18'), value: 'documents.validation_links', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.19'), value: 'documents.other', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.20'), value: 'documents.petition_customer', class: 'text-uppercase', align: 'center', sortable: false },
                { text: this.$t('digital_heritage.actions'), value: 'actions', class: 'text-uppercase' },
            ];
        } 
    },
    data(){
        return {
            items: {
                clients: []
            },
            actions: [
                {
                    name: this.$t('digital_heritage.files'),
                    icon: {
                        color: 'accent',
                        icon: 'folder'
                    },
                    link: '/files',
                    permission: 'appointment.file|i_digital_heritage.file'
                }
            ],
            request: "",
            props: {
                search: '',
                items: [],
            }
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.digital_heritage";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.request = this.$http.CancelToken.source();
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
             this.$http.get(this.$store.state.chronosApi + 'appointmentsby/PendienteArchivos/0', {headers: {Authorization: lsToken, cancelToken: this.request.token}})
            .then((response) => {
                if(response.status === 200){
                    response.data.data.forEach(x =>{
                        x = x.attributes;
                        if( x.petition != null && x.petition != undefined){
                            x['documents'] = {
                                petition: false,
                                invoice: false,
                                packing_list: false,
                                doda: false,
                                acuse_cove: false,
                                edocument: false,
                                simplified_petition: false,
                                acuse_edocument: false,
                                rectification_petition: false,
                                rrnas: false,
                                expense_account: false,
                                virtual_transfer_notice: false,
                                consolidated_notice: false,
                                certificate_of_origin_of_merchandise: false,
                                manifestation_value: false,
                                spreadsheet: false,
                                cove: false,
                                validation_links: false,
                                other: false,
                                petition_customer: false,
                            };
                            
                            x.documents_blobs.forEach(y => {
                                for (var prop in x['documents']) {
                                    if(y.attributes.filename.includes(prop)) x['documents'][prop] = true
                                }
                            });

                            x.actions = JSON.parse(JSON.stringify(this.actions))
                            x['actions'][0].link = 'upload-files/' + x.id;
                            
                            if(x.document_management == null || x.document_management == undefined) x['actions'][0].icon.color = 'azul';
                            else {
                                var required_files = typeof x.document_management.required_files == "string" ? JSON.parse(x.document_management.required_files) : x.document_management.required_files
                                var count_rf = 0;
                                for (var key in required_files) {
                                    if(required_files[key] == true) count_rf++; 
                                }
                                if(count_rf > x.documents_blobs.length) x['actions'][0].icon.color = 'warning';
                                else if(count_rf <= x.documents_blobs.length) x['actions'][3].icon.color = 'success2';
                                
                                if(x.document_management.inconvenient == true) x['actions'][0].icon.color = 'purple'
                            }
                            
                            this.props.items.push(x);
                        }
                    });
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('digital_heritage.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        cancelRequest(){
            this.request.cancel("Cancelled");
        }
    }
}
</script>

<style lang="css">

</style>