<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col sm="8" md="4" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('advanced_fulfillment_to_be_supplied.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="loadOrders()" v-permission="shipping_service + '_advanced_fulfillment.process'">
                        {{ $t('advanced_fulfillment_to_be_supplied.supply_in_bulk') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <v-data-table class="px-0 my-2"
                                no-results-text="No hay resultados"
                                :headers="headers"
                                :items="props.items"
                                :search="props.search"
                                calculate-widths
                                :footer-props="{
                                    'items-per-page-text': this.$t('datatable.shown'),
                                    showFirstLastPage: true,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2} ' + this.$t('datatable.results'),
                                    'show-current-page': true,
                                    'items-per-page-options': typeof props.rowsPP === 'undefined' ? [15, 25, 50] : props.rowsPP}"
                                :page="1"
                                :mobile-breakpoint="0"
                                item-key="id"
                                show-select
                                v-model="selected"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                                        <template v-slot:activator="{ on }">
                                            <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                            <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ action.name }}</span>
                                    </v-tooltip>
                                </template>
                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    

            <v-dialog v-model="modalDetail" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('fulfillments_cod_pendings.order_details.header') }} {{ this.orderToShow.uniqueOrderNumber }}
                            </h4>
                        </v-row>
                    </v-card-title>
                    
                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_cod_pendings.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.order_number") }}</label> <label>{{ this.orderToShow.uniqueOrderNumber }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.date") }}</label> <label>{{ this.orderToShow.createdAt }}</label></div>
                    <div class="detail_content1" v-if="shipping_service != 'otra'"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_pendings.shipping_service") }}</label> <label>{{ this.orderToShow.shippingService }}</label></div>
                    <div class="detail_content1" v-if="shipping_service == 'otra'"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_pendings.shipping_service") }}</label> <label>{{ this.orderToShow.externalShippingService }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_prepaid_pendings.invoice") }}</label> <label>{{ this.orderToShow.invoice }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.client != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.client") }}</label> <label>{{ this.orderToShow.client.name }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.cod") }}</label> <label>{{ this.orderToShow.total }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.buyer") }}</label> <label>{{ this.orderToShow.consignee.name }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.state") }}</label> <label>{{ this.orderToShow.consignee.state }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.municipality") }}</label> <label>{{ this.orderToShow.consignee.municipality }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.neighborhood") }}</label> <label>{{ this.orderToShow.consignee.neighborhood }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.street") }}</label> <label>{{ this.orderToShow.consignee.street }}</label></div>
                    <div class="detail_content1" v-if="orderToShow.consignee != null"><label style="font-weight:bold">{{ $t("fulfillments_cod_pendings.zip_code") }}</label> <label>{{ this.orderToShow.consignee.zipcode }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillments_cod_pendings.products') }}</label></div>
                    <span v-if="orderToShow.shipmentProductDetail != null">
                        <div v-for="(product, i) of orderToShow.shipmentProductDetail.data" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.attributes.product.sku }}</label> <label>{{ product.attributes.quantity }}</label></div>
                    </span>
                    <span v-if="orderToShow.products != null">
                        <div v-for="(product, i) of orderToShow.products" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.sku }}</label> <label>{{ product.quantity }}</label></div>
                    </span>

                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDetail = false">{{ $t('fulfillments_cod_pendings.go_back') }}</v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalBulkSupply" persistent max-width="90%" class="mx-0 px-0">
                <v-card style="min-width: 850px !important" class="mx-0 px-0">
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('advanced_fulfillment_to_be_supplied.fill_orders') }}
                            </h4>
                        </v-row>
                    </v-card-title>
                    
                    <div class="marker1-1 mb-3" style="font-weight:bold"><label class="mx-1 white--text">{{ $t('advanced_fulfillment_to_be_supplied.supply') }} {{ this.current_order_number }} {{ $t('advanced_fulfillment_to_be_supplied.of') }} {{ this.selected.length }} </label></div>
                    <v-row class="px-11 py-0 mt-1" justify="center" align="center">
                        <v-col cols="12" class="py-0 my-0">
                            <v-autocomplete class="mt-0 py-0" v-model="selected_box" :items="boxes" item-value="id" item-text="name" :label="$t('advanced_fulfillment_to_be_supplied.select_box')" :rules="rules_required"></v-autocomplete>
                        </v-col>
                    </v-row>
                    
                    <v-row class="px-11 py-0 mt-0 mb-4" justify="center" align="center">
                        <v-col cols="12" class="py-0 my-0">
                            <v-text-field class="py-0" v-model="selected_quantity" :label="$t('fulfillments_prepaid_pendings.boxes_per_order')" @blur="barcode = ''" :rules="numeric_required"></v-text-field>
                        </v-col>
                    </v-row>

                    <div class="marker1-1 mt-0" style="font-weight:bold">
                        <v-row>
                            <v-col cols="9" class="py-0 my-0">
                                <label class="mx-1 white--text">{{ $t('advanced_fulfillment_to_be_supplied.product') }}</label> 
                            </v-col>
                            <v-col cols="3" class="py-0 my-0">
                                <label class="mx-1 white--text">{{ $t('advanced_fulfillment_to_be_supplied.supplied') }}</label>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="mt-0 mb-8" style="width: 90%; margin: auto; height: 20px;" v-for="(product, i) of current_order_products" :key="i">
                        <v-row class="mt-3 mb-0">
                            <v-col cols="9" class="py-0 my-0">
                                <label class="mx-1">{{ product.description }}</label> 
                            </v-col>
                            <v-col cols="3" class="py-0 my-0">
                                <label class="mx-1">{{ product.scanned }} / {{ product.quantity }}</label>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row class="px-11 py-0 mt-6" justify="center" align="center">
                        <v-col cols="12" align="center" justify="center">
                            <v-btn color="amarillo" class="white--text" outlined style="width: 100%;" @click="downloadLabel">{{ $t('advanced_fulfillment_to_be_supplied.download_label') }}</v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="px-11 py-0 mt-0" justify="center" align="center" v-if="shipping_service != 'eon'">
                        <v-col cols="12" align="center" justify="center">
                            <v-btn color="amarillo" class="white--text" outlined style="width: 100%;" :disabled="!can_next" @click="downloadInvoice">{{ $t('advanced_fulfillment_to_be_supplied.download_invoice') }}</v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <!-- <v-btn class="mx-3" text @click="modalBulkSupply = false" :disabled="!can_close">{{ $t('advanced_fulfillment_to_be_supplied.go_back') }}</v-btn> -->

                            <v-btn color="amarillo" class="white--text mx-3" :disabled="!can_close" @click="closeBulkModal">
                                {{ $t('advanced_fulfillment_to_be_supplied.close') }}
                            </v-btn>
                            <v-btn @click="assingProducts()" color="amarillo" class="white--text" :disabled="!can_next">{{ $t('advanced_fulfillment_to_be_supplied.next') }}</v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>

            <v-dialog v-model="alert_modal" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                                <h3 class="text-uppercase" style="color: #00587d;">
                                    {{ $t('advanced_fulfillment_to_be_supplied.' + modal_type) }}
                                </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h4>{{ $t('advanced_fulfillment_to_be_supplied.' + modal_type + '_message') }}</h4>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex" style="justify-content: center;">
                            <v-btn color="orange" class="white--text" @click="closeModal">
                                {{ $t('advanced_fulfillment_to_be_supplied.accept') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker2"></div>
                </v-card>
            </v-dialog>
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>

import printJS from 'print-js'
import { PDFDocument } from 'pdf-lib';

export default {
    computed: {
        headers(){
            return [
                { text: this.$t('advanced_fulfillment_to_be_supplied.order'), align: "center", value: 'uniqueOrderNumber', class: 'text-uppercase' },
                { text: this.$t('advanced_fulfillment_to_be_supplied.customer'), align: "center", value: 'client.name', class: 'text-uppercase' },
                { text: this.$t('advanced_fulfillment_to_be_supplied.date'), align: "center", value: 'date', class: 'text-uppercase' },
                { text: this.$t('advanced_fulfillment_to_be_supplied.value'), align: "center", value: 'value', class: 'text-uppercase' },
                { text: this.$t('advanced_fulfillment_to_be_supplied.actions'), align: "center", value: 'actions', class: 'text-uppercase' },
            ];
        },
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
    },
    data(){
        return {
            shipping_service: this.$route.params.shipping_service,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            modalBulkSupply: false,
            modalDelete: false,
            modalDetail: false,
            alert_modal: false,
            orderToShow: "",
            modal_type: '',
            barcode: '',
            previous_product: '',
            actions: [
                {
                    name: this.$t('advanced_fulfillment_to_be_supplied.details'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                },
                {
                    name: this.$t('advanced_fulfillment_to_be_supplied.supply'),
                    icon: {
                        color: 'accent',
                        icon: 'send'
                    },
                    method: 'singleOrderSupply',
                }
            ],
            props: {
                search: '',
                items: [],
            },
            selected: [],
            boxes: [],
            selected_box: "",
            selected_quantity: "",
            current_order_number: 0,
            current_order_products: [],
            can_close: false,
            can_next: false,
            controller_name: "advanced_fulfillment"
        }
    },
    mounted(){
        let self = this; 
        const keyPressHandler = (e) => {
            if(self.last == true && /^\S+$/.test(e.key)) { 
                self.barcode = e.key;
                self.last = false;
            }
            else if(e.code == "Enter") {
                self.last = true;
                self.updateList();
            }
            else if(/^\S+$/.test(e.key)) self.barcode += e.key;
        };
        window.addEventListener ('keypress', keyPressHandler, { capture: true });

        this.$once('hook:beforeDestroy',
            () => window.removeEventListener('keypress', keyPressHandler)
        );

        if(this.shipping_service == "eon") this.controller_name = "advanced_external_fulfillment";
        else this.controller_name = "advanced_fulfillment"

        this.$store.state.module = "headers.advanced_fulfillment_" + this.shipping_service + "_to_be_supplied";
        this.index();
    },
    methods: {
        methodHandler(object, params){
            this[object](params)
        },
        index(){
            this.props.items = [];
            this.boxes = [];
            this.selected = [];
            this.selected_box = "";            
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.$http.get(this.$store.state.chronosApi + 'boxes', {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data.data.forEach(x => {
                    x = x.attributes;
                    this.boxes.push(x)
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })

            this.$http.get(this.$store.state.chronosApi + 'v1/' + this.controller_name + '/confirmed_orders?page=1&per_page=100&shipping_service=' + this.shipping_service.toUpperCase(), {headers: {Authorization: lsToken}})
            .then((response) => {
                response.data = response.data.data;
                if(response.status === 200){
                    response.data.forEach(x =>{
                        x = x.attributes;
                        x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        x.parameters = x;
                        this.props.items.push(x);
                    });
                    this.$store.state.loaderProps.visible = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            })
        },
        singleOrderSupply(order){
            this.selected = [order];
            this.loadOrders()
        },
        loadOrders: async function(){
            this.can_close = false;
            this.can_next = false;
            this.current_order_number = 1;
            if(this.selected.length > 0){
                this.modalBulkSupply = true;
                this.assingProducts()
            }else return;
        },
        assingProducts: async function(){
            if(this.can_next) this.current_order_number += 1;
            this.can_next = false;
            if(this.current_order_number <= this.selected.length){
                this.current_order_products = []; 
                var products = await this.getOrderInformation();
                products.data.data.products.forEach(x => {
                    x.scanned = 0;
                    this.current_order_products.push(x);
                })
            }
            else if(this.current_order_number < this.selected.length) this.can_close = true;
        },
        getOrderInformation: async function(){
            const lsToken = localStorage.getItem("access_token");
            return await this.$http.get(this.$store.state.chronosApi + 'v1/' + this.controller_name + '/products_by_shipment/' + this.selected[this.current_order_number - 1].id, {headers: {Authorization: lsToken}})
        },
        showDetails(order){
            this.orderToShow = order;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
 
            if(this.shipping_service == "eon"){
                this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_info/' + order.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    this.orderToShow = {
                        uniqueOrderNumber: response.data.order.unique_order_number,
                        createdAt: response.data.order.created_at.substring(0,10),
                        shippingService: response.data.order.shipping_service,
                        customer: response.data.order.name,
                        buyer: response.data.order.consignee_name,
                        products: JSON.parse(response.data.order.products).products
                    }
                    this.$store.state.loaderProps.visible = false;
                    this.modalDetail = true;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else {
                this.$http.get(this.$store.state.chronosApi + 'shipment/getOrderInformation/' + order.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    response.data = response.data.data.attributes;
                    this.$store.state.loaderProps.visible = false;
                    this.orderToShow = response.data;
                    this.modalDetail = true;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
        },
        updateList(){
            let found = false;
            let discounted = false;
            let all_are_scanned = true;
            this.barcode = this.barcode.toString().replaceAll("Enter", "");

            this.current_order_products.forEach(x => {
                if(discounted == true) return;
                if(x.barcode.toString().toLowerCase() == this.barcode.toString().toLowerCase()) found = true;
                if(x.color == 'green') {
                    if(x.scanned < x.quantity && x.barcode.toString().toLowerCase() != this.barcode.toString().toLowerCase()){
                        this.modal_type = "incomplete"
                        this.alert_modal = true;
                    }
                    
                    x.color = 'accent'
                    this.previous_product = x.description;
                }

                if(x.barcode.toString().toLowerCase() == this.barcode.toString().toLowerCase() && x.scanned < x.quantity){ 
                    x.color = 'green';
                    x.scanned++;
                    discounted = true;
                    return;
                }
            })
            
            this.current_order_products.forEach( x => {
                if(x.scanned < x.quantity) {
                    all_are_scanned = false;
                    return;
                }
            })

            this.all_scanned = all_are_scanned;

            if(discounted == false && found){
                this.modal_type = "excedent"
                this.alert_modal = true;
                return;
            }

            if(!found && this.previous_product != ""){
                this.modal_type = "incorrect_product";
                this.alert_modal = true;
                return;
            }

            else if(!found){
                this.modal_type = "not_expected_product";
                this.alert_modal = true;
                return;
            }
        },
        closeModal(){
            this.alert_modal = false;
        },
        closeBulkModal(){
            this.modalBulkSupply = false;
            this.index();
        },
        downloadLabel:async function (){
            const box = this.boxes.find(x => {
                if(x.id == this.selected_box) return x;
            })

            if(!this.validateBoxAndProducts(box)) return;

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const shipments = {
            "shipments": [{
                "id": this.selected[this.current_order_number - 1].id,
                "unique_order_number": this.selected[this.current_order_number - 1].uniqueOrderNumber,
                "client_id": this.selected[this.current_order_number - 1].client.id, 
                "boxes":{
                    "quantity": parseInt(this.selected_quantity), 
                    "box_id": box.id, 
                    "weight": box.volumetricWeight
                }
            }]}

            if(this.shipping_service == "eon"){
                const response = await this.$http.post(this.$store.state.chronosApi + 'v1/' + this.controller_name + '/generate_labels', shipments,{headers: {Authorization: lsToken}})
                var files = []
                var urls = ""
                if(typeof response.data.data == "string") urls = JSON.parse(response.data.data);
                else urls = response.data.data

                if(urls.length > 0){
                    if(urls[0].filename.includes("pdf")){
                        for(var i = 0; i < urls.length; i++){
                            const file = await this.getLabelFile(this.selected[this.current_order_number - 1].id, urls[i].index ?? 0, lsToken)

                            const arrayBuffer = await file.data.arrayBuffer();
                            files.push(new Uint8Array(arrayBuffer));
                        }
                        
                        const mergedPdf = await this.mergePDFs(files);
                        const base64File = await this.convertBlobToBase64(mergedPdf) 

                        printJS({ printable: base64File, type: 'pdf', base64: true });
                    } else {
                        for(var j = 0; j < urls.length; j++){
                            const file = await this.getLabelFile(this.selected[this.current_order_number - 1].id, urls[j].index ?? 0, lsToken)
                            files.push(window.URL.createObjectURL(file.data))
                        }

                        printJS({ printable: files, type: 'image'});
                    }
                    
                    if(this.current_order_number < this.selected.length) this.can_next = true; 
                    else this.can_close = true;

                    this.$store.state.loaderProps.visible = false;
                } else {
                    console.log("Not files found")
                    this.$store.state.loaderProps.visible = false;
                }
                
            }
            else {
                this.$http.post(this.$store.state.chronosApi + 'v1/' + this.controller_name + '/generate_labels', shipments,{headers: {Authorization: lsToken}})
                .then((response) => {               
                    printJS({ printable: response.data.images, type: 'image'});
                    this.$store.state.loaderProps.visible = false;
                    if(this.current_order_number < this.selected.length) this.can_next = true; 
                    else this.can_close = true;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
        },
        // External shipments (EON)
        async getLabelFile(id, index, lsToken){
            const file = await this.$http.get(this.$store.state.chronosApi + 'external_shipment/download_label/' + id + '/' + index, {headers: {Authorization: lsToken}, responseType:'blob' })
            return file
        },
        async mergePDFs(files) {
            const mergedPdf = await PDFDocument.create();
            for (const pdfBlob of files) {
                const pdf = await PDFDocument.load(pdfBlob);
                const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
                    copiedPages.forEach(page => {
                    mergedPdf.addPage(page);
                });
            }
            const pdf = await mergedPdf.save();
            return new Blob([pdf], { type: 'application/pdf' });
        },
        async convertBlobToBase64(blob) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result.split(',')[1];
                    resolve(base64data);
                };
                    reader.onerror = (error) => {
                    reject(error);
                };
            });
        },
        downloadInvoice: async function(){
            const box = this.boxes.find(x => {
                if(x.id == this.selected_box) return x;
            })

            if(!this.validateBoxAndProducts(box)) return;

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/getNote?id=' + this.selected[this.current_order_number - 1].id + '&format=PNG', {headers: {Authorization: lsToken}, responseType:'blob' })
            .then((response) => {
                // Get consginee data
                this.$store.state.loaderProps.visible = false; 
                printJS({ printable: response.data, type: 'image'});
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        validateBoxAndProducts(box){
            if(box == null){
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('advanced_fulfillment_to_be_supplied.select_box_first')
                }
                return false;
            }

            if(this.selected_quantity == "" || (isNaN(this.selected_quantity) || isNaN(parseFloat(this.selected_quantity)))){
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('advanced_fulfillment_to_be_supplied.wron_box_count')
                }
                return false;
            }

            var products_valid = true;

            this.current_order_products.forEach(x => {
                if(x.scanned < x.quantity){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('advanced_fulfillment_to_be_supplied.product_not_scanned')
                    }
                    products_valid = false;
                    return false;
                }
            })
            
            return products_valid;
        }
    },
    watch:{
        '$route.params.shipping_service': function() {
            location.reload();
        }
    }
}
</script>

<style>

</style>