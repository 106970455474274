<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pt-4 pb-2" >
                <v-col cols="3" class="py-0 my-0">
                    <client-selector :addAllOption="true" class="py-0 my-0 " ref="clientSelector" @getClients="v => items.clients = v" :is_national="2"></client-selector>  
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-menu ref="initial_date" v-model="menu" :close-on-content-click="false" :return-value.sync="initial_date" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field class="py-0" v-model="initial_date" :label="$t('operations_per_customer.initial_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                        </template>
                        <v-date-picker v-model="initial_date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">{{ $t('operations_per_customer.cancel') }}</v-btn>
                            <v-btn text color="primary" @click="$refs.initial_date.save(initial_date)">{{ $t('operations_per_customer.save') }}</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-menu ref="final_date" v-model="menu1" :close-on-content-click="false" :return-value.sync="final_date" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field class="py-0" v-model="final_date" :label="$t('operations_per_customer.final_date')" append-outer-icon="event" readonly v-on="on" :rules="rules_required"></v-text-field>
                        </template>
                        <v-date-picker v-model="final_date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu1 = false">{{ $t('operations_per_customer.cancel') }}</v-btn>
                            <v-btn text color="primary" @click="$refs.final_date.save(final_date)">{{ $t('operations_per_customer.save') }}</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="4" align="end" class="py-0">
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" @click="show_export_options = true" v-if="show_export_options == false">
                        {{ $t('stock_report.export') }}
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataPdf">
                        PDF
                    </v-btn>
                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="exportDataExcel">
                        Excel
                    </v-btn>

                    <v-btn class="right white--text tools-view-height mr-3" outlined color="amarillo" v-if="show_export_options" @click="show_export_options = false;">
                        <v-icon small class="px-0">
                            keyboard_arrow_left
                        </v-icon>
                    </v-btn>

                    <v-btn class="right white--text tools-view-height" color="amarillo" @click="generate">
                        {{ $t('operations_per_customer.generate') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="px-0" >
                    <v-col class="py-0">
                        <v-row class="azul font-weight-bold text-uppercase white--text px-0 py-0">
                            <v-col cols="3" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('operations_per_customer.income') }}:</span> {{ summary.income }}</v-col>
                            <v-col cols="2" class="py-1"><span class="font-weight-bold text-u1ppercase">{{ $t('operations_per_customer.outcome') }}:</span> {{ summary.outcome }}</v-col>
                            <v-col cols="2" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('operations_per_customer.fulfillment') }}:</span> {{ summary.fulfillment }}</v-col>
                            <v-col cols="2" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('operations_per_customer.externals') }}:</span> {{ summary.external }}</v-col>           
                            <v-col cols="3" class="py-1"><span class="font-weight-bold text-uppercase">{{ $t('operations_per_customer.returns') }}:</span> {{ summary.returns }}</v-col> 
                        </v-row>
                    </v-col>
                </v-card-text>

                <v-card-text class="pt-0 pb-3">
                    <v-row>
                        <v-col class="py-0">
                            <datatable :props="{headers: headers, items: props.items, search:props.search}" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>     
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]},
        headers(){
            if(this.$store.state.clientId == "all") return [
                { text: this.$t('operations_per_customer.id_operation'), align: "center", value: 'operationId', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.type'), align: "center", value: 'type', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.customer'), align: "center", value: 'client', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.date'), align: "center", value: 'date', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.sku'), align: "center", value: 'sku', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.quantity'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.damaged'), align: "center", value: 'damaged', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.batch'), align: "center", value: 'batch', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.expiration'), align: "center", value: 'expiration', class: 'text-uppercase' }, 
            ];
            else return [
                { text: this.$t('operations_per_customer.id_operation'), align: "center", value: 'operationId', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.type'), align: "center", value: 'type', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.date'), align: "center", value: 'date', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.sku'), align: "center", value: 'sku', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.quantity'), align: "center", value: 'quantity', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.damaged'), align: "center", value: 'damaged', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.batch'), align: "center", value: 'batch', class: 'text-uppercase' },
                { text: this.$t('operations_per_customer.expiration'), align: "center", value: 'expiration', class: 'text-uppercase' }, 
            ];
        },
        client: function(){
            return this.$store.state.clientId
        },
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            props: {
                search: '',
                items: [],
            },
            initial_date: '',
            final_date: '',
            report:{
                initial_date: '',
                final_date: ''
            },
            menu: false,
            menu1: false,
            summary: {
                income: 0,
                outcome: 0,
                fulfillment: 0,
                returns: 0,
                external: 0
            },
            income_ids: [],
            outcome_ids: [],
            fulfillment_ids: [],
            returns_ids: [],
            show_export_options: false
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.operations_by_customer_report";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.props.items = [];
        },
        generate(){
            this.props.items = [];
            this.summary = {
                income: 0,
                outcome: 0,
                fulfillment: 0,
                returns: 0,
                external: 0
            };

            this.income_ids = [];
            this.outcome_ids = [];
            this.fulfillment_ids = [];
            this.returns_ids = [];
            
            if(this.$store.state.warehouseId != "" && this.$store.state.clientId != "" && this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                this.$http.get(this.$store.state.chronosApi + 'reports/operationsByClient?warehouse='+ this.$store.state.warehouseId + '&client='+ this.$store.state.clientId + '&startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}})
                .then((response) => {

                    response.data.data.forEach(x => {
                        x = x.attributes;
                        var found = null;

                        x.date = x.date.split("T")[0];
                        if (x.type == "Fulfillment"){
                            found = this.fulfillment_ids.find( y => y == x.operationId)

                            if(found == null) {
                                this.summary.fulfillment++;
                                this.fulfillment_ids.push(x.operationId);
                            }
                        }
                        else if (x.type == "Retorno"){
                            found = this.returns_ids.find( y => y == x.operationId)

                            if(found == null) {
                                this.summary.returns++;
                                this.returns_ids.push(x.operationId);
                            }
                        } 
                        else if(x.type == "Entrada"){ 
                            found = this.income_ids.find( y => y == x.operationId)

                            if(found == null) {
                                this.summary.income++;
                                this.income_ids.push(x.operationId);
                            }
                        }
                        else if(x.type == "Salida"){
                            found = this.outcome_ids.find( y => y == x.operationId)

                            if(found == null) {
                                this.summary.outcome++;
                                this.outcome_ids.push(x.operationId);
                            }  
                        } 
                        else if(x.type == "FFX"){
                            x.type == "FFX";
                            found = this.outcome_ids.find( y => y == x.operationId)

                            if(found == null) {
                                this.summary.external++;
                                this.outcome_ids.push(x.operationId);
                            }  
                        }
                        this.props.items.push(x);
                    })
                })
                .catch((response) => {
                    this.$store.state.loaderProps.visible = false;
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('pendings.responses.error')
                        }
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                });
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportDataExcel(){
            const lsToken = localStorage.getItem("access_token");
            if(this.$store.state.warehouseId != "" && this.$store.state.clientId != "" && this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;

                this.$http.get(this.$store.state.chronosApi + 'reports/operationsByClientExport?warehouse='+ this.$store.state.warehouseId + '&client='+ this.$store.state.clientId + '&startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'Operaciones_Por_Cliente.xlsx');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        },
        exportDataPdf(){
            const lsToken = localStorage.getItem("access_token");
            if(this.$store.state.warehouseId != "" && this.$store.state.clientId != "" && this.initial_date != "" && this.final_date != ""){
                this.$store.state.loaderProps.visible = true;

                this.$http.get(this.$store.state.chronosApi + 'reports/operationsByClientPdf?warehouse='+ this.$store.state.warehouseId + '&client='+ this.$store.state.clientId + '&startDate='+ this.initial_date + '&endDate=' + this.final_date , {headers: {Authorization: lsToken}, responseType:'blob'})
                .then(response => {
                    this.$store.state.loaderProps.visible = false; 
                    FileSaver.saveAs(response.data, 'Operaciones_Por_Cliente.pdf');
                })
                .catch(() => {
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('yego_reports.export_error')
                    };
                })
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: this.$t('yego_reports.export_error')
                };
            }
        }
    },
    watch: {
        '$store.state.clientId': function(){
            this.props.items = [];
        }
    }
}
</script>

<style>
</style>