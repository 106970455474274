<template>
    <div id="loading">
        <v-col cols="12" sm="5" md="3" lg="1">
            <v-card color="transparent fixed-logo" flat>
                <v-card-text class="d-flex justify-center">
                    <v-img src="@/assets/Yego-white.png" contain max-width="20%"></v-img>
                </v-card-text>
            </v-card>
        </v-col>
        <div id="loading-center">
            <div id="loading-center-absolute">
                <div class="object" id="object_four"></div>
                <div class="object" id="object_three"></div>
                <div class="object" id="object_two"></div>
                <div class="object" id="object_one"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style> 
    .fixed-logo{
        position: fixed;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
        /* height: 200px; */
        /* width: 200px; */
        /* margin-top: -100px;
        margin-left: -100px; */
    }
    #loading{
        /* background: #009cde;
        background: radial-gradient(circle, rgba(228,228,228,1) 00%, rgba(0,156,222,1) 100%); */
        background: rgb(0,152,218);
        /* background: linear-gradient(45deg, rgba(0,178,255,1) 0%, rgba(7,144,205,1) 50%, rgba(54,93,121,1) 100%); */
        background: radial-gradient(circle, rgba(0,178,255,1) 0%, rgba(7,144,205,1) 30%, rgba(54,93,121,1) 100%);
        height: 100vh;
        width: 100vw;
    }
    #loading-center{
        position: fixed;
        left: 50%;
        top: 50%;
        height: 200px;
        width: 200px;
        margin-top: -100px;
        margin-left: -100px;
    }
    #loading-center-absolute {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 200px;
        width: 200px;
        margin-top: -100px;
        margin-left: -100px;
        -ms-transform: rotate(-135deg); 
        -webkit-transform: rotate(-135deg); 
        transform: rotate(-135deg);
    }
    .object{
        -moz-border-radius: 50% 50% 50% 50%;
        -webkit-border-radius: 50% 50% 50% 50%;
        border-radius: 50% 50% 50% 50%;
        position: absolute;
        border-top: 2px solid #ffffff;
        border-bottom: 2px solid transparent;
        border-left:  2px solid #ffffff;
        border-right: 2px solid transparent;
        -webkit-animation: animate 2s infinite;
        animation: animate 2s infinite;	
    }

    #object_one{
        left: 75px;
        top: 75px;
        width: 50px;
        height: 50px;
    }

    #object_two{
        left: 65px;
        top: 65px;
        width: 70px;
        height: 70px;
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }
    #object_three{
        left: 55px;
        top: 55px;
        width: 90px;
        height: 90px;
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
    }

    #object_four{
        left: 45px;
        top: 45px;
        width: 110px;
        height: 110px;
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }	

    @-webkit-keyframes animate {
        50% {
            -ms-transform: rotate(360deg) scale(0.8); 
            -webkit-transform: rotate(360deg) scale(0.8); 
            transform: rotate(360deg) scale(0.8); 
        }
    }
    @keyframes animate {
        50% {
            -ms-transform: rotate(360deg) scale(0.8); 
            -webkit-transform: rotate(360deg) scale(0.8); 
            transform: rotate(360deg) scale(0.8); 
        }
    }
</style>