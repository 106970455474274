<template>
    <div>
        <v-data-table class="px-0 my-2"
            no-results-text="No hay resultados"
            :headers="props.headers"
            :items="props.items"
            :search="props.search"
            calculate-widths
            hide-default-footer
            disable-pagination
            :mobile-breakpoint="0"
        >
            <template v-slot:item.all="{ item }">
                <td class="px-0">
                    <v-checkbox color="azul" v-model="item.all" :disabled="getValue(item.all)" :indeterminate="getValue(item.all)" class="mx-0 my-0"></v-checkbox>
                </td>
            </template>
            <template v-slot:item.view="{ item }">
                <td class="px-0">
                    <v-checkbox color="azul" v-model="item.view" :disabled="getValue(item.view)" :indeterminate="getValue(item.view)" class="mx-0 my-0"></v-checkbox>
                </td>
            </template>
            <template v-slot:item.create="{ item }">
                <td class="px-0">
                    <v-checkbox color="azul" v-model="item.create" :disabled="getValue(item.create)" :indeterminate="getValue(item.create)" class="mx-0 my-0"></v-checkbox>
                </td>
            </template>
            <template v-slot:item.edit="{ item }">
                <td class="px-0">
                    <v-checkbox color="azul" v-model="item.edit" :disabled="getValue(item.edit)" :indeterminate="getValue(item.edit)" class="mx-0 my-0"></v-checkbox>
                </td>
            </template>
            <template v-slot:item.delete="{ item }">
                <td class="px-0">
                    <v-checkbox color="azul" v-model="item.delete" :disabled="getValue(item.delete)" :indeterminate="getValue(item.delete)" class="mx-0 my-0"></v-checkbox>
                </td>
            </template>
            <template v-slot:item.file="{ item }">
                <td class="px-0">
                    <v-checkbox color="azul" v-model="item.file" :disabled="getValue(item.file)" :indeterminate="getValue(item.file)" class="mx-0 my-0"></v-checkbox>
                </td>
            </template>
            <template v-slot:item.validate="{ item }">
                <td class="px-0">
                    <v-checkbox color="azul" v-model="item.validate" :disabled="getValue(item.validate)" :indeterminate="getValue(item.validate)" class="mx-0 my-0"></v-checkbox>
                </td>
            </template>
            <template v-slot:item.restore="{ item }">
                <td class="px-0">
                    <v-checkbox color="azul" v-model="item.restore" :disabled="getValue(item.restore)" :indeterminate="getValue(item.restore)" class="mx-0 my-0"></v-checkbox>
                </td>
            </template>
            <template v-slot:item.process="{ item }">
                <td class="px-0">
                    <v-checkbox color="azul" v-model="item.process" :disabled="getValue(item.process)" :indeterminate="getValue(item.process)" class="mx-0 my-0"></v-checkbox>
                </td>
            </template>
            
            <template v-slot:item.acceptance="{ item }">
                <td class="px-0">
                    <v-checkbox color="azul" v-model="item.acceptance" :disabled="getValue(item.acceptance)" :indeterminate="getValue(item.acceptance)" class="mx-0 my-0"></v-checkbox>
                </td>
            </template>
            <template v-slot:item.confirmation="{ item }">
                <td class="px-0">
                    <v-checkbox color="azul" v-model="item.confirmation" :disabled="getValue(item.confirmation)" :indeterminate="getValue(item.confirmation)" class="mx-0 my-0"></v-checkbox>
                </td>
            </template>
            <template v-slot:item.history="{ item }">
                <td class="px-0">
                    <v-checkbox color="azul" v-model="item.history" :disabled="getValue(item.history)" :indeterminate="getValue(item.history)" class="mx-0 my-0"></v-checkbox>
                </td>
            </template>
            <template v-slot:item.manage_stores="{ item }">
                <td class="px-0">
                    <v-checkbox color="azul" v-model="item.manage_stores" :disabled="getValue(item.manage_stores)" :indeterminate="getValue(item.manage_stores)" class="mx-0 my-0"></v-checkbox>
                </td>
            </template>
            <!-- <v-data-footer :items-per-page-options="[]"></v-data-footer> -->
        </v-data-table>
    </div>    
</template>
<script>
    export default {
        props: ['props', 'page'],
        methods: {
            methodHandler(methodName, parameters){
                this.$emit('methodHandler', {
                    methodName: methodName, 
                    parameters: parameters
                });
            },
            getValue(value){
                if(value == null) {return true}
                else return false
            }
        },
    }
</script>
<style lang="scss">
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
    .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
        font-size: 11px !important;
        color: #00587d !important;
        border-bottom: 2px solid #00587d !important;
        border-collapse: collapse !important;
        padding: 0px !important;
        margin: 0px !important;
    }

    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
    .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
    .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
        font-size: 11px !important;
        padding: 0px;
        margin: 0px;
    }

    .v-data-table > .v-data-table__wrapper > table > tbody > tr > .test,
    .v-data-table > .v-data-table__wrapper > table > thead > tr > .test,
    .v-data-table > .v-data-table__wrapper > table > tfoot > tr > .test {
        font-size: 11px !important;
        display:inline-flex !important; 
        align-items: center !important;
        padding: 0px;
        margin: 0px;
    }
    .v-input__slot{
        justify-content: center;
        margin: 0px !important;
        padding: 0px !important;
    }
    .hidden {
       background-color: transparent !important;
       color: transparent !important;
    }
    .disabledClass{
        opacity: 0.1 !important;
        fill: gray !important;
        color: gray !important
    }
    
</style>