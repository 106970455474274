<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('edit_incidence.edit') }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                       <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="incidence.type" :label="$t('edit_incidence.type')" disabled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="incidence.description" :label="$t('edit_incidence.description')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="incidence.quantity" disabled :label="$t('edit_incidence.quantity')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0" align="center" justify="center">
                            <v-col cols="3" class="py-0 mt-3 px-3 mb-2">
                                <label for="" class="azul--text mt-3" style="font-weight: bold; width:100%; text-align: start">{{$t('edit_incidence.files')}}</label>
                            </v-col>
                            <v-col cols="3" class="py-0 mt-3 px-3 mb-2" align="end">
                                <v-btn class="right" color="white" @click="showModal"> {{ $t('edit_incidence.add_file') }} </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                            <v-col cols="6" class="py-0 px-6">
                                <v-row class="px-0 py-2 my-0" justify="center" align="center">
                                    <v-data-table
                                        no-results-text="No hay resultados"
                                        :headers="headers"
                                        :items="files"
                                        :footer-props="{
                                        'items-per-page-text': this.$t('datatable.shown'),
                                        showFirstLastPage: true,
                                        firstIcon: 'mdi-arrow-collapse-left',
                                        lastIcon: 'mdi-arrow-collapse-right',
                                        pageText: this.$t('datatable.of') + ' ' + this.files.length + ' ' + this.$t('datatable.results'),
                                        'items-per-page-options': [10, 25, 50, 100] }"
                                        :page="1"
                                    >
                                        <template v-slot:item.actions="{ item }">
                                            <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                                                        <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                                    </v-btn>
                                                    <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                                                        <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ action.name }}</span>
                                            </v-tooltip>
                                        </template>
                                        <template #item.name="{ item }">
                                            <a target="_blank" :href="`${item.url}`" style="font-size: 16px; color: #00587d">
                                                {{ item.name }}
                                            </a>
                                        </template>
                                        <v-data-footer :items-per-page-options="[]"></v-data-footer>
                                    </v-data-table>   
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0" justify="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">{{ $t('edit_incidence.cancel') }}</v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save">{{ $t('edit_incidence.save') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-dialog v-model="modal" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h3>
                                {{ $t('edit_incidence.upload_file') }}
                            </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <v-file-input v-model="currFiles" accept="*.*" small-chips show-size :label="$t('edit_incidence.select_file')"></v-file-input>
                            </v-col>
                        </v-row>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modal = false">{{ $t('edit_incidence.no') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="uploadFile">
                                {{ $t('edit_incidence.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        headers(){
            return [
                { text: this.$t('edit_incidence.name'), value: 'name' },
                { text: this.$t('edit_incidence.actions'), value: 'actions', width:"10%", align:"center", sortable: false},
            ];
        }
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            incidence: '',
            files: [],
            currFiles: [],
            modal: false,
            actions: [
                {
                    name: this.$t('edit_incidence.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'deleteFile',
                },
            ]
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.edit_incidence";
    },
    methods: {
        methodHandler(method, params){
            this[method](params)
        },
        index(){
            this.currFiles = [];
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'a_incidences/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.files = [];
                this.incidence = response.data.incidence;
                this.incidence.type = this.$t('edit_incidence.' + this.incidence.i_type);
                response.data.files.forEach(x =>{
                    x['actions'] = JSON.parse(JSON.stringify(this.actions));
                    x.parameters = x.index;
                    
                    this.files.push(x);
                });
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        save(){
            console.log(this.incidence);
            this.incidence = {
                description: this.incidence.description
            };
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.put(this.$store.state.chronosApi + 'a_incidences/' + this.id, this.incidence, {headers: {Authorization: lsToken}})
            .then((response) => {
                response
                this.snackbar = {
                    visible: true, 
                    color: 'success2',
                    text: this.$t('end_process.responses.correct')
                }
                this.$router.go(-1);

            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })

        },
        deleteFile(index){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            
            this.$http.delete(this.$store.state.chronosApi + 'deleteAIncidenceFile/' + index, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('edit_incidence.ok')
                    }
                }
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.index();
                this.$store.state.loaderProps.visible = false;
            })
        },
        showModal(){
            this.modal = true;
        },
        cancel(){
            this.$router.go(-1);
        },
        uploadFile(){
            var formData = new FormData();
            const lsToken = localStorage.getItem("access_token");
            formData.append("file", this.currFiles);
            this.$store.state.loaderProps.visible = true;
            // console.log([...formData]) // Print form data displaying all pr
            this.$http.post(this.$store.state.chronosApi + 'insertAIncidenceFile/' + this.id, formData, {headers: {Authorization: lsToken, contentType: 'multipart/form-data'}})
            .then((response) => {   
                if(response.status === 200){
                    this.snackbar = {
                        visible: true, 
                        color: 'success2',
                        text: this.$t('end_process.responses.correct')
                    }
                    this.modal = false;
                    this.index();
                }
            }).catch((error) => {
                console.log(error);
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('end_process.responses.update')
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        }
    }
}
</script>

<style lang="css">
</style>