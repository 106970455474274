
export default class Utils{

    months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    
    static generateArrayOfYears(qty = 9) {
        var max = new Date().getFullYear()
        var min = max - qty
        var years = []
      
        for (var i = max; i >= min; i--) {
          years.push(i)
        }
        return years
    }
      
    /**
     * Returns a 2 digit number always 
     * @param {string} num a number 
     */
    alwaysTwoDigits(num)
    {
        if(num.toString().length == 1)
            return `0${num}`;
        return num;
    }

    static getOutcomeKeys()
    {
        return ["J4", "G9", "V1"];
    }
    static getBimesters()
    {
        return ["37", "38", "39", "40", "41", "42"];
    }

    weeksYear(year)
    {
        const start = new Date(`${year}-01-01`);
        const end = new Date(`${year}-12-31`);

        const now = new Date();
        let broke = false;
        const weeks = [];
        let current = start;
        let valueStart = null;
        let valueEnd = null;
        let active = false;

        while (!broke) {
            
            //monday first day
            if(current.getDay() == 1){
                let weekText = `Semana | ${this.alwaysTwoDigits(current.getDate())}/${this.months[current.getMonth()]}`;
                valueStart = current;
                current = current.addDays(6);
                weekText += ` - ${this.alwaysTwoDigits(current.getDate())}/${this.months[current.getMonth()]}`;
                valueEnd = current;

                if(now >= valueStart && now <= valueEnd)
                    active = true;

                valueStart = `${valueStart.getFullYear()}-${valueStart.getMonth()+1}-${valueStart.getDate()}`;
                valueEnd = `${valueEnd.getFullYear()}-${valueEnd.getMonth()+1}-${valueEnd.getDate()}`;
                weeks.push({text: weekText, value:`${valueStart}|${valueEnd}`, active:active});
                active = false;
            }
            current = current.addDays(1);

            if(current > end)
                broke = true;
        }
        return weeks;
    }

    static createFilter(options, headers)
    {   
        let sortBy = '';
        let sortDesc = false;
        let itemsPerPage = '10';
        let search = '';

        if(options?.itemsPerPage){
            itemsPerPage = options.itemsPerPage;
        }
        if(isNaN(options.page))
            options.page = 1;

        if(options?.sortBy?.length >= 1){
            sortBy = options.sortBy[0];
        }
        if(options?.sortDesc?.length >=1){
            sortDesc = options.sortDesc[0]; 
        }

        if(options?.search != undefined && options?.search != ''){
           
            search = options.search;
        }

        let headersString = '';
        headers.forEach(h => {
            headersString += `&columns[]=${h.value}`;
        });

        // return {search, page: options.page, itemsPerPage, sortBy, sortDesc}
        return `?page=${options.page}&itemsPerPage=${itemsPerPage}&sortBy[]=${sortBy}&sortDesc[]=${sortDesc}&search=${search}${headersString}`;
    }

    //format > dd/mm/yyyy
    static globalDateFormat(data) {
        const new_date = new Date(data);
        if (new_date instanceof Date && !isNaN(new_date)) {
          let dateArray = new Array();
          dateArray[0] = data.substring(0, 4);
          dateArray[1] = data.substring(5, 7);
          dateArray[2] = data.substring(8, 10);
  
          let date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
  
          let ye = new Intl.DateTimeFormat("es", { year: "numeric" }).format(
            date
          );
          let mo = new Intl.DateTimeFormat("es", { month: "2-digit" }).format(
            date
          );
          let da = new Intl.DateTimeFormat("es", { day: "2-digit" }).format(date);
          let newDate = `${da}/${mo.charAt(0).toUpperCase() + mo.slice(1)}/${ye}`;
          return newDate;
        }
        return new_date;
    }

    static currencyFormat(money, _currency = "MXN") {
        //console.log(money);
        // const currency = parseFloat(money).toFixed(2);
        const formatter = new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        });
        return formatter.format(money)+" " + _currency;
    }

    /**
     * Get the same day of le last month
     * if day doesnt exist then returns the last day of last month
     * @returns {Date}
     */
    static getStartDate()
    {
        let actual = new Date();
        let month = (actual.getMonth() + 1) - 1;
        let year = actual.getFullYear();
        let day = actual.getDate();
        
        //set to december
        if(month == 0){
            month = 12;
            year = year - 1;
        }

        let start = new Date(`${month}-${day}-${year}`);

        //set to last day of past month
        if(actual.getDate() != start.getDate()){
            var d = new Date(year, month, 0);
            return d.toISOString().split('T')[0];
        }

        //same day but past month
        return start.toISOString().split('T')[0];
    } 


    static getIndexArrayObject(array, field, value)
    {
        return array.map(e => e[field]).indexOf(value);
    }


    /**
     * Removes first word from a string
     * @param {string} phrase 
     * @returns {string} phrase without first word
     */
    static deleteFirstWord(phrase)
    {
        let spplited = phrase.split(" ");
        spplited.shift();
        return spplited.join(' ');
        
    }

    /**
     * Set page scroll to top.
     */
    static scrollToTop()
    {
        window.scrollTo(0,0);
    }

    /**
     * Capitalize first letter of every word
     * @param {string} phrase 
     * @returns {string} 
     */
    static capitalizePhrase(phrase)
    {
        return phrase.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    }

    /**
     * Returns phrase without a selected word.
     * @param {string} word word to delete
     * @param {string} phrase phrase base
     * @returns {string} new phrase
     */
    static deleteWord(word, phrase)
    {
        console.log(word, phrase);
        return phrase.replaceAll(word, '');
    }

    /**
     * Check if an object has properties
     * @param {object} obj object to check
     * @returns {boolean}
     */
    static objectIsEmpty(obj) {
        if(typeof obj == 'string')
            return true;
        return Object.keys(obj).length === 0;
    }

    /**
     * Returns date in selected time zone
     * @param {*} date date string | Date
     * @param {string} tzString timeZone
     * @returns {Date}
     */
    static convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date+' 00:00:00') : date).toLocaleString("en-US", {timeZone: tzString}));   
    }


    /**
     * Returns the object's value from a path
     * @param {object} obj 
     * @param {string} path 
     * @returns value
     */
    static getObjectValue(obj, path) {
        if (!path) return obj;
        const properties = path.split('.');
        try {
            return this.getObjectValue(obj[properties.shift()], properties.join('.'))
        } catch (error) {
            return null;
        }
        //return this.getObjectValue(obj[properties.shift()], properties.join('.'))
    }

    /**
     * Trim a string at a length and adds "..." at the end.
     * @param {string} input string
     * @param {number} length 
     * @returns {string}
     */
    static trimStringAt(input, length = 25)
    {
        return input.length > length ? input.substring(0, length - 3) + "...":input;
    }

    /**
     * HEX TO RGB color conversion. 
     * @param {string} hex hex color code
     * @returns rgb object
     */
    static hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
    }
}


Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}