<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('advanced_fulfillment_prepare.prepare_orders') }} </h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-row class="px-0 py-0 mb-1" justify="center" align="center" >
                            <v-col cols="4" justify="center" align="center" class="px-1 py-1" style="text-align: left; background-color: #9fb700 !important; color: white; font-weight: 500;">
                                {{ $t('advanced_fulfillment_prepare.product') }}
                            </v-col>
                            <v-col cols="1" justify="center" align="center" class="px-1 py-1" style="text-align: center; background-color: #9fb700 !important; color: white; font-weight: 500;">
                                {{ $t('advanced_fulfillment_prepare.location') }}
                            </v-col>
                            <v-col cols="2" justify="center" align="center" class="px-1 py-1" style="text-align: center; background-color: #9fb700 !important; color: white; font-weight: 500;">
                                {{ $t('advanced_fulfillment_prepare.batch') }}
                            </v-col>
                            <v-col cols="1" justify="center" align="center" class="px-1 py-1" style="text-align: center; background-color: #9fb700 !important; color: white; font-weight: 500;">
                                {{ $t('advanced_fulfillment_prepare.reference') }}
                            </v-col>
                            <v-col cols="1" justify="center" align="center" class="px-1 py-1" style="text-align: center; background-color: #9fb700 !important; color: white; font-weight: 500;">
                                {{ $t('advanced_fulfillment_prepare.box') }}
                            </v-col>
                            <v-col cols="1" justify="center" align="center" class="px-1 py-1" style="text-align: center; background-color: #9fb700 !important; color: white; font-weight: 500;">
                                {{ $t('advanced_fulfillment_prepare.pallet') }}
                            </v-col>
                            <v-col cols="1" justify="center" align="center" class="px-1 py-1" style="text-align: center; background-color: #9fb700 !important; color: white; font-weight: 500;">
                                {{ $t('advanced_fulfillment_prepare.supplied') }}
                            </v-col>
                    </v-row>
                    <span v-for="(product, i) of products" :key="i">
                        <v-row class="px-1 py-2" justify="center" align="center" >
                            <v-col cols="4" justify="center" align="center" class="px-0 py-1" style="border-bottom: 1px solid black; text-align: left;">
                                <v-icon small :class="product.color != 'accent' ? '' : 'material-icons-outlined'" :color="product.color"> fiber_manual_record </v-icon>{{ product.description }}
                            </v-col>
                            <v-col cols="1" justify="center" align="center" class="px-0 py-1" style="border-bottom: 1px solid black;">
                                {{ product.location }}
                            </v-col>
                            <v-col cols="2" justify="center" align="center" class="px-0 py-1" style="border-bottom: 1px solid black;">
                                {{ product.batch ?? "-" }}
                            </v-col>
                            <v-col cols="1" justify="center" align="center" class="px-0 py-1" style="border-bottom: 1px solid black;">
                                {{ product.reference ?? "-" }}
                            </v-col>
                            <v-col cols="1" justify="center" align="center" class="px-0 py-1" style="border-bottom: 1px solid black;">
                                {{ product.box }}
                            </v-col>
                            <v-col cols="1" justify="center" align="center" class="px-0 py-1" style="border-bottom: 1px solid black;">
                                {{ product.pallet }}
                            </v-col>
                            <v-col cols="1" justify="center" align="center" class="px-0 py-1" style="border-bottom: 1px solid black;">
                                {{ product.discount }} / {{ product.total }}
                                <v-icon v-if="product.discount == product.total" class="material-icons-outlined" small color="green" >check_circle</v-icon>
                            </v-col>
                        </v-row>
                    </span>
                    <br>
                    <br>
                    <v-row class="px-5 py-0" justify="center" align="center">
                        <v-col class="px-0 mx-0" cols="8" justify="end" align="end">
                            <v-row justify="end" align="end">
                                <v-btn class="mx-3" outlined color="amarillo" @click="cancel()">
                                    {{ $t('advanced_fulfillment_prepare.cancel') }}
                                </v-btn>
                                <v-btn class="right white--text" :disabled="!all_scanned" color="amarillo" @click="save">
                                    {{ $t('advanced_fulfillment_prepare.save') }}
                                </v-btn> 
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>
        <v-dialog v-model="alert_modal" persistent max-width="90%">
            <v-card >
                <v-card-title class="justify-center">
                    <v-row align="center" justify="center">
                            <h3 class="text-uppercase" style="color: #00587d;">
                                {{ $t('advanced_fulfillment_prepare.' + modal_type) }}
                            </h3>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row align="center" justify="center" class="py-3 my-3 text-center">
                        <v-col cols="12">
                            <h4>{{ $t('advanced_fulfillment_prepare.' + modal_type + '_message') }} {{ previous_product }}</h4>
                        </v-col>
                    </v-row>
                    
                    <div class="d-flex" style="justify-content: center;">
                        <v-btn color="orange" class="white--text" @click="closeModal">
                            {{ $t('advanced_fulfillment_prepare.accept') }}
                        </v-btn>
                        
                    </div>
                </v-card-text> 
                <div class="marker2"></div>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>

export default {
    computed: {
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            barcode: "",
            last: false,
            order_ids: [],
            products: [],
            alert_modal: false,
            modal_type: '',
            previous_product: "",
            orders_code: "",
            current_location_barcode: "",
            all_scanned: false,
            product_from_correct_location: false,
            controller_name: "advanced_fulfillment",
            shipping_service: this.$route.params.shipping_service,
        }
    },
    mounted(){
        let self = this; 
        const leaveHandler = (e) => { 
            e.preventDefault();
            e.returnValue = '';
        };

        const keyPressHandler = (e) => {
            if(self.last == true && /^\S+$/.test(e.key)) { 
                self.barcode = e.key;
                self.last = false;
            }
            else if(e.code == "Enter") {
                self.last = true;
                self.updateList();
            }
            else if(/^\S+$/.test(e.key)) self.barcode += e.key;
        };

        window.addEventListener('beforeunload', leaveHandler, { capture: true });
        window.addEventListener ('keypress', keyPressHandler, { capture: true });
        
        this.$once('hook:beforeDestroy',
            () => window.removeEventListener('beforeunload', leaveHandler)
        );
        this.$once('hook:beforeDestroy',
            () => window.removeEventListener('keypress', keyPressHandler)
        );

        if(this.shipping_service == "eon") this.controller_name = "advanced_external_fulfillment";
        else this.controller_name = "advanced_fulfillment"

        this.index();
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            if(this.$store.state.afpendings != undefined && this.$store.state.afpendings != null){
                this.$store.state.afpendings.forEach(x => {
                    this.order_ids.push(x.id);
                })

                this.$http.post(this.$store.state.chronosApi + 'v1/' + this.controller_name + '/prepare_orders',{ shipments: this.order_ids }, {headers: {Authorization: lsToken}})
                .then((response) => {
                    this.orders_code = response.data.data.trace_id;
                    this.$store.state.trace_id = response.data.data.trace_id;
                    this.$store.state.trace_id_loaded = true;
 
                    response.data.data.products.forEach(x => {
                        console.log(x)
                        x.locations.forEach(y => {
                            this.products.push({
                                description: x.description,
                                location: y.location,
                                total: y.discount,
                                discount: 0,
                                barcode: x.barcode,
                                color: "accent",
                                batch: y.batch == null ? "-" : y.batch,
                                box: y.box,
                                location_barcode: y.barcode,
                                reference: y.reference,
                                pallet: y.pallet
                            });
                        });
                    });

                    this.products = this.products.sort((a, b) => a.location_barcode.localeCompare(b.location_barcode))

                    this.$store.state.loaderProps.visible = false;
                })
                .catch((error) => {
                    this.$store.state.loaderProps.visible = false;
                    if(error.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: error.response.data.message
                        }
                    }

                    this.$router.go(-1);
                });
            }
            else {
                this.$store.state.loaderProps.visible = false;

                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: "Not order found"
                }
            }
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        save(){
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;

            this.$http.put(this.$store.state.chronosApi + 'v1/' + this.controller_name + '/confirm_orders', {"trace_id": this.orders_code},{headers: {Authorization: lsToken}})
            .then((response) => {
                this.$store.state.loaderProps.visible = false;
                response;
                this.$store.state.trace_id = null;
                this.$router.go(-1);
            })
            .catch((error) => {
                this.$store.state.loaderProps.visible = false;
                console.log(error)
            })
        },
        closeModal(){
            this.alert_modal = false;
        },
        updateList(){
            let found = false; // product
            let discounted = false; // product discount
            let all_are_scanned = true;
            this.product_from_correct_location = false;
            let is_location = false;
            let is_product = false;
            let all_scanned_from_location = true;

            this.barcode = this.barcode.toString().replaceAll("Enter", "");

            this.products.forEach(x => {
                x.color = "accent";
                
                if(discounted == true) return;
                
                if(x.location_barcode.toLowerCase() == this.barcode.toString().toLowerCase()){
                    x.color = 'blue';
                    this.current_location_barcode = this.barcode.toString();
                    is_location = true;
                }
                else if(x.barcode.toLowerCase() == this.barcode.toString().toLowerCase() && this.current_location_barcode == x.location_barcode){
                    found = true;
                    this.product_from_correct_location = true;

                    if(x.discount < x.total){
                        x.color = 'green';
                        x.discount++;
                        discounted = true; 
                        
                        is_product = true;
                    }

                    return;
                } 
                else if(x.barcode.toLowerCase() == this.barcode.toString().toLowerCase() && this.current_location_barcode != x.location_barcode){
                    found = true;
                    this.product_from_correct_location = false;
                    is_product = true;
                    
                    return;
                }
            });

            // All products of list 
            this.products.forEach( x => {
                if(x.discount < x.total) {
                    all_are_scanned = false;
                    return;
                }
            });

            // No location scanned
            if(this.current_location_barcode == "") {
                this.modal_type = "not_expected_location";
                this.alert_modal = true;
                return;
            }

            if(this.current_location_barcode == "" || (!is_product && !is_location)) {
                this.modal_type = "not_expected_location";
                this.alert_modal = true;
                return;
            }

            // Product in list but not in the scanned location
            if((found && !this.product_from_correct_location && is_product)){
                this.modal_type = "incorrect_product";
                this.alert_modal = true;
                return;
            }

            if((!found && !is_product && !is_location)){
                this.modal_type = "not_expected_product";
                this.alert_modal = true;
                return;
            }

            if(discounted == false && found && this.product_from_correct_location){
                this.modal_type = "excedent"
                this.alert_modal = true;
                return;
            }

            // Only products from location
            this.products.forEach( x => {
                if(x.location_barcode == this.current_location_barcode){
                    if(x.discount < x.total) all_scanned_from_location = false;
                }
            });

            if(all_scanned_from_location == true) this.current_location_barcode = "";
            this.all_scanned = all_are_scanned;
        },
        cancel(){
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;

            this.$http.delete(this.$store.state.chronosApi + 'v1/' + this.controller_name + '/release_orders/' + this.orders_code, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.$store.state.loaderProps.visible = false;
                response;
            })
            .catch((error) => {
                this.$store.state.loaderProps.visible = false;
                console.log(error)
            })

            this.$router.go(-1);
        },
    },
    watch:{
        '$route.params.shipping_service': function() {
            location.reload();
        }
    }
}
</script>

<style lang="css">
</style>