<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5" justify="center" align="center">
                    <v-col cols="4" align="start" justify="start">    
                        <h3 class="azul--text text-uppercase">{{ $t('carrier_details.details') }}</h3>
                    </v-col>
                    <v-col cols="2" align="end" justify="end">
                        <v-btn class="right white--text" color="amarillo" @click="cancel" >
                            {{ $t('carrier_details.go_back') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <!-- <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0">
                            <label style="font-size: 16px; font-weight: bold;" class="azul--text">{{$t('carrier_details.general_data')}}</label>
                        </v-col>
                    </v-row> -->
                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('carrier_details.arrival_date')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.carrier.arrival_date }} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('carrier_details.arrival_time')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.carrier.arrival_time }} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('carrier_details.entry_date')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.carrier.entry_date}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('carrier_details.entry_time')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.carrier.entry_time}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('carrier_details.departure_date')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.carrier.departure_date}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('carrier_details.departure_time')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.carrier.departure_time}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('carrier_details.carrier_line')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.carrier.carrier_line}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('carrier_details.drivers_name')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.carrier.drivers_name}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('carrier_details.tract_number')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.carrier.tract_number}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('carrier_details.tract_plates')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.carrier.tract_plates}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('carrier_details.container_number')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.carrier.container_number}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('carrier_details.box_plates')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.carrier.box_plates}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('carrier_details.seal_number')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.carrier.seal_number}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('carrier_details.waybill')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.carrier.waybill}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center" >
                        <v-col cols="6" class="py-0 px-6">
                            <v-row class="px-0 py-2 my-0" justify="center" align="center" style="border-bottom:1px solid black;">
                                <label>{{ $t('carrier_details.observations')}}</label>
                                <v-spacer></v-spacer>
                                <label> {{ this.carrier.observations}} </label>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="5" class="py-0 px-3">
                            <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :disabled="true" :clearable="false" v-model="carrier.identification_photo" accept="image/*" capture="camera" :label="$t('edit_carrier.identification_photo')"></v-file-input>
                        </v-col>
                        <v-col cols="1" >
                            <v-row justify="end" align="end">
                                <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('identification')" :disabled="identification_file == false"><v-icon small>download</v-icon></v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="5" class="py-0 px-3">
                            <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :disabled="true" :clearable="false" v-model="carrier.vehicle_photo_front" accept="image/*" capture="camera" :label="$t('edit_carrier.vehicle_photo_front')"></v-file-input>
                        </v-col>
                        <v-col cols="1">
                            <v-row justify="end" align="end">
                                <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('vehicle_photo_front')" :disabled="vehicle_photo_front == false"><v-icon small>download</v-icon></v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="5" class="py-0 px-3">
                            <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :disabled="true" :clearable="false" v-model="carrier.vehicle_photo_back" accept="image/*" capture="camera" :label="$t('edit_carrier.vehicle_photo_back')"></v-file-input>
                        </v-col>
                        <v-col cols="1">
                            <v-row justify="end" align="end">
                                <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('vehicle_photo_back')" :disabled="vehicle_photo_back == false"><v-icon small>download</v-icon></v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="5" class="py-0 px-3">
                            <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :disabled="true" :clearable="false" v-model="carrier.vehicle_photo_side" accept="image/*" capture="camera" :label="$t('edit_carrier.vehicle_photo_side')"></v-file-input>
                        </v-col>
                        <v-col cols="1">
                            <v-row justify="end" align="end">
                                <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('vehicle_photo_side')" :disabled="vehicle_photo_side == false"><v-icon small>download</v-icon></v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="5" class="py-0 px-3">
                            <v-file-input prepend-icon="cloud_upload"  class="cloud_upload" :disabled="true" :clearable="false" v-model="carrier.vehicle_photo_seal" accept="image/*" capture="camera" :label="$t('edit_carrier.vehicle_photo_seal')"></v-file-input>
                        </v-col>
                        <v-col cols="1">
                            <v-row justify="end" align="end">
                                <v-btn outlined color="azul" small class="px-0 mx-1" style="min-width:25px !important" @click="getFile('vehicle_photo_seal')" :disabled="vehicle_photo_seal == false"><v-icon small>download</v-icon></v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
import FileSaver from 'file-saver';

export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            menu: false,
            entry_time_modal: false,
            identification_file: false,
            vehicle_photo_front: false,
            vehicle_photo_back: false,
            vehicle_photo_side: false,
            vehicle_photo_seal: false,
            currentDelete: "",
            current_files: [],
            carrier: {
                arrival_date: "",
                arrival_time: "", 
                carrier_line: "", 
                drivers_name: "", 
                tract_number: "", 
                tract_plates: "", 
                container_number: "", 
                box_plates: "", 
                seal_number: "", 
                waybill: "",
                observations: "",
                identification_photo: [],
                vehicle_photo_front: [],
                vehicle_photo_back: [],
                vehicle_photo_side: [],
                vehicle_photo_seal: [],
                warehouse_id: this.$store.state.warehouseId,
            }, 
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.carriers";
    },
    methods: {
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");

            this.$http.get(this.$store.state.chronosApi + 'carriers/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.current_files = [];
                response.data = response.data.data.attributes
                this.carrier = response.data;

                if(this.carrier.identification_photo != null) {
                    this.identification_file = true;
                    this.current_files.push(this.carrier.identification_photo);

                    this.carrier.identification_photo = new File(["foo"], this.carrier.identification_photo.filename, {
                        type: "text/plain",
                    });
                }
                    
                if(this.carrier.vehicle_photo_front != null) {
                    this.vehicle_photo_front = true;
                    this.current_files.push(this.carrier.vehicle_photo_front);

                    this.carrier.vehicle_photo_front = new File(["foo"], this.carrier.vehicle_photo_front.filename, {
                        type: "text/plain",
                    });
                }

                if(this.carrier.vehicle_photo_back != null) {
                    this.vehicle_photo_back = true;
                    this.current_files.push(this.carrier.vehicle_photo_back);

                    this.carrier.vehicle_photo_back = new File(["foo"], this.carrier.vehicle_photo_back.filename, {
                        type: "text/plain",
                    });
                }

                if(this.carrier.vehicle_photo_side != null) {
                    this.vehicle_photo_side = true;
                    this.current_files.push(this.carrier.vehicle_photo_side);

                    this.carrier.vehicle_photo_side = new File(["foo"], this.carrier.vehicle_photo_side.filename, {
                        type: "text/plain",
                    });
                }

                if(this.carrier.vehicle_photo_seal != null) {
                    this.vehicle_photo_seal = true;
                    this.current_files.push(this.carrier.vehicle_photo_seal);

                    this.carrier.vehicle_photo_seal = new File(["foo"], this.carrier.vehicle_photo_seal.filename, {
                        type: "text/plain",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        cancel(){
            this.$router.go(-1);
        },
        getFile(type){
            this.$store.state.loaderProps.visible = true;
            this.current_files.forEach(x => {
                if(x.filename.includes(type)){    
                this.$store.state.loaderProps.visible = true;
                    this.$http.get(this.$store.state.chronosApi + 'getSingleFile/' + x.id, { responseType: 'blob'})
                    .then(response => {
                        FileSaver.saveAs(response.data, x.filename)
                        this.$store.state.loaderProps.visible = false;
                    })
                }
            })

            this.$store.state.loaderProps.visible = false;
        },
    },
    watch:{
    }
}
</script>

<style lang="css">
</style>