export const CustomsPatchesTypes = {
    CLEAR : '[CUSTOMS_PATCHES] CLEAR',
  
    CREATE_REQUEST : '[CUSTOMS_PATCHES][CREATE] REQUEST',
    CREATE_SUCCESS : '[CUSTOMS_PATCHES][CREATE] SUCCESS',
    CREATE_FAILURE : '[CUSTOMS_PATCHES][CREATE] FAILURE',
  
    DELETE_REQUEST : '[CUSTOMS_PATCHES][DELETE] REQUEST',
    DELETE_SUCCESS : '[CUSTOMS_PATCHES][DELETE] SUCCESS',
    DELETE_FAILURE : '[CUSTOMS_PATCHES][DELETE] FAILURE',
  
    LIST_REQUEST : '[CUSTOMS_PATCHES][LIST] REQUEST',
    LIST_SUCCESS : '[CUSTOMS_PATCHES][LIST] SUCCESS',
    LIST_FAILURE : '[CUSTOMS_PATCHES][LIST] FAILURE',
  
    DETAIL_REQUEST : '[CUSTOMS_PATCHES][DETAIL] REQUEST',
    DETAIL_SUCCESS : '[CUSTOMS_PATCHES][DETAIL] SUCCESS',
    DETAIL_FAILURE : '[CUSTOMS_PATCHES][DETAIL] FAILURE',
}